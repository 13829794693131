import {IonContent,IonModal} from '@ionic/react';
import { heart } from 'ionicons/icons';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import RoomIcon from '@material-ui/icons/Room';
import Itemlist from '../../collections/RecommendCard';
import SaveItemActions, { SaveItemActionsState } from '../../common/SaveItem/Actions';
import ListIcon from '@material-ui/icons/List';
import { Card, Icon, Image } from "semantic-ui-react";
import { Link, useRouteMatch } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect,SyntheticEvent, useState ,useRef} from 'react';
import styled from 'styled-components';
import Collection from '../../../model/Collection';
import { LatLng, LatLngBounds, Layer } from 'leaflet';
import Item from '../../../model/Item';
import { getCollections, updateCollection } from '../../../util/API';
import { IonSpinner } from '@ionic/react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from 'react-leaflet';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { getUser } from '../../authentication/CurrentUser';
import {ListItem,ListItemText} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
// import Button from '../../common/Button';
import AddIcon from '@material-ui/icons/Add';

import ItemsResponse from '../../../model/ItemsResponse';
import { queryItems } from '../../../util/API';
import useQuery, { QUERY_KEY } from '../../../util/useQuery';
import useTranslations from '../../../util/useTranslation';
import useToken from '../../../util/useToken';
import Header from '../../common/Header';
import SearchResultItemsList from '../../search/ItemsList';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup'
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import UploadNewItem from '../../upload-new-Item/UploadNewItem';
import DnsIcon from '@material-ui/icons/Dns';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    display:'block',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const ChooseSampleContentItem: React.FC<{
  item?: Item;
  showModal: boolean;
  multiSelect?: boolean;
  closeModal: (result : any) => void;
  onSelect: (item: Item) => any;
}> = ({ item, showModal, closeModal, multiSelect, onSelect }) => {
  const classes = useStyles();

  // Search
  const { resetAllFilters,getLanguages,getLocations,getOther, getQuery,isActive, setQuery, searchParams, filtersApplied, setGeoLocation, getGeoLocation } =
  useQuery(QUERY_KEY);
  const [t] = useTranslations('search');
  const [send,setSend]=useState<any>([]);
  const { toggleFilter} = useQuery(send?send:null);
  const geolocationQuery = getGeoLocation();
  const [cl] = useTranslations('collections');
  const divRef = useRef<any>(null);
  const [markerIndex, setMarkerIndex] = useState<any>(null)
  const [searchResult, setSearchResult] = useState<ItemsResponse | null>(null);
  const [results, setResults]=useState<any>([]);
  const [resultsnew, setResultsNew]=useState<any>([]);
  const paramStr = searchParams().toString();
  const { path } = useRouteMatch();
  const [mapRef, setmapRef] = useState<any>(null);
  const [Conbined,setConbined]=useState<any>([])
  const [map, setMap] = useState<boolean>(false);
  const [toggle,settoggle] = useState<boolean>(false);
  const [collections, setCollections] = useState<Collection[]>([]);
  const [itemId, setitemId] = useState<number>(0);
  const [searchIn, setSearchIn] = useState<string>('');
  const [selectedSidebar, setSelectedSidebar] = useState('ExploreContent');
  const [spinner, setSpinner] = useState<boolean>(false);
  let searchedResults: any = [];
  const [chooosenCount, setChooosenCount] = useState<boolean>(false);
  const [chooosenLanguages, setChooosenLanguages] = useState<any>([]);
  const [chooosenLocations, setChooosenLocations] = useState<any>([]);
  const [chooosenReuse, setChooosenReuses] = useState<any>([]);
  const [value, setValue] = useState(getQuery());
  const [Language,setLanguage]=useState<any>([]);
  const [Location,setLocation]=useState<any>([]);
  const [Reuse,setReuse]=useState<any>([]);
  // const [showMap, setShowMap] = useState(geolocationQuery == 'true' ? true : false);
  const [showUploadItem, setShowUploadItem] = useState(false)
  const [token,setToken] = useToken();
  const { clear, items: filterItems } = t('filter', {
    returnObjects: true,
  }) as {
    clear: string;
    items: Array<{ title: string; key: string; values: string[] }>;
  };

  const [saveActionsState, setSaveActionsState] =
    useState<SaveItemActionsState>({
      item,
      event: undefined,
      dismiss: () =>
        setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });
  const query = getQuery();

  useEffect(() => {
    const fetchCollections = async () => {
      setSpinner(true);
      const {
        data: { collections },
      } = await getCollections(token, true);

      setSpinner(false);
      setCollections(collections);
    };

    fetchCollections();

    let langugeList = filterItems.filter((i) => i.key == t('filtertype.language')).map((languages) =>languages.values);
    setLanguage(langugeList[0])
    
    let locationList = filterItems.filter((i) => i.key == t('filtertype.type')).map((lcn) =>lcn.values)
    setLocation(locationList[0])

    let ReuseList = filterItems.filter((i) => i.key == t('filtertype.re_use')).map((reuse) =>reuse.values)
    setReuse(ReuseList[0])

    if(getLanguages())
    {
      setChooosenLanguages(getLanguages().split(','))
    }
    if(getLocations())
    {
      setChooosenLocations(getLocations().split(','))
    }
    if(getOther())
    {
      setChooosenReuses(getOther().split(','))
    }
  
  }, []);
  // console.log("call results useeffect",searchResult);
  // var results = searchResult?.results.map((i:any)=>({...i,choose:false})) ?? [];
  

  const [state, setState] = React.useState<any>({
    language: '',
    location: '',
    period: '',
    query: '',
  });
  const [searchString, setSearchString] = useState<any>(getQuery())


  useEffect(() => {
    let query = "";
    for(const property in state){
      var nwstate = ""
      if(property == 'location'){
        nwstate = "media-type"
      }
      if(property == 'period') {
        nwstate = "reuse"
      }
        if(property !== 'query'){
          for(var i=0;i<state[property].length;i++)
          {
            if(query){
                if(state[property]){
                    // query += '&'+property+'='+ state[property][i];
                    query += `&${nwstate ? nwstate : property}=${state[property][i]}`;
                } 
            } else {
                if(state[property]){
                    // query += property+'='+state[property][i]
                    query += `&${nwstate ? nwstate : property}=${state[property][i]}`;  
                } 
            }
          }
        }
        else{
        if(query){
            if(state[property]){
                // query += '&'+property+'='+ state[property];
                query += `&${nwstate ? nwstate : property}=${state[property]}`;  
            } 
        } else {
            if(state[property]){
                // query += property+'='+state[property]
                query += `&${nwstate ? nwstate : property}=${state[property]}`;  
            }
            
        }
      }
    }

  
      if(state)
      {
        toggleFilter();
      }
  }, [state])
  useEffect(()=>{
    console.log("query2345",geolocationQuery)
    const fetchResults = async () => {
      setSpinner(true);
      const user = getUser();
      const source = user ? user.organization : "";
      console.log("paramStr",paramStr)
      const { data } = await queryItems(paramStr, source);
      var res = data?.results.map((i:any)=>({...i,choose:false})) ?? [];
      console.log("data",res)
      // setResults(res)
      console.log("Results are",res)
     
      if(resultsnew.length > 0)
      {
        const newResults = res.map(x => {
          const item = resultsnew.find(({ id }) => id === x.id);
          return item ? item : x;
        });
        setResultsNew(newResults)
        setResults(newResults)
      }
      else
      {
        setResults(res)
      };

      setSpinner(false);
      setSearchResult(() => data);
    };

    if (query) {
      fetchResults();
    } else {
      setSearchResult(() => null);
    }

  },[paramStr])

  useEffect(() => {
    if(markerIndex > 0){

      let Item : any = searchedResults[markerIndex-1]
      mapRef.flyTo(new LatLng(Item.location[0].lat,Item.location[0].lon),13)
    }
  },[markerIndex]);

  const ResizeMap = (props: any) => {
    const { bounds } = props;
    const map1: any = useMap();

    map1._onResize();
    map1.setZoom(2);
    map1.on('moveend', function () {});
    return null;
  };

  const showSaveActions = (e: SyntheticEvent) =>{
    setSaveActionsState((prev) => ({
      ...prev,
      event: e.nativeEvent,
    }));
  }

  const PointMarker: React.FC<{
    item: any;
    index: any;
    buttonOnPress?:(choose : any)=>void;
  }> = ({item, index, buttonOnPress}) => {
    return(
      <Marker
        position={[item.location[0].lat, item.location[0].lon]}
        key={item.id}
        
      >
        <Popup closeOnClick={false}>

        <Mapcard>
          <div className="map-card">
          <Card
            image={
              (
                <>
                <Image src={item.preview_url}/>
                {item.location && (
                  <a
                    target="_blank"
                    href={`http://www.openstreetmap.org/?mlat=${item.location[0].lat}&mlon=${item.location[0].lon}&zoom=17&layers=M`}
                    // alt={item.title}
                    title="See at openstreetmap.org"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer noopener"
                  >
                    <Icon
                      size="large"
                      className="location-marker"
                      name="map marker alternate"
                      inverted
                      color="grey"
                    />
                  </a>
                )}
                </>
              ) || "http://placehold.it/200"
            }
            header={
              <Link
                to={{
                  pathname: `/app/search/items/${encodeURIComponent(item.id)}`,
                  state: { item: item },
                }}
                className="header"
                title={item.title}
              >
                {item.title}
              </Link>
            }
            title={item.description}
            description={item.description}
            extra={item.tags?.map((tag:any, i:any, { length }:any) => (
              <React.Fragment key={i}>
                <Link
                  title={`Search similar by tag: ${tag}`}
                  to={`/search?query=${tag}&page=1`}
                >
                  {tag}
                </Link>
                {i + 1 < length ? ", " : ""}
              </React.Fragment>
            ))}
          />
            <div className="card-footer">
            <Styled>
            <Button className={item.choose == false?'chooseThisItem-btn':"chooseThisItem-btn-bg"}
              
              onClick={() => buttonOnPress ? buttonOnPress(item.choose = !item.choose) : null}>
                  <div className={item.choose == false?"tag_text":"tag_white"}>
                  {t('results.choose')}
                  </div> 
               </Button>
            </Styled>
              
              <Link
              className='readmore-link'
              to={`/app/search/items/${encodeURIComponent(item.id)}`}
            >
              {t('results.more')}
            </Link>
            </div>        
          </div>
          </Mapcard>
        </Popup>
      </Marker> 
    )
  }
  
  const renderMap = () => {

    searchedResults = results.filter(({location} : any) => location && (location.length > 0));
    const bounds = (searchedResults.length && new LatLngBounds(searchedResults.reduce((acc: any , {location:[{lat, lon}] } : any) => acc.push([lat, lon]) && acc, []))) || undefined;
    console.log("searchedResults",bounds)

    return (
      bounds && (
        <div className='map-main-wrap'>
          <div className='map-img' style={{ borderRadius: '20px' }}>
            {
              <MapContainer
                whenCreated={setmapRef}
                className='map-content'
                bounds={bounds}
                // center={[43.38621, -79.83713]}
                zoom={12}
                minZoom={3}
                dragging={true}
                scrollWheelZoom={true}
              >
                <ResizeMap bounds={bounds} />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                {/* <MyComponent /> */}
                {console.log("dfghbv679",searchedResults)}
                {searchedResults.map((item: any, index: any) => (
                  <PointMarker item={item} index={index} buttonOnPress={(bool) => {
                    let newarray = [...results];
                   newarray[index]["choose"] = bool;
                   setResultsNew(newarray);
                    // setResults(newarray)
                  }} />
                ))}
              </MapContainer>
            }
          </div>
        </div>
      )
    );
  };


  const handleChange = (events: any) => {
    const newValue:any=[];
    const name = events.target.name;
    const value1 = events.target.value
    console.log("events is",value1)   
    if(value1)
    {
      setSend([{key:name,value:events.target.value}])
    }
    else
    {
      setQuery('');
      setSend([]);
      setChooosenLanguages([]);
      setChooosenReuses([]);
      setChooosenLocations([]);
    }
   
    if(!multiSelect){
      if(events.target.value && events.target.value.length){
        newValue.push(events.target.value[events.target.value.length-1]);   
      }
    } else {
      newValue.push(events.target.value); 
    }
   
    if (name == 'query' && events.key === 'Enter') { 
      // events.preventDefault();
      // return false;
        setState({
            ...state,
            [name]: events.target.value,
          });     
        setQuery(events.target.value) 
      }
    if(name !== 'query'){
        setState({
            ...state,
            [name]: newValue,
          });   
      }
 
  };

  const searchClick = () => {
    setState({
      ...state,
      ['query']: searchString,
    });
    setQuery(searchString)
  }

  const _filteredData=()=>
{
 
  var choosenCollectionItems:any=[];
  var combine1:any=[];
  for(var i=0;i<collections.length;i++)
  {
    if(collections[i].item_details && collections[i].item_details.results && collections[i].item_details.results.length)
    {
      var items=collections[i].item_details.results.filter((item)=>item.choose==true)
      choosenCollectionItems.push(items)
      // setChooosenData(choosenCollectionItems.filter((i:any)=>i.length>0))
    }
  }
  var val1=choosenCollectionItems.filter((i:any)=>i.length>0)
  var val2=results.filter((i:any)=>i.choose==true)
  for(var i=0;i<val1.length;i++)
  {
     combine1.push(...val1[i].map((fg:any)=>fg));
  } 
  
  const combined=val2.concat(combine1);
  const ids = combined.map((o:any) => o.id)
  const filtered = combined.filter(({id}:any, index:number) => !ids.includes(id, index + 1))
  setConbined(filtered)
  closeModal(filtered) 
}
 
const selectLanguage = (e:any) =>{
  if(!multiSelect){
    if(e && e.length){
      setChooosenLanguages([e[e.length-1]]);
    }else{
      setChooosenLanguages(e);
    }
  } else {
    setChooosenLanguages(e);
  }
}
const selectLocation = (e:any) =>{
  // setChooosenLocations(e)
  if(!multiSelect){
    if(e && e.length){
      setChooosenLocations([e[e.length-1]]);
    }else{
      setChooosenLocations(e);
    }
  } else {
    setChooosenLocations(e);
  }
}
const selectReuse = (e:any) =>{
  // setChooosenReuses(e)
  if(!multiSelect){
    if(e && e.length){
      setChooosenReuses([e[e.length-1]]);
    }else{
      setChooosenReuses(e);
    }
  } else {
    setChooosenReuses(e);
  }
}

const ListViewSelected=(itemselected:any,Indx:any)=>
{
  let newarray = [...results];
  newarray[Indx]["choose"] = itemselected.choose;
  setResultsNew(newarray)
  setResults(newarray)
  console.log("newResultsnewResultsnewResults",newarray)
}
const spnr = {
  top: "60%"
}
const ViewData=()=>
{
  return(
    <div className={classes.root}>
    {spinner && (<div className="spinner" style={spnr}>
    <IonSpinner color="primary" />
     </div>)}
      <CssBaseline/>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <div className='Top-bar'>
   < DnsIcon className="menu-toggle" onClick={()=>settoggle(!toggle)} />
            <Typography variant='h6' noWrap>
              {t('choose.text')}
            </Typography>
            {
              /*showModal==*/ true?
              <ClearIcon onClick={() => 
              // closeModal(results)
              _filteredData()
              }></ClearIcon>
              :''
            }
          </div>
      
        </Toolbar>
      </AppBar>

      <div className={toggle==true? "side-menu" : "show-hide" } >
      <Drawer
        // className={classes.drawer}
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <Button
            color='primary'
            className={classes.button}
            endIcon={<AddIcon />}
            onClick={() => setShowUploadItem(true)}
          >
             {t('upload.text')}
          </Button>
          <div className='sidebar-List'>
            <List key="mainList">
              <ListItem
               key="exploreContent"
                selected={
                  selectedSidebar == 'ExploreContent' ? true : false
                }
                onClick={() => setSelectedSidebar('ExploreContent')}
              >
                <ListItemText primary={t('header.headline')} />
              </ListItem>
              <ListItem
                selected={(selectedSidebar == 'MyCollections' && !itemId) ? true : false}
                key="collection"
                onClick={() => {
                  setSelectedSidebar('MyCollections');
                  setitemId(0);
                }}
              >
                <ListItemText primary={cl('header.headline')} />
              </ListItem>
              <List key="subList" className='sub-menu'>
              {collections.map((collection, i) => {
                return (
                  collection &&
                  collection.title && (
                    <React.Fragment key={collection.id}>
                      <ListItem
                        selected={(selectedSidebar == 'MyCollections' && itemId == collection.id) ? true : false}
                        onClick={(e:any) => {setitemId(collection.id);setSelectedSidebar('MyCollections')}}
                        key={i.toString()}
                      >
                        <ListItemText primary={collection.title} />
                      </ListItem>
                      </React.Fragment>        
                  )
                );
              })}
              </List>
            </List>
          </div>
        </div>
      </Drawer>
      </div>

      <main className={classes.content}>
        <Toolbar />
        {/*------------------------------------- Explore Content ------------------------------------- */}
        {selectedSidebar == 'ExploreContent' && (
          <div className='exploreContent'>
            <div className='wide'>
              <Header>
                <h2>{t('header.headline')}</h2>
               
                <div className="search-wrapper">
                  <div className={classes.search}>
                    {/* <SearchIcon onClick={()=>console.log("You Clicked")}/> */}
                    <IconButton style={{top:"12px"}} className={classes.searchIcon} onClick={()=> console.log("Clicked")}>
                      <SearchIcon className="search"/>  
                    </IconButton> 
                    </div>
                    <InputBase
                      placeholder={t('input.placeholder')}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      name='query'
                      value={searchString}
                      onKeyDown={(e)=>handleChange(e)}
                      onChange={(e) => {handleChange(e); setSearchString(e.target.value)}}
                    />
                      <ArrowForwardIcon className="arow-btn" onClick={() => searchClick()}/>  
                  </div>
             
              </Header>
              <div className='filter'>
                <Filters>
                  <FormGroup className={classes.formControl}>
                    <div className='filterlabel'>
                      <InputLabel
                        shrink
                        id='demo-simple-select-placeholder-label-label'
                      >
                       {t('filterby.text')}
                      </InputLabel>
                    </div>
                    
                    <div className='select-wrapper'>
                      {console.log("chooosenLanguages",chooosenLanguages)}
                   <MultiSelect placeholder= {t('filterby.language')} name="language"  value={chooosenLanguages}  options={Language} onChange={(e) => {selectLanguage(e.value);handleChange(e)}} />
                   <MultiSelect placeholder={t('filterby.type')}  name="location" value={chooosenLocations} options={Location} onChange={(e) => {selectLocation(e.value);handleChange(e)}} />
                   <MultiSelect placeholder={t('filterby.re_use')} name="period" value={chooosenReuse} options={Reuse} onChange={(e) => {selectReuse(e.value);handleChange(e)}} />
                    </div>
                    <div className='map-list-btn desktopview'>
                          {map == false && geolocationQuery==null ? (
                            <Button
                              onClick={() => {setMap(true);setGeoLocation('true')}}
                              className='switchbutton show-map'
                            >
                              {t('searchexplore.map')}
                              <RoomIcon />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setMap(false)
                                setGeoLocation('')
                              }}
                              className='switchbutton list-view'
                            >
                              {t('searchexplore.list')}
                              <ListIcon />
                            </Button>
                          )}
                    </div>
                  </FormGroup>
                </Filters>
            
              </div>
              <div className={ searchResult != null?'map-list-btn mobileview':'d-none mobileview'}>
                          {map == false && geolocationQuery==null? (
                            <Button
                              onClick={() => {setMap(true);setGeoLocation('true')}}
                              className='switchbutton show-map'
                            >
                              {t('searchexplore.map')}
                              <RoomIcon />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                setMap(false);
                                setGeoLocation('')
                              }}
                              className='switchbutton list-view'
                            >
                              {t('searchexplore.list')}
                              <ListIcon />
                            </Button>
                          )}
                    </div>
                    {console.log("resultsresults",results)}
              {
                searchResult != null?
                map==false && geolocationQuery==null?
                <SearchResultItemsList items={results} t={t} fromChooseContent={true} onSelectContent={(item:any,indx:any)=>{onSelect(item);ListViewSelected(item,indx)}}/>
                :
                <div className="map-content-wrapper">
              <div>
                {renderMap()}
                </div>
                <div className="mapview-card">
                  {/* <Itemlist t={t} items={searchedResults}  mapCard={true} itemClickedIndex={(i) => setMarkerIndex(i+1)}></Itemlist>  */}
                  <SearchResultItemsList items={searchedResults} mapCard={true} itemClickedIndex={(i) => setMarkerIndex(i+1)} t={t} fromChooseContent={true} onSelectContent={(item:any,indx:any)=>{onSelect(item);ListViewSelected(item,indx)}}/>
                  {/* {!!itemsWithLocation.length && (
                    <ViewedInfo>
                      <div className='info'>
                        {t('viewed.info', {
                          count: itemsWithLocation.length,
                          total: searchResult?.total_count,
                        })}
                      </div>
                      <LoadMoreButtonWrapper>
                        <Button text={t('viewed.more')} color='warning' />
                      </LoadMoreButtonWrapper>
                    </ViewedInfo>
                  )} */}
                </div>
                </div>
                :null
                
              }
            </div>
          </div>
        )}



        {/*-------------------------------------  My Collection  ------------------------------------- */}
        {selectedSidebar == 'MyCollections' && (
          <div className='myCollection'>
            <div className='wide'>
              {collections.filter((i) => i.id == itemId).length > 0
                ? collections
                    .filter((i) => i.id == itemId)
                    .map((item)=>
                        <SearchResultItemsList t={t} items={item.item_details?.results} fromChooseContent={true} onSelectContent={onSelect}></SearchResultItemsList>           
                      )
                : collections.map((collection) => 
                      <SearchResultItemsList t={t} items={collection.item_details?.results} fromChooseContent={true} onSelectContent={onSelect}></SearchResultItemsList>
                  )}
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

  return (
  <div>
  {
   showModal==true?
    <IonContent>
      <Modal isOpen={showModal} onDidDismiss={() => _filteredData()}>
      {!showUploadItem && (ViewData())}
      {showUploadItem && (<UploadNewItem viewUploadModal={showUploadItem} closeUploadModal={() => setShowUploadItem(false)}/>)}
      </Modal>
    </IonContent>
    :
    <Container>
      {!showUploadItem && (ViewData())}
      {showUploadItem && (<UploadNewItem viewUploadModal={showUploadItem} closeUploadModal={() => setShowUploadItem(false)}/>)}
    </Container>
  }
  </div>
  );
};


const Container = styled.div`
--height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  --font-family: "Blooming Elegant", sans-serif;
  
  a,button,select,li,svg{
    cursor: pointer;
  }

  .card-footer{
    display: flex;
    flex-direction: column;
  }
  .map-card .readmore-link{
    text-align: center;
    font-size: 14px;
    color: #0849ff;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
  }
  .card-footer>div{
    border-radius: 50px !important;
  }
 
  .card-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    width:100%;
  }
  .card-footer span{
    text-align: center;
      margin-top: 10px;
      color: #0749ff;
      font-weight: 600;
      font-size: 15px;
  }
  .card-footer button{
  border-color: #0749ff;
    margin-top: 15px;
    border-radius: 50px;
  }

  .card-footer button span{
  margin-top: 0;
  }


  .mobileview{
    display:none;
  }
  .d-none.mobileview{
    display:none;
  }
  .Top-bar svg:nth-child(1){
    display:none
  }
  .modal-wrapper.sc-ion-modal-md {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    opacity: 0.01;
    width: 100vh;
    height: 80vh;
    background-color: #fefefe;
  }
  .Top-bar {
    display: flex;
   justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .MuiButton-containedPrimary {
    color: rgb(0 0 0 / 87%);
    background-color: #fac807;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 15px auto !important;
    text-transform: capitalize;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    height: auto !important ;
  }

  .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding-top: 14px;
    background-color:#f7f7f7;
    height:100vh;
 }
  .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) .MuiButton-textPrimary {
    background-color: #fac807;
    color: #000;
    font-weight: 700;
    /* padding: 5px 20px; */
    border-radius: 53px;
  }
 

  .sidebar-List .MuiListItem-gutters {
    padding-left: 38px;
  }

  .sidebar-List .MuiListItem-button {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .sidebar-List .MuiListItem-button:hover {
    // background-color: #dee4f7;
    background-color: #dee4f6;
  }
  .active {
    color: #dee4f7;
    background-color: #dee4f6;
  }

  .sidebar-List .MuiListItem-button:focus-visible {
    background-color: #000;
    color: #fff;
  }
  .MuiTypography-displayBlock {
    display: block;
    font-size: 15px;
    font-weight: 700;
  }
      .sidebar-List .sub-menu .MuiTypography-displayBlock {
    font-weight: unset;
    font-size: 15px;
    text-transform: capitalize;
    color:#000;
  }

  .sidebar-List .sub-menu{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sidebar-List .sub-menu li{
    padding: 3px 17px 7px 38px;
  }

  .sidebar-List .sub-menu li:hover {
    background-color: #dee4f7;
    border-left: 6px solid #fac807;
  }

  .sidebar-List .MuiListItem-button:nth-child(3) .MuiTypography-displayBlock,
  .sidebar-List .MuiListItem-button:nth-child(4) .MuiTypography-displayBlock {
    font-size: 13px;
  }
  .MuiButton-containedPrimary:hover {
   background-color: #303f9f !important;
    color: #fff;
  }

  .select-wrapper {
    margin-bottom: 0;
    margin-top: 0px;
  }
  .select-wrapper span {
    font-weight: bold;
  }

  .search-wrapper{
      position:relative;
      // transform: translate(21%,-105%);
  }

  .search-wrapper .MuiSvgIcon-root{
    position: absolute;
    top: 41%;
    right: 10%;
    z-index: 1;
  }

  .search-wrapper div:nth-child(1) button{
    display: flex;
    padding: 0px 16px;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    // right: 5%;
    // top: 22px;
    left: 12px;
    top: 15px;
 }


  .search-wrapper .MuiInputBase-input {
    width: 100% !important;
    padding: 23px 0 21px 54px;
   // margin-left: -30px;
    margin-right: 40px;
    background-color: #fff;
    // border: 1.5px solid #3f51b5;
    border-radius: 50px;
    font-size: 13px;
    color: #291d1d;
    font-weight: 600;
    box-shadow: 0px 0px 31px 2px #dcdcef

  }


  //----------ExploreContent

  .exploreContent header {
    display: flex;
    flex-direction: column;
   min-height: 130px;
    margin-top: -63px;
  }
      .exploreContent header h2 {
    font-size: 1.7rem;
  }
      .exploreContent header div {
    width: 100%;
    position: relative;
    margin-left: 0px !important;
  }

  .exploreContent .formControl {
    margin: 8px;
    min-width: 100% !important;
    position: relative;
    width: 100% !important;
  }

  .select-wrapper {
    // height: 20px;
   display:flex;
    // transform: translate(23%,-50%);
   // transform: translate(19%,-180%)
  }

  .filterlabel label {
    font-size: 18px;
    font-weight: 700;
  }

  // .select-wrapper select {
  //   margin-top: 2px;
  //   margin-right: -21px;
  //   padding: 7px 42px 7px 19px;
  //   border-radius: 50px;
  //   border: 1px solid #0749ff;
  //   color: #0749ff;
  //   font-weight: 600;
  //   font-size: 14px;
 // }

  .select-wrapper .MuiInput-underline:before {
    content: ' ';
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:focus:not(.Mui-disabled):before {
    border-bottom: none;
    display: none;
  }
  .modal-wrapper.sc-ion-modal-md {
    -webkit-transform: translate3d(0, 40px, 0);
    -webkit-transform: translate3d(0, 40px, 0);
    -ms-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    opacity: 0.01;
    width: 66%;
    height: 80%;
  }

  .wide {
    height: 100%;
  }

  main {
    height: 92% !important;
    margin-top: 64px;
    // overflow-y: auto;
    padding: 24px 15px;
    // background-color: #f1f0f0;
  }

  .ion-page>div{
    height:100%;
  }

  .filter + div {
    padding-top: 18px;
    // padding-left: 15px;
    margin-bottom: 9px;
    margin-top: 20px;
  }
  .map-img{
    display: flex;
    justify-content: flex-start;
}

.map-img .map-content{
  position: relative;
    outline: none;
    height: 958px;
    width: 100%;
    margin-top: 20px;
  // margin-bottom:170px;
   border-radius: 20px;
   height: 958px;
}

.map-img,
.map-img .map-content{
   height: 711px;
  //  width: 100vw;
  //  margin-top: 50px;
  //  margin-bottom:170px;
   border-radius: 20px;
}

  .search-results{
    gap: 4rem;
  }
  .search.md.hydrated{
    font-size: 9px;
  }

  .search-results>div {
    // width: 47% !important;
    /* width: 45% !important; */
    // height: 12.75rem !important;
    /* height: 13.5rem !important; */
    font-size: 1.375rem;
    line-height: 1.2;
  }

  .wide .info {
    margin-bottom: 0 !important;
  }
  .item-title-link {
    font-size: 13px;
   font-size: 14px;
    display: -webkit-box;
   max-width: 172px;
    /* max-height: 15px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .item-preview {
    width: 10rem;
    // height: 8rem;
    object-fit: cover;
    border-radius: 16px;
  }
  .select-wrapper .item-details-header {
    height: auto !important;
  }
  .item-title-wrapper {
    // height: 2.5em !important;
   height: 20% !important;
  }

.item-details.hide .item-details-header.d-flex,
  .item-details.hide .item-details-header.d-flex div{
    display:none !important;
  }

  .search-wrapper {
    margin-top: 25px !important;
  }

  // .filterlabel+.select-wrapper {
  //   transform: translate(16%,-138%);
  // }
  // .filterlabel+.select-wrapper {
  //   transform: translate(0%,-45%);
  // }

  .modal-wrapper.sc-ion-modal-md{
   border-radius: 15px;
  }
span.MuiButton-label{
  text-transform: capitalize;
}
.sidebar-List>ul li:nth-child(1),
.sidebar-List>ul li:nth-child(2){
  text-transform: uppercase;
  color: #aab4ce;
}

.sidebar-List .MuiListItem-root.Mui-selected, 
.sidebar-List .MuiListItem-root.Mui-selected:hover {
  background-color: #dee4f6;
  color: #000;
}


.sidebar-List {
  width: 100%;
  background-color: #f7f7f7;
}

svg.MuiNativeSelect-icon {
  color: rgb(13 60 247);
}

header.MuiPaper-root{
  background-color: #0c3cf7;
  z-index: 99999999;
}

.arow-btn{
  position: absolute;
  top: 27% !important;
  right: 5% !important;
  z-index: 1;
 color: #0c3cf7;
}
    .MuiDrawer-docked {
  background-color: #f7f7f7;
}

.select-wrapper svg {
 display:none
}
.select-wrapper select{
  width: 110px;
  height: 17px;
  margin-top: 2px;
  margin-right: -20px;
  padding: 7px 42px 7px 19px;
  border-radius: 50px;
  border: 1px solid #0749ff;
  color: #0749ff;
  font-weight: 600;
  font-size: 14px;
}
.select-wrapper>div{
  position: relative;
  width: 12vw;
  margin-right: 15px;
  height: 2.15rem;
  border: 1px solid #0c3cf7;
  border-radius: 50px;
}

// .select-wrapper>div:after {
//   content: "";
//   position: absolute;
//   top: 28%;
//   right: 9px;
//   width: 7px;
//   height: 7px;
//   border-width: 0px 1px 1px 0px;
//   -webkit-transform: rotate(
// 46deg
// ) translate(-50%) skew(
// 10deg
// ,
// 10deg
// );
//   -ms-transform: rotate(46deg) translate(-50%) skew(10deg,10deg);
//   transform: rotate(
// 33deg
// ) translate(-50%) skew(
// -12deg
// ,
// 13deg
// );
//   -webkit-transform-origin: left;
//   -ms-transform-origin: left;
//   transform-origin: left;
//   border-style: solid;
//   border-color: #0d3cf7;
// }

.filter .MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; 
  align-items: center;
}

//-----------Model Collection
.myCollection .items>div{
  width: 29.6rem;
  height: 15rem;
  font-size: 1.75rem;
  line-height: 1.2;
}
.myCollection {
  margin-top: -74px;
}
.myCollection main {
  height: 92% !important;
  overflow-y: auto;
}

// New Select 
.newsSelect span+div div:nth-child(2) {
 width:30px;
}
.newsSelect span+div{
  min-height: 33px;
}

.newsSelect svg{
  display:block !important;
fill: #0849ff;
line-height: 1;
stroke: #0849ff;
margin-top: 4px;
}
.newsSelect span+div div:nth-child(1) div{
  border-color: hsl(228deg 94% 51%);
  border-radius: 50px;
}
.switchbutton{
  height: 40px;
  /* width: 166px; */
  margin-left: 18px;
  background-color: #0849FF;
  color: white;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-transform: none;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.switchbutton:hover{
  background-color: #031e6a;
}

.switchbutton.show-map svg{
  height: 28px;
  width: 18px;
  margin-left: 11px;
}
.switchbutton.list-view svg{
  height: 33px;
width: 27px;
  margin-left: 11px;
}
.select-wrapper>div div:nth-child(3) span:before{
  color: #0c3cf7;
  font-size: 11px;
  top: -2px;
  position: relative;
}
.p-multiselect .p-multiselect-label {
  padding: 6px 18px;
  font-size: 14px;
  color: #0c3cf7;
}
 .arow-btn {
  position: absolute;
  top: 27% !important;
  right: 5% !important;
  z-index: 1;
  color: #0c3cf7;
}
.item-details-footer>div{
  display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.search-results{
  /* flex-direction: column; */
}

.mapview-card{
  width: 35%;
}

.mapview-card .search-results {
    gap: 2rem;
}

.map-content-wrapper .mapview-card>div {
  /* height: 38%; */
  height: 92%;
    overflow: auto;
    padding: 20px 30px;
    margin-top: 23px !important;
}
.map-content-wrapper {
    height: 79vh;
}


//--------------- Media Query -------------------
@media only screen and (max-width: 1920px) {
  .item-preview {
    // height: 13rem;
}
 .arow-btn {
  right: 4% !important;
}
}
  @media only screen and (max-width: 1440px) {
    .arow-btn {
      right: 5% !important;
    }
    .filter + div {
      padding-top: 18px;
      height: 355px;
    }
    .search-results .sc-hHEiqL {
      width: 18rem;
      height: 12.75rem !important;
      font-size: 13px;
      line-height: 1px;
    }
    .search-results .sc-hHEiqL .shadow {
      display: flex;
      flex-direction: column;
    }
    .item-preview {
      width: 10rem !important;
      // height: 160px !important;
      // height: 18vh;
    object-fit: cover;
    }
    .item-details-header {
      height: 0;
    }
    .item-title {
      line-height: 14px;
      font-size: 12px;
      // text-align: center;
    }
    .item-title-wrapper {
      margin-bottom: 0em;
    }
    main {
      // height: 79% !important;
      // overflow-y: auto;
    }
    .wide .info {
      margin-bottom: -2px !important;
    }
    .makeStyles-root-28 {
      height: 100%;
    }
    .exploreContent {
      height: 100%;
    }
    .ion-page:first-child {
      height: 100% !important;
    }
    .MuiDrawer-paperAnchorDockedLeft {
      height: 100% !important;
    }
    .modal-wrapper.sc-ion-modal-md{
      width: 85%;
      height: 94%;
    }
    .exploreContent header .search-wrapper{
      margin-top: 29px !important;
    }
    .item-details-description {
      font-size: 19px;
      height: 70px;
      display: -webkit-box;
      /* max-width: 200px; */
      // max-width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top:15px;
    }
    .wide .footer{
      position: absolute;
      bottom: 0;
    width: 100%;
    background-color: #e6e8ed;
    padding: 13px 0 0;
    }
    .search-results>div {
      /* width: 100% ;  */
      /* width: 25.7vw; */
    /* margin-right: 30px !important; */
      /* height: 12rem !iportant; */

    }
    .item-title-wrapper {
      height: 20% !important;
      margin-top: 9px !important;
      font-size: 14px;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ion-button {
      font-size: 10px;
    }
    .search-results .shadow{
      gap:1rem;
      padding: 1rem;
    }
   .item-title-link {
    font-size: 21px;
    line-height: 27px;
  }
  .read-more-link{
    font-size: 15px;
  }
  .select-wrapper{
    margin-bottom: 0;
    margin-top: 0px;
  }

 // My Collectio 
 .myCollection .items>div {
    width: 45%;
    height: 100%;
}
.myCollection .item-meta {
  text-transform: uppercase;
  font-size: 0.7rem;
}
.myCollection  .item-details-footer > div{
  display: flex;
  flex-direction: row;
}
myCollection  .item-details-page-link {
  height: 65%;
  font-size: 23px;
}

.map-img, .map-img .map-content{
  padding-left: 40px !important;
}

.mapview-card {
    width: 65%;
}
  }
  @media only screen and (max-width: 1387px) {
    .search-results .sc-hHEiqL {
      width: 97%;
      height: 9.75rem;
    }
    .search-results .sc-hHEiqL .shadow {
      flex-direction: row;
    }
    .item-preview {
      width: 192px;
      // height: 100%;
    }
  }

  @media only screen and (max-width: 1313px) {
    .select-wrapper > div {
      // margin-top: 16px;
      margin-right: 12px;
    }
    .filterlabel label {
      font-size: 20px;
      margin-top: -12px;
    }
    .select-wrapper {
      /* transform: translate(-3%, -48%); */
    }
    .select-wrapper select {
      margin-top: 6px;
      margin-right: -12px;
      padding: 3px 22px 7px 19px;
    }
    .wide .info {
      margin-bottom: -2px !important;
      font-size: 0.8rem;
    }
    // .filterlabel+.select-wrapper {
    //   transform: translate(0%,-138%);
    // }
    .filterlabel{
      margin-bottom: -7px;
    }
    // .item-details-footer > div {
    //   display: flex;
    //   flex-direction: column;
    // }
    .read-more-link {
      /* margin-top: -12px; */
    }
  }


  @media only screen and (max-width: 1199px) {
.map-img,
.map-img .map-content {
    padding-left: 0px !important;
}
.search-results>div{
  width: 100%;
}
  }


  @media only screen and (max-width: 1024px) {
    .modal-wrapper.sc-ion-modal-md {
      width: 85%;
      height: 90%;
    }
    .filter + div {
      padding-top: 45px;
      height: 51vh !important;
    }
  //  .item-details-footer > div {
  //     display: flex;
  //     flex-direction: row;
  //   }
   .search-results>div {
      /* width: 100% !important; */
  }
 .item-title-wrapper,
 .item-title,
 .item-title-link {
    max-width: 100%;
   width:100%;
    text-align: left;
  }
  // My Collectio 
  .myCollection .items>div {
     width: 100%;
 }
.select-wrapper>div {
  width: 18vw;
 }
 .map-list-btn.desktopview{
  margin-top: 22px;
  margin-left: 75px;
 }
 .arow-btn {
  right: 11% !important;
}
  }

  @media only screen and (max-width:991px) {
  .mobileview{
    display:block;
  }
  .map-content-wrapper{
    flex-direction: column;
  }
 .mapview-card {
    width: 100%;
    margin: 34px auto;
    order: 1;
  }
  .mapview-card .search-results {
    display: flex;
    justify-content: center;
}
  
 /* .mapview-card {
    width: 65%;
    
} */
.map-content-wrapper>div:nth-child(1) {
    width: 100%;
}
  .map-list-btn.mobileview{
    position: relative;
  }
  .map-list-btn.mobileview .switchbutton{
  position: absolute;
  top: 105px;
  left: -31px;
  z-index:9999999;
  }
  .filter + div {
    padding-top: 0px;
    height: 0 !important;
}
.filterlabel {
  /* margin-bottom: -25px; */
}
.select-wrapper {
  transform: none;
  display: contents;
}

.map-list-btn.desktopview {
  margin-top: 10px;
  margin-left: 0;
  display:none;
}
.switchbutton{
  margin-left: 0;
}
}

@media only screen and (max-width: 922px) {
.select-wrapper>div:nth-child(3){
margin-top:10px;
 }

}
  @media only screen and (max-width: 768px) {
    .modal-wrapper.sc-ion-modal-md {
      position: relative;
    }
    .select-wrapper > div {
      margin-top: 25px;
      margin-right: 10px;
    }

    .select-wrapper select {
      margin-top: 3px;
      margin-right: -3px;
      padding: 2px 24px 7px 19px;
      font-size: 0.8rem;
    }
    .item-preview {
        height: 200px;
        width: auto !important;
        object-fit: cover;
    }
    .search-results .sc-hHEiqL .shadow {
      flex-direction: column;
    }
    .search-results .sc-hHEiqL {
      width: 97%;
      height: 100% !important;
    }
    .read-more-link {
      margin-top: 0px;
    }
    .select-wrapper{
      margin-bottom: 0;
      text-align: left;
    }
    .side-menu{
      display: block;
    }
    .MuiDrawer-docked {
      position: absolute;
      // display:block
  }
  .Top-bar svg:nth-child(1){
    display:block;
  }
  .search-results .shadow {
    display: flex;
    flex-direction: column;
  }
  .search-results>div{
    height: 30rem !important;
    width: 100% ;
  }
  
  .filter .MuiFormGroup-root{
    flex-direction: inherit !important;
    flex-wrap: inherit !important;
  }
  .item-details-description {
    font-size: 21px;
    /* height: 129px; */
    height: 93px;
    -webkit-line-clamp: 3;
  }
  .item-details-footer {
    height: 4rem;
    align-items:center;
  }

  .filterlabel {
    margin-bottom: -37px;
  }
  .chooseMe{
    text-align: right;
  }
.select-wrapper>div {
    width: 23vw;
}

.item-title-wrapper {
  height: 32% !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter + div.d-none.mobileview{
  display:flex !important;
  }
  .map-list-btn.desktopview{
display:none;
  }
  .select-wrapper>div:nth-child(3){
    margin-top:25px;
     }
     .show-hide {
      position: absolute;
      display: none;
    }
    .MuiDrawer-docked{
      // display: none;
    }
   .mapview-card {
    width: 100%;
    }

}

  @media only screen and (max-width: 754px) {
    .select-wrapper > div {
      margin-top: 25px;
      // margin-right: 0%;
    }
    .select-wrapper select {
      margin-top: 4px;
      margin-right: -3px;
      padding: 4px 24px 8px 19px;
      font-size: 0.7rem;
    }
  
  }

  @media only screen and (max-width: 634px) {
    // .select-wrapper {
    //   margin-bottom: 0;
    //   text-align: center;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }
    .select-wrapper > div {
     margin-top: 3px;
    }
    select-wrapper {
      margin-top: 21px;
      /* transform: translate(-16%, -48%); */
    }
    // .filter + div {
    //   padding-top: 17px;
    //   height: 51vh !important;
    //  padding-left: 5px;
    //   margin-top: 83px;
    // }
    .item-details-footer > div {
      // flex-direction: column;
    }
    .item-details-footer {
      height: 3rem;
      min-height: 3rem;
    }
    .select-wrapper .item-title-wrapper {
      margin-bottom: 34px;
    }
    .select-wrapper>div{
      width: 45vw;
    }
    .select-wrapper {
      flex-wrap: nowrap;
      display: flex;
      transform: none;
      margin-top: 34px;
    }
    .select-wrapper{
      height:100%;
    }
    .filter .MuiFormGroup-root{
        display: flex;
        flex-direction: column !important;
        align-items: baseline;
      }
      .item-details {
        width: 100% !important;
      }
      .chooseMe{
        justify-content: end;
      }
      .select-wrapper{
        flex-direction: column;
        flex-wrap: wrap !important;
      }
      .p-multiselect .p-multiselect-label{
        text-align: initial;
      }
      .select-wrapper>div:nth-child(3){
     margin-top: 3px;
      }
  }
  @media only screen and (max-width: 610px) {
   
  .item-details-footer{
      height: 5rem;
          min-height: 3rem;
    }
  
  }

  @media only screen and (max-width: 538px) {
    .filterlabel label {
      font-size: 20px;
      margin-top: -2px;
      margin: 9px 7px;
    }
    .exploreContent header h2 {
      font-size: 1.3rem;
    }
   .exploreContent header {
      min-height: 98px;
    }
    main {
      padding: 13px;
    }
  
  }

  @media only screen and (max-width: 425px) {
    .MuiTypography-displayBlock {
      font-size: 10px;
    }
    .select-wrapper span {
      font-size: 10px;
    }
    .MuiTypography-displayBlock {
      font-size: 11px;
    }
  
  .item-details-footer>div .chooseMe{
    text-align: right;
  }
  .map-list-btn.mobileview .switchbutton {
    left: -7%;
}
}
`;






const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  --font-family: "Blooming Elegant", sans-serif;
  
  a,button,select,li,svg{
    cursor: pointer;
  }
  .mobileview{
    display:none;
  }
  .readmore-link{
    text-align: center;
    font-size: 14px;
    color: #0849ff;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
  }
  .card-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
  }
  .Top-bar svg:nth-child(1){
    display:none
  }
  .item-details-footer>div .chooseMe{
    text-align: right;
  }

  .card-footer{
    display: flex;
    flex-direction: column;
  }
  .map-card .readmore-link{
    text-align: center;
    font-size: 13px;
    color: #0849ff;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
  }
  .card-footer>div{
    border-radius: 50px !important;
  }
 
  .card-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  
  .switchbutton{
    height: 40px;
    /* width: 166px; */
    // margin-left: 18px;
    background-color: #0849FF;
    color: white;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: none;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .switchbutton:hover{
    background-color: #031e6a;;
  }
  
  .switchbutton.show-map svg{
    height: 28px;
    width: 18px;
    margin-left: 11px;
  }
  .switchbutton.list-view svg{
    height: 33px;
  width: 27px;
    margin-left: 11px;
  }
  .exploreContent header>.search-wrapper{
    margin-top:25px;
  }
  .map-img{
    display: flex;
    justify-content: flex-start;
}

.map-img .map-content{
  position: relative;
    outline: none;
    height: 958px;
    width: 100%;
    margin-top: 20px;
  // margin-bottom:170px;
   border-radius: 20px;
   height: 958px;
}

.map-img,
.map-img .map-content{
   height: 711px;
  //  width: 100vw;
  //  margin-top: 50px;
  //  margin-bottom:170px;
   border-radius: 20px;
}


.filter .MuiFormGroup-root {
display: flex;
flex-wrap: wrap;
flex-direction: row !important; 
align-items: center;
}
  .modal-wrapper.sc-ion-modal-md {
    -webkit-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    opacity: 0.01;
    width: 100vh;
    height: 80vh;
    background-color: #fefefe;
  }
  .Top-bar {
    display: flex;
   justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .MuiButton-containedPrimary {
    color: rgb(0 0 0 / 87%);
    background-color: #fac807;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 15px auto !important;
    text-transform: capitalize;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    height: auto !important ;
  }

  .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding-top: 14px;
    background-color:#f7f7f7;
    height:100vh;
 }
  .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) .MuiButton-textPrimary {
    background-color: #fac807;
    color: #000;
    font-weight: 700;
    /* padding: 5px 20px; */
    border-radius: 53px;
  }
  // .map-main-wrap {
  //   padding-bottom: 20px;
  //   }

  .sidebar-List .MuiListItem-gutters {
    padding-left: 38px;
  }

  .sidebar-List .MuiListItem-button {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .sidebar-List .MuiListItem-button:hover {
    // background-color: #dee4f7;
    background-color: #dee4f6;
  }
  .active {
    color: #dee4f7;
    background-color: #dee4f6;
  }

  .sidebar-List .MuiListItem-button:focus-visible {
    background-color: #000;
    color: #fff;
  }
  .MuiTypography-displayBlock {
    display: block;
    font-size: 15px;
    font-weight: 700;
  }
      .sidebar-List .sub-menu .MuiTypography-displayBlock {
    font-weight: unset;
    font-size: 15px;
    text-transform: capitalize;
    color:#000;
  }

  .sidebar-List .sub-menu{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sidebar-List .sub-menu li{
    padding: 3px 17px 7px 38px;
  }

  .sidebar-List .sub-menu li:hover {
    background-color: #dee4f7;
    border-left: 6px solid #fac807;
  }

  .sidebar-List .MuiListItem-button:nth-child(3) .MuiTypography-displayBlock,
  .sidebar-List .MuiListItem-button:nth-child(4) .MuiTypography-displayBlock {
    font-size: 13px;
  }
  .MuiButton-containedPrimary:hover {
    background-color: #303f9f !important;
    color: #fff;
  }

  .select-wrapper {
    margin-bottom: 0;
    margin-top: 0px;
  }
  .select-wrapper span {
    font-weight: bold;
  }

  .search-wrapper{
      position:relative;
      // transform: translate(21%,-105%);
  }

  .search-wrapper .MuiSvgIcon-root{
    position: absolute;
    top: 41%;
    right: 10%;
    z-index: 1;
  }

  .search-wrapper div:nth-child(1) button{
    display: flex;
    padding: 0px 16px;
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    // right: 5%;
    // top: 22px;
    left: 12px;
    top: 15px;
 }


  .search-wrapper .MuiInputBase-input {
    width: 100% !important;
    padding: 23px 0 21px 54px;
   // margin-left: -30px;
    margin-right: 40px;
    background-color: #fff;
    // border: 1.5px solid #3f51b5;
    border-radius: 50px;
    font-size: 13px;
    color: #291d1d;
    font-weight: 600;
    box-shadow: 0px 0px 31px 2px #f5f5f7;

  }


  //----------ExploreContent

  .exploreContent header {
    display: flex;
    flex-direction: column;
   min-height: 130px;
    margin-top: -63px;
  }
      .exploreContent header h2 {
    font-size: 1.7rem;
  }
      .exploreContent header div {
    width: 100%;
    position: relative;
    margin-left: 0px !important;
  }

  .exploreContent .formControl {
    margin: 8px;
    min-width: 100% !important;
    position: relative;
    width: 100% !important;
  }

  .filterlabel label {
    font-size: 18px;
    font-weight: 700;
  }

  // .select-wrapper select {
  //   margin-top: 2px;
  //   margin-right: -21px;
  //   padding: 7px 42px 7px 19px;
  //   border-radius: 50px;
  //   border: 1px solid #0749ff;
  //   color: #0749ff;
  //   font-weight: 600;
  //   font-size: 14px;
 // }

  .select-wrapper .MuiInput-underline:before {
    content: ' ';
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:focus:not(.Mui-disabled):before {
    border-bottom: none;
    display: none;
  }
  .modal-wrapper.sc-ion-modal-md {
    -webkit-transform: translate3d(0, 40px, 0);
    -webkit-transform: translate3d(0, 40px, 0);
    -ms-transform: translate3d(0, 40px, 0);
    transform: translate3d(0, 40px, 0);
    opacity: 0.01;
    // width: 66%;
    // height: 80%;
  }

  .wide {
    height: 100%;
  }

  main {
    // height: 92% !important;
    height: 88% !important;
    margin-top: 64px;
    overflow-y: auto;
    padding: 24px 15px;
    background-color: #f1f0f0;
  }

  .ion-page>div{
    height:100%;
  }

  .filter + div {
    padding-top: 18px;
    // padding-left: 15px;
    margin-bottom: 9px;
    margin-top: 20px;
  }

  .search-results{
    gap: 2rem;
  }
  .search.md.hydrated{
    font-size: 9px;
  }

  .search-results>div {
    /* width: 45% !important; */
    // height: 12.75rem !important;
    /* height: 13.5rem !important; */
    font-size: 1.375rem;
    line-height: 1.2;
  }

  .wide .info {
    margin-bottom: 0 !important;
  }
  .item-title-link {
    font-size: 13px;
   font-size: 14px;
    display: -webkit-box;
   max-width: 172px;
    /* max-height: 15px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .p-multiselect.p-component.p-inputwrapper{
    // margin-top: 16px !important;
    // margin-right: 12px !important;
  }
  .item-preview {
    width: 10rem;
    // height: 8rem;
    object-fit: cover;
    border-radius: 16px;
  }
  .select-wrapper .item-details-header {
    height: auto !important;
  }
  .item-title-wrapper {
    // height: 2.5em !important;
   height: 20% !important;
  }

.item-details.hide .item-details-header.d-flex,
  .item-details.hide .item-details-header.d-flex div{
    display:none !important;
  }

  .search-wrapper {
    // margin-top: 25px !important;
  }

  
  // .filterlabel+.select-wrapper {
  //   // transform: translate(0%,-45%);
  // }
  .filterlabel{
    // margin-top: -25px;
  }
  .modal-wrapper.sc-ion-modal-md{
   border-radius: 15px;
    width: 85%;
    height: 94%;
  
  }
span.MuiButton-label{
  text-transform: capitalize;
}
.sidebar-List>ul li:nth-child(1),
.sidebar-List>ul li:nth-child(2){
  text-transform: uppercase;
  color: #aab4ce;
}

.sidebar-List .MuiListItem-root.Mui-selected, 
.sidebar-List .MuiListItem-root.Mui-selected:hover {
  background-color: #dee4f6;
  color: #000;
}

.sidebar-List {
  width: 100%;
  background-color: #f7f7f7;
}

svg.MuiNativeSelect-icon {
  color: rgb(13 60 247);
}

header.MuiPaper-root{
  background-color: #0c3cf7;
  z-index: 99999999;
}

.arow-btn{
  position: absolute;
  top: 27% !important;
  right: 6% !important;
  z-index: 1;
 color: #0c3cf7;
}
.MuiDrawer-docked {
  background-color: #f7f7f7;
}

.select-wrapper svg {
 display:none
}
.select-wrapper select{
  width: 110px;
  height: 17px;
  margin-top: 2px;
  margin-right: -20px;
  padding: 7px 42px 7px 19px;
  border-radius: 50px;
  border: 1px solid #0749ff;
  color: #0749ff;
  font-weight: 600;
  font-size: 14px;
}
.select-wrapper>div{
  position: relative;
  width: 10vw;
  margin-right: 15px;
  height: 2.15rem;
  border: 1px solid #0c3cf7;
  border-radius: 50px;
}

//-----------Model Collection
.myCollection .items>div{
  width: 29.6rem;
  height: 15rem;
  font-size: 1.75rem;
  line-height: 1.2;
}
.myCollection {
  margin-top: -74px;
}
.myCollection main {
  height: 92% !important;
  overflow-y: auto;
}

// New Select 
.newsSelect span+div div:nth-child(2) {
 width:30px;
}
.newsSelect span+div{
  min-height: 33px;
}

.newsSelect svg{
  display:block !important;
fill: #0849ff;
line-height: 1;
stroke: #0849ff;
margin-top: 4px;
}
.newsSelect span+div div:nth-child(1) div{
  border-color: hsl(228deg 94% 51%);
  border-radius: 50px;
}

.select-wrapper>div div:nth-child(3) span:before{
  color: #0c3cf7;
  font-size: 11px;
  top: -2px;
  position: relative;
}
.p-multiselect .p-multiselect-label {
  padding: 6px 18px;
  font-size: 14px;
  color: #0c3cf7;
}

.item-details-footer>div{
  display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.mapview-card{
  width: 56%;
}

.mapview-card .search-results {
    gap: 2rem;
}

/* .map-content-wrapper .mapview-card>div {
  height: 38%;
    overflow: auto;
    padding: 20px 30px;
} */

.search-results>div{
  /* width: 26rem !important; */
    width: 33rem !important;
}

.map-content-wrapper .mapview-card>div {
  /* height: 38%; */
  height: 92%;
    overflow: auto;
    padding: 20px 30px;
    margin-top: 50px !important;
}
.map-content-wrapper {
    height: 79vh;
}

//--------------- Media Query -------------------
 
  @media only screen and (max-width: 1450px) {
  .switchbutton {
    margin-top: 0px;
    // margin-left: 87px;
    }
  }

  @media only screen and (max-width: 1440px) {
    .filter + div {
      padding-top: 18px;
      height: 355px;
    }
    .search-results .sc-hHEiqL {
      width: 18rem;
      height: 12.75rem !important;
      font-size: 13px;
      line-height: 1px;
    }
    .search-results .sc-hHEiqL .shadow {
      display: flex;
      flex-direction: column;
    }
    .item-preview {
      width: 10rem !important;
      // height: 160px !important;
      // height: 18vh;
      object-fit: cover;
    }
    .item-details-header {
      height: 0;
    }
    .item-title {
      line-height: 14px;
      font-size: 12px;
      // text-align: center;
    }
    .item-title-wrapper {
      margin-bottom: 0em;
    }
    // main {
    //   height: 79% !important;
    //   overflow-y: auto;
    // }
    .wide .info {
      margin-bottom: -2px !important;
    }
    .makeStyles-root-28 {
      height: 100%;
    }
    .exploreContent {
      height: 100%;
    }
    .ion-page:first-child {
      height: 100% !important;
    }
    .MuiDrawer-paperAnchorDockedLeft {
      height: 100% !important;
    }
    .modal-wrapper.sc-ion-modal-md{
      // width: 85%;
      // height: 94%;
    }
    .exploreContent header .search-wrapper{
      margin-top: 29px !important;
    }
    .item-details-description {
      font-size: 14px;
      height: 70px;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .wide .footer{
      position: absolute;
      bottom: 0;
    width: 100%;
    background-color: #e6e8ed;
    padding: 13px 0 0;
    }
    .search-results>div {
      /* width: 47% !important;
      height: 12rem !important; */
    }
    .item-title-wrapper {
      height: 20% !important;
      margin-top: 9px !important;
      font-size: 14px;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ion-button {
      font-size: 10px;
    }
    .search-results .shadow{
      gap:1rem;
      padding: 1rem;
    }
   .item-title-link {
      font-size: 13px;
  }
  .read-more-link{
    font-size: 10px;
  }
  .select-wrapper {
    margin-bottom: 0;
    margin-top: 0px;
  }

 // My Collectio 
 .myCollection .items>div {
    width: 47%;
    height: 100%;
}
.myCollection .item-meta {
  text-transform: uppercase;
  font-size: 0.7rem;
}
.myCollection  .item-details-footer > div{
  display: flex;
  flex-direction: row;
}
myCollection  .item-details-page-link {
  height: 65%;
  font-size: 23px;
}
}

  @media only screen and (max-width: 1387px) {
    .search-results .sc-hHEiqL {
      width: 97%;
      height: 9.75rem;
    }
    .search-results .sc-hHEiqL .shadow {
      flex-direction: row;
    }
    .item-preview {
      width: 192px;
      // height: 100%;
    }
  }

  @media only screen and (max-width: 1313px) {
    .select-wrapper > div {
      // margin-top: 16px;
      margin-right: 12px;
    }
    .filterlabel label {
      font-size: 20px;
      // margin-top: -12px;
    }
    .select-wrapper {
      // transform: translate(-3%, -48%);
    }
    .select-wrapper select {
      margin-top: 6px;
      margin-right: -12px;
      padding: 3px 22px 7px 19px;
    }
    .wide .info {
      margin-bottom: -2px !important;
      font-size: 0.8rem;
    }
 .filterlabel {
      // margin-top: -14px;
  }
   
  }

  @media only screen and (max-width: 1313px) {
    .item-details-footer > div {
      display: flex;
      // flex-direction: column;
    }
    .read-more-link {
      margin-top: -12px;
    }
  }

  @media only screen and (max-width: 1248px) {
  .item-preview {
    width: 120px!important;
}
}
@media only screen and (max-width: 1199px) {
.item-preview {
  width: 112px!important;
}
.search-results {
  gap: 1.5rem;
}
.search-results>div {
  /* width: 48% !important; */
}
.MuiDrawer-paperAnchorDockedLeft,
.MuiDrawer-docked{
  width: 220px;
}
}

  @media only screen and (max-width: 1075px) {
  .switchbutton {
    margin-top: 13px;
}
}

  @media only screen and (max-width: 1024px) {
    .modal-wrapper.sc-ion-modal-md {
      width: 85%;
      height: 90%;
    }
    .map-content-wrapper{
    flex-direction: column;
  }
    .filter + div {
      padding-top: 45px;
      height: 51vh !important;
    }
   .item-details-footer > div {
      display: flex;
      flex-direction: row;
    }
   .search-results>div {
      /* width: 100% !important; */
  }
 .item-title-wrapper,
 .item-title,
 .item-title-link {
    max-width: 100%;
   width:100%;
    text-align: left;
  }
  // My Collectio 
  .myCollection .items>div {
     width: 100%;
 }

// new style
.mobileview{
  display:block;
}
.desktopview{
  display:none;
}
.map-list-btn.mobileview{
  position: relative;
}
.map-list-btn.mobileview .switchbutton{
position: absolute;
top: 105px;
left: -7%;
z-index:99999999;
}
.map-content-wrapper>div:nth-child(1) {
    width: 97%;
    order: 1;
}
.mapview-card {
    width: 100%;
    order: 1;
}


.search-results>div {
    width: 100% !important;
}


.filter + div {
  padding-top: 0px;
  height: 0 !important;
}
.switchbutton {
  width: 206px;
  padding-right: -10px;
}
.arow-btn {
  right: 11% !important;
}
  }

  

  /* .mapview-card {
    width: 100%;
    margin: 34px auto;
  }
  .mapview-card .search-results {
    display: flex;
    justify-content: center;
} */


  @media only screen and (max-width: 768px) {
    .modal-wrapper.sc-ion-modal-md {
      position: relative;
    }
    .select-wrapper > div {
      // margin-top: 25px;
      margin-right: 10px;
    }
    .item-title-link.a {
  font-size: 19px !important;
  line-height: 30px !important;
}
    .select-wrapper select {
      margin-top: 3px;
      margin-right: -3px;
      padding: 2px 24px 7px 19px;
      font-size: 0.8rem;
    }
    .item-preview {
      width: 100%;
      // height: 100%;
    }
    .search-results .sc-hHEiqL .shadow {
      flex-direction: column;
    }
    .search-results .sc-hHEiqL {
      width: 97%;
      height: 100% !important;
    }
    .read-more-link {
      margin-top: 0px;
    }
    .select-wrapper {
      margin-bottom: 0;
      text-align: left;
    }
  .filter .MuiFormGroup-root{
    flex-direction:  column !important;
    flex-wrap: inherit !important;
    align-items: baseline;
  }
  .search-results .shadow {
    display: flex;
    flex-direction: column;
  }
  .select-wrapper>div {
    width: 41vw;
    margin: 10px auto;
  }
  .map-list-btn.mobileview .switchbutton {
    left: -15%;
  }
.search-results>div {
    height: 28rem !important;
}
.item-preview{
width:auto !important;
}
.item-details-description{
  margin-top: 2px;

}
.read-more-link {
  font-size: 14px !important;
}

.item-title-wrapper {
  height: 40% !important;
  -webkit-line-clamp: 2;
}
.item-details-description {
  font-size: 16px;
  height: 85px;
  -webkit-line-clamp: 3;
}
.item-details-footer > div{
  height: 50px;
}
.item-details-footer>div .chooseMe{
  display: flex;
  align-items: center
}
.item-details-footer {
  height: 4rem;
}
/* .search-results>div{
  height: 30rem;;
} */
  }

  @media only screen and (max-width: 754px) {
    .select-wrapper > div {
      // margin-top: 25px;
      margin-right: 0%;
    }
    .select-wrapper select {
      margin-top: 4px;
      margin-right: -3px;
      padding: 4px 24px 8px 19px;
      font-size: 0.7rem;
    }
    .select-wrapper{
      flex-wrap: nowrap;
     }
  }
  @media only screen and (max-width: 575px) {
  // .show-hide {
  //   position: absolute;
  //   display: none;
  // }
  .MuiDrawer-docked{
    display: block;
  }
  .Top-bar svg:nth-child(1){
    display:block;
  }
}

  @media only screen and (max-width: 634px) {
    // .select-wrapper {
    //   margin-bottom: 0;
    //   text-align: center;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }
    .select-wrapper > div {
     margin-top: 3px;
    }
    .select-wrapper {
      flex-direction: column;
    }
   
    .item-details-footer > div {
      // flex-direction: column;
    }
    .item-details-footer {
      height: 3rem;
      min-height: 3rem;
    }
    .select-wrapper .item-title-wrapper {
      margin-bottom: 34px;
    }
    .read-more-link {
      // margin-bottom: 16px;
    }
    .select-wrapper>div{
      width: 45vw;
    }
    .select-wrapper {
      flex-wrap: nowrap;
      display: flex;
      transform: none;
    }
    .select-wrapper{
      height:100%;
    }
    .filter .MuiFormGroup-root{
      display: flex;
      flex-direction: column !important;
      align-items: baseline;
    }
    .map-list-btn.mobileview .switchbutton {
      left: -10%;
  }
  .side-menu{
    display: block;
  }
  .show-hide {
    position: absolute;
    display: none;
  }
  
  .Top-bar svg:nth-child(1){
    display:block;
  }
  
.item-details-footer>div .chooseMe {
  justify-content: flex-end;
  }
  }
  
  @media only screen and (max-width: 575px) {
  // header+.MuiDrawer-docked{
  //   width: 33% !important;
  // }
  // .MuiDrawer-paperAnchorDockedLeft{
  //   width: 33%;
  // }
  .filterlabel+.select-wrapper{
    display:flex;
    flex-direction: column !important;
  }
  .select-wrapper{
    display:flex;
    flex-direction: column !important;
    width: 43vw;
    flex-wrap: wrap;
  }
  ion-button {
    font-size: 10px;
    width: 148px;
  }
//   .map-list-btn.mobileview .switchbutton {
//     left: -27%;
// }
.read-more-link {
  font-size: 12px !important;
  width: 97px;
}
.item-title-link.a {
  font-size: 19px !important;
  line-height: 30px !important;
}
.arow-btn {
  right: 20% !important;
}
}

  @media only screen and (max-width: 538px) {
    .select-wrapper {
      // transform: translate(-7%, 134%);
    }
    .filterlabel label {
      font-size: 20px;
      margin-top: -2px;
      margin: 9px 7px;
    }
    .exploreContent header h2 {
      font-size: 1.3rem;
    }
   .exploreContent header {
      min-height: 98px;
    }
    main {
      padding: 13px;
    }
  }

  @media only screen and (max-width: 500px){
// .map-list-btn.mobileview .switchbutton {
//     left: -31%;
// }
}
  @media only screen and (max-width: 425px) {
    .MuiTypography-displayBlock {
      font-size: 10px;
    }
    .select-wrapper span {
      font-size: 10px;
    }
    .MuiTypography-displayBlock {
      font-size: 11px;
    }
  //   .map-list-btn.mobileview .switchbutton {
  //     left: -38%;
  // }
  }

  @media only screen and (max-width: 375px){
// .map-list-btn.mobileview .switchbutton {
//     left: -45%;
// }
ion-button {
  font-size: 11px !important;
}
h6.MuiTypography-noWrap{
  font-size: 15px;
}
}
@media only screen and (max-width: 320px){
.item-details-description {
  font-size: 16px;
  height: 81px;
}
}

`;

const Filters = styled.div`
  font-size: 0.875rem;
  display: flex;

  & > *:not(.clear) + *:not(.clear) {
    margin-left: 1rem;
  }

  .clear {
    text-decoration: underline;
    margin-left: auto;
    color: var(--ion-color-secondary);
    cursor: pointer;
    align-self: flex-start;
  }
`;

const ViewedInfo = styled.div`
  .info {
    margin-bottom: 1.75rem;
    color: var(--ion-color-secondary);
  }
`;

const LoadMoreButtonWrapper = styled.div`
  margin-bottom: 5rem;

  ion-button {
    --border-radius: 100px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
  }
`;

// const Mapcard = styled.div`
// .map-card .ui{
//   width: 12rem;
//   height: 100%;
// }
// .map-card .ui.image{
//   border: 0;
//   width: 12rem;
//  height: 8rem;
//   object-fit: cover;
//   border-radius: 16px;
// }
// .leaflet-container a.leaflet-popup-close-button {
//   position: absolute;
//   top: 4px !important;
//   right: 2px !important;
// }
// // .header {
// //   color: #0849ff;
// //   font-size: 20px !important;
// //   text-transform: capitalize;
// //   line-height: inherit;
// //   font-weight: bold;
// //   word-wrap: break-word;
// //   font-size: 21px;
// //   font-family: 'Source Sans Pro',serif;
// //   text-decoration: none;
// // }

// .map-card .description{
//   color: var(--ion-color-secondary);
//   font-size: 17px;
//   line-height: 1.5;
//   font-family: 'Source Sans Pro';
//   display: -webkit-box;
//   max-width: 200px;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
// }

// .extra.contet{
//       color: #0849ff;
//       font-size: 14px !important;
//       text-transform: capitalize;
//       line-height: 1.6;
//       font-weight: bold;
//       word-wrap: break-word;
//       font-size: 21px;
//       font-family: 'Source Sans Pro',serif;
// }
// `;
const Mapcard = styled.div`
.map-card .ui{
width: 12rem;
height: 100%;
}
.map-card .ui.image{
border: 0;
width: 12rem;
height: 8rem;
object-fit: cover;
border-radius: 16px;
}
.leaflet-container a.leaflet-popup-close-button {
position: absolute;
top: 4px !important;
right: 2px !important;
}
.header {
color: #0849ff;
font-size: 20px !important;
text-transform: capitalize;
line-height: inherit;
font-weight: bold;
word-wrap: break-word;
font-size: 21px;
font-family: 'Source Sans Pro',serif;
text-decoration: none;
}

.map-card .description{
color: var(--ion-color-secondary);
font-size: 17px;
line-height: 1.5;
font-family: 'Source Sans Pro';
display: -webkit-box;
max-width: 200px;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
}

.extra.contet{
  color: #0849ff;
  font-size: 14px !important;
  text-transform: capitalize;
  line-height: 1.6;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 21px;
  font-family: 'Source Sans Pro',serif;
}
`;

const Styled = styled.div`

.tag_text{
  text-overflow: ellipsis;
  //width: 88px;
  overflow: hidden;
  color: #0749ff;
  font-size: 11px;
}

.tag_white{
  color: #fff;
  font-size: 11px;
}
.chooseThisItem-btn,.chooseThisItem-btn:hover {
  border-radius: 50px;
  border: 1px solid #0749ff;
    color: #0749ff;
    padding: 5px 18px;
    font-weight: 600;
    font-size: 11px;
}
.chooseThisItem-btn-bg,.chooseThisItem-btn-bg:hover{
background-color:#0749ff;
color:#fff;
border-radius:50px;
padding: 5px 18px;
font-weight: 600;
    font-size: 11px;
}


IonButton .button-inner {
  display: block !important;
  position: relative;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 72px !important;
  // height: 13px !important;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

`

export default ChooseSampleContentItem;