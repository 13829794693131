import getSymbolFromCurrency from 'currency-symbol-map';

const getPriceText = (item, t) => {
    if (!item.price) {
      return '';
    }
    if (item.price.value == 0) {
      return t('price.free');
    }
    // TODO: localize currency
    const currencySymbol = getSymbolFromCurrency(item.price.currency) ? getSymbolFromCurrency(item.price.currency) : item.price.currency;
    return `${item.price.value} ${currencySymbol}`
  }

const getPriceClass = (item, baseClass='rectangle', freeClass='free', paidClass='notFree') => {
  if (!item.price) { return ''}
  const priceClass = (item.price && item.price.value > 0) ? paidClass : freeClass;
  return baseClass + ' ' + priceClass;
}

const getPreviewImage = (item) => {
  if (item?.preview_url) {
    return item?.preview_url;
  }

  if (item?.image_url) {
    return item?.image_url;
  }

  return '/assets/bg/header.png';
}

const isComingSoon = (item) => {
  return item?.platform?.slug == 'coming-soon';  
}

const isCompleted = (item) => {
  return item?.platform?.slug == 'completed';  
}



const shortenLocationName = (name) => {
  if (name) {
    return name.split(",")[0]
  }
  return "";
}

export {
  getPriceText,
  getPriceClass,
  getPreviewImage,
  isComingSoon,
  isCompleted,
  shortenLocationName 
} 