import {
  IonChip, IonCol, IonGrid, IonLabel,
  IonList,
  IonRow
} from '@ionic/react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import React, { FC, useEffect } from 'react';
import Delete from '../../../assets/icons/delete.svg';
import DropDownArrow from '../../../assets/icons/dropdownarrow.svg';
import DropDownArrowActive from '../../../assets/icons/dropdownarrowactive.svg';
import HaggadaBook from '../../../assets/icons/haggada-icon.svg';
import LinkIcon from '../../../assets/icons/link-icon.svg';
import WhiteArrow from '../../../assets/icons/whitearrow.svg';
import useTranslations from '../../../util/useTranslation';
import DeletePopup from '../DeletePopup/DeletePopup';
import { StyledHosting } from './hostingStyled';

type Props = { data: Array<any>, guestEmail: Array<any>, openInvitation: Function }

const Hosting: FC<Props> = ({ data, guestEmail, openInvitation }) => {
  const [t] = useTranslations('passover');
  const [hostingData, setHostingData] = React.useState<any>([]);
  const [inviteHosting, setInviteHosting] = React.useState<any>();
  const [guestEmailList, setGuestEmailList] = React.useState([{}]);
  const [openDelete, setOpenDelete] = React.useState(false);

  const toggleInviteHosting = (id: number) => {
    if (isOpenHostingInvite(id)) {
      setInviteHosting(null);
    } else {
      setInviteHosting(id);
    }
  };
  const isOpenHostingInvite = (id: number) => {
    return inviteHosting === id ? true : false;
  };

  useEffect(() => {
    setHostingData(data);
    setGuestEmailList(guestEmail);
  })

  console.log('daata', data);

  return (
    <div>
      <StyledHosting>
        <div className="ion-margin-buttom">
          <div className='subHeader'>{t("subheader.items.hosting")}</div>
          <div>
            <IonGrid className="ion-no-padding">
              {hostingData.map((data: any, i: any) => {
                return (
                  <div key={i} className="ion-margin-top ion-padding bgcolor" >
                    <div>
                      <IonRow className='d-flex ion-align-items-center'>
                        <IonCol size='2' className='name'>{data.name}</IonCol>
                        <IonCol size='1.5' className='d-flex'>
                          <img src={LinkIcon} alt='Link Icon' />{' '}
                          <Link className='publicShare' href='#'>{data.link}</Link>
                        </IonCol>
                        <IonCol className='field'>{data.date}</IonCol>
                        <IonCol className='field'>{data.time}</IonCol>
                        <IonCol className='field d-flex'>{data.guestCount} guests
                          <Button
                            className='field'
                            variant='text'
                            color='primary'
                            onClick={() => toggleInviteHosting(data.id)}
                            startIcon={
                              <img
                                src={
                                  isOpenHostingInvite(data.id)
                                    ? DropDownArrowActive
                                    : DropDownArrow
                                }
                                alt='DropDownArrow'
                                style={{ width: '8px', height: '12px' }}
                              />
                            }
                          ></Button>
                        </IonCol>
                        <IonCol size='2' className='d-flex ion-align-items-center field'>
                          <img
                            src={HaggadaBook}
                            alt='Haggada Book'
                            style={{ width: '27px', height: '35px' }}
                          />
                          <Link className='reference' href='#'>
                            {data.ref}
                          </Link>
                        </IonCol>
                        <IonCol>
                          <a href="https://yahad.net/room/402634?code=aG9zdEVtYWlsPWFsZXhhbmRlci5yYWdpbnNreSsyQGdtYWlsLmNvbQ%3D%3D&lang=en-he&chapter=&viewerEvent=true&isSync=true" target="_blank">
                            <Button
                              className='startSederButton'
                              variant='contained'
                              color='primary'
                              startIcon={
                                <img
                                  src={WhiteArrow}
                                  alt='Arrow'
                                  style={{ width: '16.14px', height: '16px' }}
                                />
                              }
                            >
                              {data.buttonName}
                            </Button>
                          </a>
                        </IonCol>
                        <IonCol className="ion-text-center">
                          <Button
                            variant='text'
                            onClick={() => setOpenDelete(true)}
                            color='primary'
                          >
                            <img
                              src={Delete}
                              alt='Trace'
                              style={{ height: '20px', width: '18px' }}
                            />
                          </Button>
                        </IonCol>
                      </IonRow>
                      {isOpenHostingInvite(data.id) && (
                        <div>
                          <hr className="hr-bgcolor" />
                          <div className="margin-left-pr">
                            {guestEmailList.map((guestEmail: any, i: any) => {
                              return (
                                <div key={i}>
                                  <IonList className="ion-no-padding bgcolor">
                                    <IonLabel>
                                      <img
                                        src={Delete}
                                        alt='Trace'
                                        style={{
                                          height: '10px',
                                          width: '11px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      <div className="guestEmailList">
                                        {guestEmail.email}
                                      </div>
                                      <IonChip className="chip">
                                        <IonLabel>Approved</IonLabel>
                                      </IonChip>
                                    </IonLabel>
                                  </IonList>
                                </div>
                              );
                            })}
                            <IonList lines='none' className="bgcolor">
                              <IonLabel>
                                <Button
                                  variant='text'
                                  onClick={() => openInvitation(true)}
                                  color='primary'
                                >
                                  <span className="linkGuestInvite">
                                    + {t('link.guest_invite')}
                                  </span>
                                </Button>
                              </IonLabel>
                            </IonList>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </IonGrid>
          </div>
        </div>

        {/* Create Delete Popup */}
        {openDelete && (
          <DeletePopup isOpen={openDelete} setDeleteModal={setOpenDelete} />
        )}

      </StyledHosting>
    </div>
  );
};

export default Hosting;