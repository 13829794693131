import L, { LatLngBounds } from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import '../../../../node_modules/leaflet/dist/leaflet.css';
import useQuery, { QUERY_KEY } from '../../../util/useQuery';
import useTranslations from '../../../util/useTranslation';
import PointMarker from './PointMarker';
const Map: React.FC<{
  allData: any;
  isLocationMap?: boolean;
  handleBboxdata: any;
  searchData: any;
  data: any;
  hideTitle?: boolean;
  zoom?: number;
}> = ({
  allData,
  handleBboxdata,
  searchData,
  data,
  isLocationMap,
  hideTitle,
  zoom
}) => {
  const experiencesWithLocation = allData?.filter((i) => i.location);
  const [hm] = useTranslations('home');
  var maplocation: any = [];
  const [map, setMap] = useState<any>(null);
  const { mapLocation } = useQuery(QUERY_KEY);
  var southWest = L.latLng(-89.98155760646617, -180),
  northEast = L.latLng(89.99346179538875, 180);
  var bounds2 = L.latLngBounds(southWest, northEast);
  const bounds =
    (experiencesWithLocation?.length &&
      new LatLngBounds(
        experiencesWithLocation?.reduce(
          (acc: any, { location: { lat, lng } }: any) =>
            acc.push([lat, lng]) && acc,
          []
        )
      )) ||
    undefined;

  function MyComponent() {
    const map = useMapEvents({
      dragend: (e) => {
        map.panInsideBounds(bounds2, { animate: false });
      },
    });
    return null;
  }

  const LeafletgeoSearch = () => {
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      const searchControl = new (GeoSearchControl as any)({
        provider: provider,
        style: 'bar',
        showMarker: false,
        showPopup: true,
        marker: {
          icon: new L.Icon({
            iconSize: [25, 41],
            iconAnchor: [10, 41],
            popupAnchor: [2, -40],
            iconUrl:
              'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
            shadowUrl:
              'https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png',
          }),
          draggable: false,
        },
        popupFormat: ({ result }) => result.label,
        maxMarkers: 3,
        retainZoomLevel: false,
        animateZoom: true,
        autoClose: false,
        searchLabel: 'Search..',
        keepResult: true,
      });

      map.addControl(searchControl);

      return () => map.removeControl(searchControl);
      // eslint-disable-next-line
    }, []);

    return null;
  };

  const handleMapCreated = (map) => {
    setMap(map);
    if (!isLocationMap) {
      handleBboxdata(map.getBounds().toBBoxString().split(','));
    }
  };

  const ResizeMap = (props: any) => {
    const { bounds } = props;
    const map1: any = useMap();

    map1._onResize();
    //map1.setZoom(2);
    map1.on('moveend', function () {});
    return null;
  };

  return (
    <div style={{width: '100%'}}>
        <div>
          <h3>{!hideTitle ? hm('map_title') : null}</h3>
        </div>
      
      <div className='map-wrap'>
        {bounds && (
          <div className='map-img' style={{ borderRadius: '20px' }}>
            {data?.map_url ? (
              <iframe
                width='100%'
                height='100%'
                frameBorder='0'
                src={data.map_url}
              />
            ) : (
              <MapContainer
                whenCreated={handleMapCreated}
                className='map-content'
                bounds={bounds}
                zoom={zoom}
                minZoom={3}
                dragging={true}
                scrollWheelZoom={true}
              >
                <ResizeMap bounds={bounds} />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <MyComponent />
                {map && <LeafletgeoSearch />}
                {experiencesWithLocation.map((item: any, index: any) => (
                  <PointMarker
                    item={item}
                    index={index}
                  />
                ))}
              </MapContainer>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
