import React, { useState, useEffect, useRef } from 'react';
import Header from '../../pages/Home/Header';
import Footer from '../../pages/Home/Footer';
import { Link, useRouteMatch } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import { ItemType } from '../../model/ItemType';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { LeftArrow, RightArrow } from './arrows';
import useDrag from './useDrag';
import { LatLng, LatLngBounds, Layer } from 'leaflet';
import FilterItem from '../search/FilterItem';
import SubjectFilter from '../search/subjects';
import { Card } from './card';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SearchInput from '../search/Input';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from 'react-leaflet';
import ItemsResponse from '../../model/ItemsResponse';
import CardComponent from '../../pages/Home/Card';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import useQuery, { QUERY_KEY } from '../../util/useQuery';
import useTranslations from '../../util/useTranslation';
import Pagination from '@material-ui/lab/Pagination';
import { IonContent, IonModal } from '@ionic/react';
import {
  getSubjects,
  exploreExperiences,
  queryExperiences,
} from '../../util/API';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ClearIcon from '@material-ui/icons/Clear';
import RoomIcon from '@material-ui/icons/Room';
import ListIcon from '@material-ui/icons/List';
import bannerImg from '../../assets/bg/banner.png';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { NavLink } from 'react-router-dom';
import ExperienceBrowse from '../ExperienceBrowse/ExperienceBrowse';



interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#0849FF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#0849FF',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const SearchExplore: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslations('search');
  const [spinner, setSpinner] = useState(false);
  const [duration, setDuration] = React.useState(2000);
  var maplocation: any = [];
  const itemsPerPage = 15;
  const { params } = useRouteMatch<{ id?: string }>();
  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
  ]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [page, setpage] = useState<number>(1);
  let [allData, setallData] = useState<any>([]);
  type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
      (ev: React.MouseEvent) =>
        dragMove(ev, (newPos) => {
          if (scrollContainer.current) {
            const currentScroll = scrollContainer.current.scrollLeft;
            scrollContainer.current.scrollLeft = currentScroll + newPos;
          }
        });

  function onWheel(
    apiObj: scrollVisibilityApiType,
    ev: React.WheelEvent
  ): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext(undefined, undefined, undefined, { duration });
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev(undefined, undefined, undefined, { duration });
    }
  }
  let [defaultData, setdefaultData] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [subjectData, setSubjectData] = useState<any>([]);
  const [bbox, setBbox] = useState<any>();
  const [mapRef, setMapRef] = useState<any>(null);
  const [map, setMap] = useState<boolean>(false);
  const { toggleFilter } = useQuery('guided', 'true');
  const [ds] = useTranslations('dashboard');
  const {
    filter: filters,
    experiences: expType,
    language: languages,
    holiday: holidayRelated,
  } = t('searchexplore.filterslist', {
    returnObjects: true,
  }) as {
    filter: Array<{ id: string; text: string }>;
    experiences: Array<{ id: string; text: string }>;
    language: Array<{ id: string; text: string }>;
    holiday: Array<{ id: string; text: string }>;
  };
  const { getQuery, setQuery, mapLocation, searchParams, getGeoLocation } =
    useQuery(QUERY_KEY);
  const query = getQuery();
  const [clearSearchData, setClearSearchData] = useState<boolean>(
    query ? true : false
  );
  const geolocationQuery = getGeoLocation();
  const paramStr = searchParams().toString();

  const fetchExperiences = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    } = await exploreExperiences();
    setSpinner(false);
    setallData(experiences);
    setdefaultData(experiences);
  };

  const fetchSubjects = async () => {
    setSpinner(true);
    const { data } = await getSubjects();
    setSpinner(false);
    setSubjectData(data.subjects);
  };

  useEffect(() => {
    // expTypes.map((exType) => fetchExperiences(exType));
    fetchExperiences();
    fetchSubjects();
  }, []);

  let experiencecLocation: any = [];

  const onPageChange = (event, value) => {
    setpage(value);
    document.getElementById('section')?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  useEffect(() => {
    const fetchResults = async () => {
      setSpinner(true);
      const {
        data: { experiences },
      }: any = await queryExperiences(paramStr);
      setSpinner(false);
      setallData(experiences);
    };
    if (query || geolocationQuery) {
      fetchResults();
    } else {
      // setSearchResult(() => null);
    }
    if (!paramStr) {
      setChecked(false);
      setallData(defaultData);
    }
  }, [paramStr, bbox, query]);

  const _ApplyFilter = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    }: any = await queryExperiences(paramStr);
    setSpinner(false);
    setallData(experiences);
  };
  function MyComponent() {
    const map = useMapEvents({
      dragend: (e) => {
        const data = e.target.getBounds().toBBoxString().split(',');
        maplocation = [{ key: 'location', value: data.join(',') }];
        mapLocation(maplocation);
      },
    });
    return null;
  }

  const _showMap = () => {
    setMap(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    toggleFilter();
  };

  const zoom = 5;


  return (
    <div className='main' id='main-pr'>
      <style>{css}</style>
      <Header />
      <div className='container'>
        <h2 className='experience-the-jewis'>{t('searchexplore.header')}</h2>
      </div>
      <div className='container'>
        <div className='searchBar'>
          <div className='search-inner-wrap'>
            <SearchInput
              {...{
                getQuery,
                setQuery,
                placeholder: t('input.searchexplore'),
              }}
              clearSearch={clearSearchData}
              explore={true}
            />
          </div>
          <div className='filterview' id='search-explore-tag'>
            <div className='filter-wrapper d-flex'>
              {filters.map((item) => (
                <div className='filter'>
                  <FilterItem
                    text={item.text}
                    value={item.id}
                    filterKey='type'
                    explore={true}
                  />
                </div>
              ))}
            </div>
            <div className='d-flex'>
              <Button className='morefilters' onClick={() => setOpen(true)}>
                {t('searchexplore.morefilter')}
              </Button>
              <div className='map-list-btn desktop'>
                {map == false ? (
                  <Button
                    onClick={() => _showMap()}
                    className='switchbutton show-map'
                  >
                    {t('searchexplore.map')}
                    <RoomIcon />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      mapLocation(null);
                      setMap(false);
                    }}
                    className='switchbutton list-view'
                  >
                    {t('searchexplore.list')}
                    <ListIcon />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {spinner && (
        <div className='spinner'>
          <IonSpinner color='primary' />
        </div>
      )}
      {/* <div
        className='container d-flex popular-card-wrap'
        style={{ marginTop: '55px', borderBottom: '1px solid #7782A0' }}
      >
        <h5 className='poplular-subject-title'>{t('searchexplore.subject')}</h5>
        
        <div className='card-wrap'>
        <Slider {...settings}>
          {subjectData.map((item) => (      
            <SubjectFilter parmId={params?.id} value={item?.name} img={item?.image} id={item?.id} filterKey='subject' moreFilter={true} explore={true} /> 
          ))}
            </Slider>
        </div>
        
      </div> */}
    {/*  <div
        className='container d-flex popular-card-wrap'
        style={{ marginTop: '55px', borderBottom: '1px solid #7782A0' }}
      >
        <h5 className='poplular-subject-title'>{t('searchexplore.subject')}</h5>
        <div className='slider-wrap' onMouseLeave={dragStop}>
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            transitionDuration={duration} 
          >
            {subjectData.map((item) => (
              //  <SubjectFilter parmId={params?.id} value={item?.name} img={item?.image} id={item?.id} filterKey='subject' moreFilter={true} explore={true} />
              <Card
                value={item?.name}
                filterKey='subject'
                img={item?.image}
                itemId={item.id.toString()}
                parmId={params?.id}
                key={item.id.toString()}
              />
            ))}
          </ScrollMenu>
        </div>
      </div>
            */}
      {console.log("data is", allData)}
      <div id="section">
      {map == true ? (
        allData.length > 0 ? (
           <div className='container'>
              <ExperienceBrowse isShowExperienceMap allData={allData} handleBboxdata={(value) => setBbox(value)} searchData={clearSearchData} zoom={zoom} />
            </div>
        ) : (
          query ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}
              >
                {t('searchexplore.noresult')}
              </span>
            </div>
            : null
        )
      ) : (allData.length > 0 ? (
        <div>
          <div
            className='container search-explore-card-wrap'
            style={{ marginTop: '60px' }}
          >
            <div className='map-list-btn mobileview'>
              {map == false ? (
                <Button
                  onClick={() => _showMap()}
                  className='switchbutton show-map'
                >
                  {t('searchexplore.map')}
                  <RoomIcon />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    mapLocation(null);
                    setMap(false);
                  }}
                  className='switchbutton list-view'
                >
                  {t('searchexplore.list')}
                  <ListIcon />
                </Button>
              )}
            </div>
{console.log("ALLDATA IS",allData)}
            {allData
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((experience) => (
                <ExperienceBrowse isShowExperienceList item={experience} ds={ds} zoom={zoom} />
              ))}
          </div>
          <div className='pagination-wrap' style={{ marginTop: '50px' }}>
            {allData.length > 15 ? (
              page == 1 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span className='experiences'>
                    {t('searchexplore.displayed', {
                      count: allData.length,
                    })}
                  </span>
                </div>
              ) : null
            ) : null}
            <div className='pagination-section container'>
              <div className='pagination'>
                <Pagination
                  hidePrevButton={page == 1 ? true : false}
                  hideNextButton={
                    page == Math.ceil(allData.length / itemsPerPage)
                      ? true
                      : false
                  }
                  onChange={onPageChange}
                  count={Math.ceil(allData.length / itemsPerPage)}
                  page={page}
                  defaultPage={1}
                  color='primary'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        query ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}>
              {t('searchexplore.noresult')}
            </span>
          </div>
          : null
      ))}
      </div>
      <div
        className='banner-img d-flex'
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <div className='container'>
          <h5>{t('searchexplore.footerbanner.text')} </h5>
          <p>{t('searchexplore.footerbanner.desc')}</p>
          <NavLink
            to={`/app/new-experience`}
            style={{ textDecoration: 'none' }}
            className="newslatter-create-btn"
          >
            <Button
              className='createNew'
              variant='contained'
              color='primary'
              endIcon={<AddIcon />}
            >
              {t('searchexplore.footerbanner.button')}
            </Button>
          </NavLink>
        </div>
      </div>

      {open == true ? (
        <IonContent>
          <div className='abc'>
            <Modal isOpen={open} onDidDismiss={() => setOpen(false)}>
              <div className='filtermodel'>
                <div className='model-heading'>
                  <div>
                    <span> {t('searchexplore.popup.head')}</span>
                    <div className='canclebtn'>
                      <ClearIcon onClick={() => setOpen(false)}></ClearIcon>
                    </div>
                  </div>
                </div>
                <div className='filter-model-wrap'>
                  <div className='content-wrap'>
                    <section>
                      <h5> {t('searchexplore.popup.exphead')}</h5>
                      <p>{t('searchexplore.popup.exptitle')} </p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {expType.map((item) => (
                          <FilterItem
                            text={item.text}
                            value={item.id}
                            param={filters}
                            filterKey='type'
                            moreFilter={true}
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.guided')}</h5>
                      <p>{t('searchexplore.popup.exptitle')} </p>
                      <div className='tag-wrap Guided-tag'>
                        <span
                          className='everthing'
                          style={{ color: checked ? '#c3c9d8' : '#0849ff' }}
                        >
                          {t('searchexplore.popup.toggleone')}
                        </span>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={checked}
                              onChange={handleChange}
                              name='checked'
                            />
                          }
                          style={{ color: checked ? '#0849ff' : '#c3c9d8' }}
                          label={t('searchexplore.popup.toggletwo')}
                        />
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.language')}</h5>
                      <p>{t('searchexplore.popup.languagetitle')}</p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {languages.map((item) => (
                          <FilterItem
                            text={item.text}
                            value={item.id}
                            moreFilter={true}
                            filterKey='language'
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.holiday')}</h5>
                      <p>{t('searchexplore.popup.holidaytitle')}</p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {holidayRelated.map((item) => (
                          <FilterItem
                            text={item.text}
                            value={item.id}
                            moreFilter={true}
                            filterKey='holiday'
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                </div>
                <div className='d-flex footer-wrap signin'>
                  <div>
                    <NavLink
                      to='#'
                      onClick={() => {
                        setChecked(false);
                        // setOpen(false);
                        setallData(defaultData);
                      }}
                      className='tos'
                    >
                      {t('searchexplore.popup.clear')}
                    </NavLink>
                    <Button
                      className='signupbtn'
                      variant='contained'
                      onClick={() => {
                        setOpen(false);
                        _ApplyFilter();
                      }}
                      color='primary'
                      endIcon={<ArrowRightAltIcon />}
                    >
                      {t('searchexplore.popup.apply')}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </IonContent>
      ) : null}

      <Footer />
    </div>
  );
};

const css = `
--body{
  // overflow:hidden;
  background-color:red;
}
.newslatter-create-btn{
  margin-top:20px;
}
.newslatter-create-btn+.createNew {
 margin-left: 0px !important;
}
.d-flex{
  display:flex;
}
.search-explore-card-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 2.2rem;
}

.main {
  height: 100% !important;
  overflow-y: auto;
}
  .searchBar{
     display: flex;
     justify-content: space-between;
     margin-top: 80px;
  }
  .filterview{
    display: flex;
    align-items: center;
  }

  .filter{
    margin-left: 18px;
  }

  .experiences{
    color: #8992ac;
    padding-top: 75px;
    padding-bottom: 43px;
  }

  .morefilters{   
  height: 41px;
  // width: 11%;
  margin-left: 18px;
  // border-radius: 25px;
  // color: black;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: none;
  box-sizing: border-box;
  border: 2px solid #b1b1b1;
  border-radius: 20px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #3b3b3b;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background-color: transparent;
  }
  .morefilters:hover{
    border: 1px solid rgb(17, 79, 255);
    color: rgb(17, 79, 255);
    background-color: transparent;
  }
  .switchbutton{
    height: 40px;
    // width: 15%;
    margin-left: 18px;
    background-color: #0849FF;
    color: white;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .switchbutton:hover{
    background-color: #031e6a;
  }

  .switchbutton.show-map svg{
    height: 28px;
    width: 18px;
    margin-left: 11px;
  }
  .switchbutton.list-view svg{
    height: 33px;
  width: 27px;
    margin-left: 11px;
  }
 

  .pagination{
    display:flex;
    justify-content:center;
    margin-bottom:100px
  }
 
  // .signupbtn {
  //     color: #fff;
  //     background-color:#0849FF;
  //     margin-left: 20px;
  //     text-transform: none;
  //     height: 20px;
  //     width: 56px;
  //     color: #FFFFFF;
  //     font-family: "Source Sans Pro";
  //     font-size: 16px;
  //     font-weight: bold;
  //     letter-spacing: 0;
  //     line-height: 20px;
  //     border-radius: 25px;
  //     width: 132px;
  //     height: 40px;
  //   }
  //   .signupbtn:hover {
  //     color: #fff;
  //     background-color:#0849FF;
  //   }
    .title{
        justify-content: center;
        display: flex;
        padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      border-radius: 25px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #0849FF;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
  .my-custom-class {
    --background: red;
  }
  .card,.highlight{
  height: 227px;
  width: 179px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  // padding: 0px 20px;
  text-align: center;
  // margin:0 auto;
  margin-left:15px;
  }
  
.card-wrap {
  // display: flex;
  // // width: 2050px;
  // width: 1165px;
  // margin-top: -41px;
  // padding: 26px 0;
  // // overflow: auto;
  // flex-direction: column;
  display: flex;
  width: 2050px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}
.slick-prev, .slick-next{
  // background-color:red;
}
.slick-prev:before,.slick-next:before,.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  color: #000;
}
 .card img,
 .highlight img {
  height: 153px;
    max-width: 152px;
    border-radius: 15px;
    object-fit: cover;
}
.highlight img{
  box-shadow: 0px 0px 11px 1px #fac807;
}
.slick-initialized .slick-slide {
  display: block;
  width: 185px !important;
}

.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
  width: 85%;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 55px;
   text-align: center;
}

.highlight span{
  width: 85%;
  height: 31px;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 70px;
  text-align: center;
}

.map-img{
    display: flex;
    justify-content: center;
}

.map-img .map-content{
  position: relative;
    outline: none;
    height: 958px;
    width: 1594px;
    margin-top: 50px;
  margin-bottom:170px;
   border-radius: 20px;
   height: 958px;
}

 .map-img,
 .map-img .map-content{
   height: 711px;
  width: 100%;
  margin-top: 0px;
  margin-bottom:170px;
   border-radius: 20px;
 }

.map-wrap{
position:relative;
}
.map-wrap .leaflet-popup-content-wrapper .leaflet-popup-content{
  width: 350px;
 }
 .leaflet-popup-pane .leaflet-popup-content {
  margin: 0;
 }
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 60px;
top: 20px;
}

.shareStory{
 color:#fff;
   height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}

.createNew {
height: 40px;
// width: 230px;
width:100%;
border-radius: 25.6px;
background-color: #fac807;
color: #2a2a2a;
font-family: 'Source Sans Pro';
font-size: 15px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-transform: capitalize;
margin-left: 20px;
}
.createNew:hover {
background-color: #fac807;
color: #2a2a2a;
}

.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
height: 100px;
width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .createNew{
margin-left: 0px;
}

.shareStory .shareguid{
padding: 30px 100px 0px 100px;
}

.shareStory .shareguid h4{
height: 24px;
width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
height: 72px;
width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

}

.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}
.banner-img{
  height: 350px;
  width: 100%;
  background-size: cover;
  padding: 61px 0;
  background-position: 44% 10%;
  background-repeat: no-repeat;
}
.banner-img .container{
  align-items: center;
  width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-img .container h5{
// height: 72px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 28px;
font-weight: bold;
letter-spacing: 0;
line-height: 31px;
text-align: center;
padding: 0 26px;
}

.banner-img .container p{
// height: 72px;
width: 411px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}


.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
height: 128px;
width: 840px;
margin-top: 100px;
color: #0849ff;
font-family: "Source Sans Pro";
font-size: 54px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
height: 87px;
width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

ion-item {
  width: 459px;
  border-radius: 25px;
  background-color: #FFFFFF;
  box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
}

.poplular-subject-title{
  // height: 49px;
  height: 120px;
  width: 100px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.model-heading{
  display: flex;
  justify-content: space-between;
  padding: 34px 34px 0px 34px;
}
.filter-model-wrap{
  padding-top: 20px;
  height: 73vh;
  overflow: auto;
}

.model-heading span{
  height: 48px;
  width: 204px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}
.pagination li button{
  color:717C9C;
}
.pagination li + li {
  margin-left: 0;
}

}
.map-img .leaflet-popup-content {
margin:0px 0px ;
}

.slider-wrap{
  // width:100%;
  width:95%;
  margin-bottom: 25px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.map-list-btn.mobileview{
display:none;
}

.leaflet-popup-content{
  margin:0 0;
}

@media only screen and (max-width: 1980px) {
ion-item {
  width: 100%;
}
.searchBar .search-inner-wrap{
  width: 23%;
}
#search-explore-tag .filterview {
  width: 78%;
  // width: 72%;
  // justify-content: flex-end;
  justify-content: space-between;
}
.filter-btn-tag {
  width: 95%;
}
.search-explore-tag>div{
  width: 100%;
}
.filter {
  width: 164px;
  margin-left: 6px;
}
.filter-wrapper.d-flex{
  justify-content: flex-end;
}
.filter-btn-tag .tag_text{
  font-size:15px;
  outline:none;
}

}

@media only screen and (max-width: 1791px) {

.filterview {
  width: auto;
}
.show-map span:nth-child(1){
  font-size: 0.7vw;
}
.filter{
  width: 9.5vw;
}

}
@media only screen and (max-width: 1620px) {

.map-img, .map-img .map-content {
  height: 680px;
  // width: 640px;
}
.line {
  height: 680px;
}
.filter-wrapper.d-flex {
  justify-content: flex-end;
  // width: 66%;
}
}
@media only screen and (max-width: 1502px) {
}

@media only screen and (max-width: 1440px) {
// .map-img, .map-img .map-content {
// width: 42vw;
// }
.map-img, .map-img .map-content {
  width: 90vw;
  margin: 28px auto;
}
.map-main-wrap{
  padding-bottom: 57px;
}
.slider-wrap {
  width: 95%;
}
}


@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}
// .map-img, .map-img .map-content {
// width: 40vw;
// }
.card-wrap {
width: 100vw;
  height: auto;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
// .card span,
// .highlight span {
// height: 32px;
// width: 117px;
// }

.partner-card .card-1 {
height: 420px;
width: 48%;
}
}

@media only screen and (max-width: 1244px) {

 .filter-wrapper.d-flex {
  //  width: 61%;
}
 }

@media only screen and (max-width: 1200px) {
.filterview {
  justify-content: initial;
  width: 81%;
}
.morefilters,
.switchbutton {
  font-size: 1vw;
  padding-left: 13px;
  padding-right: 13px;
}
.experience-the-jewis {
  margin-top: 20px;
}

}


@media only screen and (max-width: 1199px) {
  .card-wrap .card img, .card-wrap .highlight img {
    height: 67%;
    max-width: 158px;
    object-fit: cover;
  }
  
  .card-wrap {
    height: auto;
  
  }
 
  
}

@media only screen and (max-width: 1024px) {
 
.experience-the-jewis {
  width: 100%;
  font-size: 4vw;
}
.j-story-is-a-communi {
  width: 100%;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  font-size: 2.7vw;
}
.shareStory .shareguid {
  padding: 57px 100px 2px 35px;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4{
margin-top: 53px;
}
.shareStory .col-md-4:nth-child(3){
// padding: 206px 0 50px;
}
.partner-card p {
font-size: 1.8vw;
}

}

@media only screen and (max-width: 991px) {
  
.filter-btn-tag .tag_text {
  font-size: 13px;
}

.show-map span:nth-child(1) {
  font-size: 13px;
}
.slider-wrap {
  width: 90%;
}

.card {
  height: 227px;
  width: 178px;
}
.card span {
height: 31px;
// width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}
.searchBar {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}
.searchBar .search-inner-wrap {
  width: 100%;
  margin-bottom:30px;
}
.filterview {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.filter-wrapper.d-flex{
  // width: 100%;
}
.filter {
  // width: 170px;
  width: 146px;
  margin-bottom:30px;
}
.morefilters, .switchbutton {
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

.popular-card-wrap{
  display: flex;
  // flex-direction: column;
  align-items: center;
}
.poplular-subject-title {
  width: 100%;
    height: 30px;
}
.card-wrap {
  height: auto;
  width: 877px;
  overflow: auto;
}

}



@media only screen and (max-width: 768px) {
  .map-list-btn.mobileview {
  display: block;
}

.morefilters{
display:block;
}
.map-list-btn.mobileview{
  width: 0px !important;
  margin-left: -34px;
}
.recommended-cards-wrapper .recommendedCard .shadow {
  // display: flex;
  // flex-direction: column;
}
.mobile-view{
  position: relative;
}
.map-img, .map-img .map-content {
  width: 100%;
}
.map-list-btn.desktop{
  display: none;
}

// .filter-wrapper+.d-flex{
//   display: none;
// }

.popular-card-wrap{
  margin-top: 15px !important;
}
.experience-the-jewis{
line-height: 43px;  
}
// .signupbtn{
// font-size: 1.8vw;
//     width: 115px;
// }


.card-wrap .card img, .card-wrap .highlight img {
  height: 67%;
  max-width: 150px;
  object-fit: cover;
}

.shareStory .col-md-4 {
width: 100%;
}
.shareStory {
height: 100vh;
}
.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .shareguid {
padding: 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
margin-top: 22px;
}
.shareStory h2 {
width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
margin-top: -8px;
}
.partner-card p {
width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}

.card-wrap {
  margin-top: 23px;
  max-width: 669px;
  overflow-x:auto;
}
.container.search-explore-card-wrap>div{
 width:45%;
  //  width:100%;
}

.full-name {
  font-size: 8px;
}
.book-btn {
  top: 240px ;
}
}

@media only screen and (max-width: 600px) {
.slider-wrap {
  width: 88%;
}
.row-2>div{
  width: 100%;
}
}

@media only screen and (max-width: 575px) {
  .container.search-explore-card-wrap>div {
    width: 80%;
    margin: 0 auto;
  }
  
.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 263.75px;
width: 306.09px;
}
.experience-the-jewis {
height: 70px;
line-height: 31px;
font-size: 23px;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
// width: 100%;
height: 35vh;
}
.line {
height: 1px;
width: 90vw;
margin-top: 40px;
}
.card-wrap {
width: 87vw;
}
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: -11px;
}
.partner-card .card-1 {
margin: 24px 0;
}

.filter {
  margin-left: 9px;
}
// .card, .highlight {
//   height: 260px;
//   width: 100%;
// }
.slider-wrap {
  width: 72% !important;
}

}

@media only screen and (max-width: 475px) {
.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.banner-img .container,
.banner-img .container p {
  width: 95%;
}
.banner-img .createNew {
  height: 40px;
  // width: 55%;
}
// .signupbtn {
//   color: #fff;
//   background-color: #0849FF;
//   margin-left: 10px;
// }
.createNew {
  width: 32px;
}

}

@media only screen and (max-width: 425px) {
  .map-list-btn.mobileview .switchbutton{
    left: -6% !important;
  }
  .map-list-btn.mobileview .switchbutton.list-view {
    left: 0% !important;
  }
.j-story-is-a-communi {
font-size: 16px;
line-height: 20px;
}
.shareStory h2 {
height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}
// .slider-wrap {
//   width: 85% !important;
// }
}

@media only screen and (max-width: 375px){
.banner-img .createNew {
  height: 40px;
  // width: 65%;
  margin-top: 26px;
}
.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
.popular-card-wrap{
  display: flex;
  flex-direction: column;
}
}
@media only screen and (max-width: 360px){

}
@media only screen and (max-width: 320px){
.morefilters, .switchbutton {
  padding-left: 16px;
  padding-right: 16px;
}
.jewish-vienna {
  width: 116px;
}
.map-list-btn.mobileview .switchbutton {
  left: -22px !important
}
.slider-wrap {
  width: 70%;
}
}

#listOfCard .leaflet-popup-content{
  margin:0 0 !important;
    }


// New Style
--body{
  overflow:hidden;
}
  .main{
      // background-color: rgb(17, 79, 255);
      flex-direction: column;
      display: flex ;
      overflow-y: auto ;
      height:100vh
  }
 
  .logo {
      width: 108px;
      height: 50px;
      margin-right:80px
  }
 
  .signupbtn {
      color: #fff;
      background-color:#0849FF;
      margin-left: 20px;
      text-transform: none;
      height: 20px;
      // width: 56px;
      color: #FFFFFF;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      border-radius: 25px;
      // width: 132px;
      height: 40px;
    }
    .signupbtn:hover {
      color: #fff;
      background-color:#0849FF;
    }
    .navheader{
      color: #717C9C;
  text-decoration-line: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  }
  
    .title{
        justify-content: center;
        display: flex;
        padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      margin-top: 70px;
      border-radius: 25px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #0849FF;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }

  // .card{
  //     height: 227px;
  // width: 205px;
  // justify-content: center;
  // display: flex;
  // cursor:pointer;
  // flex-direction: column;
  // //padding: 0px 20px;
  // text-align: center;
  // }

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  height: 787px;
  width: 622px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}

.card-wrap .card img{
height: 180px;
width: 180px;
border-radius:15px;
object-fit: cover;
}
.card-wrap .card img:hover{
box-shadow: 0px 0px 11px 1px #fac807;
}
.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
   height: 31px;
  //  width: 180px;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 70px;
   text-align: center;
}
.card span:hover{
color: #000;
}
.map-img,
.map-img .map-content{
   height: 711px;
  //  width: 735px;
   border-radius: 20px;
}

.map-wrap{
position:relative;
}
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 60px;
top: 20px;
}

.shareStory{
 color:#fff;
   height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}

.createNew {
height: 40px;
// width: 230px;
width:100%;
border-radius: 25.6px;
background-color: #fac807;
color: #2a2a2a;
font-family: 'Source Sans Pro';
font-size: 15px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-transform: capitalize;
margin-left: 20px;
}
.createNew:hover {
background-color: #fac807;
color: #2a2a2a;
}

.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
height: 100px;
width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .createNew{
margin-left: 0px;
}

.shareStory .shareguid{
padding: 30px 100px 0px 100px;
}

.shareStory .shareguid h4{
height: 24px;
width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
height: 72px;
width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

}

.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}

.banner-img{
height:100%;
width:100%;
background-size: cover;
}
.banner-img .container{
justify-content: space-between;
align-items: center;
}

.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
height: 128px;
width: 649px;
color: #0849ff;
font-family: "Source Sans Pro";
font-size: 56px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
height: 87px;
width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

.learMore{
box-sizing: border-box;
height: 42px;
width: 162px;
border: 2px solid #FFFFFF;
border-radius: 25.6px;
margin-right:10px;
}
.learMore span:nth-child(1){
height: 20px;
width: 105.39px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
text-transform: capitalize;
}
.shareguid svg{
margin-top: 16px;
margin-right:35px;
width: 60px;
height: 60px;
}

.mobileview{
display:none;
}
.Component-switchBase-44.Component-checked-47 + .Component-track-46 {
  background-color: #0749ff !important;
}

.filtermodel{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media only screen and (max-width: 1791px) {

}
@media only screen and (max-width: 1620px) {
.map-img, .map-img .map-content {
  height: 680px;
  // width: 640px;
}
.line {
  height: 680px;
}
}
@media only screen and (max-width: 1502px) {

}
// @media only screen and (max-width: 1469px) {
//   .recommended-cards-wrapper .recommendedCard{
//     width: 25vw;
//   }
// }
@media only screen and (max-width: 1440px) {
.map-img, .map-img .map-content {
// width: 42vw;
}
.shareStory .shareguid p{
width:100%;
}
.shareStory .shareguid {
padding: 30px 100px 0px 64px;
} 
}

@media only screen and (max-width: 1199px) {
.shareStory .shareguid p {
  height: 80px;
}
// .terms-of-service {
//   height: 28px;
//   width: 93px;
// }
.j-story-is-the-pro {
  margin-right: -8px;
}
.map-wrap .map-search {
  width: 80%;
}
.shareguid svg {
  margin-right: 9px;
}
}
@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}
.map-img, .map-img .map-content {
// width: 40vw;
}
.card-wrap {
// width: 42vw;
  height: 680px;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
// .card span {
// height: auto;
// }
.partner-card .card-1 {
height: 420px;
width: 48%;
}
}

@media only screen and (max-width: 1024px) {
.experience-the-jewis {
  width: 100%;
  font-size: 4vw;
}
.j-story-is-a-communi {
  width: 100%;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  font-size: 2.7vw;
}
.shareStory .shareguid {
  padding: 57px 100px 2px 35px;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4{
margin-top: 53px;
}
.shareStory .col-md-4:nth-child(3){
// padding: 206px 0 50px;
}
.partner-card p {
font-size: 1.8vw;
}

}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.card span {
height: 31px;
// width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}

}

@media only screen and (max-width: 768px) {
.experience-the-jewis{
line-height: 53px;  
font-size: 6vw;
height: 58%;
}
.modal-wrapper {
  height: 98vh;
}
.map-img, .map-img .map-content {
  width: 100%;
}
// .signupbtn{
// font-size: 16px;
//     width: 115px;
//     margin-top: 50px;
//     margin-left: 0px;
// }

.navheader {
  margin-top: 15px;
  margin-left: 13px;
  font-size: 18px;
  }
  .navheader .list-item{
    margin-left:0px;
    }

.mobileview{
  display:block;
}

.filter {
  width: 180px !important;
}
.createNew {
// width: 194px;
font-size: 13px;
margin-left: 0px;
}
.create-new-explore{
margin-top: 20px;
}
.filterview {
  overflow: auto;
  justify-content: flex-start;
  flex-direction: initial;
}
.filter-model-wrap {
  padding-top: 20px;
  height: 78vh
}

.morefilters {
  display: block;
  width: 140px;
  margin-bottom: 30px;

}

.modal-wrapper {
  height: 94vh;
  width: 87vw;
}

.clear-icon{
display: flex;
position: absolute;
right: 19px;
top: 19px;
color: #9e9e9e;
}

// .card {
// height: 260px;
// width: 100%;
// }
.card-wrap .card img {
height: 200px;
width: 100%;
object-fit: cover;
}
.shareStory .col-md-4 {
width: 100%;
}
.shareStory {
height: 100vh;
}
.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .shareguid {
padding: 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
margin-top: 22px;
}
.shareStory h2 {
width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
margin-top: -8px;
}
.partner-card p {
width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}
.book-btn {
  // top: 153px !important;
}
}

@media only screen and (max-width: 575px) {
.recommended-cards-wrapper .recommendedCard.carousel-slider-card{
  // width: 400px !important;
  // overflow: hidden;
}
.filter-model-wrap {
  height: 71vh;
}
.book-btn {
  top: 158px;
}

.slick-initialized .slick-slide {
  display: block;
  // width:400px !important;
  
}
.map-list-btn.mobileview {
  margin-left: -104px !important;
}
.slider-wrap {
  width: 83%;
}

.mobileview{
  display:block;
}

.desktopview{
  display:none;
}
.search-results {
  gap: 0rem;
}
.banner-content-wrap {
  width: 90%;
}
.leaflet-control-zoom{
display:none;
}
.map-card-wrap h3{
text-align:center;
}
// .signupbtn{
// // width: 57px;
// width: 54%;
// }
// .signupbtn>span{
// align-items: center;
// justify-content: center;
// }
.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 493.75px;
// width: 555.09px;
width: 100vw;
transform: translate(0, 5%);
}
.title {
padding-top: 80px;
}
.experience-the-jewis {
height: 62%;
line-height: 53px;
font-size: 43px;
}
.j-story-is-a-communi {
font-size: 4.7vw;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
width: 100%;
height: 35vh;
border-radius: 0px;
}
.map-card-wrap .container{
width:auto;
}
.line {
height: 1px;
width: 90vw;
margin-top: 40px;
}
.card-wrap {
width: 100vw;
height: 100%;
}
.map-card-wrap {
margin-bottom: 50px;
}
.explore-wrap h3{
margin-top: 50px;
}
// .card {
// height: 260px;
// // width: 45%;
// margin: 0 auto;
// }
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: -11px;
}
.partner-card .card-1 {
margin: 24px 0;
}

.shareStory .shareguid p {
height: fit-content;
width: 100%;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4 {
margin-top: 14px;
}
.shareStory .shareguid h4 {
height: fit-content;
}
.shareStory {
// padding-bottom: 80px;
// height: 107vh;
padding-bottom: 100%;
height: 116vh;
}
.shareStory .col-md-4:nth-child(3) {
padding: 100px 0 0px;
}

.shareStory .col-md-4:nth-child(3) .shareguid {
  margin-bottom: -10px;
}
.shareStory .col-md-4:nth-child(3) .shareguid svg{
margin-top: -95px;
margin-bottom: 47px;
}

.shareStory .col-md-4:nth-child(3)  .mobileview{
display: flex;
flex-direction: column;
}
.shareStory .col-md-4:nth-child(3)  .mobileview a{
margin-top: 20px;
}
.map-wrap .map-search {
width: 93vw;
}
.map-card-wrap {
margin-top: 60px;
}
}

@media only screen and (max-width: 475px) {

.map-wrap .map-search {
width: 76vw;
}
.line{
width: 100%;
}
.map-card-wrap {
margin-top: 50px;
}
.map-wrap .map-search {
width: 90vw;
}

}

@media only screen and (max-width: 425px) {
.shareStory h2 {
height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}

.slider-wrap {
  width: 90%;
}
.poplular-subject-title{
 margin-right: 0px;
}
}
@media only screen and (max-width: 375px){
.shareStory {
height: 149vh;
padding-bottom: 8%;
}
.shareStory .shareguid {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}
.shareStory .shareguid h4 {
width: 100%;
}
.shareStory .shareguid h4 {
margin-top: -39px;
}
.shareStory .col-md-4:nth-child(3) .mobileview {
align-items: center;
}
}
@media only screen and (max-width: 360px){
}


// New Style
--body{
  overflow:hidden;
}
  .main{
      // background-color: rgb(17, 79, 255);
      flex-direction: column;
      display: flex ;
      overflow-y: auto ;
      height:100vh
  }
 
  .logo {
      width: 108px;
      height: 50px;
      margin-right:80px
  }
    .title{
        justify-content: center;
        display: flex;
        padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      margin-top: 70px;
      border-radius: 25px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #0849FF;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }

  // .card{
  //     height: 227px;
  // width: 205px;
  // justify-content: center;
  // display: flex;
  // cursor:pointer;
  // flex-direction: column;
  // padding: 0px 20px;
  // text-align: center;
  // }

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  height: 787px;
  width: 622px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}

.card-wrap .card img{
height: 180px;
width: 180px;
border-radius:15px;
object-fit: cover;
}
.card-wrap .card img:hover{
box-shadow: 0px 0px 11px 1px #fac807;
}
.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
   height: 31px;
  //  width: 180px;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 70px;
   text-align: center;
}
.card span:hover{
color: #000;
}
.map-img,
.map-img .map-content{
   height: 711px;
  //  width: 735px;
   border-radius: 20px;
}

.map-wrap{
position:relative;
}
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 60px;
top: 20px;
}

.shareStory{
 color:#fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}

.createNew {
height: 40px;
// width: 230px;
border-radius: 25.6px;
background-color: #fac807;
color: #2a2a2a;
font-family: 'Source Sans Pro';
font-size: 15px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-transform: capitalize;
margin-left: 20px;
}
.createNew:hover {
background-color: #fac807;
color: #2a2a2a;
}

.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
height: 100px;
width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .createNew{
margin-left: 0px;
}

.shareStory .shareguid{
padding: 30px 100px 0px 100px;
}

.shareStory .shareguid h4{
height: 24px;
width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
height: 72px;
width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

}
.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}
.banner-img{
// height:100%;
width:100%;
background-size: cover;
//background-position: 0 -133px;
}
.banner-img .container{
justify-content: space-between;
align-items: center;
flex-direction: column;
}

.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
height: 128px;
width: 649px;
// color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 56px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
height: 87px;
width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

.learMore{
box-sizing: border-box;
height: 42px;
width: 162px;
border: 2px solid #FFFFFF;
border-radius: 25.6px;
margin-right:10px;
}
.learMore span:nth-child(1){
height: 20px;
width: 105.39px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
text-transform: capitalize;
}
.shareguid svg{
margin-top: 16px;
margin-right:35px;
width: 60px;
height: 60px;
}

// .mobileview{
// display:none;
// }

.Component-switchBase-44.Component-checked-47 + .Component-track-46 {
  background-color: #0749ff !important;
}

@media only screen and (max-width: 1791px) {

}
@media only screen and (max-width: 1620px) {

.map-img, .map-img .map-content {
  height: 680px;
  // width: 640px;
}
.line {
  height: 680px;
}
}
@media only screen and (max-width: 1502px) {

}
// @media only screen and (max-width: 1469px) {
//   .recommended-cards-wrapper .recommendedCard{
//     width: 25vw;
//   }
// }
@media only screen and (max-width: 1440px) {
.map-img, .map-img .map-content {
// width: 42vw;
}
.shareStory .shareguid p{
width:100%;
}
.shareStory .shareguid {
padding: 30px 100px 0px 64px;
} 
}

@media only screen and (max-width: 1199px) {
.shareStory .shareguid p {
  height: 80px;
}
// .terms-of-service {
//   height: 28px;
//   width: 93px;
// }
.j-story-is-the-pro {
  margin-right: -8px;
}
.map-wrap .map-search {
  width: 80%;
}
.shareguid svg {
  margin-right: 9px;
}
}
@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}
.map-img, .map-img .map-content {
// width: 40vw;
}
.card-wrap {
// width: 42vw;
  height: 680px;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
// .card span {
// height: auto;
// }
.partner-card .card-1 {
// height: 420px;
width: 48%;
}
}

@media only screen and (max-width: 1024px) {
.experience-the-jewis {
  width: 100%;
  font-size: 4vw;
}
.j-story-is-a-communi {
  width: 100%;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  font-size: 2.7vw;
}
.shareStory .shareguid {
  padding: 57px 100px 2px 35px;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4{
margin-top: 53px;
}
.shareStory .col-md-4:nth-child(3){
// padding: 206px 0 50px;
}
.partner-card p {
// font-size: 1.8vw;
height:auto;
}

}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.card span {
height: 31px;
// width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}
.filter-wrapper.d-flex {
  justify-content: flex-start;
}
}

@media only screen and (max-width: 768px) {
  .banner-content-wrap {
    width: 70%;
    padding: 70px 0 0 0;
}
.modal-wrapper {
  height: 98vh;
}
.filterview {
  overflow: auto;
  justify-content: flex-start;
}
.map-img, .map-img .map-content {
  border-radius: 0;
}
.switchbutton{
    margin-left: 0px;
    position: absolute;
    top: 0;
    z-index: 1111;
}
.banner-inner-img {
  transform: translate(0, -5%);
}
.map-img, .map-img .map-content {
  width: 100%;
}
.search-explore-card-wrap{
  position:relative;
  margin-top: 0px;
}
.search-explore-card-wrap .switchbutton{
  background-color: #0849FF;
  position: absolute;
  left: -39px;
  top: 100px;
  z-index: 1;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.map-list-btn.mobileview .switchbutton{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: #0849FF;
    position: absolute;
    left: -38px;
    top: 100px;
    z-index: 1111;
}


.map-list-btn.mobileview .switchbutton.list-view{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background-color: #0849FF;
  position: absolute;
  left: -40px; 
  top: 158px;
  z-index: 1111;
}

.desktopview {
  display: none;
}

.shareStory .col-md-4:nth-child(3) .mobileview{
  display: flex;
  flex-direction: column;
}

.banner-img .container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.experience-the-jewis{
line-height: 53px;  
font-size: 6vw;
// height: 58%;
}
// .signupbtn{
// font-size: 16px;
//     width: 115px;
//     margin-top: 50px;
//     margin-left: 0px;
// }

.createNew {
// width: 194px;
font-size: 13px;
margin-left: 0px;
}
.create-new-explore{
margin-top: 20px;
}

.learMore {
  margin-bottom: 15px;
}

.footer-row-wrap .md.hydrated{
  display: block;
}
.clear-icon{
display: flex;
position: absolute;
right: 19px;
top: 19px;
color: #9e9e9e;
}
.submit-btn-wrap{
  justify-content: flex-end;
  margin-top: 20px;
}

// .card {
// height: 260px;
// width: 100%;
// }
.card-wrap .card img {
height: 200px;
width: 100%;
object-fit: cover;
}
.shareStory .col-md-4 {
width: 100%;
}

.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .shareguid {
padding: 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
margin-top: 0px;
}
.shareStory h2 {
width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
margin-top: -8px;
}
.partner-card p {
width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}
.banner-content-wrap {
  padding: 82px 0 0 0;
}
.book-btn {
  // top: 153px !important;
}
}

@media only screen and (max-width: 575px) {
.recommended-cards-wrapper .recommendedCard.carousel-slider-card{
  // width: 400px !important;
  // overflow: hidden;
}
.search-explore-tag.withmorefilter {
  width: 176px;
}
.tag-wrap > div {
  margin: 9px 0;
}
.slick-initialized .slick-slide {
  display: block;
  // width:400px !important;

}
.please-sumbit-your-d{
  width:100%;
}

.mobileview{
  display:block;
}

.desktopview{
  display:none;
}
.search-results {
  gap: 0rem;
}
.banner-content-wrap {
  width: 90%;
}
.leaflet-control-zoom{
display:none;
}
.map-card-wrap h3{
text-align:center;
}
.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 493.75px;
// width: 555.09px;
width: 100vw;
transform: translate(0, 5%);
}
.title {
padding-top: 80px;
}
.experience-the-jewis {
height: 62%;
line-height: 53px;
font-size: 43px;
}
.j-story-is-a-communi {
font-size: 4.7vw;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
width: 100%;
height: 55vh;
border-radius: 0px;
}
.map-card-wrap .container{
width:auto;
}
.line {
height: 1px;
width: 100vw;
margin-top: 40px;
}
.card-wrap {
width: 100vw;
height: 100%;
}
.map-card-wrap {
margin-bottom: 50px;
}
.explore-wrap h3{
margin-top: 50px;
}
// .card {
// height: 260px;
// // width: 45%;
// margin: 0 auto;
// }
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
// height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: 0px;
}
.partner-card .card-1 {
margin: 24px 0;
}

.map-wrap .map-search{
width: 83vw;
left: 19px;
}
.map-search>div{
width: 80%;
}
.row-2 button{
margin-top:15px;
}
.shareStory .shareguid p {
height: fit-content;
width: 100%;
}
// .shareStory .col-md-4:nth-child(2) .shareguid h4 {
// margin-top: 14px;
// }
.shareStory .shareguid h4 {
height: fit-content;
}
.shareStory {
// padding-bottom: 100%;
}
.shareStory .col-md-4:nth-child(3) {
padding: 100px 0 0px;
}

.shareStory .col-md-4:nth-child(3) .shareguid {
  margin-bottom: -10px;
}
.shareStory .col-md-4:nth-child(3) .shareguid svg{
margin-top: -95px;
margin-bottom: 47px;
}

.shareStory .col-md-4:nth-child(3)  .mobileview{
display: flex;
flex-direction: column;
}
.shareStory .col-md-4:nth-child(3)  .mobileview a{
margin-top: 20px;
}
.map-wrap .map-search {
width: 93vw;
}

.submit-btn-wrap{
justify-content: end;
}
.list-footer{
flex-wrap: wrap;
}

.list-footer .list:nth-child(2){
display: flex;
flex-direction: column;
order: 1;
}
.list-footer .list:nth-child(4){
order: 1;
}
.j-story-is-the-pro {
width: 100%;
font-size: 15px;
}

.map-card-wrap {
margin-top: 60px;
}
}

@media only screen and (max-width: 475px) {

.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.line{
width: 100%;
}
.map-card-wrap {
margin-top: 50px;
}
.map-wrap .map-search {
width: 90vw;
}

}

@media only screen and (max-width: 425px) {
.shareStory h2 {
height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}


}
@media only screen and (max-width: 375px){
.shareStory {
// padding-bottom: 8%;
}
.shareStory .shareguid {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}
.shareStory .shareguid h4 {
width: 100%;
}
.shareStory .shareguid h4 {
margin-top: -39px;
}
.shareStory .col-md-4:nth-child(3) .mobileview {
align-items: center;
}
}
@media only screen and (max-width: 360px){
.shareguid img {
  margin-bottom: 18px;
}
}
  `;

export const LegendSeparator = styled.div`
  float: left;
  height: 1px;
  background-color: rgba(113, 124, 156, 0.5);
  width: 0;
  margin: 0.438rem 0.313rem 0 0.313rem;
  flex: 1;
`;
const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  --font-family: 'Blooming Elegant', sans-serif;
  --color: red;

  .ion-page {
    background-color: #fff;
  }
  .modal-wrapper {
    height: 94%;
    width: 946px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  }

  .model-heading > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #7782a0;
  }

  .content-wrap {
    padding: 0 47px;
    margin-bottom: 35px;
  }
  .content-wrap section h5 {
    height: 40px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .content-wrap section p {
    height: 25px;
    width: 364px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
  }

  .content-wrap .filter {
    background-color: #f7f7f7;
    height: 37px;
    margin-left: 0px !important;
    margin-right: 8px !important;
    padding: 11px 20px;
    border-radius: 20px;
    color: #696363;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    border-radius: 20px;
  }
  .filter:hover {
    background-color: rgb(17, 79, 255);
    color: #f7f7f7;
  }
  .tag-wrap {
    margin-top: 42px;
  }
  .tag-wrap > div {
    margin: 9px;
  }

  .tag-wrap.Guided-tag {
    margin-top: 35px;
  }

  .everthing {
    height: 20px;
    width: 76px;
    // color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }

  .tag-wrap label {
    margin: 0 10px;
  }
  .Component-switchBase-2.Component-checked-5 + .Component-track-4 {
    background-color: #0849ff !important;
    height: 83%;
  }
  // .Component-root-1{
  //   height: 83%;
  // }
  .Component-switchBase-2.Component-checked-5 {
    transform: translate(16px, -2px) !important;
  }
  .tos {
    width: 216px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }
  .footer-wrap.signin {
    padding: 16px 47px 0;
  }
  .footer-wrap.signin > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-top: 1px solid #7782a0;
    padding: 25px 0;
  }
  
  #listOfCard .leaflet-popup-content {
    margin: 0 0 !important;
  }
  @media only screen and (max-width: 1024px) {
    .content-wrap .filter {
      padding: 11px 16px;
      font-size: 1.2vw;
    }
  }
  @media only screen and (max-width: 991px) {
    .modal-wrapper {
      height: 94%;
      width: 91%;
    }
  }
  @media only screen and (max-width: 768px) {
    .content-wrap .filter {
      width: 24vw;
    }
    .modal-wrapper {
      height: 98vh;
    }
    .footer-wrap.signin > div {
      align-items: center;
      padding: 25px 0;
    }
    .footer-wrap .signupbtn {
      margin-top: 1px;
    }
    .tag-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .filterview {
      overflow: auto;
      justify-content: flex-start;
    }
    .book-btn {
      /* top: 153px !important; */
    }
  }
  @media only screen and (max-width: 575px) {
    .content-wrap .filter {
      padding: 11px 6px;
      font-size: 1.8vw;
    }
    .tag-wrap > div {
      margin: 9px 0;
    }
    .book-btn {
      top: 158px;
    }
    .search-explore-tag.withmorefilter {
      width: 176px;
    }
   
  }
  @media only screen and (max-width: 475px) {
    .footer-wrap.signin > div {
      align-items: baseline;
      flex-direction: column;
    }
    .tos{
margin-bottom: 20px;
    }
    .footer-wrap .signupbtn {
  width:100%;
    }
  }
  @media only screen and (max-width: 425px) {
    .content-wrap section p {
      width: 100%;
    }
    .tag-wrap.Guided-tag {
      margin-top: 57px;
    }
    .content-wrap section h5 {
      height: auto;
    }
    .tag-wrap label {
      margin: 15px 10px 0;
    }
  }
`;

export default SearchExplore;
