import RoomIcon from '@material-ui/icons/Room';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import clockIcon from "../../../../assets/bg/clock-icon.svg";
import { ItemType } from '../../../../model/ItemType';
import { getPreviewImage, getPriceClass, getPriceText, isComingSoon, isCompleted, shortenLocationName } from '../../../../util/Utils';
import { StyledExperienceList } from './ExperienceListStyle';
import { StyledItemCard } from './ItemCardStyle';
import { ItemCardWrapper } from './ItemCardWrapperStyle';

const ExperienceList: React.FC<{ data?: any; ds: any; item?: any }> = ({
  data,
  ds,
  item,
}) => {

  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
    '3d-tour',
    'event'
  ]);

  return (
    <StyledExperienceList>
      <div className='search-results recommended-cards-wrapper' id='listOfCard'>
        {item ? (
          <ItemCardWrapper className='recommendedCard'>
            <StyledItemCard
              className='shadow experi-wrapper'
              style={{ padding: '1.2rem', gap: ' 1.1rem' }}
            >
              {isComingSoon(item) ? 
                <div className="coming-wrapper"><span className="coming-wrapper-text">{ds('coming-soon')}</span></div>
                : null
              }
              {isCompleted(item) ? 
                <div className="completed-wrapper"><span className="completed-wrapper-text">{ds('completed')}</span></div>
                : null
              }
      
              <img
                className='item-preview'
                src={getPreviewImage(item)}
                alt='Image'
              />
              <div
                className='item-details d-flex d-flex-col '
                style={{ marginTop: '-12px' }}
              >
                <div
                  className='item-details-header d-flex'
                  style={{ visibility: 'visible' }}
                >
                  <div
                    className='item-details-menu-toggler'
                    style={{ marginLeft: 'inherit' }}
                  >
                    <span className={getPriceClass(item, 'book-btn', '', 'euro')}>{getPriceText(item, ds)}</span>
                  </div>
                </div>
                <div className='item-title-wrapper hide'>
                  <span className='self-guid self-guided-tour'>
                    {expTypes.map((i) =>
                      item?.type == i
                        ? `${ds(`experienceitems.${i}`)}`
                        : null
                    )}
                  </span>
                  <h4 className='item-title jewish-vienna'>
                    {console.log("item?.id", item?.id)}
                    <Link
                      className='item-title-link'
                      to={`/experiences/${encodeURIComponent(item?.slug)}`}
                    >
                      {item?.title}
                    </Link>
                  </h4>
                </div>
                <p className='item-details-description walking-tour-on-jewi hide'>
                  {item?.description}
                </p>
                <div
                  className='d-flex icon-wrap'
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginTop: '26px',
                  }}
                >
                  <div className='pin-icon-wrap d-flex'>
                    <RoomIcon />
                    {!item?.locations || item?.locations.length == 0 ? (
                      <span>{ds('not-provide')}</span>
                    ) : (
                      <span>
                        <NavLink to={'/locations/' + item?.locations[0].locations_id?.slug} className="item-title-link">
                        {shortenLocationName(item?.locations[0].locations_id?.title)}
                        </NavLink>
                        </span>
                    )}
                  </div>
                  {item.duration ? <div className='clock-iocn-wrap d-flex'>
                    <img src={clockIcon} alt="clockIcon" />
                    <span>{item.duration}m</span>
                  </div> : null}
                </div>
              </div>
            </StyledItemCard>
          </ItemCardWrapper>
        ) : (
          Object.values(data).map((item: any) => {
            return (
              <>
                <ItemCardWrapper className='recommendedCard'>
                  <StyledItemCard
                    className='shadow box-wrapper'
                    style={{ padding: '1.2rem', gap: ' 1.1rem' }}
                  >
                    { isComingSoon(item) ? 
                     <div className="coming-wrapper"><span className="coming-wrapper-text">{ds('coming-soon')}</span></div>
                     : null
                    }
                    {isCompleted(item) ? 
                      <div className="completed-wrapper"><span className="completed-wrapper-text">{ds('completed')}</span></div>
                      : null
                    }
                    <img
                      className='item-preview'
                      src={getPreviewImage(item)}
                      alt='Image'
                    />
                    <div
                      className='item-details d-flex d-flex-col '
                      style={{ marginTop: '-12px' }}
                    >
                      <div
                        className='item-details-header d-flex'
                        style={{ visibility: 'visible' }}
                      >
                        <div
                          className='item-details-menu-toggler'
                          style={{ marginLeft: 'inherit' }}
                        >
                          <span className='book-btn'>FREE</span>
                        </div>
                      </div>
                      <div className='item-title-wrapper hide'>
                        <span className='self-guid self-guided-tour'>
                          {expTypes.map((i) =>
                            item?.type == i
                              ? `${ds(`experienceitems.${i}`)}`
                              : null
                          )}
                        </span>
                        <h4 className='item-title jewish-vienna'>
                          <Link
                            className='item-title-link'
                            to={`/experiences/${encodeURIComponent(item?.slug)}`}
                          >
                            {item?.title}
                          </Link>
                        </h4>
                      </div>
                      <p className='item-details-description walking-tour-on-jewi hide'>
                        {item?.description}
                      </p>
                      <div
                        className='d-flex icon-wrap'
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '26px',
                        }}
                      >
                        <div className='pin-icon-wrap d-flex'>
                          <RoomIcon />
                          {item?.locations.length == 0 ? (
                            <span>{ds('not-provide')}</span>
                          ) : (
                            <span>
                              <NavLink to={'/locations/' + item?.locations[0].locations_id?.slug} className="item-title-link">
                                {shortenLocationName(item?.locations[0].locations_id?.title)}
                                </NavLink>
                             </span>
                          )}
                        </div>
                        {item.duration ? <div className='clock-iocn-wrap d-flex'>
                          <img src={clockIcon} alt="clockIcon" />
                          <span>{item.duration}m</span>
                        </div> : null}
                      </div>
                    </div>
                  </StyledItemCard>
                </ItemCardWrapper>
              </>
            );
          })
        )}
      </div>
    </StyledExperienceList>
  );
};


export default ExperienceList;