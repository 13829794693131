import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import SignIn from '../components/authentication/SignIn';
import Collection from '../components/collections/Collection';
import CollectionDetail from '../components/collections/CollectionItemList';
import ItemDetailsPage from '../components/collections/ItemDetails';
import Footer from '../components/common/Footer';
import NavBar from '../components/common/NavBar';
import Dashboard from '../components/dashboard/Dashboard';
import ItemDetails from '../components/details/ItemDetails';
import ExperienceDetails from '../components/experience-details/ExperienceDetails';
import ItemWidget from '../components/item-widget/ItemWidget';
import NewExperience from '../components/new-experience/NewExperience';
import PassoverEvents from '../components/PassoverEvents/passoverEvents';
import Profile from '../components/profile/Profile';
import SearchWidget from '../components/search-widget/SearchWidget';
import SearchWidgetModal from '../components/search-widget/SearchWidgetModal';
import Search from '../components/search/Search';
import StyledPage from '../components/styled/Page';
import useToken from '../util/useToken';
import useTranslations from '../util/useTranslation';

const Root: React.FC<{ path: string }> = ({ path }) => {
  const [t] = useTranslations('common');
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useToken();

  return (
    <IonPage>
      <IonContent>
        <StyledPage>
          <NavBar path={path} t={t} hideForRoute={[`${path}/search-widget`, `${path}/new-experience`, `${path}/item-widget`]} />
          <SignIn open={!token} redirect={location.pathname} onCloseSignIn={() => { history.push('/home'); history.go(0); }} />
          <Switch>
            <Route path={`${path}/dashboard`} component={Dashboard} />
            <Route path={`${path}/search`} exact component={Search} />
            <Route path={`${path}/search/items/:id+`} component={ItemDetails} />
            <Route path={`${path}/collection`} component={Collection} />
            <Route path={`${path}/search/item-detail/:id+`} component={ItemDetailsPage} />
            <Route path={`${path}/collectionDetail/:id+`} component={CollectionDetail} />
            <Route path={`/app/new-experience`} component={NewExperience} />
            <Route path={`${path}/experience-details`} component={ExperienceDetails} />
            <Route path={`${path}/events`} component={PassoverEvents} />
            <Route path={`${path}/tour`} component={ExperienceDetails} />
            <Route path={`${path}/exhibit`} component={ExperienceDetails} />
            <Route path={`${path}/seminar`} component={ExperienceDetails} />
            <Route path={`${path}/story`} component={ExperienceDetails} />
            <Route path={`${path}/longread`} component={ExperienceDetails} />
            <Route path={`${path}/search-widget-modal`} component={SearchWidgetModal} />
            <Route path={`${path}/search-widget`} component={SearchWidget} />
            <Route path={`${path}/item-widget`} component={ItemWidget} />
            <Route path={`${path}/profile`} component={Profile} />
            <Redirect from={path} to={`${path}/dashboard`} />
          </Switch>

          <Footer hideForRoute={[`${path}/search-widget`, `${path}/item-widget`]} />
        </StyledPage>
      </IonContent>
    </IonPage>
  );
};

export default Root;
