import {
    IonCol,
    IonDatetime, IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState, useEffect } from 'react';
import Arrow from '../../../assets/icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import useTranslations from '../../../util/useTranslation';


import { Modal } from './SederFormStyled';
import {
    EnterTag,
    IconWrapper,
    Tag,
    TagContent
} from '../../experience-details/ExperienceDetails.style';

const CreateSedarForm = ({ experiences, isOpen, setOpenModal }) => {
    const [t] = useTranslations('passover');
    const [textName, setTextName] = React.useState<string>();
    const [form, setForm] = useState<any>([]);
    const [tags, setTags] = React.useState<any>([]);
    const [tag, setTag] = React.useState('');
    const [focus, setFocus] = useState(false);

    
    const handleClose = () => {
        setOpenModal(false);
    };
    const submitForm = (form) => {

    };

    const removeTag = (index: number) =>
        setTags(tags.filter((_: any, i: any) => i !== index));
    const handleChangeTag = (e: any) => {
        setTag(e.target.value);
    };
    const handleEnteredTag = (e: any) => {
        if (e && e.key == 'Enter') {
            addTag(tag, '#0849ff');
        }
    };
    const addTag = (name: string, bgColor: string) => {
        let tagexist = tags.find(
            (t: any) => t.label.toLowerCase() === name.toLowerCase()
        );

        if (tagexist) {
            alert(t('alert.email.already'));
        } else {
            let newTag = {
                label: name,
                bgColor: bgColor,
            };
            name.trim()
                ? tags.length < 30
                    ? setTags([...tags, newTag])
                    : alert(t('alert.email.maxlimit'))
                : alert(t('alert.email.enter'));
            setTag('');
        }
    };

    return (
        <div>
            <Modal isOpen={isOpen} backdropDismiss={false} overflow-X={Hidden}>
                <div>
                    <div className='canclebtn'>
                        <ClearIcon onClick={handleClose}></ClearIcon>
                    </div>
                    <div className='title'>
                        <h3>{t('setupseder.headline')}</h3>
                    </div>

                    <div className='field-wrap'>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <IonLabel className='forLabel'>{t('labels.name')}</IonLabel>
                                <IonItem>
                                    <IonInput
                                        defaultValue={textName}
                                        placeholder={t('placeholder.name')}
                                        onIonChange={(e) => setTextName(e.detail.value!)}
                                    ></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <IonLabel className='forLabel'>
                                    {t('labels.datetime')}
                                </IonLabel>
                                <span className="displayBlock">
                                    {t('labels.sublabel')}
                                </span>
                                <div className='d-flex'>
                                    <IonCol>
                                        <IonItem>
                                            <IonDatetime
                                                placeholder='Select a date'
                                                display-format='DD/MM/YYYY'
                                                picker-format='DD/MMMM/YYYY'
                                            ></IonDatetime>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem>
                                            <IonDatetime
                                                display-format='HH:mm a'
                                                picker-format='HH:mm a'
                                                placeholder='Select Time'
                                            ></IonDatetime>
                                        </IonItem>
                                    </IonCol>
                                </div>
                            </IonCol>
                        </IonRow>

                        <IonRow className='ionRow'>
                            <IonCol>
                                <IonCol>
                                    <IonLabel className='forLabel'>
                                        {t('labels.dropdown')}
                                    </IonLabel>
                                </IonCol>
                                <IonCol>
                                    <Link className='viewHaggaadot' target='_blank' href='/app/dashboard'>
                                        {t('link.viewHaggaadot')}
                                    </Link>
                                </IonCol>
                                <IonItem>
                                    <IonSelect
                                        className='experienceSelector'
                                        interface='popover'
                                        placeholder={t('placeholder.dropdown')}
                                    >
                                        {experiences?.map((exp: any, i: any) => {
                                            return (
                                                <IonSelectOption value={exp.id}>{exp.title}</IonSelectOption>
                                            )
                                        })}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <div>
                            <span className='uploadHaggadah'>
                                Or{' '}
                                <Link className='link' href='#'>
                                    {t('link.uploadHaggadah')}
                                </Link>{' '}
                                PDF file, up to 10 MB
                            </span>
                        </div>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <IonLabel className='forLabel'>
                                    {t('labels.inviteGuest')}
                                </IonLabel>


                                <div className="contentEmailList">
                                    <TagContent>
                                        {tags.map((tag: any, i: any) => (
                                            <Tag
                                                key={i}
                                                theme={{
                                                    bgColor: tag.label ? tag.bgColor : '#717c9c',
                                                }}
                                            >
                                                {tag.label ? tag.label : tag}
                                                <IconWrapper onClick={(e) => removeTag(i)}>
                                                    <CloseIcon />
                                                </IconWrapper>
                                            </Tag>
                                        ))}
                                    </TagContent>
                                    <EnterTag
                                        placeholder={t('placeholder.textarea')}
                                        value={tag}
                                        onChange={handleChangeTag}
                                        onKeyDown={handleEnteredTag}
                                        onFocus={() => setFocus(true)}
                                        onBlur={() =>
                                            setTimeout(() => {
                                                setFocus(false);
                                            }, 300)
                                        }
                                    ></EnterTag>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <Button
                                    className='createNew'
                                    variant='contained'
                                    onClick={() => submitForm(form)}
                                    color='primary'
                                    disabled={tags.length === 0}
                                    startIcon={
                                        <img
                                            src={Arrow}
                                            alt='Arrow'
                                            style={{ width: '16.14px', height: '16px' }}
                                        />
                                    }
                                >
                                    <span className="color-black">
                                        {t('button.submit')}
                                    </span>
                                </Button>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CreateSedarForm;