import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './scrollToTop';
import TagManager from 'react-gtm-module'


// import { Auth0Provider } from '@auth0/auth0-react';

const { REACT_APP_AUTH0_DOMAIN = '', REACT_APP_AUTH0_CLIENT_ID = '', REACT_APP_GOOGLE_TAG } = process.env;
const tagManagerArgs = {
  gtmId: REACT_APP_GOOGLE_TAG
}
// Google Analytics
TagManager.initialize(tagManagerArgs)

  // window.localStorage.setItem('language','en')
ReactDOM.render(
  <React.StrictMode>
    {/* TODO uncomment Auth0 when needed */}
    {/* <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/dashboard`}
    > */}
    <Suspense fallback={'Loading...'}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
      
    </Suspense>
    {/* </Auth0Provider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
