import {
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonText,
  IonToolbar,
} from '@ionic/react';
import React, { useCallback,useEffect } from 'react';
import styled from 'styled-components';
import Brand from '../components/home/Brand';
import DesktopNavMenu from '../components/home/DesktopNavMenu';
import Footer from '../components/home/Footer';
import MobileNavMenu from '../components/home/MobileNavMenu';
import ForWho from '../components/home/sections/ForWho';
import HowDone from '../components/home/sections/HowDone';
import Partners from '../components/home/sections/Partners';
import NavMenuItem from '../interfaces/NavMenuItem';
import useTranslations from '../util/useTranslation';

const { PUBLIC_URL } = process.env;

const TravelHome: React.FC = () => {
  const [t] = useTranslations('home');

  const scrollToSection = useCallback((id: string) => {
    const section = document.querySelector(`#${id}`);
    section?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const navMenuItems = t('menu', { returnObjects: true }) as NavMenuItem[];
  return (
    <IonPage>
      <MobileNavMenu {...{ navMenuItems, scrollToSection }} />
      <IonContent id='homeContent' scrollY={true}>
        <HomeHeader>
          <IonToolbar>
            <div className='headline'>
              <div className='container'>
                <div className='d-flex ion-justify-content-between'>
                  <Brand />
                  <DesktopNavMenu {...{ navMenuItems, scrollToSection }} />
                  <IonMenuButton />
                </div>
                <div className='headline__text'>
                  <IonText color='warning'>
                    <h1>{t('header.headline')}</h1>
                  </IonText>
                  <IonText>
                    <h2>{t('header.subHeadline')}</h2>
                  </IonText>
                </div>
              </div>
            </div>
          </IonToolbar>
          <img
            className='mockup'
            src={`${PUBLIC_URL}/assets/mockup.mobile.png`}
            alt='App'
          />
          <div className='strips'>
            <div className='container'>
              <div className='description'>
                <IonText color='dark'>{t('description')}</IonText>
              </div>
            </div>
          </div>
        </HomeHeader>

        <ForWho />
        <HowDone />
        <Partners />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

const HomeHeader = styled(IonHeader)`
  ion-toolbar {
    --color: var(--ion-color-primary-contrast);
    --background: url('/assets/bg/header.png') no-repeat 0 0 / cover;
  }

  ion-menu-button {
    --color: #fff;
  }

  .headline {
    padding: 2.5em 0 52vw;
    min-height: 120vw;

    &__text {
      margin-top: clamp(2.3em, 8vw, 4.8em);
    }

    h1 {
      font-weight: bold;
      font-size: clamp(2.625em, 8vw, 4em);
    }

    h2 {
      font-size: clamp(1.75em, 6vw, 2.625em);
    }
  }

  .mockup {
    width: 100%;
    position: absolute;
    transform: translateY(-45%);
    z-index: 10;
  }

  .description {
    font-size: clamp(1.5em, 2vw, 1.625em);
    padding-top: 48.6vw;
    padding-bottom: 1.375em;
  }

  @media screen and (min-width: 1024px) {
    ion-menu-button {
      display: none;
    }

    .headline {
      min-height: initial;
      padding-bottom: 2.875em;

      &__text {
        width: 50%;
      }
    }

    .mockup {
      content: url('/assets/mockup.desktop.png');
      width: 60%;
      right: 0;
      transform: translateY(-33%);
    }

    .description {
      width: 48%;
      padding: clamp(1.375em, 5vw, 4em) 0;
    }
  }
`;

export default TravelHome;
