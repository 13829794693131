import mobileListStyled from 'styled-components';

export const StyledExperienceMobile = mobileListStyled.div`

  .search-results {
    display: flex;
    flex-wrap: wrap;
    gap: 2.2rem;
    max-width: 1765px;
    margin: 0 auto;
  }

  .recommended-cards-wrapper .recommendedCard {
    position: relative;
    height: 264px;
    width: 34.7rem;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  }

  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: 8vw;
  }
  .book-btn {
    padding: 5px 21px;
    font-size: 14px !important;
    color: #000;
    background-color: #fac807;
    border-radius: 7px;
    font-weight: 900 !important;
    position: absolute;
    top: -7px;
    right: 45px;
  }
  .book-btn.euro {
    background-color: #000;
    color: #fff !important ;
  }
  .self-guided-tour {
    height: 24px;
    width: 149px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
  }
  .jewish-vienna {
    width: 281px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 15px;
  }
  .walking-tour-on-jewi {
    height: 40px;
    /* width: 250px; */
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .pin-icon-wrap svg,
  .clock-iocn-wrap svg {
    height: 21px;
    width: 20px;
    fill: #0849ff;
  }
  .pin-icon-wrap span,
  .clock-iocn-wrap span {
    height: 21px;
    width: 94px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 6px;
  }
  #listOfCard .item-title-wrapper {
    max-width: 100%;
  }

  @media only screen and (max-width: 2097px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 33.7rem;
    }
  }
  @media only screen and (max-width: 2040px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 32.7rem;
    }
  }

  @media only screen and (max-width: 1982px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 31.7rem;
    }
  }
  @media only screen and (max-width: 1924px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 30.7rem;
    }
  }

  @media only screen and (max-width: 1867px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 29.7rem;
    }
  }
  @media only screen and (max-width: 1809px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 28.7rem;
    }
  }
  @media only screen and (max-width: 1752px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.7rem;
    }
  }

  @media only screen and (max-width: 1694px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.5rem;
    }
    #listOfCard .item-details {
      width: 64%;
    }
  }
  @media only screen and (max-width: 1683px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 25.8vw;
    }
  }

  @media only screen and (max-width: 1502px) {
    #listOfCard .item-details {
      width: 67% !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.9vw;
    }
    #listOfCard .item-details {
      width: 55% !important;
    }
    .walking-tour-on-jewi {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1366px) {
    .walking-tour-on-jewi {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1284px) {
    #listOfCard .item-details {
      width: 67% !important;
    }
    .banner-inner-img {
      transform: translate(0, 22%);
    }
  }
  @media only screen and (max-width: 1244px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.5vw;
    }
  }

  @media only screen and (max-width: 1199px) {
    .pin-icon-wrap span,
    .clock-iocn-wrap span {
      width: 85px;
      font-size: 12px;
    }

    .recommended-cards-wrapper .recommendedCard {
      width: 43.2vw;
    }
  }
  @media only screen and (max-width: 1088px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 43.1vw;
    }
  }

  @media only screen and (max-width: 1074px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 42.2vw;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    .recommended-cards-wrapper .recommendedCard .item-preview {
      width: auto;
  height: 165px;
    }
    #listOfCard .item-details {
      width: 60% !important;
    }
    .jewish-vienna {
      margin-top: 6px;
    }
    .walking-tour-on-jewi + .icon-wrap {
      margin-top: 15px !important;
    }
    .item-details-header {
      margin-top: -11px;
    }
    .book-btn {
      top: -15px;
    }
  }
  @media only screen and (max-width: 991px) {
    #listOfCard .item-details {
      width: 55% !important;
    }
    .recommended-cards-wrapper .recommendedCard .item-preview {
      width: 15vw;
    }
  }

  @media only screen and (max-width: 768px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 85%;
      height: auto;
    }
    #listOfCard .item-details {
      width: 100% !important;
    }
    .recommended-cards-wrapper .recommendedCard .shadow{
      flex-direction: column;
    }
    .jewish-vienna .item-title-link{
   font-size:20px;
    }
  }

  @media only screen and (max-width: 575px) {
  .recommended-cards-wrapper{
    position: relative;
  }
  .sliderBtn-wrap .arrow-btn.prev{
    position: absolute;
  }
  .jewish-vienna .item-title-link,
  .jewish-vienna{
    width: 100% !important;
  }

  .item-title-wrapper {
    max-width:100% !important;
  }
  .sliderBtn-wrap .arrow-btn.next{
    position: absolute;
  }
  }

 .recommended-cards-wrapper .recommendedCard {
    width: 90%;
}
.recommendedCard .shadow{
  flex-direction: column;
  gap: 2.1rem;
}
.recommended-cards-wrapper .recommendedCard {
  box-shadow:none;
}
.recommended-cards-wrapper .recommendedCard .item-preview {
  width: 100%;
  height: 50%;
 
}
.walking-tour-on-jewi {
  height: 46%;
  margin-top: 19px;
}
.book-btn {
  top: 47%;
}
.slick-dots li button:before {
  font-size: 13px;
}
.sliderBtn-wrap button svg{
  width: 50px;
  height: 35px;
  fill:#7782a0;
}

.slick-dots {
  bottom: -44px;
}
.map-card-wrap {
  margin-bottom: 50px;
  margin-top: 50px;
}
.explore-wrap h3 {
  margin-top: 60px;
}
.line {
  width: 100vw;
}
.search-results {
  gap: 0.2rem;
}
  }

  @media only screen and (max-width: 375px) {
    #listOfCard .item-details {
      width: 100% !important;
    }
    .pin-icon-wrap span,
    .jjTLoc .clock-iocn-wrap span {
      width: 68px;
    }
  }

  @media only screen and (max-width: 320px) {
    .jewish-vienna {
      width: 203px;
    }
  }
`;