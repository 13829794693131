import mobileItemCardWrapStyled from 'styled-components';

export const StyledItemCardWrapperMobile = mobileItemCardWrapStyled.div`
width: 29rem;
height: 18.75rem;
font-size: 1.375rem;
line-height: 1.2;

.item-details-header {
  visibility: hidden;
}
`;