import { IonContent, IonModal, IonSpinner } from '@ionic/react';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ClearIcon from '@material-ui/icons/Clear';
import ListIcon from '@material-ui/icons/List';
import RoomIcon from '@material-ui/icons/Room';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import {
  useMapEvents
} from 'react-leaflet';
import { NavLink, useRouteMatch } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import img4 from '../../assets/img4.jpg';
import { ItemType } from '../../model/ItemType';
import Footer from '../../pages/Home/Footer';
import Header from '../../pages/Home/Header';
import {
  exploreExperiences,
  getLocations,
  queryExperiences
} from '../../util/API';
import useQuery, { QUERY_KEY } from '../../util/useQuery';
import useTranslations from '../../util/useTranslation';
import ExperienceBrowse from '../ExperienceBrowse/ExperienceBrowse';
import FilterToolbar from '../FilterToolbar/FilterToolbar';
import FilterItem from '../search/FilterItem';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#0849FF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#0849FF',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const Location: React.FC = () => {
  const [ds] = useTranslations('dashboard');
  const [t] = useTranslations('search');
  const [checked, setChecked] = useState<boolean>(false);
  const [bbox, setBbox] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const itemsPerPage = 12;
  const [map, setMap] = useState<boolean>(false);
  const sliderRef = useRef<any>(null);
  let [defaultData, setdefaultData] = useState<any>([]);
  const [page, setpage] = useState<number>(1);
  const [spinner, setSpinner] = useState(false);
  const [location, setLocation] = useState<any>();
  const { params } = useRouteMatch<{ id?: string }>();
  const zoom = 8;

  var maplocation: any = [];
  let [allData, setallData] = useState<any>([]);
  const { getQuery, setQuery, mapLocation, searchParams, getGeoLocation } =
    useQuery(QUERY_KEY);
  const query = getQuery();
  const paramStr = searchParams().toString();
  const {
    filter: filters,
    experiences: expType,
    language: languages,
    holiday: holidayRelated,
  } = t('searchexplore.filterslist', {
    returnObjects: true,
  }) as {
    filter: Array<{ id: string; text: string }>;
    experiences: Array<{ id: string; text: string }>;
    language: Array<{ id: string; text: string }>;
    holiday: Array<{ id: string; text: string }>;
  };
  const { toggleFilter } = useQuery('guided', 'true');
  const _ApplyFilter = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    }: any = await queryExperiences(paramStr);
    setSpinner(false);
    setallData(experiences);
  };
  const onPageChange = (event, value) => {
    setpage(value);
    document.getElementById('section')?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  useEffect(() => {
  }, []);

  const fetchLocationData = async () => {
    setSpinner(true);
    console.log(params);
    const {
      data: { locations },
    } = await getLocations(params?.id);
    setSpinner(false);
    setLocation(locations[0]);

    let experiences: any[] = []
    locations[0].experiences.forEach((exp_loc_dict) => {
      experiences.push(exp_loc_dict.experiences_id);
    });
    setallData(experiences);
    setdefaultData(experiences);
  };

  useEffect(() => {
    fetchLocationData();
  }, [params?.id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    toggleFilter();
  };

  const openMap = () => {
    setOpen(true);
  }

  const handleMap = (value: any) => {
    setMap(value);
  }

  useEffect(() => {
    const fetchResults = async () => {
      setSpinner(true);
      const {
        data: { experiences },
      }: any = await queryExperiences(paramStr);
      setSpinner(false);
      setallData(experiences);
    };
    if (!paramStr) {
      setChecked(false);
      setallData(defaultData);
    } else {
      fetchResults();
    }
  }, [paramStr, bbox]);

  const renderArrows = () => {
    return (
      <div className="sliderBtn-wrap">
        <ButtonBase
          className='arrow-btn prev'
          onClick={() => sliderRef.current.slickPrev()}
        >
          <FiArrowLeft />
        </ButtonBase>
        <ButtonBase
          className='arrow-btn next'
          onClick={() => sliderRef.current.slickNext()}
        >
          <FiArrowRight />
        </ButtonBase>
      </div>
    );
  };

  const Partners = () => {
    return (
      <div className='d-flex partnership'>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <span className='created-in-partnersh'>Partners</span>
          <div className='d-flex img-list'>
            {location?.partners.map((partner, index) => (
              <div>
                <img src={partner.partners_id.logo_url} alt={partner.title} />
              </div>
            ))
            }
          </div>
        </div>
      </div>
    )
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const _showMap = () => {
    setMap(true);
  };

  const Images = () => {
    if (location?.images.length > 0) {
      return location?.images;
    }
    //return [ {url: 'https://via.placeholder.com/600/0849ff/fff.png?text=|'} ]
    return [{ url: '/assets/bg/header.png' }]
  }
  return (
    <div className='main' id='main-pr'>
      <Helmet>
        <title>{location?.title}</title>
        <meta property="og:title" content={location?.title} />
        <meta property="og:description" content={location?.description} />
        <meta property="og:image" content={Images()[0].url} />
      </Helmet>
      <style>{css}</style>
      <Header />
      <div className="sliderWrap" style={{ marginTop: '60px', position: 'relative' }}>
        {renderArrows()}
        <span className='d-flex slider-text-wrap'>
          <RoomIcon />
          <span>{location?.title}</span>
        </span>
        <Slider ref={sliderRef} {...settings}>
          {Images().map((image, index) => (
            <div className='slider-item'>
              <img src={image.url} />
            </div>
          ))
          }
        </Slider>
      </div>

      <div className='operated-section'>
        <div className='container'>
          <div className='d-flex operator-wrapper'>
            <div className='d-flex operator-sub-one'>
              {/* <div className='d-flex'>
                <div>
                  <img src={img4} className='operator-profile-pic' />
                </div>
                <div className='operation-profile-title'>
                  <span className='operated-by'>{ds('location.area-guide')}</span>
                  <span className='centropa'>Mark Segenreich</span>
                </div>
        </div> */}
              <div className='operator-sub-two mobileview'>
                {Partners()}
              </div>
              <div className='location-description'>
                <p dangerouslySetInnerHTML={{ __html: location?.description }} ></p>
              </div>
            </div>
            <div className='operator-sub-two desktopview'>
              {Partners()}
            </div>
          </div>
        </div>
      </div>

      <div id="section">
      <FilterToolbar location={location} mapLocation={mapLocation} map={map} handleOpen={() => openMap()} handleMap={(value) => handleMap(value)} />

      <div className='map-list-btn mobileview'>
        {map == false ? (
          <Button
            onClick={() => setMap(true)}
            className='switchbutton show-map'
          >
            {t('searchexplore.map')}
            <RoomIcon />
          </Button>
        ) : (
          <Button
            onClick={() => {
              mapLocation(null);
              setMap(false);
            }}
            className='switchbutton list-view'
          >
            {t('searchexplore.list')}
            <ListIcon />
          </Button>
        )}

      </div>
      {spinner && (
        <div className='spinner'>
          <IonSpinner color='primary' />
        </div>
      )}

      {map == true ? (
        allData.length > 0 ? (
          <div className='container'>
            <ExperienceBrowse isShowExperienceMap allData={allData} handleBboxdata={(value) => setBbox(value)} zoom={zoom} />
          </div>
        ) : (
          query ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span
                style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}
              >
                {t('searchexplore.noresult')}
              </span>
            </div>
            : null
        )
      ) : allData.length > 0 ? (
        <div className="container">
          <div
            className='search-explore-card-wrap'
            style={{ marginTop: '60px' }}
          >
            {allData
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((experience) => (
                <ExperienceBrowse isShowExperienceList item={experience} ds={ds} zoom={zoom} />
              ))}
          </div>
          <div className='pagination-wrap' style={{ marginTop: '50px' }}>
            {allData.length > 12 ? (
              page == 1 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span className='experiences'>
                    {ds('location.display', {
                      count: allData.length
                    })}
                  </span>
                </div>
              ) : null
            ) : null}
            <div className='pagination-section container'>
              <div className='pagination'>
                <Pagination
                  hidePrevButton={page == 1 ? true : false}
                  hideNextButton={
                    page == Math.ceil(allData.length / itemsPerPage)
                      ? true
                      : false
                  }
                  onChange={onPageChange}
                  count={Math.ceil(allData.length / itemsPerPage)}
                  page={page}
                  defaultPage={1}
                  color='primary'
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}>
            {t('searchexplore.noresult')}
          </span>
        </div>
      )}
</div>
  {/* 
      <div className='container related-Resources-wrap' >
        <div className='related-Resources'>
          <h2>{ds('location.resource')}</h2>
          <div className='d-flex related-card-wrap'>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>
            <div className='related-card'>
              <div>
                <img src={img4} className='related-card-img' />
                <span>Operation Passover</span>
                <p>
                  JDC in an unprecedented Passover campaign in the Soviet Union
                  durng perestroika
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      */}
      {open == true ? (
        <IonContent>
          <div className='abc'>
            <Modal isOpen={open} onDidDismiss={() => setOpen(false)}>
              <div className='filtermodel'>
                <div className='model-heading'>
                  <div>
                    <span> {t('searchexplore.popup.head')}</span>
                    <div className='canclebtn'>
                      <ClearIcon onClick={() => setOpen(false)}></ClearIcon>
                    </div>
                  </div>
                </div>
                <div className='filter-model-wrap'>
                  <div className='content-wrap'>
                    <section>
                      <h5> {t('searchexplore.popup.exphead')}</h5>
                      <p>{t('searchexplore.popup.exptitle')} </p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {expType.map((item) => (
                          <FilterItem
                            text={item.text}
                            value={item.id}
                            param={filters}
                            filterKey='type'
                            moreFilter={true}
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.guided')}</h5>
                      <p>{t('searchexplore.popup.exptitle')} </p>
                      <div className='tag-wrap Guided-tag'>
                        <span
                          className='everthing'
                          style={{ color: checked ? '#c3c9d8' : '#0849ff' }}
                        >
                          {t('searchexplore.popup.toggleone')}
                        </span>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={checked}
                              onChange={handleChange}
                              name='checked'
                            />
                          }
                          style={{ color: checked ? '#0849ff' : '#c3c9d8' }}
                          label={t('searchexplore.popup.toggletwo')}
                        />
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.language')}</h5>
                      <p>{t('searchexplore.popup.languagetitle')}</p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {languages.map((item) => (
                          <FilterItem
                          text={item.text}
                          value={item.id}
                            moreFilter={true}
                            filterKey='language'
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                  <div className='content-wrap'>
                    <section>
                      <h5>{t('searchexplore.popup.holiday')}</h5>
                      <p>{t('searchexplore.popup.holidaytitle')}</p>
                      <div
                        className='tag-wrap'
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {holidayRelated.map((item) => (
                          <FilterItem
                            text={item.text}
                            value={item.id}
                            moreFilter={true}
                            filterKey='holiday'
                            explore={true}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                </div>
                <div className='d-flex footer-wrap signin'>
                  <div>
                    <NavLink
                      to='#'
                      onClick={() => {
                        setChecked(false);
                        setOpen(false);
                        setallData(defaultData);
                      }}
                      className='tos'
                    >
                      {t('searchexplore.popup.clear')}
                    </NavLink>
                    <Button
                      className='signupbtn'
                      variant='contained'
                      onClick={() => {
                        setOpen(false);
                        _ApplyFilter();
                      }}
                      color='primary'
                      endIcon={<ArrowRightAltIcon />}
                    >
                      {t('searchexplore.popup.apply')}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </IonContent>
      ) : null}
      <Footer />
    </div>
  );
};
const css = `
--body{
  overflow:hidden;
}
.d-flex{
  display:flex;
}
.slider-arrow {
  position: absolute;
  width: 75%;
  color:white
  margin-top: 251px;
}
.slick-dots li button:before {
  font-size: 12px;
  display:none;
  opacity:0.5;
  color:#fff;
}
.slick-dots li{
  width:11px;
}
.slick-dots li.slick-active button:before{
  opacity:0.95;
  color:#fff;
}
.arrow-btn {
  top: 45%;
  font-size: 35px;
  z-index: 1;
}
.mobileview{
  display:none;
}
.next {
  float: right;
}
.search-explore-card-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
}
.slick-slide > div {
  display: grid;
  place-items: center;
  width: 88%;
  margin-top: 50px;
  margin: auto;
  height: 550px;
  padding: 0px;
}
.slick-slide img{
  width: 100%;
  height: 550px;
  border-radius: 25px;
  object-fit: cover;
} 

// Location Style

.slick-slide .slider-item{
  position: relative;
  }
  .slider-text-wrap{ 
    color: #fff;
    position: absolute;
    top: 44%;
    z-index: 100;
    width: 100%;
    height: 14%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slider-text-wrap svg {
    width: 3em;
    height: 2em;
  }
  .slider-text-wrap span{
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 53px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
  }
  .operator-profile-pic {
    height: 110px;
    width: 111px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
    margin-right: 30px;
  }
  .operator-profile-pic svg {
    width: 50px;
    height: 50px;
    fill: #486cb3;
  }
  .operation-profile-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .operated-by {
    // height: 30px;
    width: 105px;
    color: #717c9c;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
margin-bottom:20px;    
  }
  .centropa {
    height: 32px;
    width: 304px;
    color: #0849ff;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }
  .operator-sub-one {
    // width: 65%;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: baseline;
    flex-direction: column;

    // margin-right: 154px;
  }
  .operator-sub-one p {
    // height: 180px;
    width: 973px;
    color: #717c9c;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
  }


  .operator-wrapper {
    margin-top: 5vh;
    margin-bottom: 2vh;
  }

  .location-description {
    margin-top: 5vh;
    margin-bottom: 10vh;

  }

  // .operated-section {
  //   padding: 75px 0;
  // }
  .actions-card {
    height: 412px;
    width: 455px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px 72px;
    background-color: #fff;
    box-shadow: 0px 0px 14px 0px #9e9e9e;
    border-radius: 20px;
  }
  .Ops-rectangle {
    box-sizing: border-box;
    height: 41px;
    width: 301px;
    border: 1px solid #0849ff;
    border-radius: 20px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions-card h2 {
    text-align: center;
    height: 60px;
    width: 304px;
    color: #0849ff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 auto 47px;
  }
  .izi-travel {
    height: 28.44px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24.8px;
  }
  .Ops-rectangle svg {
    height: 19px;
    width: 41.83px;
   fill: #0749ff;
  }
  .youMightAlsoLike {
    padding:130px 0;
  }
  .created-in-partnersh {
    height: 19px;
    width: 172px;
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
  }
  .created-in-partnersh+img-list{
    margin-left:47px;
  }
  .img-list img{
    height: 88.47px;
    width: 107.4px;
    object-fit: scale-down;
  }
  .img-list div{
  margin-right:55px;
}
.partnership{
  padding:39px 0;
  align-items: center;
  text-transform: uppercase;
}
// .link{
//   margin-left:15px;
// }

.related-Resources-wrap{
  padding-top: 73px;
  border-top: 1px solid;
}

.related-card{
  width:auto;
  // width: 16.7vw;
  padding: 50px 0px 50px 0px;
}
.related-card div{
  margin-right: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.related-card-wrap{
  flex-wrap: wrap;
}
.related-card div img{
  height: 206px;
  width: 300px;
  border-radius: 15px;
  background-color: #E8E8E8;
  margin-bottom:27px;
}

.related-card div span{
height: 30px;
  width: 281px;
  color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.related-card div p{
height: 60px;
width: 250px;
color: #717C9C;
font-family: "Source Sans Pro";
font-size: 16px;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}

.related-Resources h2{
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}
  .searchBar{
     display: flex;
     justify-content: space-between;
    //  margin-top: 80px;
  }
  .filterview{
    display: flex;
    align-items: center;
  }

  .filter{
    margin-left: 18px;
  }

  .experiences{
    color: #8992ac;
    padding-top: 75px;
    padding-bottom: 43px;
  }

  .morefilters{   
  height: 41px;
  width: 100%;
  margin-left: 18px;
  // border-radius: 25px;
  color: black;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: none;
  box-sizing: border-box;
  border: 1px solid #2A2A2A;
  border-radius: 20px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background-color: transparent;
  }
  .morefilters:hover{
    border: 1px solid rgb(17, 79, 255);
    color: rgb(17, 79, 255);
    background-color: transparent;
  }
  .switchbutton{
    height: 40px;
    width: max-content;
    margin-left: 18px;
    background-color: #0849FF;
    color: white;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .switchbutton:hover{
    background-color: #031e6a;
  }

  .switchbutton.show-map svg{
    height: 28px;
    width: 18px;
    margin-left: 11px;
  }
  .switchbutton.list-view svg{
    height: 33px;
  width: 27px;
    margin-left: 11px;
  }
 

  .pagination{
    display:flex;
    justify-content:center;
    // margin-bottom:100px;
    margin-bottom:70px;
  }

  .pagination li + li {
   margin-left:0; 
  }
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
.map-img{
    display: flex;
    justify-content: center;
}

.map-img .map-content{
  position: relative;
    outline: none;
    height: 958px;
    width: 1594px;
    margin-top: 50px;
  margin-bottom:170px;
   border-radius: 20px;
   height: 958px;
}

.map-img,
.map-img .map-content{
   height: 711px;
  //  width: 100vw;
   margin-top: 50px;
   margin-bottom:170px;
   border-radius: 20px;
}

.map-wrap{
position:relative;
}
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 60px;
top: 20px;
}
.banner-img{
  height: 350px;
  width: 100%;
  background-size: cover;
  padding: 61px 0;
  background-position: 44% 10%;
  background-repeat: no-repeat;
}
.banner-img .container{
  align-items: center;
  width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-img .container h5{
height: 72px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 28px;
font-weight: bold;
letter-spacing: 0;
line-height: 31px;
text-align: center;
padding: 0 26px;
}

.banner-img .container p{
height: 72px;
width: 411px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}


.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
height: 128px;
width: 840px;
margin-top: 100px;
color: #0849ff;
font-family: "Source Sans Pro";
font-size: 54px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
height: 87px;
width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}




ion-item {
  width: 459px;
  border-radius: 25px;
  background-color: #FFFFFF;
  box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
}

.poplular-subject-title{
  // height: 49px;
  height: 120px;
  width: 100px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.model-heading{
  display: flex;
  justify-content: space-between;
  padding: 34px 34px 0px 34px;
}
.filter-model-wrap{
  padding-top: 20px;
  height: 72vh;
  overflow: auto;
}

.model-heading span{
  height: 48px;
  width: 204px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}

.pagination li button{
  color:717C9C;
}

.map-img .leaflet-popup-content {
margin:0px 0px ;
width:282px !important;
 }


.sliderBtn-wrap{
    width: 75%;
    position: absolute;
    color: white;
    marginTop: 251px;
    left:  210px;
    // top: 11%;
    top: 48%;
    z-index: 200;

}


.map-img, .map-img .map-content {
  height: 680px;
  width: 85vw;
  margin:50px auto 170px;
}


.filtermodel{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-wrap.signin > div {
  padding: 25px 0;
}

@media only screen and (max-width: 1980px) {
  .operator-sub-one {
    width: 52vw;
    margin-right: 52px;
  }
  .operator-sub-one p{
    width:100%;
  }
}

@media only screen and (max-width: 1199px) {
  .online-wrap.first {
    width: 20%;
  }
}

@media only screen and (max-width: 1440px) {
  .header-bg {
  margin-bottom: 460px;
  }
//   .operated-section {
//     padding: 75px 0 75px;
// }
.slider-text-wrap {
  top: 43%;
}
.sliderBtn-wrap {
  left: 12.5%;
}
.related-card {
  width: 18vw;
}
.related-card div p {
  width: 100%;
}
}

@media only screen and (max-width: 1366px) {
 .header-bg {
    margin-bottom: 520px;
}
// .footer-section .row-3 .list {
//   width: 147px;
// }



}

@media only screen and (max-width: 1199px) {
.slider-text-wrap {
  top: 45%;
}
}


@media only screen and (max-width: 1024px) {
  // .sliderBtn-wrap{
  //   left: 125px;
  // }
  .related-card div{
    margin-right: 0px;
  }
  .online-wrap svg {
    fill: #fff;
    margin-right: 9px;
    width: 22px;
  }
  .online {
    font-size: 20px;
  }
  .online-wrap span {
    width: 55%;
  }
  .virtual-exhibit {
    font-size: 20px;
  }
  .operator-sub-one p {
    font-size: 17px;
  }
 .img-list div {
    margin-right: 38px;
}
.actions-card {
 width:100%;
 padding: 2rem 2rem;
 justify-content: center;
}

}

@media only screen and (max-width: 991px) {
  .online-wrap.first {
    width: 18%;
  }
  .operator-sub-one {
    width: 46vw;
    margin-right: 8%;
}
.operator-profile-pic {
  height: 81px;
  width: 82px;
  margin-right: 100px;
}
.slider-text-wrap span {
  font-size: 32px;
}
.slider-text-wrap svg {
  width: 2em;
  height: 1.7em;
}

}

@media only screen and (max-width: 768px) {
  .arrow-btn{
    display:none;
  }
  .searchBar .search-inner-wrap{
    text-align: left;
  }
  .sliderWrap{
    margin-top: 0 !important;
  }
  .slick-slide img{
    border-radius: 0;
  }
  .operator-profile-pic {
    margin-right: 30px;
}
.filter-model-wrap {
  padding-top: 20px;
  height: 78vh
}
.slick-dots{
  bottom:15px;
}
  .slick-dots li button:before {
    display:block;
  }
  .slider-text-wrap{
        //width: 34%;
        display:flex;
        flex-direction:column;
  }
  .map-list-btn.mobileview{
    position: relative;
  }
  .map-list-btn.mobileview .switchbutton{
    position: absolute;
    top: 162px;
    left: -35px;
    z-index: 1111;
    width: 185px;
  }
  .filterview {
    width: 100%;
    flex-direction: initial;
  }
  .map-list-btn.desktop{
    display:none;
  }
  .mobileview{
    display:block;
  }
  .desktopview{
    display:none;
  }
  .filterview {
    width: 100%;
    flex-direction: initial;
  }
  .rectangle {
    margin: 53px 0 44px;
  }
  .online-wrap.first {
    width: 45%;
  }
  .online-wrap span {
    width: 91%;
  }
.header-bg {
    margin-bottom: 405px;
}
.img-wrap img {
  height: 30vh;
}
.img-wrap .img-sub-wrap img {
  height: 125px !important;

}
.header-bg {
  margin-bottom: 225px;
  // height: 396px;
}

.operator-wrapper,
.partnership {
flex-direction: column;
margin-top: 40px;
align-items: baseline;
}
.operator-sub-one {
width: 100%;
margin-right: 0px;
margin: 0 auto;
}
.operator-profile-pic {
height: 90px;
width: 90px;
}
.operator-profile-pic svg {
width: 37px;
}
.centropa {
width: auto;
font-size: 19px;
}

.operator-sub-one p {
// height: 100%;
width: 100%;
font-size: 15px;
line-height: 30px;
}

.actions-card {
height: 100%;
width: 100%;
padding: 40px 72px;
}
.operator-sub-two {
// margin-top: 40px;
margin-top: -69px;

}
.partnership > div {
margin-top: 21px;
// flex-direction: column;
}
.img-list {
margin-top: 20px;
}
.img-list img {
height: 46.47px;
width: 152.4px;
  padding: 0px 20px;
}
// .operated-section {
// padding: 0px 0 0;
// }
.actions-card h2 {
height: 40px;
width: 100%;
}
.img-list div {
margin-right: 15px;
}
.created-in-partnersh {
// width: 35%;
font-size: 19px;
text-align: left;
}
.search-explore-card-wrap>div{
  // width: 45%;
}
.map-img,
.map-img .map-content {
height: 25vh;
}
.youMightAlsoLike {
padding: 0px 0;
}
.map-main-wrap {
padding-bottom: 20px;
}
.Ops-rectangle {
width: 77vw;
}
.sliderBtn-wrap {
  width: 68%;
  left: 16.5%;
}
.slick-slide > div {
  width: 100%;
}
.related-card-wrap{
  // flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  margin-bottom:35px;
}
.related-card {
  // width: 90vw;
    padding: 50px 0px 0px 0px;
    width: 45vw;
    margin: 0 auto
}
.related-card div p {
  height: auto;
}
.search-inner-wrap{
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.map-img,
.map-img .map-content {
  // width: 100%;
  height: 70vh;
  border-radius: 15px;
}

}

@media only screen and (max-width: 575px) {
.slider-text-wrap svg {
  width: 2em;
  height: 5vw;
}
.slider-text-wrap {
  top: 49%;
  width: 100%;
}
.sliderBtn-wrap {
  top: 53.9%;
  width: 63%;
  left: 18.5%;
}
.slider-text-wrap span {
  font-size: 5.2vw;
}

}

@media only screen and (max-width: 425px) {
  .rectangle {
    font-size: 17px;
  }
  .imgs-wrapper.d-flex {
    display: flex;
    flex-direction: column;
  }
  .img-wrap img {
    height: 200px;
    width: 100%;
  }
.header-bg {
margin-bottom: 820px;
  }
  .img-wrap {
    padding: 0px 5px;
}
.img-wrap .img-sub-wrap img {
  height: 200px !important;
}
.slick-slide img {
  // height: 380px;
}
.sliderBtn-wrap {
  width: 61%;
  left: 20.5%;
}
.arrow-btn {
  font-size: 20px;
}
.related-card {
  width: 100vw;
  padding: 21px 0px 0px 0px;
}
.related-card:first-child div,
.related-card div,
.related-card:last-child div {
  margin-right: 0;
  margin-left:0;
}

}

@media only screen and (max-width: 375px) {
  .header-bg {
    margin-bottom: 915px;
}
  .actions-card {
    padding: 40px 20px;
  }
  .created-in-partnersh {
    // width: 100%;
    font-size: 3.8vw;
  }
  .link {
    font-size: 4vw;
  }
  .j-story-is-the-pro {
    width: 283px;
    margin-right: 0px;
  }
}


@media only screen and (max-width: 1980px) {
ion-item {
  width: 100%;
}
.searchBar .search-inner-wrap{
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: bold;
}
.filterview {
  // width: 78%;
  // width: 72%;
  justify-content: flex-end;
}
.filter-btn-tag {
  width: 95%;
}
.search-explore-tag>div{
  width: 95%;
}
.filter {
  width: 155px;
  margin-left: 6px;
}
.filter-wrapper.d-flex{
  justify-content: flex-end;
}

}

@media only screen and (max-width: 1791px) {

.filterview {
  width: 100%;
}
}
@media only screen and (max-width: 1620px) {

// .map-img, .map-img .map-content {
//   height: 680px;
//   width: 640px;
// }
.line {
  height: 680px;
}
.filter-wrapper.d-flex {
  justify-content: flex-end;
  width: 66%;
}
}
@media only screen and (max-width: 1502px) {

}

@media only screen and (max-width: 1440px) {

.map-img, .map-img .map-content {
  width: 90vw;
  margin: 28px auto;
}
.map-main-wrap{
  padding-bottom: 57px;
}
}
@media only screen and (max-width: 1366px) {
.filterview {
  width: 100%;
}
.searchBar {
  flex-direction: column;
}
.searchBar .search-inner-wrap{
  margin-bottom: 25px;
}
.filter-wrapper.d-flex {
  // width: 80%;
}
.filter {
  width: 171px;
}
}

@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}

.card-wrap {
width: 100vw;
  height: auto;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
.card span,
.highlight span {
height: 32px;
width: 117px;
}

.partner-card .card-1 {
height: 420px;
width: 48%;
}


}

@media only screen and (max-width: 1244px) {
  

 }

@media only screen and (max-width: 1200px) {
.filterview {
  justify-content: initial;
    width: 92%;
    overflow: auto;
    padding-bottom: 24px;
  }
  .filter-wrapper.d-flex {
    width: 97%;
}
.filter {
  width: 215px;
}
.morefilters,
.switchbutton {
  // font-size: 1vw;
  padding-left: 13px;
  padding-right: 13px;
}
.experience-the-jewis {
  margin-top: 20px;
}
}


@media only screen and (max-width: 1199px) {
  .card-wrap .card img, .card-wrap .highlight img {
    height: 67%;
    max-width: 158px;
  }
  
  .card-wrap {
    height: auto;
  
  }

  
}

@media only screen and (max-width: 1024px) {
  .filter-wrapper.d-flex {
    // width: 60%;
}
.filter {
  // width: 108px;
}
.morefilters {
  width: 150px;
}
.switchbutton {
  width: 200px;
}
.experience-the-jewis {
  width: 100%;
  font-size: 4vw;
}
.j-story-is-a-communi {
  width: 100%;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  font-size: 2.7vw;
}
.shareStory .shareguid {
  padding: 57px 100px 2px 35px;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4{
margin-top: 53px;
}
.shareStory .col-md-4:nth-child(3){
// padding: 206px 0 50px;
}
.partner-card p {
// font-size: 1.8vw;
}
// .footer-section .row-3 .list {
// width: 170px;
// }
.related-card {
  width: 19.8vw;
  margin: 0 auto;
}
}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.related-card {
  width: 25.8vw;
}

.card span {
height: 31px;
width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}


.searchBar {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}
.searchBar .search-inner-wrap {
  width: 100%;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
      text-align: center;
      margin-bottom: 38px;
}
.operation-profile-title {
  margin-right: -25px;
}
.filterview {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.filter-wrapper.d-flex{
  width: 100%;
}
.filter {
  width: 18%;
  margin-bottom:30px;
}
.morefilters, .switchbutton {
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

.popular-card-wrap{
  display: flex;
  flex-direction: column;
}
.poplular-subject-title {
  width: 100%;
    height: 30px;
}
.card-wrap {
  height: auto;
  width: 877px;
  overflow: auto;
}

}

@media only screen and (max-width: 768px) {
.experience-the-jewis{
line-height: 43px;  
} 
.searchBar .search-inner-wrap{
  text-align: left;
  margin-top:40px;
}
.related-card {
  width: 43.8vw;
}
.mobileview{
  display:block;
}
.filterview {
  overflow: auto;
  justify-content: flex-start;
  flex-direction: initial;
  width: 100%;
  align-items: center;
  display: flex;
}
.filter{
  margin-bottom:0;
}

.filter-wrapper.d-flex{
  justify-content: flex-start;
  width: auto;
}

.footer-wrap.signin > div {
  align-items: baseline !important;
  flex-direction: column;
  padding: 25px 0;
}
.tos{
  margin-bottom: 20px;
      }
      .footer-wrap .signupbtn {
    width:100%;
      }

.search-explore-card-wrap{
  position: relative;
}
.search-explore-tag>div {
  width: 190px;
}
.container.search-explore-card-wrap .map-list-btn.mobileview{
  position: absolute;
  z-index: 11;
  left: -71px;
}
.container.search-explore-card-wrap .map-list-btn.mobileview .switchbutton{
  width: 170px;

}

// .filter-wrapper.d-flex {
//   width: 72%;
//   overflow: auto;
// }

.filterview .morefilters{
  width: 150px;
}

.filter {
  width: 200px;
}
.desktopview{
  display:none;
}
// .signupbtn{
// font-size: 1.8vw;
//     width: 115px;
// }
// .signupbtn span span:nth-child(1){
// display:none;
// }

.card,.highlight {
  height: 220px;
  width: 26%;
}

.card-wrap .highlight span, .card-wrap .card span {
  width: 100%;
}

.card-wrap .card img, .card-wrap .highlight img {
  height: 67%;
  max-width: 150px;
}

.shareStory .col-md-4 {
width: 100%;
}
.shareStory {
height: 100vh;
}
.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .shareguid {
padding: 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
margin-top: 22px;
}
.shareStory h2 {
width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
margin-top: -8px;
}
.partner-card p {
width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}

.card-wrap {
  margin-top: 23px;
  max-width: 669px;
  overflow-x:auto;
}
.container.search-explore-card-wrap>div{
width:100%;
}

}

@media only screen and (max-width: 575px) {

.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 263.75px;
width: 306.09px;
}
.experience-the-jewis {
height: 70px;
line-height: 31px;
font-size: 23px;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {

}
.line {
height: 1px;
width: 90vw;
margin-top: 40px;
}
.card-wrap {
width: 87vw;
}
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: -11px;
}
.partner-card .card-1 {
margin: 24px 0;
}
.filter {
  margin-left: 9px;
}
.card, .highlight {
  height: 220px;
  width: 44%;
}

}

@media only screen and (max-width: 475px) {
.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.banner-img .container,
.banner-img .container p {
  width: 100%;
}
.banner-img .createNew {
  height: 40px;
  width: 55%;
}
}

@media only screen and (max-width: 425px) {
.j-story-is-a-communi {
font-size: 16px;
line-height: 20px;
}
.shareStory h2 {
height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}
.search-explore-card-wrap>div {
  width: 90%;
  margin: 0 auto;
}
.related-card {
  width: 100%;
}
}

@media only screen and (max-width: 375px){
.banner-img .createNew {
  height: 40px;
  width: 65%;
  margin-top: 26px;
}
.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
}
@media only screen and (max-width: 360px){
}
@media only screen and (max-width: 320px){
.morefilters, .switchbutton {
  padding-left: 16px;
  padding-right: 16px;
}
.jewish-vienna {
  width: 203px;
}
}

#listOfCard .leaflet-popup-content{
  margin:0 0 !important;
    }

`;

const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  --font-family: 'Blooming Elegant', sans-serif;
  --color: red;

  .ion-page {
    background-color: #fff;
  }
  .modal-wrapper {
    height: 94%;
    width: 946px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  }

  .model-heading > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #7782a0;
  }

  .content-wrap {
    padding: 0 47px;
    margin-bottom: 35px;
  }
  .content-wrap section h5 {
    height: 40px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .content-wrap section p {
    height: 25px;
    width: 364px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
  }

  .content-wrap .filter {
    background-color: #F7F7F7;
    height: 37px;
    margin-left: 0px !important;
    margin-right: 8px !important;
    padding: 11px 20px;
    border-radius: 20px;
    color: #696363;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    border-radius: 20px;
  }
  .filter:hover {
    background-color: rgb(17, 79, 255);
    color: #f7f7f7;
  }
  .tag-wrap {
    margin-top: 42px;
  }
  .tag-wrap>div{
    margin:9px;
  }

  .tag-wrap.Guided-tag {
    margin-top: 35px;
  }

  .everthing {
    height: 20px;
    width: 76px;
    // color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }

  .tag-wrap label {
    margin: 0 10px;
  }
  .Component-switchBase-2.Component-checked-5 + .Component-track-4 {
    background-color: #0849ff !important;
    height: 83%;
  }

  .Component-switchBase-2.Component-checked-5 {
    transform: translate(16px, -2px) !important;
  }
  .tos {
    width: 216px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }


  .footer-wrap.signin {
    padding: 16px 47px 0;
  }
  .footer-wrap.signin > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-top: 1px solid #7782a0;
    padding: 25px 0;
  }
  
  #listOfCard .leaflet-popup-content {
    margin: 0 0 !important;
  }
  @media only screen and (max-width: 1024px){
  .content-wrap .filter {
    padding: 11px 16px;
    font-size: 1.2vw;
  }
}
  @media only screen and (max-width: 991px){
    .modal-wrapper{
      height: 94%;
      width: 91%;
    }

  }
  @media only screen and (max-width: 768px){
   .content-wrap .filter {
    width: 24vw;
  
  }
  .searchBar .search-inner-wrap{
    text-align: left;
  }
  .signupbtn{
    font-size: 1.8vw;
        width: 115px;
        margin-left: 0px;
        margin-bottom: 15px;
    }
    
  .map-list-btn.desktop{
    display:none;
  }
 .tag-wrap {
    display: flex;
    flex-wrap: wrap;
}
.related-card-wrap{
  margin-bottom:35px !important;
}
  }
  @media only screen and (max-width: 575px){
.content-wrap .filter {
    padding: 11px 6px;
    font-size: 1.8vw;
}
}
@media only screen and (max-width: 425px){
.content-wrap section p {
  width: 100%;
}
.tag-wrap.Guided-tag {
  margin-top: 57px;
}
.content-wrap section h5 {
  height: auto;
}
.tag-wrap label {
  margin: 15px 10px 0;
}
// Footer Style
.main{
  // background-color: rgb(17, 79, 255);
  flex-direction: column;
  display: flex ;
  overflow-y: auto ;
  height:100vh
}

.d-flex{
  display:flex;
}
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
}


@media only screen and (max-width: 768px) {
    .map-list-btn.desktop{
      display:none;
    }
    .filterview {
      width: 100%;
      flex-direction: initial;
    }
.signupbtn{
  font-size: 1.8vw;
      width: 115px;
      margin-left: 0px;
      margin-bottom: 15px;
  }
.row-2 {
  margin-bottom: 39px;
}

}


@media only screen and (max-width: 375px){

.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
}

@media only screen and (max-width: 320px){

.jewish-vienna {
  width: 203px;
}
}
`;
export default Location;