import { TFunction } from 'react-i18next';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ItemType } from '../../model/ItemType';
// import Item from '../../model/Item';
import profile from '../../assets/jeaish.png';
import {
  IonItem,
  IonList,
  useIonPopover,
  IonLabel,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import Slider from 'react-slick';
import RoomIcon from '@material-ui/icons/Room';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const RecommendCard: React.FC<{ data?: any; ds: any; item?: any }> = ({
  data,
  ds,
  item,
}) => {
  const List = [
    'bdufrj',
    'dfrujkm',
    'dfhgbvjkfd',
    'dfjhbvd',
    'dfvjhgn',
    'dfbhvujkdhnv',
    'Rftgbvcfdxrthgb',
    'Fgthngy',
    'gcyhnjygv',
    'Gyhnj',
  ];
  const [initials, setInitials] = useState(List.slice(0, 6));
  const sliderRef = useRef<any>(null);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    // centerMode: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
  ]);

  const images = [
    'https://api.europeana.eu/thumbnail/v2/url.json?uri=http%3A%2F%2Fmuseum.zib.de%2Fsgml%2Feuimg.php%3Fimg%3Dz0072665&type=IMAGE',
    'https://api.europeana.eu/thumbnail/v2/url.json?uri=https%3A%2F%2Fmedia.jhn.ngo%2Fproxy%3Furl%3Dhttps%3A%2F%2Fmedia.jhn.ngo%2FNLI%2FIIIFv21%2FFL62417661%2Ffull%2Fmax%2F0%2Fdefault.jpg&type=IMAGE',
    'https://api.europeana.eu/thumbnail/v2/url.json?uri=http%3A%2F%2Fmuseum.zib.de%2Fsgml%2Feuimg.php%3Fimg%3Dgr001696&type=IMAGE',
    'https://api.europeana.eu/thumbnail/v2/url.json?uri=https%3A%2F%2Fmedia.jhn.ngo%2Fproxy%3Furl%3Dhttps%3A%2F%2Fmedia.jhn.ngo%2FNLI%2FIIIFv21%2FFL62417661%2Ffull%2Fmax%2F0%2Fdefault.jpg&type=IMAGE',
  ];

  const renderArrows = () => {
    return (
      <div className='sliderBtn-wrap'>
        <ButtonBase
          className='arrow-btn prev'
          onClick={() => sliderRef.current.slickPrev()}
        >
          <NavigateBeforeIcon />
        </ButtonBase>
        <ButtonBase
          className='arrow-btn next'
          onClick={() => sliderRef.current.slickNext()}
        >
          <NavigateNextIcon />
        </ButtonBase>
      </div>
    );
  };
  return (
    <Styled>
      { data[0]?.images.length ?
        <div className='search-results recommended-cards-wrapper'>
          {console.log("datadata",data)}
          {renderArrows()}
          {/* {
            Object.values(data).map((item: any) => {
              return (
                <> */}
          <ItemCardWrapper className='recommendedCard carousel-slider-card'>
            <Slider ref={sliderRef} {...settings}>
              {data[0]?.images.map((item: any) => {
                return (
                  <>
                    <ItemCardWrapper className='recommendedCard'>
                      <StyledItemCard
                        className='shadow'
                        style={{ padding: '1.2rem', gap: ' 1.1rem' }}
                      >
                        <img src={item} alt="img1"></img>
                      </StyledItemCard>
                    </ItemCardWrapper>
                  </>
                );
              })}
            </Slider>
          </ItemCardWrapper>
        </div>
      : null}
    </Styled>
  );
};

const Styled = styled.div`
  .search-results {
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
    // gap: 2.3rem;
    gap: 2.2rem;
    // max-width: 1500px;
    max-width: 1765px;
    margin: 0 auto;
  }

  .recommended-cards-wrapper .recommendedCard {
    position: relative;
    height: 264px;
    // width: 559px;
    // width: 20.2vw;
    width: 34.7rem;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  }

  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: 8vw;
  }
  .book-btn {
    padding: 5px 21px;
    font-size: 14px !important;
    color: #000;
    background-color: #fac807;
    border-radius: 7px;
    font-weight: 900 !important;
    position: absolute;
    top: -7px;
    right: 45px;
  }
  .book-btn.euro {
    background-color: #000;
    color: #fff !important ;
  }
  .self-guided-tour {
    height: 24px;
    width: 149px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
  }
  .jewish-vienna {
    height: 50px;
    width: 281px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 15px;
  }

  .jewish-vienna .item-title-link {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    width: 225px;
    height: 50px;
  }

  .walking-tour-on-jewi {
    height: 40px;
    /* width: 250px; */
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .pin-icon-wrap svg,
  .clock-iocn-wrap svg {
    height: 21px;
    width: 20px;
    fill: #0849ff;
    margin-right: 6px;
  }
  .pin-icon-wrap span,
  .clock-iocn-wrap span {
    height: 21px;
    width: 94px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  #listOfCard .item-title-wrapper {
    height: auto;
    max-width: 100%;
  }
  @media only screen and (max-width: 2097px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 33.7rem;
    }
  }
  @media only screen and (max-width: 2040px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 32.7rem;
    }
  }

  @media only screen and (max-width: 1982px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 31.7rem;
    }
  }
  @media only screen and (max-width: 1924px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 30.7rem;
    }
  }

  @media only screen and (max-width: 1867px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 29.7rem;
    }
  }
  @media only screen and (max-width: 1809px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 28.7rem;
    }
  }
  @media only screen and (max-width: 1752px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.7rem;
    }
  }

  @media only screen and (max-width: 1694px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.5rem;
    }
    #listOfCard .item-details {
      width: 64%;
    }
  }
  @media only screen and (max-width: 1683px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 25.8vw;
    }
  }

  @media only screen and (max-width: 1502px) {
    #listOfCard .item-details {
      width: 67% !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.9vw;
    }
    #listOfCard .item-details {
      width: 55% !important;
    }
    .walking-tour-on-jewi {
      width: 100%;
    }
    .jewish-vienna .item-title-link {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: nowrap;
      width: 72%;
    }
  }

  @media only screen and (max-width: 1366px) {
    .walking-tour-on-jewi {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1284px) {
    #listOfCard .item-details {
      width: 67% !important;
    }
    .banner-inner-img {
      transform: translate(0, 22%);
    }
  }
  @media only screen and (max-width: 1244px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 27.5vw;
    }
  }

  @media only screen and (max-width: 1199px) {
    .pin-icon-wrap span,
    .clock-iocn-wrap span {
      width: 85px;
      font-size: 12px;
    }

    .recommended-cards-wrapper .recommendedCard {
      width: 43.2vw;
    }
  }
  @media only screen and (max-width: 1088px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 43.1vw;
    }
  }

  @media only screen and (max-width: 1074px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 42.2vw;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    .recommended-cards-wrapper .recommendedCard .item-preview {
      width: 150px;
      height: 100%;
    }
    #listOfCard .item-details {
      width: 60% !important;
    }
    .jewish-vienna {
      margin-top: 6px;
    }
    .jewish-vienna .item-title-link {
      font-size: 13px;
      height: 34px;
    }
    .recommended-cards-wrapper .recommendedCard .item-title-wrapper {
      margin-bottom: -0.5em;
    }
    .walking-tour-on-jewi + .icon-wrap {
      margin-top: 15px !important;
    }
    .item-details-header {
      margin-top: -11px;
    }
    .book-btn {
      top: -15px;
    }
  }
  @media only screen and (max-width: 991px) {
    #listOfCard .item-details {
      width: 55% !important;
    }
    .recommended-cards-wrapper .recommendedCard .item-preview {
      width: 15vw;
    }
  }

  @media only screen and (max-width: 768px) {
    .recommended-cards-wrapper .recommendedCard {
      width: 85%;
      // height: 198px;
      height: 380px;
    }
    .img-wrap {
        padding: 20px 35px
      }
    #listOfCard .item-details {
      width: 80% !important;
    }
    .sliderBtn-wrap{
      position: relative;
      z-index: 11;
    }
    .sliderBtn-wrap .prev{
      position: absolute;
      // top: 50%;
      top: 180px;
      z-index: 1;
      left: -45px;
    }
    .sliderBtn-wrap .next{
      position: absolute;
      // top: 50%;
      top: 180px;
      z-index: 1;
      right: -45px;
    }
    .sliderBtn-wrap svg{
      fill: #fff;
      width: 82px;
      height: 35px;
    }
    .slick-dots li button{
      display:none;
    }
    .recommended-cards-wrapper .recommendedCard .shadow img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius:15px;
    }
    .recommended-cards-wrapper .recommendedCard{
      background-color: transparent !important;
    }
    .recommended-cards-wrapper .recommendedCard .shadow {
      box-shadow: none;
      background-color: transparent;
      padding:0 !important;
  }
  .book-btn {
    top: 170px;
  }

}

  @media only screen and (max-width: 575px) {
  .recommended-cards-wrapper{
    position: relative;
  }
  .item-title{
    height: 18px;
  }
  
  .jewish-vienna .item-title-link,
  .jewish-vienna{
    width: 100% !important;
  }

  .item-title-wrapper {
    max-width:100% !important;
  }
 

 .recommended-cards-wrapper .recommendedCard {
    // width: 90%;
    width: 100%;
}
.recommendedCard .shadow{
  flex-direction: column;
  gap: 2.1rem;
}
.recommended-cards-wrapper .recommendedCard {
  // height: 400px;
  // border-radius: 0px;
  box-shadow:none;
}
.recommended-cards-wrapper .recommendedCard .item-preview {
  width: auto;
  height: 165px;
}
.walking-tour-on-jewi {
  height: 46%;
  margin-top: 31px;
}
.book-btn {
  top: 47%;
}
.slick-dots li button:before {
  font-size: 13px;
}
.sliderBtn-wrap button svg{
  width: 50px;
  height: 35px;
  z-index:11;
}

.slick-dots {
  bottom: -44px;
}
.map-card-wrap {
  margin-bottom: 50px;
  margin-top: 50px;
}
.explore-wrap h3 {
  margin-top: 60px;
}
.line {
  width: 100vw;
}
.search-results {
  gap: 0.2rem;
}
}

  @media only screen and (max-width: 375px) {
    #listOfCard .item-details {
      width: 80% !important;
    }
    .pin-icon-wrap span,
    .jjTLoc .clock-iocn-wrap span {
      width: 68px;
    }
  }

  @media only screen and (max-width: 320px) {
    .jewish-vienna {
      width: 203px;
    }
  }
`;

const ItemCardWrapper = styled.div`
  width: 29rem;
  height: 18.75rem;
  font-size: 1.375rem;
  line-height: 1.2;

  .item-details-header {
    visibility: hidden;
  }
`;

const StyledItemCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;

  h4,
  p {
    padding: 0;
    margin: 0;
  }

  .item {
    &-preview {
      width: 10rem;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &-title {
      color: var(--ion-color-primary);
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      word-wrap: break-word;

      &-wrapper {
        height: 2.5em;
        margin-bottom: 0.5em;
        flex-shrink: 0;
        max-width: 175px;
      }

      &-link {
        text-decoration: none;
      }
    }

    &-details {
      width: 100%;

      &-header {
        height: 1rem;
      }

      &-menu {
        &-toggler {
          font-size: 2rem;
          margin-left: auto;
          align-self: flex-end;
          color: var(--ion-color-secondary);
          cursor: pointer;
        }
      }

      &-description {
        color: var(--ion-color-secondary);
        font-size: 1rem;
        line-height: 1.25;
      }

      &-footer {
        height: 2rem;
        min-height: 2rem;
        margin-top: auto;
      }
    }
  }
`;



export default RecommendCard;
