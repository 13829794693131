import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";

const currency = "EUR";
const style = { layout: "vertical" };

const ButtonWrapper = ({ currency, showSpinner, amount, onSend }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency
      }
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount
                  }
                }
              ]
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={(data, actions:any) => {
          return actions.order.capture().then(() => {
            // Your code here after capture the order
            onSend(data);
          });
        }}
      />
    </>
  );
};

export default function PaypalButton({ price, onSend }) {
  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <ButtonWrapper currency={currency} showSpinner={false} amount={price} onSend={onSend} />
    </div>
  );
}