import { key } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router';

export const QUERY_KEY = 'query';
export const GEOLOCATION_KEY = 'geolocated';

const useQuery = (filterKey: any, value: string = '') => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = () => new URLSearchParams(location.search);

  const getQuery = () => searchParams().get(QUERY_KEY) as string;
  const getLanguages = () => searchParams().get('language') as any;
  const getLocations = () => searchParams().get('location') as any;
  const getOther = () => searchParams().get('period') as any;

  const setQuery = (query: string) => {
    let actualQuery;

    if (query) {
      actualQuery= searchParams();
      actualQuery.set(QUERY_KEY, query);
    } else {
      // Clear all parameters
      actualQuery = new URLSearchParams();
    }
    history.push({ search: actualQuery.toString() });
  };

  const getGeoLocation = () => searchParams().get(GEOLOCATION_KEY) as string;

  const setGeoLocation = (location : string) => {
    const actualQuery = searchParams(); 

    if (location) {
      actualQuery.set(GEOLOCATION_KEY, location)
    } else {
      actualQuery.delete(GEOLOCATION_KEY);
    }
    console.log("actualQuery",actualQuery.toString())
    history.push({ search: actualQuery.toString() });
  }

  const filtersApplied = (): boolean => {
    const currentParams = searchParams();

    let result = false;
    currentParams.forEach((v, k) => {
      if (k !== QUERY_KEY && k !== GEOLOCATION_KEY) {
        result = true;
      }
    });

    return result;
  };
  const mapLocation = (maploc:any) => {
    const currentParams = searchParams();
    const newParams = new URLSearchParams();
    // Appending scoped pair if it wasn't present in actual query
    currentParams.forEach((v, k) => {
      // Appending every pair except the scoped one
      if (!(k === filterKey && v === value)) {
        newParams.append(k, v);
      }
    });
     if(maploc==null)
     {
      currentParams.forEach((v, k) => {
        // Appending every pair except the scoped one
        if(k=="location")
        {
          newParams.delete(k);
        }
      });
     }
     else
     {
      maploc.map((i)=>
      {if (!currentParams.getAll(i.key).includes(i.value)) {
        if(i.key=="location")
        {
          newParams.delete(i.key);
        }
        newParams.append(i.key, i.value);
      }
    }
      );
     }
    history.push({ search: newParams.toString() });
  };

  const toggleFilter = (): void => {
    const currentParams = searchParams();
    const newParams = new URLSearchParams();
    currentParams.forEach((v, k) => {
      // Appending every pair except the scoped one
      if (!(k === filterKey && v === value)) {
        newParams.append(k, v);
      }
    
    });

    // Appending scoped pair if it wasn't present in actual query
    if(typeof filterKey == "string")
    {
      if (!currentParams.getAll(filterKey).includes(value)) {
        newParams.append(filterKey, value);
      } 
    }
    else
    {
      console.log("filterKey",filterKey)
      filterKey.map((i)=>
      {if (!currentParams.getAll(i.key).includes(i.value)) {
        console.log("value",i.value)
        newParams.append(i.key, i.value);
        if(i.value.length > 0)
        {
          console.log("value5",i.value) 
          newParams.set(i.key, i.value);
        }
        else
        { newParams.delete(i.key); 
        }
      }
    }
      )
    }
    // if (!currentParams.getAll(filterKey).includes(value)) {
    //   newParams.append(filterKey, value);
    // }
    // localStorage.setItem("queryString",newParams.toString())
    history.push({ search: newParams.toString() });
  };

  const resetAllFilters = (): void => {
    const newParams = new URLSearchParams();
    const query = getQuery();

    if (query) {
      newParams.set(QUERY_KEY, query);
    }

    history.push({ search: newParams.toString() });
  };

  const isActive = (): boolean =>
    searchParams().getAll(filterKey).includes(value);

  return {
    filtersApplied,
    toggleFilter,
    resetAllFilters,
    mapLocation,
    searchParams,
    getQuery,
    setQuery,
    getLanguages,
    getLocations,
    getOther,
    isActive,
    setGeoLocation,
    getGeoLocation
  };
};

export default useQuery;
