import useTranslations from '../../util/useTranslation';
import validator from 'validator';
import i18n from 'i18next';
import React, { useState,useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IonCol, IonRow} from '@ionic/react';
import Button from '@material-ui/core/Button';
import { Col } from 'reactstrap';
import {
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendRightTitle,
  LegendSeparator,

} from './Style';
import { getUser, setUser } from '../authentication/CurrentUser';
import { updatePassword, updateLanguage, updateGeneralInfo } from '../../util/API';
import useToken from '../../util/useToken';
import { useHistory, useLocation } from 'react-router';


const Profile: React.FC = () => {
  const [t, lang] = useTranslations('profile');
  const history = useHistory();
  const location = useLocation();
  const fileInput = useRef<any>(null);
  const [minPasswordLength, maxPasswordLength] = [8, 40];
  const userObject = getUser();
  const [token,setToken] = useToken();
  const [name,setName]=useState(userObject.first_name);
  const [profile,setProfile]=useState<string>(userObject.avatar);
  const [organization,setOrganization]=useState(userObject.organization);
  const [email,setEmail]=useState(userObject.email);
  const [oldpassword,setOldPassword]=useState('');
  const [newpassword,setNewPassword]=useState('');
  const [confirmnewpswd,setConfirmNewPswd]=useState('');
  var hasNumber = /\d/;

  const {items: filterItems } = t('languagefilter', {
    returnObjects: true,
  }) as {
    items: Array<{ title: string; id: string; value: string }>;
  };


  const _onChangePassword=()=>
  {
    if (
      //!oldpassword ||
      !newpassword ||
      !confirmnewpswd ||
      newpassword.length < minPasswordLength ||
      confirmnewpswd.length < minPasswordLength
    ) {
      alert(t('alerts.form'));
    } 
    else if(newpassword != '' && confirmnewpswd != '')
    {
      if(newpassword !== confirmnewpswd)
      {
        alert(t('alerts.match'));
      }
      else
      {
        updatePassword(newpassword, token).then((user) => {
          alert(t('alerts.success'));
        });
      }
    }
    else { 
        alert(t('alerts.success'));
    }
  }
  
  const _langChange=(event)=>
  {
    //setLang(event.target.value);
    // Update i18n
    const params = new URLSearchParams({['lang']: event.target.value });
    history.replace({ pathname: location.pathname, search: params.toString() }); 
    
    // Update currently cached user
    let user = getUser();
    user.language = event.target.value;
    setUser(user);
    // Update API
    updateLanguage(user.language, token).then((user) => {
      console.log("Language updated successfully");
    });
  }

  const _updateData=()=>
  {
    if (
      !name ||
      !email ||
      hasNumber.test(name) ||
      !validator.isEmail(email)
    ) {
      alert(t('alerts.update_form'));
    }
    else {
      // Update API
      updateGeneralInfo(name, email, organization, token).then((user) => {
        console.log("User updated successfully");
        alert(t('alerts.update_success'));
      });
    }
  }
  
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    if (fileInput && fileInput.current) {
      fileInput.current.click()
    } 
  };
  
  const onChange=(event)=>
  {
    const upload_file = event.target.files[0];
    if(upload_file != undefined)
    {
      setProfile(URL.createObjectURL(upload_file));
    }
    
  }

  return (
    <Styled>
      <div>
        <div className='container d-flex profile-main-wrap'>
          {console.log("profile",profile)}
          <div>
            <h2>{t('header.headline')}</h2>
          </div>
          <div className='d-flex'>
            <Col className='profile-section-1'>
              <div className='d-flex'>
                <div className='profile-img'>
                  <img
                    src={profile}
                    alt='alt text here'
                  />
                </div>
                <div className="profile-description">
                  <h4>{t('profile_picture')}</h4>
                  <input type="file" name="image" onChange={onChange} accept="image/*" id="upload" ref={fileInput}  style={{ display: 'none' }}></input>
                  <span style={{cursor:"pointer"}} onClick={onButtonClick}>{t('upload')}</span>
                </div>
              </div>
            </Col>
            <Col className='profile-section-2'>
              <IonRow>
                <IonCol>
                  <FieldSet style={
                          hasNumber.test(name)
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.name')}</LegendLeftTitle>
                        <LegendSeparator className='separator'   style={
                                hasNumber.test(name)
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent           
                      type='text'
                      id='name'
                      onChange={(e)=>setName(e.target.value)}
                      value={name}
                      maxLength={40}
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              {hasNumber.test(name) ? (
                    <label className='Errorlabel'>
                      {t('errorlabel.name')}
                    </label>
                  ) : null}
              <IonRow>
                <IonCol>
                  <FieldSet>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.organization')}</LegendLeftTitle>
                        <LegendSeparator className='separator'></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent
                      maxLength={40}
                      onChange={(e)=>setOrganization(e.target.value)}
                      value={organization}
                      type='text'
                      id='Organization'
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <FieldSet   style={
                          !validator.isEmail(email) && email.length
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.email')}</LegendLeftTitle>
                        <LegendSeparator className='separator'  style={
                                !validator.isEmail(email) && email.length
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent
                      type='email'
                      id='email'
                      onChange={(e)=>setEmail(e.target.value)}
                      value={email}
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              {email == '' ? null : !validator.isEmail(email) ? (
                    <label className='Errorlabel'>{t('errorlabel.email')}</label>
                  ) : null}
              <div style={{width: '65% ', margin: '0 auto'}} >
              <Button
                className='updatepbtn'
                variant='contained'
                color='primary'
                onClick={()=>_updateData()}
              >
                {t('update')}
              </Button>
              </div>
            </Col>
            <Col className='profile-section-3'>
              <IonRow>
                <IonCol>
                  <FieldSet>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.old_pswd')}</LegendLeftTitle>
                        <LegendSeparator className='separator'></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent
                      maxLength={maxPasswordLength}
                      onChange={(e)=>setOldPassword(e.target.value)}
                      value={oldpassword}
                      type='password'
                      id='oldpassword'
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <FieldSet  style={
                          newpassword.length < minPasswordLength && newpassword.length
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.new_pswd')}</LegendLeftTitle>
                        <LegendSeparator className='separator'  style={
                                newpassword.length < minPasswordLength && newpassword.length
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent
                      maxLength={maxPasswordLength}
                      onChange={(e)=>setNewPassword(e.target.value)}
                      value={newpassword}
                      type='password'
                      id='newpassword'
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              {newpassword.length == 0 ? null : newpassword.length < minPasswordLength ? (
                    <label className='Errorlabel'>
                      {t('errorlabel.password')}
                    </label>
                  ) : null}
              <IonRow>
                <IonCol>
                  <FieldSet style={
                          confirmnewpswd.length < minPasswordLength && confirmnewpswd.length
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }>
                    <Legend>
                      <LegendContainer>
                        <LegendLeftTitle>{t('fields.confirm_pswd')}</LegendLeftTitle>
                        <LegendSeparator className='separator' style={
                                confirmnewpswd.length < minPasswordLength && confirmnewpswd.length
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }></LegendSeparator>
                      </LegendContainer>
                    </Legend>
                    <ExperienceContent
                      maxLength={maxPasswordLength}
                      onChange={(e)=>setConfirmNewPswd(e.target.value)}
                      value={confirmnewpswd}
                      type='password'
                      id='confirmnewpassword'
                    />
                  </FieldSet>
                </IonCol>
              </IonRow>
              {confirmnewpswd.length == 0 ? null : confirmnewpswd.length < minPasswordLength ? (
                    <label className='Errorlabel'>
                       {t('errorlabel.password')}
                    </label>
                  ) : null}
              <div style={{width: '65% ', margin: '0 auto'}} >
              <Button
                className='chnagepaswbtn'
                variant='contained'
                color='primary'
                onClick={()=>_onChangePassword()}
                // endIcon={<ArrowRightAltIcon />}
              >
                {t('change_pswd')}
              </Button>
              </div>
            </Col>
            <Col className='profile-section-4'>
              <div className='custom-select-wrapper main-custom-select-wrap'>
                <div className='custom-select-wrapper'>
                  <span style={{ marginRight: '15px' }}>{t('dash_language')}</span>
                  <div className='select-box'>
                    <div
                      className='select-box__current select_block'
                      tabIndex={0}
                      onChange={_langChange}
                    >
                       {filterItems?.map(({ title,id,value}, idx) => (
                        <div className='select-box__value'>
                        <input
                          className='select-box__input'
                          type='radio'
                          id={id}
                          value={value}
                          name='customSelect'
                          checked={lang == value ? true : false}
                        />
                        <p className='select-box__input-text'>{title}</p>
                      </div>
                       ))}
                      <img
                        className='select-box__icon'
                        src='http://cdn.onlinewebfonts.com/svg/img_295694.svg'
                        alt='Arrow Icon'
                        aria-hidden='true'
                      />
                    </div>

                    <ul className='select-box__list'>
                    {filterItems?.map(({ title,id,value}, idx) => (
                        <li>
                        <label className='select-box__option' htmlFor={id}>
                          {title}
                        </label>
                      </li>
                       ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
h4{
  font-size: 12px;
}
.profile-description{
  margin-left:30px;
}
.profile-description span{
  font-size: 15px;
    color: #114fff;
    font-weight: 700;
}
.profile-main-wrap{
  margin: 50px auto;
}
  .container {
    max-width: 1500px;
  }
  .profile-main-wrap {
    flex-direction: column;
  }
  .profile-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  .col{
    width: 33.3%;
    display: flex;
    flex-direction: column;
    margin-top: 33px;
    justify-content: space-between;
  }

  .col .hydrated{
    width: 80%;
    margin: 0 auto;
  }

  .profile-section-2.col,
  .profile-section-3.col{
    border-right: 2px solid #b8becd;
  }


  // fields design
  input:selected{
    backgroung-color:#000;
  }

  // Custom Select

  .custom-select-wrapper{
    display:flex;
    align-items:center;
    width:100%;
    justify-Content:flex-end;
    flex-direction: column;
    }

  .select-box {
    position: relative;
    display: block;
    // width: 30%;
    width: 40%;
    font-size: 18px;
    color: #60666d;
  }
  .select-box__current {
    position: relative;
    cursor: pointer;
    outline: none;
  }
  .select-box__current:focus + .select-box__list {
    opacity: 1;
    animation-name: none;
  }
  .select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
  }
  .select-box__current:focus .select-box__icon {
    transform: translateY(-50%) rotate(180deg);
  }
  .select-box__icon {
    position: absolute;
    top: 50%;
    right: -3px;
    transform: translateY(-50%);
    width: 11px;
    opacity: 0.3;
    transition: 0.2s ease;
  }
  .select-box__value {
    display: flex;
  }
  .select-box__input {
    display: none;
  }
  .select-box__input:checked + .select-box__input-text {
    display: block;
  }
  .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px;
    color: #7a84a2;
    font-weight: bold;
    font-size: 15px;
    background-color: #fff;
  }
  .select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 0px 30px -10px rgb(10 10 10 / 33%);
    z-index: 99999;
  }
  .select-box__option {
    display: block;
    padding: 5px 15px;
    background-color: #fff;
    font-size: 14px;
  }
  .select-box__list li:first-child .select-box__option {
    border-top: 1px solid #cccccc61;
    border-left: 1px solid #cccccc61;
    border-bottom: 1px solid transparent;
    border-right: 1px solid #cccccc61;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .select-box__list li .select-box__option {
    border-top: 1px solid transparent;
    border-left: 1px solid #cccccc61;
    border-bottom: 1px solid transparent;
    border-right: 1px solid #cccccc61;
    padding-left: 20px;
  }
  .select-box__list li:last-child .select-box__option {
    border-top: 1px solid transparent;
    border-left: 1px solid #cccccc61;
    border-bottom: 1px solid #cccccc61;
    border-right: 1px solid #cccccc61;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .select-box__option:hover,
  .select-box__option:focus {
    color: #546c84;
    background-color: #fbfbfb;
  }
  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }


  // style
  
 .Errorlabel{
  color: red;
  display: flex;
  font-size: 15px;
  justify-content: flex-start;
  margin: -32px 58px 12px;
 }

.field-wrap{
  width: 65%;
      margin: 10px auto 16px;
}

fieldset input{
  padding:0;
  height:100%;
  font-size:14px;
}

input:autofill-selected{
  background-color:#fff;
}

fieldset input:selected,
fieldset input:focus{
  background-color:#fff;
}


fieldset {
  margin: 0 auto 35px;
  border: 1px solid #ccc;
}

.separator{
  margin: 7px 0 0 0;
}

.updatepbtn{
  color: white;
  background-color: rgb(17, 79, 255);
  font-size: 12px;
  font-weight: 800;
  border-radius: 50px;
  text-transform: capitalize;
}
.updatepbtn:hover{
  color: #000;
  background-color: #fac807;
}
.chnagepaswbtn{
  color: #000;
  background-color: #fac807;
  font-size: 12px;
  font-weight: 800;
  border-radius: 50px;
  text-transform: capitalize;
}
.chnagepaswbtn:hover{
  color: white;
  background-color: rgb(17, 79, 255);
}

`;

export default Profile;
