import {
    IonChip,
    IonCol,
    IonGrid,
    IonLabel,
    IonList,
    IonRow,
} from '@ionic/react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import React, { useEffect, FC } from 'react';
import { StyledInvitedAsGuest } from './guestInviteStyled';
import WhiteArrow from '../../../assets/icons/whitearrow.svg';
import Delete from '../../../assets/icons/delete.svg';
import DropDownArrow from '../../../assets/icons/dropdownarrow.svg';
import DropDownArrowActive from '../../../assets/icons/dropdownarrowactive.svg';
import HaggadaBook from '../../../assets/icons/haggada-icon.svg';
import LinkIcon from '../../../assets/icons/link-icon.svg';
import useTranslations from '../../../util/useTranslation';
import DeletePopup from '../DeletePopup/DeletePopup';

type Props = { guestData: Array<any>, guestEmail: Array<any>, openInvitation: Function }

const InviteAsGuest: FC<Props> = ({ guestData, guestEmail, openInvitation }) => {
    const [t] = useTranslations('passover');
    const [inviteGuestList, setInviteGuestList] = React.useState([{}]);
    const [inviteAsGuest, setInviteAsGuest] = React.useState<any>();
    const [guestEmailList, setGuestEmailList] = React.useState([{}]);
    const [openDelete, setOpenDelete] = React.useState(false);

    const toggleInviteAsGuest = (id: number) => {
        if (isOpenGuestInvite(id)) {
            setInviteAsGuest(null);
        } else {
            setInviteAsGuest(id);
        }
    };
    const isOpenGuestInvite = (id: number) => {
        return inviteAsGuest === id ? true : false;
    };

    useEffect(() => {
        setInviteGuestList(guestData);
        setGuestEmailList(guestEmail);
    })

    return (
        <div>
            <StyledInvitedAsGuest>
                <div className='subHeader'>{t("subheader.items.invite_guest")}</div>
                <div>
                    <div>
                        <IonGrid className="ion-no-padding">
                            {inviteGuestList.map((guestData: any, i: any) => {
                                return (
                                    <div key={i} className="ion-margin-top ion-padding bgcolor">
                                        <IonRow className='d-flex ion-align-items-center'>
                                            <IonCol size='2' className='name'>{guestData.name}</IonCol>
                                            <IonCol size='1.5' className='d-flex'>
                                                <img src={LinkIcon} alt='Link Icon' />{' '}
                                                <Link className='publicShare' href='#'>
                                                    {guestData.link}
                                                </Link>
                                            </IonCol>
                                            <IonCol className='field'>{guestData.date}</IonCol>
                                            <IonCol className='field'>{guestData.time}</IonCol>
                                            <IonCol className='field d-flex'>{guestData.guestCount} guests
                                                <Button
                                                    className='field'
                                                    variant='text'
                                                    color='primary'
                                                    onClick={() => toggleInviteAsGuest(guestData.id)}
                                                    startIcon={
                                                        <img
                                                            src={
                                                                isOpenGuestInvite(guestData.id)
                                                                    ? DropDownArrowActive
                                                                    : DropDownArrow
                                                            }
                                                            alt='DropDownArrow'
                                                            style={{ width: '8px', height: '12px' }}
                                                        />
                                                    }
                                                ></Button>
                                            </IonCol>
                                            <IonCol className='d-flex ion-align-items-center field' size='2'>
                                                <img
                                                    src={HaggadaBook}
                                                    alt='Haggada Book'
                                                    style={{ width: '27px', height: '35px' }}
                                                />
                                                <Link className='reference' href='#'>{guestData.ref}</Link>
                                            </IonCol>
                                            <IonCol>
                                                <Button
                                                    className='startSederButton'
                                                    variant='contained'
                                                    // onClick={() => setOpen(true)}
                                                    color='primary'
                                                    startIcon={
                                                        <img
                                                            src={WhiteArrow}
                                                            alt='Arrow'
                                                            style={{ width: '16.14px', height: '16px' }}
                                                        />
                                                    }
                                                >
                                                    {guestData.buttonName}
                                                </Button>
                                            </IonCol>
                                            <IonCol className="ion-text-center">
                                                <Button
                                                    variant='text'
                                                    onClick={() => setOpenDelete(true)}
                                                    color='primary'
                                                >
                                                    <img
                                                        src={Delete}
                                                        alt='Trace'
                                                        style={{ height: '20px', width: '18px' }}
                                                    />
                                                </Button>
                                            </IonCol>
                                        </IonRow>
                                        {isOpenGuestInvite(guestData.id) && (
                                            <div>
                                                <hr className="hr-bgcolor" />
                                                <div className="margin-left-pr">
                                                    {guestEmailList.map((guestEmail: any, i: any) => {
                                                        return (
                                                            <div key={i}>
                                                                <IonList className="ion-no-padding bgcolor">
                                                                    <IonLabel>
                                                                        <img
                                                                            src={Delete}
                                                                            alt='Trace'
                                                                            style={{
                                                                                height: '10px',
                                                                                width: '11px',
                                                                                marginRight: '10px',
                                                                            }}
                                                                        />
                                                                        <div className="guestEmailList">
                                                                            {guestEmail.email}
                                                                        </div>

                                                                        <IonChip className="chip">
                                                                            <IonLabel>Approved</IonLabel>
                                                                        </IonChip>
                                                                    </IonLabel>
                                                                </IonList>
                                                            </div>
                                                        );
                                                    })}
                                                    <IonList lines='none' className="bgcolor">
                                                        <IonLabel>
                                                            <Button
                                                                variant='text'
                                                                onClick={() => openInvitation(true)}
                                                                color='primary'
                                                            >
                                                                <span className="linkGuestInvite">
                                                                    + {t('link.guest_invite')}
                                                                </span>
                                                            </Button>
                                                        </IonLabel>
                                                    </IonList>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </IonGrid>
                    </div>
                </div>
                {/* Create Delete Popup */}
                {openDelete && (
                    <DeletePopup isOpen={openDelete} setDeleteModal={setOpenDelete} />
                )}
            </StyledInvitedAsGuest>
        </div>
    );
};

export default InviteAsGuest;