import React, { useState } from 'react';
import ChooseSampleContentItem from '../common/SaveItem/ChooseContentItem';
import useTranslations from '../../util/useTranslation';
import { useLocation } from 'react-router';
import queryString from 'query-string';


import { IonButton } from '@ionic/react';
import styled from 'styled-components';

const SearchWidgetModal: React.FC = () => {
    const [t] = useTranslations('item', 'search','save-item');
    const location = useLocation();
    let params = queryString.parse(location.search);

    const [menuSelection, setMenuSelection] = useState<string>('chooseContent')

    const [choosedItems, setChoosedItems] = useState([])

    const FilteredData = (data : any) => {
      let choosenItems = data.filter((dd : any) => {
        if(dd.choose){
          return dd;
        }
      })
      setMenuSelection('');
      setChoosedItems(choosenItems)
      window.parent.postMessage({ message: params['id'], value: choosenItems }, "*");
    }

    return(
        <>
        <Styled>
          <div>
            <IonButton onClick={() => setMenuSelection('chooseContent')}>
             {t('content')}
            </IonButton>
          </div>

          <div>
            <b> {t('choosenitem')}</b> {choosedItems.length}
          </div>

          { menuSelection == 'chooseContent' && <ChooseSampleContentItem  
            showModal={true}
            closeModal={(data) => FilteredData(data)}
            multiSelect={true}
            onSelect={(item: any) => {}}  />}
        </Styled>
        </>
    )
};

const Styled = styled.div`
height: 73vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

`;
export default SearchWidgetModal