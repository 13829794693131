import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import useTranslations from '../../util/useTranslation';

const Footer = (props:any) => {
  const{hideForRoute}=props;
  const [t] = useTranslations('common');
  let history=useHistory();
  let id = history.location.pathname.split('/')[3] ;

  return (
    <div>
      {
        hideForRoute && hideForRoute.indexOf(history.location.pathname)>-1 || history.location.pathname == `/experiences/${id}` || history.location.pathname == `/search-explore/${id}`?'':
        <Styled>
        <div className='container wide'>
          <div className='copy'>
            &copy;  {t('common.footer.j-story')}
          </div>
          <NavLink to='tos' className='tos'>
          {t('common.footer.terms_service')}
          </NavLink>
        </div>
      </Styled>
      }
    </div>
   
  );
};

const Styled = styled.footer`
  min-height: 70px;
  background-color: var(--ion-color-secondary);
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: auto;

  a {
    color: inherit;
  }

  .container {
    // display: flex;
    // justify-content: space-between;
  }
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export default Footer;
