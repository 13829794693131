import styled from "styled-components";

export const Styled = styled.nav`
.createNew {
    height: 40px;
    width: 230px;
    border-radius: 5px;
    background-color: #fac807;
    color: #2a2a2a;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;
    margin-left: 20px;
  }
  .createNew:hover {
    background-color: #fac807;
    color: #2a2a2a;
  }

  .startSederButton {
    height: 40px;
    width: 230px;
    border-radius: 5px;
    background-color: #0849ff;
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;
    margin-left: 20px;
  }
  .startSederButton:hover {
    background-color: #0849ff;
    color: #ffffff;
  }

  .subHeader {
    height: 48px;
    width: 204px;
    color: #545454;
    font-size: 20px;
    font-weight: bold;
    line-height: 48px;
    margin-top: 20px;
  }

  .name {
    color: #0849ff;
    font-size: 24px;
    font-weight: bold;
    padding-left: 16px;
  }

  .publicShare {
    text-decoration-line: none;
    font-size: 13px;
    font-weight: 600;
    color: #0849ff;
  }
  .reference {
    text-decoration-line: underline;
    font-size: 13px;
    font-weight: 600;
    color: #545454;
    padding-left: 10px;
  }
  .field {
    color: #545454;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
  }

  .banner {
    width: 740px;
    height: 200px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media only screen and (max-width: 768px) {
    .banner {
      width: 100%;
      height: auto;
      background-size: cover;
      padding: 14px 0;
    }
  }

  @media only screen and (max-width: 425px) {
    .banner {
      width: 100%;
      height: auto;
      padding: 14px 0;
    }
  }
  .containerTextSupport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin: 0 25%;
  }
  .containerTextReadMore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin: 0 10%;
  }
  .banners-font {
    font-size: 28px !important;
    font-weight: 800 !important;
  } 
  .separation {
    justify-content: space-between;
    text-align: center;
    margin: 75px auto 100px;
  }
  .supportProject {
    height: 31px;
    width: 192px;
    color: #fa2229;
    font-size: 22px;
    font-weight: bold;
    line-height: 31px;
  }
  .moreProject {
    height: 31px;
    width: 293px;
    color: #fac807;
    font-size: 22px;
    font-weight: bold;
    line-height: 31px;
  }
  .grid-color {
    background-color: #F6F5F5;
  }
`;