import mobileItemCardStyled from 'styled-components';

export const StyledItemCardMobile = mobileItemCardStyled.div`
width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;

  h4,
  p {
    padding: 0;
    margin: 0;
  }

  .item {
    &-preview {
      width: 10rem;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &-title {
      color: var(--ion-color-primary);
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      word-wrap: break-word;

      &-link {
        text-decoration: none;
      }
    }

    &-details {
      width: 100%;

      &-header {
        height: 1rem;
      }

      &-menu {
        &-toggler {
          font-size: 2rem;
          margin-left: auto;
          align-self: flex-end;
          color: var(--ion-color-secondary);
          cursor: pointer;
        }
      }

      &-description {
        color: var(--ion-color-secondary);
        font-size: 1rem;
        line-height: 1.25;
      }

      &-footer {
        height: 2rem;
        min-height: 2rem;
        margin-top: auto;
      }
    }
  }
  .coming-wrapper {
    background-color: rgb(113 124 156);
    position: absolute;
    z-index:111;
    top: 28px;
    left: -12px;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    line-height: 16px;
    font-size: 13px;
    font-weight: 600;
    transform: rotateZ(-20deg);
    text-transform: uppercase;
    &-text {
      position: relative;
      top: 32%;
      color: #fff;
    }
  }

  .completed-wrapper {
    background-color: rgb(12, 64, 56);
    position: absolute;
    z-index:111;
    top: 28px;
    left: -12px;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    line-height: 16px;
    font-size: 15px;
    font-weight: 600;
    transform: rotateZ(-20deg);
    text-transform: uppercase;
    &-text {
      position: relative;
      top: 32%;
      color: #fff;
    }
  }
`;