import React from 'react';
import styled from 'styled-components';
import FilterItem from './FilterItem';

const Filter: React.FC<{ title: string; filterKey: string; values: string[] }> =
  ({ title, filterKey, values }) => {
    return (
      <Styled>
        <h4>{title}</h4>
        <div className='values'>
          {values?.map((value, idx) => (
              <FilterItem
                text={value}
                value={value}
                filterKey={filterKey}
                key={`${title}${value}${idx}`}
              />
          ))}
        </div>
      </Styled>
    );
  };

const Styled = styled.div`
  width: 30%;

  h4 {
    padding: 0;
    margin: 0;
    color: var(--ion-color-secondary);
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .values {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default Filter;
