import React, { useState, useEffect, useRef } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  getSubjects,
  exploreExperiences,
  queryExperiences,
} from '../../util/API';
import { Icon, Card, Image } from 'semantic-ui-react';
import SearchInput from '../../components/search/Input';
import partner from '../../assets/partner-icon.png';
import donate from '../../assets/donate-icon.png';
import mobile from '../../assets/bg/mobile-icon-yellow.svg';
import exhibit from '../../assets/bg/exhibit-icon-yellow.svg';
import seminar from '../../assets/bg/Seminar-icon-yellow.svg';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import CardComponent from './Card';
import useTranslations from '../../util/useTranslation';
import Header from './Header';
import Footer from './Footer';
import useQuery, { QUERY_KEY } from '../../util/useQuery';
import ItemsResponse from '../../model/ItemsResponse';
import { LatLng, LatLngBounds, Layer } from 'leaflet';
import { IonSpinner } from '@ionic/react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import styled from 'styled-components';
import bannerImg from '../../assets/bg/header.png';
import bannerInnerImg from '../../assets/header-image.png';
import stripBg from '../../assets/bg/Strip-BG.png';
import MobileCard from './MobileViewCard';
import ExperienceBrowse from '../../components/ExperienceBrowse/ExperienceBrowse';

const Home: React.FC = () => {
  const [loadmore, setloadmore] = useState<boolean>(false);
  const [au] = useTranslations('authentication');
  const [hm] = useTranslations('home');
  const [spinner, setSpinner] = useState(false);
  const [send, setSend] = useState<any>([]);
  let history = useHistory();
  const [bbox, setBbox] = useState<any>();
  const [allsubjects, setallsubjects] = useState<any>([]);
  const [t] = useTranslations('search');
  // const { path } = useRouteMatch();
  const [subjectData, setSubjectData] = useState<any>([]);
  const [searchResult, setSearchResult] = useState<ItemsResponse | null>(null);
  const {
    resetAllFilters,
    getQuery,
    setQuery,
    searchParams,
    filtersApplied,
    setGeoLocation,
    getGeoLocation,
  } = useQuery(QUERY_KEY);
  const query = getQuery();
  const paramStr = searchParams().toString();
  const { toggleFilter } = useQuery(send ? send : null);
  const [map, setMap] = useState<any>(null);
  const [ds] = useTranslations('dashboard');
  let [exps, setExps] = useState<any>([]);
  let [allData, setallData] = useState<any>([]);
  const geolocationQuery = getGeoLocation();
  const [markerIndex, setMarkerIndex] = useState<any>(null);
  const [clearSearchData, setClearSearchData] = useState<boolean>(
    query ? true : false
  );

  const fetchExperiences = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    } = await exploreExperiences();
    setSpinner(false);
    if (experiences.length > 0) {
      if (experiences.length > 6) {
        setloadmore(true);
      } else {
        setloadmore(false);
      }
      setallData(experiences);
      setExps(experiences.slice(0, 6));
    }
  };

  const fetchSubjects = async () => {
    setSpinner(true);
    const { data } = await getSubjects();
    setSpinner(false);
    setSubjectData(data.subjects);
    setallsubjects(data.subjects.map((i) => i.name));
    // setSearchResult(() => data);
  };

  useEffect(() => {
    // expTypes.map((exType) => fetchExperiences(exType));
    fetchExperiences();
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (
      bbox != undefined &&
      paramStr != undefined &&
      allsubjects != undefined
    ) {
      setSend([
        { key: 'location', value: bbox.join(',') },
        { key: 'subjects', value: allsubjects.join(',') },
      ]);
    }

    const fetchResults = async () => {
      const { data } = await queryExperiences(paramStr);
      // setSearchResult(() => data);
    };
    if (query || geolocationQuery) {
      toggleFilter();
      fetchResults();
    } else {
      setSearchResult(() => null);
    }
  }, [paramStr, query, bbox, allsubjects]);

  const results: any = searchResult?.results ?? [];
  let experiencecLocation: any = searchResult?.results ?? [];
  // let itemsWithLocation = searchResult?.results ?? [];

  // useEffect(() => {
  //   if (markerIndex > 0) {
  //     let Item: any = itemsWithLocation[markerIndex - 1];
  //     map.flyTo(new LatLng(Item.location[0].lat, Item.location[0].lon), 13);
  //   }
  // }, [markerIndex]);

  const _loadMore = () => {
    setloadmore(false);
    setExps(allData);
  };
  return (
    <div className='main'>
      <style>{css}</style>
      {spinner && (
        <div className='spinner'>
          <IonSpinner color='primary' />
        </div>
      )}
      <Header navigateToSection={true}/>
      <div
        className='banner-img d-flex'
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <div className='container d-flex'>
          <div className='banner-content-wrap'>
            <h2 className='experience-the-jewis'>{hm('header.headtitle')}</h2>
            <p className='j-story-is-a-communi'>{hm('header.subheadtitle')}</p>
          </div>
          <img src={bannerInnerImg} className='banner-inner-img' />
        </div>
      </div>
      <div>
        <div className='container'>
          <span className='title'>{hm('explore')}</span>
          <div className='desktopview hero-section-cards' style={{ marginTop: '55px' }}>
            <ExperienceBrowse isShowExperienceList data={exps} ds={ds} />
          </div>
          <div className='mobileview hero-section-cards' style={{ marginTop: '55px' }}>
            <ExperienceBrowse isShowExperienceListMobile data={exps} ds={ds} />
          </div>

          {loadmore == true ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                className='exploreMore'
                variant='contained'
                onClick={() => history.push(`/search-explore`)}
                color='primary'
                endIcon={<ArrowRightAltIcon />}
              >
                {hm('explore_button')}
              </Button>
            </div>
          ) : null}
        </div>
        <div className='map-card-wrap'>
          <div className='container'>
            <div className='row'>
              <ExperienceBrowse isShowExperienceMap allData={allData} handleBboxdata={(value) => setBbox(value)} searchData={clearSearchData}/>

            </div>
          </div>
        </div>
      </div>
      <div
        className='shareStory'
        style={{
          backgroundImage: `url(${stripBg})`,
        }}
      >
        <div className='container row'>
          <div className='col-md-4'>
            <h2 style={{ color: '#fff' }}>{hm('jstory_share.head')}</h2>

            <p>{hm('jstory_share.desc')}</p>
            <div className='d-flex desktopview share-your-story-desktop-btn'>
              <Button className='learMore' variant='outlined' color='primary'>
                {hm('learn-more')}
              </Button>
              {/* <NavLink
                to={`/app/new-experience`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className='createNew'
                  variant='contained'
                  color='primary'
                  endIcon={<AddIcon />}
                >
                  {hm('navBar.experience')}
                </Button>
              </NavLink>
      */}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='shareguid self-guid d-flex'>
              <div>
                <img src={mobile} alt='mobile-image' />
              </div>
              <div className='shareguid-content'>
                <h4>{hm('jstory_share.tour')}</h4>
                <p>{hm('jstory_share.tour_desc')}</p>
              </div>
            </div>
            <div className='shareguid d-flex'>
              <div>
                <img src={exhibit} alt='mobile-image' />
              </div>
              <div className='shareguid-content'>
                <h4>{hm('jstory_share.exhibit')}</h4>
                <p>{hm('jstory_share.exhibit_desc')}</p>
              </div>
            </div>
          </div>
          <div
            className='col-md-4'
            style={{ display: 'flex' }}
            id="ThirdSection"
          >
            <div className='shareguid d-flex'>
              <div>
                <img src={seminar} alt='mobile-image' />
              </div>
              <div className='shareguid-content'>
                <h4>{hm('jstory_share.seminar')}</h4>
                <p>{hm('jstory_share.seminar_desc')}</p>
              </div>
            </div>
            <div className='d-flex mobileview'>
              <Button className='learMore' variant='outlined' color='primary'>
                {hm('learn-more')}
              </Button>
              {/* <NavLink
                to={`/app/new-experience`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className='createNew'
                  variant='contained'
                  color='primary'
                  endIcon={<AddIcon />}
                >
                  {hm('navBar.experience')}
                </Button>
              </NavLink>
          */}
            </div>
          </div>
        </div>
      </div>
      <div className='partner-card' id = 'partnerCardSection'>
        <div className='container'>
          <div className='d-flex partner-card-wrap'>
            <div className='card-1'>
              <img src={partner} alt='partner-image' />
              <div></div>
              <h4>{hm('be_a_member.section1.title')}</h4>

              <p>{hm('be_a_member.section1.desc')}</p>
              <a className='partner-btn' href='mailto:infoi@jhn.ngo'>
                {hm('be_a_member.section1.button')}
              </a>
            </div>
            <div className='card-1'>
              <img src={donate} alt='donate-image' />
              <div></div>
              <h4>{hm('be_a_member.section2.title')}</h4>

              <p>{hm('be_a_member.section2.desc')}</p>
              <a className='partner-btn' href='mailto:infoi@jhn.ngo'>
                {hm('be_a_member.section2.button')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const css = `
  --body{
    overflow:hidden;
  }

  .leaflet-container a {
    color: #0849ff;
  }
    .main{
        // background-color: rgb(17, 79, 255);
        flex-direction: column;
        display: flex ;
        overflow-y: auto ;
        height:100vh
    }
    .logo {
        width: 108px;
        height: 50px;
        margin-right:80px
    }
  
    .signupbtn {
        color: #fff;
        background-color:#0849FF;
        margin-left: 20px;
        text-transform: none;
        height: 20px;
        width: 56px;
        color: #FFFFFF;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        border-radius: 25px;
        width: 132px;
        height: 40px;
      }
      .signupbtn:hover {
        color: #fff;
        background-color:#0849FF;
      }
      .title{
          justify-content: center;
          display: flex;
          padding-top: 65px;
          color: #0849FF;
          font-family: "Source Sans Pro";
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 0;
          line-height:0px;
          text-align: center;
      }
      h3{
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 30px;
      }
      .exploreMore{
        height: 40px;
        width: 200px;
        margin-top: 70px;
        border-radius: 8px;
        background-color: #0849FF;
      color: #FFFFFF;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: none;
    }
    .exploreMore:hover{
        background-color: #031e6a;
      color: #FFFFFF;
    }
    .row{
        display:flex;
        justify-content: space-between;
    }
    .container {
        // max-width: 1500px;
        max-width: 1765px;
        margin: 0 auto;
    }

    .card{
        height: 227px;
    width: 205px;
    justify-content: center;
    display: flex;
    cursor:pointer;
    flex-direction: column;
    padding: 0px 20px;
    text-align: center;
    }
  
 .card-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 787px;
    width: 622px;
    margin-top: -41px;
    padding: 26px 0;
    overflow: auto;
 }

 .card-wrap .card img{
  height: 180px;
  width: 180px;
  border-radius:15px;
  object-fit: cover;
 }

 .card-wrap .card img:hover{
  box-shadow: 0px 0px 11px 1px #fac807;
 }
 .line{
    background-color: #7782A0;
    height: 712px;
    width: 2px;
    margin-top: 90px;
 }

 .card span{
  // height: 45px;
  height: 59px !important;
  width: 180px;
  color: #717C9C;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 70px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

 }
.card span:hover{
  color: #000;
}
 .map-img,
 .map-img .map-content{
     height: 711px;
     width: 100%;
     border-radius: 20px;
 }

 .map-wrap{
  position:relative;
 }
 .map-wrap .leaflet-popup-content-wrapper .leaflet-popup-content{
  width: 350px;
 }
 .leaflet-popup-pane .leaflet-popup-content {
  margin: 0;
 }
.map-wrap .map-search{
  position: absolute;
  z-index: 1111;
  width: 80%;
  left: 60px;
  top: 20px;
}

 .shareStory{
   color:#fff;
    //  height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
 }

 .item-native{
   height:35px !important;
   min-height:35px !important;
 }
.col-md-4{
  width: 33.33333333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
}
.shareStory #ThirdSection.col-md-4{
  justify-content:flex-start;
}

.shareStory .col-md-4{
  padding: 70px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shareStory h2{
  // height: 100px;
  // width: 405px;
  // margin-bottom: 38px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  // line-height: 40px;
}

.shareStory p{
  // height: 100px;
  margin-bottom: 38px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.shareStory .shareguid  {
  justify-content: center;
  margin-bottom: 36px;
}
.shareStory .shareguid h4{
  // height: 24px;
  // width: 236px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
  height: 60px;
  width: 65.92px;
}

.shareguid  img{
  max-width: fit-content;
  border: 0;
}
.partner-card .card-1{
  height: 420px;
  width: 622px;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  margin: 125px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 125px;
  text-align: center;
  align-items: center;
}

.partner-card .partner-card-wrap{
  display: flex;
  justify-content: center;
}

.partner-card .card-1:nth-child(1) {
  margin-right:27px;
}
.partner-card  h4{
  // height: 31px;
  width: 304px;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.partner-card  p{
// height: 72px;
// width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
  box-sizing: border-box;
    height: 42px;
    width: 200px;
    border: 2px solid #0849FF;
    border-radius: 25.6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 40px;
}
.partner-card .partner-btn:hover {
  background-color:#031e6a;
  color:#fff;
}
.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}

.right-footer img{
  margin-right: 40px;
}


.banner-img{
// height:100%;
width:100%;
background-size: cover;
}
.banner-img .container{
  justify-content: space-between;
  align-items: center;
}

.banner-inner-img{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(0, 12%);
}

.experience-the-jewis {
//  height: 128px;
  // width: 649px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 56px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 64px;
}
.j-story-is-a-communi {
  // height: 87px;
  // width: 682px;
  width: 100%;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 40px;
  padding-top:30px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}

.sub-list{
  flex-direction: column;
}

.learMore{
  box-sizing: border-box;
  height: 42px;
  width: 162px;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  margin-right:10px;
}
.learMore span:nth-child(1){
  height: 20px;
  width: 105.39px;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
}

.shareguid img{
  margin-top: 16px;
  margin-right:5px;
  width: fit-content;
  height: 60px;
}
.shareguid {
  justify-content: center;
}

.mobileview{
  display:none;
}

.shareguid-content {
  margin-left: 40px;
}
.shareStory .col-md-4:nth-child(1){
  justify-content:flex-start;
  padding-right: 30px;
}

.leaflet-control-zoom{
display:none;
}
@media only screen and (max-width: 1791px) {

}
@media only screen and (max-width: 1620px) {
  .map-img, .map-img .map-content {
    height: 680px;
    width: 100%; //640px;
  }
  .line {
    height: 680px;
  }
}
@media only screen and (max-width: 1502px) {

}

@media only screen and (max-width: 1440px) {
.map-img, .map-img .map-content {
  width: 100%; //42vw;
}
.shareStory .shareguid p{
  width:100%;
}

}

@media only screen and (max-width: 1366px) {
  
.shareStory .col-md-4:nth-child(2){
  padding: 150px 0 50px 15px;
}
.shareStory .col-md-4:nth-child(3){
  padding: 150px 0 50px 15px;
}
}
@media only screen and (max-width: 1199px) {
    .shareStory .shareguid p {
      height: 80px;
    }
    .shareguid-content{
width:100%;
    }
    .shareguid img {
     margin-right: 0px;
    }
    // .terms-of-service {
    //   height: 28px;
    //   width: 93px;
    // }
    .j-story-is-the-pro {
      margin-right: -8px;
    }
    .map-wrap .map-search {
      width: 80%;
    }
    .shareguid svg {
      margin-right: 9px;
    }
}
@media only screen and (max-width: 1284px) {
      .banner-inner-img {
        transform: translate(-17%, 22%);
    }

    .map-img, .map-img .map-content {
      width: 40vw;
    }
    .card-wrap {
      width: 42vw;
        height: 680px;
        margin-top: -19px;
        padding-top: 0;
    }
    .explore-wrap h3 {
      margin-bottom: 50px;
    }
    .card span {
      height: auto;
    }
    .partner-card .card-1 {
      // height: 420px;
      width: 48%;
    }
    .shareStory .shareguid p{
      width: 100% !important;
      font-size: 17px !important;
    }
}

@media only screen and (max-width: 1244px) {

.shareStory .shareguid p{
  width: 100%;
  font-size: 14px;
}
}

@media only screen and (max-width: 1024px) {
      .experience-the-jewis {
        width: 100%;
        // font-size: 4vw;
      }
      .j-story-is-a-communi {
        // width: 100%;
      }
      .banner-content-wrap{
        width: 70%;
      }
      .j-story-is-a-communi {
        // font-size: 2.7vw;
      }

    .shareStory .col-md-4:nth-child(3) .shareguid img{
      margin-top: -84px;
      margin-bottom: 65px;
      justify-content:center;
    }
    .shareStory h2{
      // width: 100%;
      // height:auto;
    }
    .learMore{
      min-width: auto;
    }
    .shareStory .shareguid h4 {
      margin-top: -91px;
    }
    .shareStory .col-md-4:nth-child(2) .shareguid h4{
     margin-top: 10px;
    }
    .shareStory .col-md-4:nth-child(3){
      padding: 150px 0 50px;
    }
    .shareStory #ThirdSection.col-md-4{
      justify-content: center;
      justify-content: center;
      margin-top: -115px;
    }
    .partner-card p {
      // font-size: 1.8vw;
    font-size: 17px;
    }
    .share-your-story-desktop-btn{
      display:flex;
      flex-direction:column;
    }
    .desktopview.share-your-story-desktop-btn .createNew{
      margin-top: 15px;
      margin-left:0px;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .j-story-is-a-communi {
    margin-top: 10vh;
  }
  .shareStory .shareguid p {
    width: 85%;
    height: auto !important;
}
.shareStory .col-md-4:nth-child(2){
  padding: 96px 0 51px 50px;
}
}

@media only screen and (max-width: 991px) {
      .card {
        height: 227px;
        width: 178px;
    }
    .shareguid-content{
      width:67%;
    }
  
.shareStory .shareguid p {
    width: 85%;
}
.shareStory .shareguid h4 {
  // width: 100%;
}
.shareStory .col-md-4 {
  justify-content: space-evenly;
}
    .shareStory h2{
      font-size: 30px;
    }
    .shareStory p {
      font-size: 16px;
    }
    .card span {
      // height: 31px;
      width: 100%;
    }
    .shareguid-content {
      margin-left: 3px;
  }
    .shareStory .shareguid p {
      height:auto;

      font-size: 16px;
    }
  .shareStory .desktopview{
    flex-wrap: wrap;
  }
    .shareStory .col-md-4:nth-child(3) .shareguid img{
      // height: auto;
    }
    .shareStory .col-md-4:nth-child(2) .shareguid img{
      // height: auto;
      // margin-top: -5px;
    }
   
    .shareStory .col-md-4:nth-child(2) .shareguid:nth-child(2){
      // padding: 7px 0px 2px 18px;
    }
    .partner-card-wrap .card-1 p{
      padding: 10px 25px;
    }
    .partner-card .partner-btn {
      margin-top: 30px;
    }
   
    .shareguid-content {
      margin-left: 25px;
  }
}

@media only screen and (max-width: 768px) {
.experience-the-jewis{
  line-height: 43px;  
}
.item-title-link.a {
  font-size: 19px !important;
  line-height: 36px !important;
}
.shareStory #ThirdSection.col-md-4{
  margin-top:75px;
}
.mobileview.hero-section-cards{
display:none !important;
}
.desktopview.hero-section-cards{
display:block !important;
}

.card {
  height: 260px;
  width: 100%;
}
.card-wrap .card img {
  height: 200px;
  width: 100%;
}
.shareStory .col-md-4 {
  width: 100%;
}
.shareStory {
  // height: 100vh;
}

  .shareStory .shareguid p{
    height:auto !important;
  }
  .desktopview.hero-section-cards .recommended-cards-wrapper .recommendedCard {
    // width: 45%;
    width: 100%;
    height: auto;
}
.desktopview.hero-section-cards .book-btn {
  top: -15px;
}
}

@media only screen and (max-width: 610px) {
.item-title-wrapper hide{
  // flex-direction: column !important;
}
}

@media only screen and (max-width: 575px) {
      .recommended-cards-wrapper .recommendedCard.carousel-slider-card{
        // width: 400px !important;
        // overflow: hidden;
      }
      .slick-initialized .slick-slide {
        display: block;
        // width:400px !important;
      }
    
      .mobileview.hero-section-cards {
        display: block !important;
    }
    .desktopview.hero-section-cards {
      display: none !important;
    }

      .mobileview{
        display:block;
      }
      .desktopview {
        display: none;
      }

    .search-results {
        gap: 0rem;
      }
      .banner-content-wrap {
        width: 90%;
    }
    .leaflet-control-zoom{
      display:none;
    }
    .map-card-wrap h3{
    text-align:center;
    }
   

    .banner-img .container {
      display: flex;
      flex-direction: column;
    }
    .banner-inner-img {
      height: 100%;
      // width: 555.09px;
      width: 100vw;
      transform: translate(0, 5%);
    }
    .title {
      padding-top: 120px;
    }
    .experience-the-jewis {
      // height: 62%;
      // line-height: 53px;
      // font-size: 43px;
      // height: auto !important;
    line-height: 54px !important;
    font-size: 39px !important;
    }
    .book-btn {
      // top: 56% !important;
      top: -10px !important;      
  }
  .item-details-menu-toggler .book-btn {
    right:25px !important;
  }
    .banner-inner-img {
      transform: translate(0, 4%) !important; 
  }
  .walking-tour-on-jewi + .icon-wrap{
    justify-content: unset !important;
  }
  .self-guided-tour {
    font-size: 12px !important;
  }
 .jewish-vienna .item-title-link {
    font-size: 18px;
    height: 46px;
}
    .title {
      padding-top: 95px !important;
    }
    .j-story-is-a-communi {
      font-size: x-large !important;
  }
    .j-story-is-a-communi {
      font-size: 4.7vw;
    }
    .banner-content-wrap {
      margin: 52px 0 0;
    }
    .map-card-wrap .row{
      display: flex;
      flex-direction: column;
    }
    .map-img, .map-img .map-content {
      width: 100%;
      height: 90vh;
      border-radius: 0px;
    }
    .map-card-wrap .container{
      // width:auto;
    }
    .line {
      height: 1px;
      width: 100vw;
      margin-top: 40px;
    }
    .card-wrap {
      width: 100vw;
      height: 100%;
    }
    .map-card-wrap {
      margin-bottom: 50px;
    }
    .explore-wrap h3{
      margin-top: 50px;
    }
    .card {
      height: 260px;
      width: 45%;
      margin: 0 auto;
      }
    .partner-card .partner-card-wrap {
      flex-direction: column;
    }
    .partner-card .card-1 {
      height: 320px;
      width: 100%;
    }
    .partner-card .partner-btn {
      // margin-top: -11px;
    }
    .partner-card .card-1 {
      margin: 24px 0;
    }
    .row-3 .list {
      width: 135px;
    }
  
    .map-wrap .map-search{
      width: 83vw;
      left: 19px;
    }
    // .map-search>div{
    //   width: 80%;
    // }
    .row-2 button{
      margin-top:15px;
    }
    .shareStory .shareguid p {
      height: fit-content;
      width: 100%;
    }
    .shareStory .col-md-4:nth-child(2) .shareguid h4 {
      // margin-top: 14px;
    }
    .shareStory .shareguid h4 {
      // height: fit-content;
    }
    

    .shareStory .col-md-4:nth-child(3) .shareguid {
        margin-bottom: -10px;
    }
    .shareStory .col-md-4:nth-child(3) .shareguid svg{
      margin-top: -95px;
      margin-bottom: 47px;
    }

    .shareStory .col-md-4:nth-child(3)  .mobileview{
      display: flex;
      flex-direction: column;
    }
    .shareStory .col-md-4:nth-child(3)  .mobileview a{
      margin-top: 20px;
    }
    .map-wrap .map-search {
      width: 93vw;
    }
  
    .map-card-wrap {
      margin-top: 60px;
    }
    .shareguid-content{
      margin-left: 40px;
    }
}

@media only screen and (max-width: 475px) {
    .map-wrap .map-search {
      width: 76vw;
    }
    .please-sumbit-your-d {
      width: 100%;
    }
    .line{
      width: 100%;
    }
    .map-card-wrap {
      margin-top: 50px;
    }
    .map-wrap .map-search {
      width: 90vw;
    }
}

@media only screen and (max-width: 425px) {
.shareStory h2 {
  // height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
  font-size: 4vw;
}

}
@media only screen and (max-width: 375px){
    .shareStory .shareguid {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 12px 0px 2px 0px;
    }
    .shareguid-content {
      margin-left: 0px; 
    }
    .shareStory .col-md-4:nth-child(2) .shareguid h4 {
      margin-top: 11px;
    }
    .shareguid img {
      margin-right: 0px;
    }
    .shareStory .shareguid h4 {
      // width: 100%;
    }
    .shareStory .shareguid h4 {
      margin-top: -39px;
    }
    .shareStory .col-md-4:nth-child(3) .mobileview {
      align-items: center;
    }
    #ThirdSection .learMore {
      margin-bottom:20px;
    }
    #ThirdSection .mobileview{
      text-align:center;
    }
    .shareStory #ThirdSection.col-md-4{
      margin-top:0px;
    }

}
@media only screen and (max-width: 360px){
  
}

// New Style
--body{
  overflow:hidden;
}
  .main{
      // background-color: rgb(17, 79, 255);
      flex-direction: column;
      display: flex ;
      overflow-y: auto ;
      height:100vh
  }
  
  .logo {
      width: 108px;
      height: 50px;
      margin-right:80px
  }
    .title{
        justify-content: center;
        display: flex;
        // padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      margin-top: 70px;
      border-radius: 8px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #031e6a;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }

  .card{
      height: 227px;
  width: 205px;
  justify-content: center;
  display: flex;
  cursor:pointer;
  flex-direction: column;
  padding: 0px 20px;
  text-align: center;
  }

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  height: 787px;
  width: 622px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}

.card-wrap .card img{
height: 180px;
width: 180px;
border-radius:15px;
}
.card-wrap .card img:hover{
box-shadow: 0px 0px 11px 1px #fac807;
}
.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
  //  height: 31px;
  height: 59px;
   width: 180px;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 70px;
   text-align: center;
}
.card span:hover{
color: #000;
}
.map-img,
.map-img .map-content{
   height: 711px;
   width: 100%;
   border-radius: 20px;
}

.map-wrap{
position:relative;
}
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 20px;
top: 20px;
}

.shareStory{
 color:#fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}
.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
// height: 100px;
// width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid h4{
// height: 24px;
// width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
// height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
// height: 72px;
// width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

}
.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}
.banner-img{
// height:100%;
width:100%;
background-size: cover;
}
.banner-img .container{
justify-content: space-between;
align-items: center;
// flex-direction: column;
}

.banner-inner-img{
// height: 534.75px;
// width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
// height: 128px;
// width: 649px;
// width: 100%;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 56px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
// height: 87px;
// width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

.learMore{
box-sizing: border-box;
height: 42px;
width: 162px;
border: 2px solid #FFFFFF;
border-radius: 8px;
margin-right:10px;
}
.learMore span:nth-child(1){
height: 20px;
width: 105.39px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
text-transform: capitalize;
}
.shareguid svg{
margin-top: 16px;
margin-right:35px;
width: 60px;
height: 60px;
}

.mobileview{
display:none;
}

@media only screen and (max-width: 1620px) {
.map-img, .map-img .map-content {
  height: 680px;
  width: 100%; //640px;
}
.line {
  height: 680px;
}
}
@media only screen and (max-width: 1440px) {
.map-img, .map-img .map-content {
width: 100%; //42vw;
}
.shareStory .shareguid p{
width:100%;
} 
}

@media only screen and (max-width: 1199px) {
.shareStory .shareguid p {
  height: 80px;
}
// .terms-of-service {
//   height: 28px;
//   width: 93px;
// }
.j-story-is-the-pro {
  margin-right: -8px;
}
.map-wrap .map-search {
  width: 80%;
}
.shareguid svg {
  margin-right: 9px;
}
}
@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}
.map-img, .map-img .map-content {
width: 40vw;
}
.card-wrap {
width: 42vw;
  height: 680px;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
.card span {
height: auto;
}
.partner-card .card-1 {
// height: 420px;
// width: 48%;
}
}

@media only screen and (max-width: 1024px) {
.experience-the-jewis {
  // width: 100%;
  // font-size: 4vw;
}
.j-story-is-a-communi {
  // width: 100%;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  // font-size: 2.7vw;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}

.shareStory .col-md-4:nth-child(3){
// padding: 240px 0 50px 15px;
padding: 55px 0 50px 47px;
}
.shareguid-content{
  margin-left: 19px;
}
.partner-card p {
  font-size: 17px;
  // height: 29% !important;
}
// .footer-section .row-3 .list {
// width: 170px;
// }
}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.shareguid-content{
  width:67%;
}
.shareStory .col-md-4:nth-child(3){
  padding:0px 0 50px 47px !important;
}


.card span {
//height: 31px;
width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}
.shareStory .shareguid h4 {
  // height: auto;
  // width: 24vw;
  line-height: 22px;
}
}


@media only screen and (max-width: 768px) {
  .banner-content-wrap {
    width: 70%;
    padding: 70px 0 0 0;
}
.shareStory .col-md-4:nth-child(2){
padding:15px 0 0px 15px;
}
.shareStory .col-md-4:nth-child(2) .shareguid:nth-child(2){
  padding:px 0 0px 0px;
}
.desktopview{
display:none !important;
}
.banner-inner-img {
  transform: translate(0, -5%);
}
.mobileview.hero-section-cards{
  display:none
  }
  .desktopview.hero-section-cards{
  display:block;
  }

.desktopview {
  display: block;
}
.shareStory .col-md-4:nth-child(3) .mobileview{
  display: flex;
  flex-direction: column;
}

.banner-img .container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.experience-the-jewis{
line-height: 53px;  
font-size: 56px;
// height: 58%;
}
.footer-row-wrap .md.hydrated{
  display: block;
}
.card {
height: 260px;
width: 100%;
}
.card-wrap .card img {
height: 200px;
width: 100%;
}
.shareStory .col-md-4 {
width: 100%;
}

.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .shareguid:nth-child(2) {
  padding: 0px 0px 0px 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) .shareguid img{
  // margin-left:35px !important;
  margin-left:17px !important;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}

.shareStory h2 {
// width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
// margin-top: -8px;
}
.partner-card p {
// width: 100%;
// height: 29% ;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}
.banner-content-wrap {
  padding: 82px 0 0 0;
}

}

@media only screen and (max-width: 575px) {
  .title{
    line-height:36px !important
}
.recommended-cards-wrapper .recommendedCard.carousel-slider-card{
}
.slick-initialized .slick-slide {
  display: block;

}
.jewish-vienna .item-title-link {
  font-size: 20px !important;
}
.line{
  display:none;
}
.map-img, .map-img .map-content {
  height: 55vh !important;
  border-radius: 0 !important;
}
.map-card-wrap {
  margin-bottom: 142px;
  margin-top: 80px !important;
}
.mobileview{
  display:block;
}
.card-wrap {
  width: 100vw !important;
}

.desktopview{
  display:none;
}
.search-results {
  gap: 0rem;
}
.banner-content-wrap {
  width: 90%;
}
.leaflet-control-zoom{
display:none;
}
.map-card-wrap h3{
text-align:center;
}
.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 100%;
// width: 555.09px;
width: 100%;
transform: translate(0, 5%);
}
.title {
padding-top: 80px;
}
.experience-the-jewis {
// height: 62%;
line-height: 53px;
font-size: 43px;
}
.j-story-is-a-communi {
font-size: 4.7vw;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
width: 100%;
height: 55vh;
border-radius: 0px;
}
.map-card-wrap .container{
width:auto;
}
.line {
height: 1px;
width: 100vw;
margin-top: 40px;
}
.card-wrap {
width: 100vw;
height: 100%;
}
.map-card-wrap {
margin-bottom: 50px;
}
.explore-wrap h3{
margin-top: 50px;
}
.card {
height: 100%;
width: 45%;
margin: 0 auto;
}
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
// height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: 0px;
}
.partner-card .card-1 {
margin: 24px 0;
}
.map-wrap .map-search{
width: 83vw;
left: 19px;
}
.map-search>div{
width: 80%;
}
.row-2 button{
margin-top:15px;
}
.shareStory .shareguid p {
height: fit-content;
width: 100%;
}
// .shareStory .col-md-4:nth-child(2) .shareguid h4 {
// margin-top: 14px;
// }
.shareStory .shareguid h4 {
// height: fit-content;
}
.shareStory {
// padding-bottom: 100%;
}
.shareStory .col-md-4:nth-child(3) {
padding: 24px 0 0px;
}


.shareStory .col-md-4:nth-child(3) .shareguid {
  margin-bottom: -10px;
}
.shareStory .col-md-4:nth-child(3) .shareguid svg{
margin-top: -95px;
margin-bottom: 47px;
}

.shareStory .col-md-4:nth-child(3)  .mobileview{
display: flex;
flex-direction: column;
}
.shareStory .col-md-4:nth-child(3)  .mobileview a{
margin-top: 20px;
}
.map-wrap .map-search {
width: 93vw;
}
.map-card-wrap {
margin-top: 60px;
}
.card {
  height: auto;
  width: 45% !important;
}

}

@media only screen and (max-width: 475px) {

.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.line{
width: 100%;
}
.map-card-wrap {
margin-top: 50px;
}
.map-wrap .map-search {
width: 90vw;
}

}

@media only screen and (max-width: 425px) {
.shareStory h2 {
// height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}
}
@media only screen and (max-width: 375px){
.please-sumbit-your-d {
width: 100%;
margin-bottom: 45px;
}
.right-footer img {
margin-right: 22px;
}
.j-story-is-the-pro {
height: 100%;
}
.shareStory {
// padding-bottom: 8%;
}
.shareStory .shareguid {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}
.shareStory .shareguid h4 {
// width: 100%;
}
.shareStory .shareguid h4 {
margin-top: -39px;
}
.shareStory .col-md-4:nth-child(3) .mobileview {
align-items: center;
}
.shareStory .col-md-4:nth-child(2){
  padding: 0 0 0 0;
}
.shareguid-content{
  margin-left:0;
}
.shareStory .shareguid img,.shareStory .col-md-4:nth-child(3) .shareguid img{
  margin:0 !important;
  margin-left:0 !important;
}
.shareStory .col-md-4:nth-child(3){
  padding:0 0 !important;
}
}
@media only screen and (max-width: 360px){

.shareguid img {
  margin-bottom: 18px;
}
}

// New Style
--body{
  overflow:hidden;
}
  .main{
      // background-color: rgb(17, 79, 255);
      flex-direction: column;
      display: flex ;
      overflow-y: auto ;
      height:100vh
  }

  .logo {
      width: 108px;
      height: 50px;
      margin-right:80px
  }
    .title{
        justify-content: center;
        display: flex;
        // padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      margin-top: 70px;
      border-radius: 8px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #031e6a;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }

  .card{
      height: 227px;
  width: 205px;
  justify-content: center;
  display: flex;
  cursor:pointer;
  flex-direction: column;
  padding: 0px 20px;
  text-align: center;
  }

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  height: 787px;
  width: 622px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}

.card-wrap .card img{
height: 180px;
width: 180px;
border-radius:15px;
}
.card-wrap .card img:hover{
box-shadow: 0px 0px 11px 1px #fac807;
}
.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
  //  height: 31px;
  height: 59px;
   width: 180px;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 70px;
   text-align: center;
}
.card span:hover{
color: #000;
}
.map-img,
.map-img .map-content{
   height: 711px;
   width: 100%;
   border-radius: 20px;
}

.map-wrap{
position:relative;
}
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 20px;
top: 20px;
}

.shareStory{
 color:#fff;
  //  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}

.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
// height: 100px;
// width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}

.shareStory .shareguid h4{
// height: 24px;
// width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
// height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
// height: 72px;
// width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

}
.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}
.banner-img{
// height:100%;
width:100%;
background-size: cover;
}
.banner-img .container{
justify-content: space-between;
align-items: center;
}

.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
// height: 128px;
// width: 649px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 56px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
// height: 87px;
// width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

.learMore{
box-sizing: border-box;
height: 42px;
width: 162px;
border: 2px solid #FFFFFF;
border-radius: 8px;
margin-right:10px;
}
.learMore span:nth-child(1){
height: 20px;
width: 105.39px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
text-transform: capitalize;
}
.shareguid svg{
margin-top: 16px;
margin-right:35px;
width: 60px;
height: 60px;
}

.mobileview{
display:none;
}
// .shareStory .col-md-4:nth-child(3){
//   justify-content:flex-start;
// }

@media only screen and (max-width: 1791px) {
}
@media only screen and (max-width: 1620px) {
.map-img, .map-img .map-content {
  height: 680px;
  width: 100%; 640px;
}
.line {
  height: 680px;
}
}


@media only screen and (max-width: 1440px) {
.map-img, .map-img .map-content {
width: 100%; //42vw;
}
.shareStory .shareguid p{
width:100%;
}

}

@media only screen and (max-width: 1199px) {
.shareStory .shareguid p {
  height: 80px;
}
// .terms-of-service {
//   height: 28px;
//   width: 93px;
// }
.j-story-is-the-pro {
  margin-right: -8px;
}
.map-wrap .map-search {
  width: 80%;
}
.shareguid svg {
  margin-right: 9px;
}
}
@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}
.map-img, .map-img .map-content {
//width: 40vw;
width: 100%;
}
.card-wrap {
width: 42vw;
  height: 680px;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
.card span {
height: auto;
}
.partner-card .card-1 {
// height: 420px;
width: 48%;
}
.shareguid-content{
margin-left:12px;
}
.partner-card .card-1{
  padding:0 20px;
}
}

@media only screen and (max-width: 1024px) {
.experience-the-jewis {
  // width: 100%;
  // font-size: 4vw;
}
.j-story-is-a-communi {
  // width: 100%;
}
.banner-content-wrap{
  width: 70%;
}

.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
// .shareStory .col-md-4:nth-child(2) .self-guid h4{
// margin-top: 53px;
// }
.shareStory .col-md-4:nth-child(3){
  padding: 55px 0 50px 47px !important;
  justify-content: end !important: 
}
.partner-card p {
  font-size: 17px;
}
.shareguid-content{
  margin-left:19px;
}
.shareguid img{
  margin-top:0px ;
}
}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.shareStory .col-md-4:nth-child(3){
  padding:0px 0 50px 47px !important;
  margin-top:99px;
}
.card span {
height: 31px;
width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}

}
@media only screen and (max-width: 800px) {
.shareStory .col-md-4:nth-child(3){
  margin-top:-180px;
}
.banner-img .container{
  flex-direction:column;
}
.experience-the-jewis{
  font-size:56px;
  margin-top:40px;
}
.partner-card .card-1{
  padding:0;
}
.banner-inner-img{
  transform:translate(0, 11%);
}
.jewish-vienna .item-title-link{
  width: 160px;
}
.pin-icon-wrap span,
.clock-iocn-wrap span {
  width: 70px !important;
  font-size: 12px !important;
}
}

@media only screen and (max-width: 800px) {
.desktopview.share-your-story-desktop-btn .createNew {
  font-size: 12px;
}
.card {
  width: 220px;
}
.card-wrap {
  justify-content: center;
}
.explore-wrap h3 {
  text-align: center;
}
}

@media only screen and (max-width: 768px) {
.experience-the-jewis{
line-height: 53px;  
// font-size: 6vw;
// height: 58%;
}
.banner-content-wrap {
  width: 100%;
}
.j-story-is-a-communi {
  margin-top: 26px;
}
.banner-inner-img {
  transform: translate(0, 15%);
}
.mobileview.hero-section-cards{
  display:none
  }
  .desktopview.hero-section-cards{
  display:block;
  }
.mobileview{
  display:block !important;
}

.card {
height: 260px;
width: 100%;
}
.card-wrap .card img {
height: 200px;
width: 100%;
}
.shareStory .col-md-4 {
width: 100%;
}
.shareStory {
// height: 100vh;
}
.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
// .shareStory .shareguid {
// padding: 0px;
// }
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 90px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
// margin-top: 0px;
}
.shareStory h2 {
// width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
// margin-top: -8px;
}
.partner-card p {
// width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}
  .shareStory {
    // padding-bottom: 80px;
    // height: 107vh;
    padding-bottom: 30px;
    // height: 116vh;
    }
    .shareguid img {
      margin-right: 14px !important;
    }
    .shareguid{
      justify-content:flex-start;
    }
    .shareStory .col-md-4:nth-child(3){
      margin-top:99px;
     padding:0px 0 50px 0 !important
    }
}

@media only screen and (max-width: 575px) {
.recommended-cards-wrapper .recommendedCard.carousel-slider-card{
  // width: 400px !important;
  // overflow: hidden;
}
.slick-initialized .slick-slide {
  display: block;
  // width:400px !important;

}
// .learMore {
//   margin-bottom: 0px !important;
// }

.mobileview{
  display:block;
}

.desktopview{
  display:none;
}
.search-results {
  gap: 0rem;
}
.banner-content-wrap {
  width: 90%;
}
.leaflet-control-zoom{
display:none;
}
.map-card-wrap h3{
text-align:center;
}

.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 493.75px;
// width: 555.09px;
width: 100vw;
transform: translate(0, 5%);
}
.title {
padding-top: 80px;
}
.experience-the-jewis {
// height: 62%;
line-height: 53px;
font-size: 43px;
}
.j-story-is-a-communi {
font-size: 4.7vw;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
width: 100%;
height: 35vh;
border-radius: 0px;
}
.map-card-wrap .container{
width:auto;
}
.line {
height: 1px;
width: 100vw;
margin-top: 40px;
}
.card-wrap {
width: 100vw;
height: 100%;
}
.map-card-wrap {
margin-bottom: 50px;
}
.explore-wrap h3{
margin-top: 50px;
}
.card {
height: 260px;
width: 45%;
margin: 0 auto;
}
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
height: 320px;
width: 100%;
}
.partner-card .partner-btn {
// margin-top: -11px;
}
.partner-card .card-1 {
margin: 24px 0;
}
.map-wrap .map-search{
width: 83vw;
left: 19px;
}
.map-search>div{
width: 80%;
}
.row-2 button{
margin-top:15px;
}
.shareStory .shareguid p {
height: fit-content;
width: 100%;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4 {
// margin-top: 14px;
}
.shareStory .shareguid h4 {
// height: fit-content;
}
.shareStory {
// padding-bottom: 80px;
// height: 107vh;
padding-bottom: 20px;
// height: 116vh;
}
.shareStory .col-md-4:nth-child(3) {
padding: 100px 0 0px;
}

.shareStory .col-md-4:nth-child(3) .shareguid {
  margin-bottom: -10px;
}
.shareStory .col-md-4:nth-child(3) .shareguid svg{
margin-top: -95px;
margin-bottom: 47px;
}

.shareStory .col-md-4:nth-child(3)  .mobileview{
display: flex;
flex-direction: column;
}
.shareStory .col-md-4:nth-child(3)  .mobileview a{
margin-top: 20px;
}
.map-wrap .map-search {
width: 93vw;
}

.map-card-wrap {
margin-top: 60px;
}
.recommended-cards-wrapper .recommendedCard .item-preview {
  // width: auto !important;
  width: 100% !important;
  height: 270px !important;
}
#listOfCard.recommended-cards-wrapper .recommendedCard .item-preview {
  // width: auto !important;
  width: 15vw !important;
  height: 170px !important;
}
.sliderBtn-wrap{
  // position: relative;
}
.sliderBtn-wrap .arrow-btn.prev {
  position: absolute;
  top: 50%;
  left: -15px;
}
.sliderBtn-wrap .arrow-btn.next {
  position: absolute;
  top: 50%;
  right: -15px  ;
  z-index: 99999;
}
.card {
    // height: auto;
    width: 45% !important;
}
.card-wrap .card img {
  height: 204px !important;
      width: 244px !important;
  margin: 0 auto !important;
}
.card span {
  line-height: 47px !important;
}
.map-card-wrap {
  margin-bottom: 40px !important;
}
.shareStory .col-md-4:nth-child(1) p {
  margin-top: 32px !important;
}

}

@media only screen and (max-width: 475px) {

.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.line{
width: 100%;
}
.map-card-wrap {
margin-top: 50px;
}
.map-wrap .map-search {
width: 90vw;
}

.shareguid-content {
  width: 90%;
  margin-left: 0px;
}

}



@media only screen and (max-width: 475px){
.learMore {
  margin-bottom: 13px;
}
#ThirdSection .shareguid+.mobileview{
text-align:center;
}
}

@media only screen and (max-width: 425px) {
  .shareStory h2 {
  // height: 66px;
  }
  .shareStory .col-md-4:nth-child(1) p{
  font-size: 4vw;
  }
  }

@media only screen and (max-width: 375px){
  #listOfCard.recommended-cards-wrapper .recommendedCard .item-preview {
    width:25vw !important;
  }
.please-sumbit-your-d {
width: 100%;
margin-bottom: 45px;
}
.right-footer img {
margin-right: 22px;
}
.j-story-is-the-pro {
height: 100%;
}
.shareStory {
// height: 149vh;
padding-bottom: 8%;
}
.shareStory .shareguid {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}
.shareStory .shareguid h4 {
// width: 100%;
}
.shareStory .shareguid h4 {
margin-top: -39px;
}
.shareStory .col-md-4:nth-child(3) .mobileview {
align-items: center;
}
.card {
   height: 260 !important;
  width: 84% !important;
}
.shareStory .col-md-4:nth-child(2) .shareguid img {
  height: auto;
  margin-top: -18px;
  margin-bottom: 20px;
  margin-left: 0;
}
// .shareStory .shareguid {
//   padding: 30px 0px 0px 15px !important;
// }
.shareStory .shareguid h4{
  margin:0 auto !important;
}
.partner-card p {
  // height: auto !important;
}
}
@media only screen and (max-width: 360px){

}
`;

export default Home;
