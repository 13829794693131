import { IonContent, IonModal, IonSpinner } from '@ionic/react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ClearIcon from '@material-ui/icons/Clear';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ListIcon from '@material-ui/icons/List';
import RoomIcon from '@material-ui/icons/Room';
import ExperienceBrowse from '../components/ExperienceBrowse/ExperienceBrowse';
import FilterToolbar from '../components/FilterToolbar/FilterToolbar';
import FilterItem from '../components/search/FilterItem';
import { ItemType } from '../model/ItemType';
import { exploreExperiences, getProjects, queryExperiences } from '../util/API';
import useQuery, { QUERY_KEY } from '../util/useQuery';
import useTranslations from '../util/useTranslation';

import Footer from './Home/Footer';
import Header from './Home/Header';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#0849FF',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#0849FF',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const ProjectPage = () => {
  const [allData, setAllData] = useState<any>([]);
  const [spinner, setSpinner] = useState(false);
  const [exps, setExps] = useState<any>([]);

  const [project, setProject] = useState<any>();
  const [ds] = useTranslations('dashboard');
  const itemsPerPage = 12;
  const [page, setpage] = useState<number>(1);
  const { params } = useRouteMatch<{ id?: string}>();
  const { getQuery, setQuery, mapLocation, searchParams, getGeoLocation } = useQuery(QUERY_KEY);

  const query = getQuery();
  const [bbox, setBbox] = useState<any>();
  const [checked, setChecked] = useState<boolean>(false);
  const paramStr = searchParams().toString();
  const [map, setMap] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = useState<any>();
  let [defaultData, setdefaultData] = useState<any>([]);
  let experiencecLocation: any = [];
  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
  ]);
  var maplocation: any = [];
  const [mapRef, setMapRef] = useState<any>(null);
  const [t] = useTranslations('search');

  const zoom = 3;

  const {
    filter: filters,
    experiences: expType,
    language: languages,
    holiday: holidayRelated,
  } = t('searchexplore.filterslist', {
    returnObjects: true,
  }) as {
    filter: Array<{ id: string; text: string }>;
    experiences: Array<{ id: string; text: string }>;
    language: Array<{ id: string; text: string }>;
    holiday: Array<{ id: string; text: string }>;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    toggleFilter();
  };

  const { toggleFilter } = useQuery('guided', 'true');

  const _ApplyFilter = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    }: any = await queryExperiences(paramStr);
    setSpinner(false);
    setAllData(experiences);
  };

  const fetchProjectData = async () => {
    setSpinner(true);
    console.log(params);
    const {
      data: { projects },
    } = await getProjects(params?.id);
    setSpinner(false);
    setProject(projects[0]);
    setMap(projects[0].default_view == 'map');

    setAllData(projects[0].experiences);
    setExps(projects[0].experiences);
    console.log(projects[0]);
  };

  useEffect(() => {
    fetchProjectData();
  }, [params?.id]);

  const openMap = () => {
    setOpen(true);
  }

  const handleMap = (value: any) => {
    setMap(value);
  }


  const onPageChange = (event, value) => {
    setpage(value);
    document.getElementById('section')?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  console.log(project?.main_partners);

  return (
    <>
      <Helmet>
        <title>{project?.title}</title>
        <meta property="og:title" content={project?.title} />
        <meta property="og:description" content={project?.description} />
      </Helmet>
      <div className='main' id='main-pr' style={{ overflowY: 'scroll', height: '100vh' }}>
        <style>{css}</style>
        <Header />
        <div className='project-wrap'>
          {spinner && (
            <div className='spinner'>
              <IonSpinner color='primary' />
            </div>
          )}
          <div className='container'>
            <div className='about-this-wrap'>
              <div className='d-flex about-this-content-wrap'>
                <div>
                  <h2>{project?.title}</h2>
                  <span>{project?.duration}</span>
                </div>
                <div className='d-flex'>
                  <div className='europe-commition-img d-flex'>

                    {project?.main_partners.map((partner, index) => (
                      <a href={partner.partners_id.url} target="_blank">
                        <img src={partner.partners_id.logo_url} alt={partner.partners_id.name} />
                      </a> 
                    )) }
                  </div>
                </div>
              </div>
              <div className='about-project-title'>
                <h4>{ds('project.about')}</h4>
                <div className='d-flex'>
                  <div className='about-description'>
                    <p dangerouslySetInnerHTML={{ __html: project?.description }} ></p>
                  </div>
                </div>
              </div>
            </div>
            <div className='partnership-with d-flex'>
              <div>
                <span>{ds('project.partnership')}</span>
              </div>
              <div className='partnership-img-wrap d-flex'>
                {project?.partners.map((partner, index) => (
                  <div className='partner-logo-wrap'>
                    <a href={partner.partners_id.url} target="_blank">
                      <img src={partner.partners_id.logo_url + '?width=150'} alt={partner.partners_id.name} style={{ objectFit: 'scale-down' }} />
                    </a>
                  </div>
                ))}

              </div>
            </div>
          </div>
        <div dangerouslySetInnerHTML={{ __html: project?.content }} ></div>

         <div id="section">
          <FilterToolbar location={location} mapLocation={mapLocation} map={map} handleOpen={() => openMap()} handleMap={(value) => handleMap(value)} />
          <div className='map-list-btn mobileview'>
        {map == false ? (
          <Button
            onClick={() => setMap(true)}
            className='switchbutton show-map'
          >
            {t('searchexplore.map')}
            <RoomIcon />
          </Button>
        ) : (
          <Button
            onClick={() => {
              mapLocation(null);
              setMap(false);
            }}
            className='switchbutton list-view'
          >
            {t('searchexplore.list')}
            <ListIcon />
          </Button>
        )}

      </div>
          {spinner && (
            <div className='spinner'>
              <IonSpinner color='primary' />
            </div>
          )}
          {map == true ? (
            allData.length > 0 ? (
              <div className='container'>
                <ExperienceBrowse isShowExperienceMap hideTitle allData={allData} handleBboxdata={(value) => setBbox(value)} zoom={zoom}/>
              </div>
            ) : (
              query ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span
                    style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}
                  >
                    {t('searchexplore.noresult')}
                  </span>
                </div>
                : null
            )
          ) : allData.length > 0 ? (
            <div className="container">
              <div
                className='search-explore-card-wrap'
                style={{ marginTop: '60px' }}
              >
                {allData
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((experience) => (
                    <ExperienceBrowse isShowExperienceList  item={experience} ds={ds} zoom={zoom}/>
                  ))}
              </div>
              <div className='pagination-wrap' style={{ marginTop: '50px' }}>
                {allData.length > 12 ? (
                  page == 1 ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span className='experiences'>
                        {ds('location.display', {
                          count: allData.length
                        })}
                      </span>
                    </div>
                  ) : null
                ) : null}
                <div className='pagination-section container'>
                  <div className='pagination'>
                    <Pagination
                      hidePrevButton={page == 1 ? true : false}
                      hideNextButton={
                        page == Math.ceil(allData.length / itemsPerPage)
                          ? true
                          : false
                      }
                      onChange={onPageChange}
                      count={Math.ceil(allData.length / itemsPerPage)}
                      page={page}
                      defaultPage={1}
                      color='primary'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontSize: '20px', color: '#114fff', padding: '30px' }}>
                {t('searchexplore.noresult')}
              </span>
            </div>
          )}
          </div>
          {open == true ? (
            <IonContent>
              <div className='abc'>
                <Modal isOpen={open} onDidDismiss={() => setOpen(false)}>
                  <div className='filtermodel'>
                    <div className='model-heading'>
                      <div>
                        <span> {t('searchexplore.popup.head')}</span>
                        <div className='canclebtn'>
                          <ClearIcon onClick={() => setOpen(false)}></ClearIcon>
                        </div>
                      </div>
                    </div>
                    <div className='filter-model-wrap'>
                      <div className='content-wrap'>
                        <section>
                          <h5> {t('searchexplore.popup.exphead')}</h5>
                          <p>{t('searchexplore.popup.exptitle')} </p>
                          <div
                            className='tag-wrap'
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                          >
                            {expType.map((item) => (
                              <FilterItem
                                text={item.text}
                                value={item.id}
                                param={filters}
                                filterKey='type'
                                moreFilter={true}
                                explore={true}
                              />
                            ))}
                          </div>
                        </section>
                      </div>
                      <div className='content-wrap'>
                        <section>
                          <h5>{t('searchexplore.popup.guided')}</h5>
                          <p>{t('searchexplore.popup.exptitle')} </p>
                          <div className='tag-wrap Guided-tag'>
                            <span
                              className='everthing'
                              style={{ color: checked ? '#c3c9d8' : '#0849ff' }}
                            >
                              {t('searchexplore.popup.toggleone')}
                            </span>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={checked}
                                  onChange={handleChange}
                                  name='checked'
                                />
                              }
                              style={{ color: checked ? '#0849ff' : '#c3c9d8' }}
                              label={t('searchexplore.popup.toggletwo')}
                            />
                          </div>
                        </section>
                      </div>
                      <div className='content-wrap'>
                        <section>
                          <h5>{t('searchexplore.popup.language')}</h5>
                          <p>{t('searchexplore.popup.languagetitle')}</p>
                          <div
                            className='tag-wrap'
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                          >
                            {languages.map((item) => (
                              <FilterItem
                                text={item.text}
                                value={item.id}
                                moreFilter={true}
                                filterKey='language'
                                explore={true}
                              />
                            ))}
                          </div>
                        </section>
                      </div>
                      <div className='content-wrap'>
                        <section>
                          <h5>{t('searchexplore.popup.holiday')}</h5>
                          <p>{t('searchexplore.popup.holidaytitle')}</p>
                          <div
                            className='tag-wrap'
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                          >
                            {holidayRelated.map((item) => (
                              <FilterItem
                                text={item.text}
                                value={item.id}
                                moreFilter={true}
                                filterKey='holiday'
                                explore={true}
                              />
                            ))}
                          </div>
                        </section>
                      </div>
                    </div>
                    <div className='d-flex footer-wrap signin'>
                      <div>
                        <NavLink
                          to='#'
                          onClick={() => {
                            setChecked(false);
                            setOpen(false);
                            setAllData(defaultData);
                          }}
                          className='tos'
                        >
                          {t('searchexplore.popup.clear')}
                        </NavLink>
                        <Button
                          className='signupbtn'
                          variant='contained'
                          onClick={() => {
                            setOpen(false);
                            _ApplyFilter();
                          }}
                          color='primary'
                          endIcon={<ArrowRightAltIcon />}
                        >
                          {t('searchexplore.popup.apply')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </IonContent>
          ) : null}
        </div>
        <Footer />
      </div>
    </>
  );
};
const css = `
--body{
  overflow:hidden;
}

.d-flex{
  display:flex;
}
.search-explore-card-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
}
 
  .searchBar{
     display: flex;
     justify-content: space-between;
     margin-top: 80px;
  }
  .filterview{
    display: flex;
    align-items: center;
  }

  .filter{
    margin-left: 18px;
  }

  .experiences{
    color: #8992ac;
    padding-top: 75px;
    padding-bottom: 43px;
  }

  .morefilters{   
  height: 41px;
  // width: 11%;
  margin-left: 18px;
  // border-radius: 25px;
  color: black;
  font-family: "Source Sans Pro",sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: none;
  box-sizing: border-box;
  border: 1px solid #2A2A2A;
  border-radius: 20px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background-color: transparent;
  }
  .morefilters:hover{
    border: 1px solid rgb(17, 79, 255);
    color: rgb(17, 79, 255);
    background-color: transparent;
  }
  .switchbutton{
    height: 40px;
    // width: 15%;
    margin-left: 18px;
    background-color: #0849FF;
    color: white;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-transform: none;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .switchbutton:hover{
    background-color: #031e6a;;
  }

  .switchbutton.show-map svg{
    height: 28px;
    width: 18px;
    margin-left: 11px;
  }
  .switchbutton.list-view svg{
    height: 33px;
  width: 27px;
    margin-left: 11px;
  }
 

  .pagination{
    display:flex;
    justify-content:center;
    margin-bottom:100px
  }

  .signupbtn {
      color: #fff;
      background-color:#0849FF;
      margin-left: 20px;
      text-transform: none;
      height: 20px;
      width: 56px;
      color: #FFFFFF;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      border-radius: 25px;
      width: 132px;
      height: 40px;
    }
    .signupbtn:hover {
      color: #fff;
      background-color:#0849FF;
    }
    .title{
        justify-content: center;
        display: flex;
        // padding-top: 65px;
        color: #0849FF;
        font-family: "Source Sans Pro";
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
    }
    h3{
      color: #0849FF;
      font-family: "Source Sans Pro";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .exploreMore{
      height: 40px;
      width: 200px;
      border-radius: 25px;
      background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover{
      background-color: #0849FF;
    color: #FFFFFF;
  }
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
  .my-custom-class {
    --background: red;
  }
  .card,.highlight{
  height: 227px;
  width: 179px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  // padding: 0px 20px;
  text-align: center;
  // margin:0 auto;
  margin-left:15px;
  }
  
.card-wrap {
  display: flex;
  width: 2050px;
  margin-top: -41px;
  padding: 26px 0;
  overflow: auto;
}

.card-wrap .card img,
.card-wrap .highlight img {
  height: 153px;
    max-width: 152px;
    border-radius: 15px;
}
.card-wrap .highlight img{
  box-shadow: 0px 0px 11px 1px #fac807;
  // transform: scale(1.2);

}

.line{
  background-color: #7782A0;
  height: 712px;
  width: 2px;
  margin-top: 90px;
}

.card span{
  width: 100%;
   color: #717C9C;
   font-family: "Source Sans Pro";
   font-size: 18px;
   font-weight: bold;
   letter-spacing: 0;
   line-height: 55px;
   text-align: center;
}

.highlight span{
  width: 100%;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 55px;
  text-align: center;
}

.map-img{
    display: flex;
    justify-content: center;
}

.map-img .map-content{
  position: relative;
    outline: none;
    height: 958px;
    width: 1594px;
    margin-top: 0px;
  margin-bottom:170px;
   border-radius: 20px;
   height: 958px;
}
.map-wrap{
position:relative;
}
.map-wrap .leaflet-popup-content-wrapper .leaflet-popup-content{
  width: 350px;
 }
 .leaflet-popup-pane .leaflet-popup-content {
  margin: 0;
 }
.map-wrap .map-search{
position: absolute;
z-index: 1111;
width: 80%;
left: 60px;
top: 20px;
}

.shareStory{
 color:#fff;
   height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item-native{
 height:35px !important;
 min-height:35px !important;
}
.col-md-4{
width: 33.33333333%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 70px 0;
}

.shareStory .col-md-4{
padding: 70px 0 50px;
display: flex;
flex-direction: column;
justify-content: center;
}
.shareStory h2{
height: 100px;
width: 405px;
// margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 36px;
font-weight: bold;
letter-spacing: 0;
// line-height: 40px;
}

.shareStory p{
// height: 100px;
margin-bottom: 38px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
}
// .shareStory .createNew{
// margin-left: 0px;
// }

.shareStory .shareguid{
padding: 30px 100px 0px 100px;
}

.shareStory .shareguid h4{
height: 24px;
width: 236px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
font-weight: bold;
letter-spacing: 0;
line-height: 24px;
}
.shareStory .shareguid p{
height: 80px;
width: 304px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 20px;
}

.partner-card img{
height: 60px;
width: 65.92px;
}

.partner-card .card-1{
height: 420px;
width: 622px;
border-radius: 15px;
background-color: #FFFFFF;
box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
margin: 125px 0;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 125px;
text-align: center;
align-items: center;
}

.partner-card .partner-card-wrap{
display: flex;
justify-content: center;
}

.partner-card .card-1:nth-child(1) {
margin-right:27px;
}
.partner-card  h4{
height: 31px;
width: 304px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 24px;
font-weight: bold;
letter-spacing: 0;
line-height: 30px;
text-align: center;
}
.partner-card  p{
height: 72px;
width: 405px;
color: #545454;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}

.partner-card .partner-btn{
box-sizing: border-box;
  height: 42px;
  width: 200px;
  border: 2px solid #0849FF;
  border-radius: 25.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

}
.partner-card .partner-btn span{
height: 20px;
// width: 131px;
color: #0849FF;
font-family: "Source Sans Pro";
font-size: 16px;
font-weight: bold;
letter-spacing: 0;
line-height: 20px;
text-align: center;
}
.banner-img{
  height: 350px;
  width: 100%;
  background-size: cover;
  padding: 61px 0;
  background-position: 44% 10%;
  background-repeat: no-repeat;
}
.banner-img .container{
  align-items: center;
  width: 411px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-img .container h5{
height: 72px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 28px;
font-weight: bold;
letter-spacing: 0;
line-height: 31px;
text-align: center;
padding: 0 26px;
}

.banner-img .container p{
height: 72px;
width: 411px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 20px;
letter-spacing: 0;
line-height: 24px;
text-align: center;
}


.banner-inner-img{
height: 534.75px;
width: 589.09px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
transform: translate(0, 12%);
}

.experience-the-jewis {
height: 128px;
width: 840px;
margin-top: 100px;
color: #0849ff;
font-family: "Source Sans Pro";
font-size: 54px;
font-weight: bold;
letter-spacing: 0;
line-height: 64px;
}
.j-story-is-a-communi {
height: 87px;
width: 682px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 30px;
letter-spacing: 0;
line-height: 40px;
}

.map-card-wrap{
margin-bottom:142px;
margin-top: 152px;
}


.sub-list{
flex-direction: column;
}

ion-item {
  width: 459px;
  border-radius: 25px;
  background-color: #FFFFFF;
  box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
}

.poplular-subject-title{
  // height: 49px;
  height: 120px;
  width: 100px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.model-heading{
  display: flex;
  justify-content: space-between;
  padding: 34px 34px 0px 34px;
}
.filter-model-wrap{
  padding-top: 20px;
  height: 72vh;
  overflow: auto;
}

.model-heading span{
  height: 48px;
  width: 204px;
  color: #545454;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
}
.pagination li button{
  color:717C9C;
}
.europe-commition-img{
  align-items: end;
}
.about-this-content-wrap>div{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.map-img .leaflet-popup-content {
margin:0px 0px ;
}

.europe-commition-img img{
    width: 40vw;
    //max-width: 15%;
    height: 100%;
}

.about-this-wrap{
    padding: 40px 0 50px;
    border-bottom: 1px solid #7782A0;
}

.about-this-content-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}
.europe-commition-img{
    display: flex;
    justify-content: flex-end;
}
.about-this-content-wrap span{
    color: #717C9C;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.29px;
    line-height: 16px;
}
.about-project-title>div.d-flex{
    justify-content: space-between;
}

.about-project-title h4{
    height: 40px;
    width: 710px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}

.about-project-title .about-description{
    width: 100%;
    column-count: 2;
    column-span: all;
    column-gap: 5vw;
    
}
.about-project-title .about-description li + li {
  margin-left: 0;
}


.about-project-title p{
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
}

.exptitle{
    height: 40px;
    width: 710px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}

.partnership-with img{
    //max-width: 75%;
    margin: 0 2vh;
}
.partnership-img-wrap .partner-logo-wrap {
    max-width: 15vw;
   // margin: 0 auto;
    display: flex;
    justify-content: center;
}
.partnership-with{
    padding:100px 0 108px;
    display: flex;
    justify-content: space-between;
}

.partnership-with span{
    height: 19px;
    width: 250px;
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    partnership-with
}
.partnership-img-wrap{
    width:81%;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1980px) {
ion-item {
  width: 100%;
}
.searchBar .search-inner-wrap{
  width: 28%;
}
.filterview {
  width: 78%;
  // width: 72%;
  justify-content: flex-end;
}
.filter-btn-tag {
  width: 95%;
}
.search-explore-tag>div{
  width: 95%;
}
.filter {
  width: 15%;
  margin-left: 6px;
}
.filter-wrapper.d-flex{
  justify-content: flex-end;
}

}

@media only screen and (max-width: 1791px) {
.filterview {
  width: 100%;
}
}
@media only screen and (max-width: 1620px) {
.map-img, .map-img .map-content {
  // height: 680px;
  // width: 640px;
  width: 100%;
}
.line {
  height: 680px;
}
.filter-wrapper.d-flex {
  justify-content: flex-end;
  width: 66%;
}
}
@media only screen and (max-width: 1502px) {
}

@media only screen and (max-width: 1440px) {
.about-this-content-wrap>div{
    width: 100%;
}
}


@media only screen and (max-width: 1284px) {
.banner-inner-img {
  transform: translate(0, 22%);
}

.card-wrap {
width: 100vw;
  height: auto;
  margin-top: -19px;
  padding-top: 0;
}
.explore-wrap h3 {
margin-bottom: 50px;
}
.card span,
.highlight span {
height: 32px;
width: 117px;
}

.partner-card .card-1 {
height: 420px;
width: 48%;
}

}

@media only screen and (max-width: 1244px) {

 .filter-wrapper.d-flex {
   width: 61%;
}

 }

@media only screen and (max-width: 1200px) {
.filterview {
  justify-content: initial;
  width: 81%;
}
.morefilters,
.switchbutton {
  font-size: 1vw;
  padding-left: 13px;
  padding-right: 13px;
}
.experience-the-jewis {
  margin-top: 20px;
}
}


@media only screen and (max-width: 1199px) {
  .card-wrap .card img, .card-wrap .highlight img {
    height: 67%;
    max-width: 158px;
  }
  
  .card-wrap {
    height: auto;
  
  }
  
}

@media only screen and (max-width: 1024px) {
.experience-the-jewis {
  width: 100%;
  font-size: 4vw;
}
.j-story-is-a-communi {
  width: 100%;
}
.europe-commition-img img {
  width: 40vw;
  max-width: 100%;
  height: auto;
}
.banner-content-wrap{
  width: 70%;
}
.j-story-is-a-communi {
  font-size: 2.7vw;
}
.shareStory .shareguid {
  padding: 57px 100px 2px 35px;
}
.shareStory .shareguid h4 {
margin-top: -91px;
}
.shareStory .col-md-4:nth-child(2) .shareguid h4{
margin-top: -9px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4{
margin-top: 53px;
}
.shareStory .col-md-4:nth-child(3){
// padding: 206px 0 50px;
}
.partner-card p {
font-size: 1.8vw;
}
}

@media only screen and (max-width: 991px) {
.card {
  height: 227px;
  width: 178px;
}
.card span {
height: 31px;
width: 100%;
}
.partner-card-wrap .card-1 p{
padding: 0 25px;
}
.partner-card .partner-btn {
margin-top: 30px;
}

.searchBar {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
}
.searchBar .search-inner-wrap {
  width: 100%;
  margin-bottom:30px;
}
.filterview {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.filter-wrapper.d-flex{
  width: 100%;
}
.filter {
  width: 18%;
  margin-bottom:30px;
}
.morefilters, .switchbutton {
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
}

.popular-card-wrap{
  display: flex;
  flex-direction: column;
}
.poplular-subject-title {
  width: 100%;
    height: 30px;
}
.card-wrap {
  height: auto;
  width: 877px;
  overflow: auto;
}

}


@media only screen and (max-width: 768px) {
   .map-img .map-content {
     margin-top:0;
  }
  .map-img, .map-img .map-content {
    width:100%;
    height:50vh;
    margin-bottom:45px;
  }
.experience-the-jewis{
line-height: 43px;  
}
.search-explore-card-wrap>div{
  width: 46%;
}
.signupbtn{
  // font-size: 1.8vw;
      width: 115px;
      margin-left: 0px;
      margin-bottom: 15px;
  }
  .map-list-btn.mobileview{
    position: relative;
  }
  .map-list-btn.mobileview .switchbutton{
    position: absolute;
    top: 162px;
    left: -35px;
    z-index: 1111;
    width: 185px;
  }
  .map-list-btn.desktop{
    display:none;
  }
.europe-commition-img {
  display: flex;
  justify-content: initial;
  margin-top: 40px;
  align-items: end;
}
.about-this-content-wrap {
  display: flex;
  justify-content: initial;
  flex-direction: column;
  margin-bottom: 50px;
}
.partnership-with{
  flex-direction: column;
}
.partnership-img-wrap div{
    max-width: 25vw;
    margin: 14px auto;
}
.partnership-img-wrap{
  flex-wrap: wrap;
  margin-top: 30px;
  width:100%;
}
.signupbtn{
// font-size: 1.8vw;
    width: 115px;
}
.card,.highlight {
  height: 220px;
  width: 26%;
}

.card-wrap .highlight span, .card-wrap .card span {
  width: 100%;
}

.card-wrap .card img, .card-wrap .highlight img {
  height: 67%;
  max-width: 150px;
}

.shareStory .col-md-4 {
width: 100%;
}
.shareStory {
height: 100vh;
}
.shareStory .row{
display: flex;
    flex-direction: column;
}
.shareStory .col-md-4:nth-child(1) {
padding: 50px 0 0px;
}
.shareStory .col-md-4 {
padding: 0px 0 0px;
}
.shareStory .shareguid {
padding: 0px;
}
.shareStory .col-md-4:nth-child(2){
display:flex;
}
.shareStory .col-md-4:nth-child(3) {
padding: 50px 0 0px;
}
.shareStory .col-md-4:nth-child(2) .self-guid h4 {
margin-top: 22px;
}
.shareStory h2 {
width: 100%;
font-size: 5vw;
}
.shareStory .col-md-4:nth-child(1) p{
margin-top: -8px;
}
.partner-card p {
width: 100%;
}
.partner-card .card-1 {
padding: 0 2px;
}
.partner-card h4 {
width: 100%;
font-size: 19px;
}
.card-wrap {
  margin-top: 23px;
  max-width: 669px;
  overflow-x:auto;
}
.container.search-explore-card-wrap>div{
width:100%;
}
.signupbtn {
    margin-bottom: 17px;
    margin-top: 20px;
}
}

@media only screen and (max-width: 575px) {
.experiences {
  width: 94%;
  text-align:center;
}
.search-explore-card-wrap>div {
  width: 80%;
  margin: 0 auto;
}
.banner-img .container {
display: flex;
flex-direction: column;
}
.banner-inner-img {
height: 263.75px;
width: 306.09px;
}
.experience-the-jewis {
height: 70px;
line-height: 31px;
font-size: 23px;
}
.banner-content-wrap {
margin: 52px 0 0;
}
.map-card-wrap .row{
display: flex;
flex-direction: column;
}
.map-img, .map-img .map-content {
// width: 100%;
height: 35vh;
}
.line {
height: 1px;
width: 90vw;
margin-top: 40px;
}
.card-wrap {
width: 87vw;
}
.partner-card .partner-card-wrap {
flex-direction: column;
}
.partner-card .card-1 {
height: 320px;
width: 100%;
}
.partner-card .partner-btn {
margin-top: -11px;
}
.partner-card .card-1 {
margin: 24px 0;
}

.map-wrap .map-search{
width: 80vw;
}
.map-search>div{
width: 80%;
}
.row-2 button{
margin-top:15px;
}

.filter {
  margin-left: 9px;
}
.card, .highlight {
  height: 220px;
  width: 44%;
}
.europe-commition-img img {
    width: 40vw;
    height: 100%;
}
.about-this-content-wrap>div h2{
  
        font-size: 28px;
}
.about-this-content-wrap {
    margin-bottom: 27px
}
.about-project-title>div.d-flex{
    flex-direction: column;
}
.about-project-title .about-description {
    width: 100%;
}

.partnership-with {
    padding: 45px 0 40px;
    flex-direction: column;
}
.partnership-img-wrap {
    width: 95%;
    margin-top: 37px;
    display: flex;
    flex-wrap: wrap;
}
.partnership-img-wrap div{
  max-width: 50%;
    margin: 12px auto;
    display: flex;
    justify-content: center;
}

.partnership-img-wrap .partner-logo-wrap {
  max-width: 25vw;
}

}

@media only screen and (max-width: 475px) {
.map-wrap .map-search {
width: 76vw;
}
.please-sumbit-your-d {
width: 100%;
}
.banner-img .container,
.banner-img .container p {
  width: 100%;
}
.banner-img .createNew {
  height: 40px;
  width: 55%;
}

}

@media only screen and (max-width: 425px) {
.j-story-is-a-communi {
font-size: 16px;
line-height: 20px;
}
.shareStory h2 {
height: 66px;
}
.shareStory .col-md-4:nth-child(1) p{
font-size: 4vw;
}

}

@media only screen and (max-width: 375px){
.banner-img .createNew {
  height: 40px;
  width: 65%;
  margin-top: 26px;
}
.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
}
@media only screen and (max-width: 360px){
}
@media only screen and (max-width: 320px){
.morefilters, .switchbutton {
  padding-left: 16px;
  padding-right: 16px;
}
.jewish-vienna {
  width: 203px;
}
}

#listOfCard .leaflet-popup-content{
  margin:0 0 !important;
}
  
    @media only screen and (max-width: 991px) {
   
    }
    
    @media only screen and (max-width: 768px) {

      .signupbtn{
        // font-size: 1.8vw;
            width: 115px;
            margin-left: 0px;
            margin-bottom: 15px;
        }
    .about-this-content-wrap span {
      margin-top: 27px !important;
    }
    
    @media only screen and (max-width: 575px) {
      .please-sumbit-your-d {
        width: 100%;
      }
    }
  
    @media only screen and (max-width: 425px) {
  
    }
    
    @media only screen and (max-width: 320px){
    
    .jewish-vienna {
      width: 203px;
    }
    }
  `;

const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  --font-family: 'Blooming Elegant', sans-serif;
  --color: red;

  .ion-page {
    background-color: #fff;
  }
  .modal-wrapper {
    height: 94%;
    width: 946px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
  }

  .model-heading > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #7782a0;
  }

  .content-wrap {
    padding: 0 47px;
    margin-bottom: 35px;
  }
  .content-wrap section h5 {
    height: 40px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .content-wrap section p {
    height: 25px;
    width: 364px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
  }

  .content-wrap .filter {
    background-color: #F7F7F7;
    height: 37px;
    margin-left: 0px !important;
    margin-right: 8px !important;
    padding: 11px 20px;
    border-radius: 20px;
    color: #696363;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    border-radius: 20px;
  }
  .filter:hover {
    background-color: rgb(17, 79, 255);
    color: #f7f7f7;
  }
  .tag-wrap {
    margin-top: 42px;
  }
  .tag-wrap>div{
    margin:9px;
  }

  .tag-wrap.Guided-tag {
    margin-top: 35px;
  }

  .everthing {
    height: 20px;
    width: 76px;
    // color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }

  .tag-wrap label {
    margin: 0 10px;
  }
  .Component-switchBase-2.Component-checked-5 + .Component-track-4 {
    background-color: #0849ff !important;
    height: 83%;
  }

  .Component-switchBase-2.Component-checked-5 {
    transform: translate(16px, -2px) !important;
  }
  .tos {
    width: 216px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }


  .footer-wrap.signin {
    padding: 16px 47px 0;
  }
  .footer-wrap.signin > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-top: 1px solid #7782a0;
    padding: 25px 0;
  }
  
  #listOfCard .leaflet-popup-content {
    margin: 0 0 !important;
  }
  @media only screen and (max-width: 1024px){
  .content-wrap .filter {
    padding: 11px 16px;
    font-size: 1.2vw;
  }
}
  @media only screen and (max-width: 991px){
    .modal-wrapper{
      height: 94%;
      width: 91%;
    }

  }
  @media only screen and (max-width: 768px){
   .content-wrap .filter {
    width: 24vw;
  
  }
  .searchBar .search-inner-wrap{
    text-align: left;
  }
  .signupbtn{
    font-size: 1.8vw;
        width: 115px;
        margin-left: 0px;
        margin-bottom: 15px;
    }
    
  .map-list-btn.desktop{
    display:none;
  }
 .tag-wrap {
    display: flex;
    flex-wrap: wrap;
}
.related-card-wrap{
  margin-bottom:35px !important;
}
  }
  @media only screen and (max-width: 575px){
.content-wrap .filter {
    padding: 11px 6px;
    font-size: 1.8vw;
}
}
@media only screen and (max-width: 425px){
.content-wrap section p {
  width: 100%;
}
.tag-wrap.Guided-tag {
  margin-top: 57px;
}
.content-wrap section h5 {
  height: auto;
}
.tag-wrap label {
  margin: 15px 10px 0;
}
// Footer Style
.main{
  // background-color: rgb(17, 79, 255);
  flex-direction: column;
  display: flex ;
  overflow-y: auto ;
  height:100vh
}

.d-flex{
  display:flex;
}
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
}


@media only screen and (max-width: 768px) {
    .map-list-btn.desktop{
      display:none;
    }
    .filterview {
      width: 100%;
      flex-direction: initial;
    }
.signupbtn{
  font-size: 1.8vw;
      width: 115px;
      margin-left: 0px;
      margin-bottom: 15px;
  }
.row-2 {
  margin-bottom: 39px;
}

}


@media only screen and (max-width: 375px){

.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
}

@media only screen and (max-width: 320px){

.jewish-vienna {
  width: 203px;
}
}
`;

export default ProjectPage;
