import { IonModal } from '@ionic/react';
import styled from 'styled-components';

export const Modal = styled(IonModal)`
--height: 760px;
--width: 500px;
justify-content: center !important;
padding: 40px 0;
overflow: auto;
::-webkit-scrollbar {
  display: none;
}

.canclebtn {
  text-align: right;
  width: 96%;
  padding: 17px 0px 0 0;
  margin-bottom: -29px;
}

.canclebtn svg {
  fill: #8b7f9e;
  cursor: pointer;
}

.title {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 30px;
}
.title h3 {
  font-weight: 700;
  color: var(--ion-color-primary);
  font-size: 24px;
}

.field-wrap {
  width: 80%;
  margin: 10px auto 66px;
}

.viewHaggaadot {
  padding-left: 89px;
  text-decoration-line: none;
  font-size: 13px;
  font-weight: 600;
  color: #0849ff;
}

.createNew {
  height: 40px;
  width: 230px;
  border-radius: 5px;
  background-color: #fac807;
  color: #2a2a2a;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: capitalize;
  left: 41%;
}
.createNew:hover {
  background-color: #fac807;
  color: #2a2a2a;
}
.ionRow {
  margin: 3% auto;
}
.forLabel {
  color: #8c9597;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
}
span {
  color: #8c9597;
  font-size: 12px;
}
.link {
  font-size: 16px !important;
  text-decoration: underline;
  color: #0849ff;
}
.uploadHaggadah {
  margin-left: 5px;
}
.displayBlock {
    display: block;
}
.color-black {
  color: #000000;
}
.contentEmailList {
  background-color: #ffffff;
  height: 160px !important;
  overflow: auto !important;
}

.experienceSelector {
  min-width: 100%;
}
`;