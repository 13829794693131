import React, { useState, useEffect, useRef } from 'react';
import Header from '../../pages/Home/Header';
import Footer from '../../pages/Home/Footer';
import bannerinner from '../../assets/bg/banner-inner-img.png';
import abouttech from '../../assets/bg/abouttech.png';
import useTranslations from '../../util/useTranslation';
import MapIcon from '@material-ui/icons/Map';
import {
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendRightTitle,
  LegendSeparator,
} from '../experience-details/ExperienceDetails.style';
import { IonCol, IonRow } from '@ionic/react';
import Button from '@material-ui/core/Button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import tourIcon from '../../assets/bg/tour-icon.svg';
import mobileIcon from '../../assets/bg/mobile-icon.svg';
import exhibitIcon from '../../assets/bg/exhibit-icon.svg';
import seminarIcon from '../../assets/bg/seminar-Icon.svg';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {
  const [au] = useTranslations('authentication');
  const [co] = useTranslations('common');
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  var hasNumber = /\d/;
  const [FName, setFName] = React.useState<string>('');
  const [organization, setOrganization] = React.useState<string>('');
  const [subject, setSubject] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [errorOnSubmit, setErrorOnSubmit] = React.useState<boolean>(false);
  const _onSubmit = () => {
    if (
      !FName ||
      !organization ||
      !email ||
      !subject ||
      hasNumber.test(FName) ||
      !pattern.test(email)
    ) {
      setErrorOnSubmit(true);
      // alert(au('submitSubjectValidation.form'));
    } else {
      alert(au('submitSubjectValidation.success'));
    }
  };
  return (
    <div className='main'>
      <Helmet>
        <title>{co('common.title')}</title>
        <meta name='title' property='og:title' content={co('common.title')} />
        <meta property='og:description' content={co('common.desc1')} />
        <meta
          property='og:image'
          content='https://j-story.org/static/media/header-image.8e8bdd9a.png'
        />
      </Helmet>
      <style>{css}</style>
      <Header />
      <div
        className='about-section-wrap'
        style={{ backgroundColor: '#f7f7f7' }}
      >
        <div>
          <div className='container'>
            <h2 className='about_title text-center'>{co('common.about')}</h2>
          </div>
        </div>
        <div className='container'>
          <div className='d-flex about_content_box'>
            <div className='about_content'>
              <h2>{co('common.title')}</h2>
              <p>{co('common.desc1')}</p>
              <p>{co('common.desc2')}</p>
              <p>{co('common.desc3')}</p>
            </div>
            <div className='about_content_img'>
              <img src={bannerinner} alt='About_Image'></img>
            </div>
          </div>
        </div>
        <div className='bg-blue'>
          <div className='container'>
            <div className='d-flex tech_box'>
              <div className='tech_image'>
                <img src={abouttech} alt='about-technology'></img>
              </div>
              <div className='tech_content'>
                <h2>{co('common.technology')}</h2>
                <p>{co('common.innerstripdesc')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='shareStory-main-wrap'>
            <h2>{co('common.share-story.title')}</h2>
            <div className='shareStoryCard-wrap'>
              <div className='shareStoryCard'>
                {/* <MapIcon /> */}
                <img src={tourIcon} alt='tourIcon' />
                <h4>{co('common.share-story.walkingtour_title')}</h4>
                <p>{co('common.share-story.walkingtour_desc')}</p>
              </div>
              <div className='shareStoryCard'>
                {/* <MapIcon /> */}
                <img src={mobileIcon} alt='tourIcon' />
                <h4>{co('common.share-story.onlinetour_title')}</h4>
                <p>{co('common.share-story.onlinetour_desc')}</p>
              </div>
            </div>
            <div className='shareStoryCard-wrap'>
              <div className='shareStoryCard'>
                {/* <MapIcon /> */}
                <img src={exhibitIcon} alt='tourIcon' />
                <h4>{co('common.share-story.virtual_title')}</h4>
                <p>{co('common.share-story.virtual_desc')}</p>
              </div>
              <div className='shareStoryCard'>
                {/* <MapIcon /> */}
                <img src={seminarIcon} alt='tourIcon' />
                <h4>{co('common.share-story.seminar_title')}</h4>
                <p>{co('common.share-story.seminar_desc')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='support-wrap d-flex'>
            <div>
              <h2>{co('common.share-story.contact')}</h2>
            </div>
            <div>
              <div className='fieldset-wrap'>
                <IonRow>
                  <IonCol>
                    <div className='title'>
                      <FieldSet
                        style={{
                          backgroundColor: 'transparent',
                          border:
                            hasNumber.test(FName) ||
                            (errorOnSubmit == true && FName == '')
                              ? '1px solid red'
                              : '1px solid #c0c5d3',
                        }}
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle>
                              {' '}
                              {au('fields.fullname')}
                            </LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                hasNumber.test(FName) ||
                                (errorOnSubmit == true && FName == '')
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: '#c0c5d3' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          onChange={(e) => {
                            setFName(e.target.value);
                            setErrorOnSubmit(false);
                          }}
                          value={FName}
                          id='fname'
                          maxLength={30}
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '17px',
                            color: '#717C9C',
                          }}
                        />
                      </FieldSet>
                      {hasNumber.test(FName) ? (
                        <label className='Errorlabel about_error_label'>
                          {au('errorlabel.name')}
                        </label>
                      ) : null}
                      {errorOnSubmit == true && FName == '' ? (
                        <label className='Errorlabel about_error_label'>
                          {au('errorlabel.field')}
                        </label>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className='title'>
                      <FieldSet
                        style={{
                          border:
                            errorOnSubmit == true && organization == ''
                              ? '1px solid red'
                              : '1px solid #c0c5d3',
                        }}
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle>
                              {' '}
                              {au('fields.organization')}
                            </LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                errorOnSubmit == true && organization == ''
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: '#c0c5d3' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          type='text'
                          onChange={(e) => {
                            setOrganization(e.target.value);
                            setErrorOnSubmit(false);
                          }}
                          value={organization}
                          id='organization'
                          maxLength={100}
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '17px',
                            color: '#717C9C',
                          }}
                        />
                      </FieldSet>
                      {errorOnSubmit == true && organization == '' ? (
                        <label className='Errorlabel about_error_label'>
                          {au('errorlabel.field')}
                        </label>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className='title'>
                      <FieldSet
                        style={{
                          backgroundColor: 'transparent',
                          border:
                            (!pattern.test(email) && email.length) ||
                            (errorOnSubmit == true && email == '')
                              ? '1px solid red'
                              : '1px solid #c0c5d3',
                        }}
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle>
                              {' '}
                              {au('fields.email')}
                            </LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                (!pattern.test(email) && email.length) ||
                                (errorOnSubmit == true && email == '')
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: '#c0c5d3' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          type='text'
                          onChange={(e) => {
                            setErrorOnSubmit(false);
                            setEmail(e.target.value);
                          }}
                          value={email}
                          id='email'
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '17px',
                            color: '#717C9C',
                          }}
                        />
                      </FieldSet>
                      {email == '' ? (
                        errorOnSubmit == true ? (
                          <label className='Errorlabel about_error_label'>
                            {au('errorlabel.field')}
                          </label>
                        ) : null
                      ) : !pattern.test(email) ? (
                        <label className='Errorlabel about_error_label'>
                          {au('errorlabel.email')}
                        </label>
                      ) : null}
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className='title'>
                      <FieldSet
                        style={{
                          backgroundColor: 'transparent',
                          border:
                            errorOnSubmit == true && subject == ''
                              ? '1px solid red'
                              : '1px solid #c0c5d3',
                        }}
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle>
                              {' '}
                              {au('fields.subject')}
                            </LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                errorOnSubmit == true && subject == ''
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: '#c0c5d3' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          type='text'
                          onChange={(e) => {
                            setErrorOnSubmit(false);
                            setSubject(e.target.value);
                          }}
                          value={subject}
                          id='subject'
                          maxLength={40}
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '17px',
                            color: '#717C9C',
                          }}
                        />
                      </FieldSet>
                      {errorOnSubmit == true && subject == '' ? (
                        <label className='Errorlabel about_error_label'>
                          {au('errorlabel.field')}
                        </label>
                      ) : null}
                    </div>
                  </IonCol>
                </IonRow>
                <Button
                  className='signupbtn'
                  variant='contained'
                  onClick={() => _onSubmit()}
                  color='primary'
                  endIcon={<ArrowRightAltIcon />}
                  style={{ marginTop: '40px' }}
                >
                  <span> {au('submit')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const css = `
.main{
  // background-color: rgb(17, 79, 255);
  flex-direction: column;
  display: flex ;
  overflow-y: auto ;
  height:100vh
}
.about_error_label {
  color: red;
  padding-left:15px;
  font-size:16px;
  text-align:left;
  font-weight:lighter;
}
.d-flex{
  display:flex;
}
  .row{
      display:flex;
      justify-content: space-between;
  }
  .container {
      // max-width: 1500px;
      max-width: 1765px;
      margin: 0 auto;
  }
  .separator{
    background-color: rgb(184 190 206);
    width: 0px;
    margin: 7px 0px 1px;
  height:1px;
  }
  
  .signupbtn {
    color: #fff;
    background-color:#0849FF;
    margin-left: 20px;
    text-transform: none;
    height: 20px;
    // width: 56px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    border-radius: 25px;
    // width: 132px;
    height: 40px;
    padding:auto 10px;
  }
.signupbtn:hover {
  color: #fff;
  background-color:#0849FF;
}

.about-section-wrap{
  background-color:#fff;
}
.text-center{
  text-align: center;
}
.bg-blue{
  background: linear-gradient(44.84deg, rgba(8,73,255,0.9) 0%, rgba(0,182,234,0.9) 100%);
}
.about_title{
  padding: 100px 0 75px;
}
.about_content_box,
.tech_box{
  justify-content: space-evenly;
  padding: 100px 0;
}

.about_content{
  width: 621px;
}
.about_content h2{
  font-size: 36px;
}
.about_content_box .about_content p{
  font-size: 24px;
  light-height: 26px;
  color: #717C9C;
  letter-spacing: normal;
  margin: 20px 0 !important;
}

.tech_content{
  // width: 621px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tech_content h2{
  height: 40px;
  width: 100%;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom:10px;
}
.tech_content p{
  // height: 243px;
width: 520px;
color: #FFFFFF;
font-family: "Source Sans Pro";
font-size: 18px;
letter-spacing: 0;
line-height: 27px;
}
.shareStory-main-wrap{
  padding:100px 0;
}
.shareStory-main-wrap h2{
  // height: 84px;
  width: 577px;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin:0 auto 90px;
}
.shareStoryCard{
  width: 435px;
}
.shareStoryCard-wrap{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 80px;
}
.shareStoryCard h4{
  height: 24px;
  width: 236px;
  color: #0849FF;
  text-transform: uppercase;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 20px auto 20px;
}
.shareStoryCard p{
  // height: 108px;
  // width: 435px;
  color: #717C9C;
  font-family: "Source Sans Pro";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}
.shareStoryCard svg{
  height: 70px;
  width: 70.41px;
    fill: url(#gradient-vertical) gray;
    font-size: 32px;
}

.support-wrap{
  justify-content: space-evenly;
  padding: 100px 0;
  border-top: 1px solid #c0c5d3;
}
.support-wrap h2{
  // height: 108px;
  width: 436px;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
}

.title{
  // width: 20vw;
}



.title fieldset {
  border: 2px solid #000;
  // height: 47px;
  margin: 10px 0 auto ;
  box-sizing: border-box;
  height: 55px;
  // width: 353px;
  border: 1px solid rgba(113,124,156,0.5);
  border-radius: 19px;
  background-color: rgb(247, 247, 247);
    // width: 100%;
    margin-right: 0px !important;
}

.title fieldset input{
  line-height: 0px;
  padding: 0 0;
  font-size: 15px;
}
.fieldset-wrap{
  display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media only screen and (max-width: 1366px) {
.title fieldset {
  width: 35vw;
}

}

@media only screen and (max-width: 1199px) {
  .tech_image img{
    max-width: 85%;
  }
  }
@media only screen and (max-width: 1024px) {

  .about_content{
    margin-right: 31px;
  }

.j-story-is-the-pro{
  margin-right: 0px; 
  margin-bottom: auto; 
  font-weight: 800;
  line-height: 21px;
}

.row-3{
  flex-direction: column;
}

.support-wrap,
.shareStoryCard-wrap,
.about_content_box,
.tech_box{
  justify-content: space-between;
}

}

@media only screen and (max-width: 768px) {
    .about_title {
      padding: 32px 0 0px;
      text-align: left !important;
  }
  .shareStory-main-wrap h2{
    margin:0 auto 30px;
  }
  .about_content_box, .tech_box{
    padding: 10px 0 ;
  }
.row-2 {
  margin-bottom: 39px;
}

.about_content_box, .tech_box{
 flex-direction: column;
}
.support-wrap, .shareStoryCard-wrap, .about_content_box, .tech_box{
  flex-wrap: wrap;
  display: flex;
}
.about_content {
 width: 100%;
  margin: 0 auto 40px;
}
.about_content p {
  font-size: 17px;
}
.tech_content p{
  width:100%;
 text-align:left;
 padding-top: 20px;
}

.shareStoryCard h4{
  text-transform: uppercase;
}

.about_content_img {
  margin: 0 auto 40px;
}
.about_content_box,{
  padding:0 0;
}
.tech_image {
  margin: 0 auto;
}
.tech_content {
  width: 100%;
  margin: 20px auto 0;
  align-items: center;
  text-align: center;
}
.tech_content h2 {
  width: 100%;
  line-height: 73px;
  text-align: initial;
}
.tech_box,.support-wrap{
  padding: 50px 0;
}
.shareStoryCard {
  width: 38vw;
}
.shareStoryCard p,.shareStoryCard h4{
  width:100%;
}
.support-wrap h2 {
  width: 100%;
    margin-bottom: 20px;
    font-size: 30px;
}
.title fieldset {
  width: 90vw;
}
.please-sumbit-your-d{
width:100%;
}
.shareStory-main-wrap h2 {
  width: 100%;
}
.please-sumbit-your-d {
  width: 100% !important;
}
.shareStory-main-wrap {
  padding: 100px 0 30px;
}
.signupbtn {
    margin-bottom: 17px;
    margin-top: 20px;
}
}

@media only screen and (max-width: 575px) {
  .footer-row-wrap .md.hydrated{
  display: block;
  }
  .row-2>div{
  width:100%;
  }
  .get-in-touch{
  width:100%;
  }
  .map-wrap .map-search{
  width: 80vw;
  }
  .map-search>div{
  width: 80%;
  }
  .row-2 button{
  margin-top:15px;
  }
  .about_title {
    padding: 30px 0 0;
}
.about_content,.shareStory-main-wrap h2 {
  width: 100%;
}
.about_content_img,.tech_image{
  display: flex;
}
.about_content_img img{
  // max-width: 60%;
  border: 0;
  margin: 0 auto;
}
.about_content_box{
  padding:0 0 ;
}
.shareStoryCard {
  width: 90vw;
  margin:20px auto;
}
.shareStoryCard-wrap{
  margin-bottom: 0px;
}
.shareStoryCard p{
  height:100%;
}
}

@media only screen and (max-width: 475px) {
.j-story-is-the-pro{
  height:100%;
}
}

@media only screen and (max-width: 425px) {
  .tech_content p {
    width: 100%;
  }
  .please-sumbit-your-d {
    width: 100%;
  }
  .support-wrap h2 {
    height: 100%;
  }
  .title fieldset {
    width: 86vw;
}
.about_title{
  font-size: 21px;
}
.about_content h2 {
  font-size: 24px;
}
.about_content_img, .tech_image {
  justify-content: center;
}
.tech_content p {
  height: 100%;
}
.about_content_img {
  margin: 21px auto 40px;
}

}

@media only screen and (max-width: 375px){

.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
.row-2 .createNew {
  margin-left: 0px;
}
}

@media only screen and (max-width: 320px){

.jewish-vienna {
  width: 203px;
}
}

@media only screen and (max-width: 768px) {

    .about_content h2 {
      font-size: 31px !important; 
      margin-top: 42px !important;
    }
    .fieldset-wrap .title {
      justify-content: center;
      // display: flex;
      padding-top: 0px !important;
      padding-left:0;
    }
}

@media only screen and (max-width: 575px) {
.fieldset-wrap .signupbtn{
  margin-top: 40px;
 margin-left: auto;
}


}

@media only screen and (max-width: 375px){

.please-sumbit-your-d {
  width: 100%;
  margin-bottom: 45px;
}
.right-footer img {
  margin-right: 22px;
}
}

@media only screen and (max-width: 320px){

.jewish-vienna {
  width: 203px;
}
}


  `;
export default About;
