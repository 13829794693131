import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import SignIn from '../../components/authentication/SignIn';
import useToken from '../../util/useToken';
import useTranslations from '../../util/useTranslation';
import { getUser } from '../authentication/CurrentUser';

const UserMenu: React.FC = () => {
  const { path } = useRouteMatch();
  const [token, setToken] = useToken();
  let history = useHistory();
  const [openDropDown, setOpenDropDown] = useState(false)
  const [t] = useTranslations('home');
  const [dt] = useTranslations('common');
  const location = useLocation();
  const [openSignIn, setOpenSignIn] = useState(false);

  const userObject = getUser();

  const onLogout = () => {
    setToken("");
    history.push('/home');
    history.go(0);
    setOpenSignIn(false);
  }

  const handleCloseModal = () => {
    setOpenSignIn(false);
    history.push('/home');
  }

  const SubMenu = () => {
    return (
      <div className="nav__submenu_wrapper" >
        <ul className="nav__submenu">
          <li className="nav__submenu-item list-item navheader">
            <span>
              {userObject?.email}
            </span>
          </li>
          <hr />
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/app/dashboard'>{dt('common.nav.dashboard', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/app/search'>{dt('common.nav.search', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/app/collection'>{dt('common.nav.collections', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/app/events'>{dt('common.nav.events', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/app/profile'>{dt('common.nav.profile', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to='/'>{dt('common.nav.home', { ns: 'common' })}</Link>
            </span>
          </li>
          <li className="nav__submenu-item list-item navheader">
            <span>
              <Link to="#" onClick={onLogout}>{dt('common.nav.sign_out', { ns: 'common' })}</Link>
            </span>
          </li>
        </ul>
      </div>
    )
  }

  if (!token) {
    return (
      <div>
        <div className='navheader list-item' onClick={() => setOpenSignIn(true)} >
          {t('navBar.signin')}
        </div>
        {openSignIn && <SignIn open={!token} redirect={location.pathname} onCloseSignIn={handleCloseModal} />}
      </div>
    )
  }
  return (
    <StyledMenu>
      <div className='navheader list-item nav__menu-item'
        onMouseEnter={() => setOpenDropDown(true)}
        onMouseLeave={() => setOpenDropDown(false)}
      >
        <div className="user-details-wrapper">
          <Link to="/app/profile" className="user-details-link">
            <span className='avatar'>
              <img src={userObject?.avatar}></img>
            </span>
            <span className="user-name">
              {userObject?.first_name}
            </span>
          </Link>
          {
            openDropDown == false ?
              <ExpandMoreIcon onClick={() => setOpenDropDown(true)} /> :
              <ExpandLessIcon onClick={() => setOpenDropDown(false)} />
          }
        </div>
        {
          openDropDown == true ?
            SubMenu() :
            null
        }
      </div>
    </StyledMenu>
  )
};

/*
<div className="submenu user-menu">
                    <ul>
                        <li className="user-menu-header">
                            <h6>Alexander Raginsky</h6>
                            <div>a@b</div>
                        </li>
                        <li>
                            <Link to="/app/dashboard">Dashboard</Link>
                        </li>
                        <li>
                            <Link to="#">Logout</Link>
                        </li>
                    </ul>

                </div>
    
*/

const StyledMenu = styled.div`
.nav__submenu_wrapper{

}
.nav__menu-item {
    display: inline-block;
    position: relative;
  
    &:hover {
      background-color: $color-blue;
  
      .nav__submenu {
        display: block;
      }
    }
  }

      
  .nav__submenu {
    font-weight: 300;
    text-transform: none;
    display: block;
    position: absolute;
    width: 180px;
    z-index: 100;
    // height: 147px;<UserMenu />
    width: 214px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px 10px rgb(113 124 156 / 10%);
    top: 60px;
    justify-content: center;
    text-align: center;
    padding: 26px;
  }

    li {
        display: flex;
    }
  
  .nav__submenu-item:nth-child(1) a{
    font-size: 16px;
  }
  .nav__submenu-item:nth-child(2) a{
    color: #0849FF !important;
    font-family: "Source Sans Pro",sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .nav__submenu-item:nth-child(n+3),
  li.nav__submenu-item+li.nav__submenu-item{
    margin-left:0px;
  }
  .nav__submenu-item:nth-child(n+3) a{
    color: #717C9C;
    font-family: "Source Sans Pro",sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;   
  }

  .nav__submenu-item:nth-child(n+3):hover a {
    color: #0849FF !important;
  }
     
      
  .nav__submenu-item {
    &:hover {
      background: rgba(#000, 0.1);
    }
    a {
        text-decoration: none;
        color: var(--ion-color-secondary);
    }
  }
  .nav__submenu-item{
    .list-item{
      span{
  //       height: 19px;
  // width: 160px;
  color: #717C9C;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
      }
    }
  }
  hr{
    border: 1px solid #717C9C;
  }
  .user-name{
    color: #717c9c;
  }

  .user-details-link{
    text-decoration: none;
  }

  .user-details-wrapper{
    display: flex;
    align-items: center;
  }

  .user-details-wrapper svg{
    font-size: 21px;
    margin-top: -2px;
    margin-left: 10px;
}

  // For now, hide user menu 
  @media only screen and (max-width: 780px) {
    .nav__menu-item img {
      //display: none;
      width: 25px;
    }
  }
@media only screen and (max-width: 768px) {
  .nav__submenu{
			display:none;
		}
  }
`;


export default UserMenu;
