import React, { useEffect } from 'react';
import Select from 'react-select';
import {
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendSeparator,
} from './Style';
import { ReactComponent as Greylogo } from '../../assets/grey-logo.svg';
// import greylogo from '../../assets/grey-logo.png';
import starLogo from '../../assets/star-logo.png';
import i18n from 'i18next';
import { IonCol, IonRow } from '@ionic/react';
import aepjLogo from '../../assets/aepj-logo.png';
import useToken from '../../util/useToken';
import { useHistory, useLocation } from 'react-router';
import useTranslations from '../../util/useTranslation';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { components } from 'react-select';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import SignIn from '../../components/authentication/SignIn';
import { point } from 'leaflet';

const Footer: React.FC = () => {
  const [hm] = useTranslations('home');
  const [au] = useTranslations('authentication');

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  let history = useHistory();
  var hasNumber = /\d/;
  const [FName, setFName] = React.useState<string>('');
  const [errorOnSubmit, setErrorOnSubmit] = React.useState<boolean>(false);
  const [lang, setlang] = React.useState<string>('en');
  const [organization, setOrganization] = React.useState<string>('');
  const [subject, setSubject] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const location = useLocation();
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [token, setToken] = useToken();
  const { languges: langugeList } = hm('footer', {
    returnObjects: true,
  }) as {
    languges: Array<{ value: string; label: string }>;
  };
  const { Option } = components;
  const IconOption = (props) => (
      <Option {...props}>
      <CheckRoundedIcon style={{color:'white'}}/>
        {props.data.label}
      </Option>
  );
  const handleSelect = (event) => {
    langchange(event.value);
    window.location.reload();
  };

  const _onSubmit = () => {
    if (
      !FName ||
      !organization ||
      !email ||
      !subject ||
      hasNumber.test(FName) ||
      !pattern.test(email)
    ) {
      setErrorOnSubmit(true);
    }
    // if (!FName || !organization || !email || !subject || hasNumber.test(FName) || !pattern.test(email)) {
    // 	// alert(au('submitSubjectValidation.form'));
    //   setErrorOnSubmit(true)
    // }
    else {
      alert(au('submitSubjectValidation.success'));
    }
  };

  React.useEffect(() => {
    let defaultSelected = localStorage.getItem('language');
    if (defaultSelected != null) {
      langchange(defaultSelected);
      setlang(defaultSelected);
    }
  }, [lang]);

  const langchange = (e: any) => {
    localStorage.setItem('language', e);
    let selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage != null) {
      i18n.changeLanguage(selectedLanguage);
      setlang(selectedLanguage);
    }
  };

  const handleCloseSignInModal = () => {
    setOpenSignIn(false);
    history.push('/home');
  };
  const handleCloseSignUpModal = () => {
    setOpenSignUp(false);
    history.push('/home');
  };

  const colourStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: 'transparent',
      color: '#fff !important',
      width: '140px',
      marginRight: '30px',
      padding: ' 0px 13px',
      border: '1px solid #FAC807',
      borderRadius: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #FAC807',
        boxShadow: 'none',
      },
      '&:focus': {
        border: '1px solid #FAC807',
      },
    }),
    container: (base) => ({
      ...base,
      width: '150px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: 'pointer',
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      '&:hover': {
        color: '#fff',
      },
      '&:focus': {
        color: '#fff',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#fff', 
      '&:hover': {
        color: '#fff',
      },
      '&:focus': {
        color: '#fff',
      },
    }),
  };

  return (
    <div className='footer-section'>
      {openSignIn && (
        <SignIn
          open={!token}
          redirect={location.pathname}
          onCloseSignIn={handleCloseSignInModal}
        />
      )}
      {openSignUp && (
        <SignIn
          open={!token}
          signUpModal={true}
          redirect={location.pathname}
          onCloseSignIn={handleCloseSignUpModal}
        />
      )}
      <style>{css}</style>
      <div className='container'>
        <div className='footer-row-wrap'>
          <div className='d-flex row-1'>
            <div>
              <Greylogo style={{ height: '45px', marginLeft: '-30px' }} />
            </div>
            <div
              className='right-footer'
              style={{ display: 'flex', alignItems: ' center' }}
            >
              <span>{hm('partners.headline')} </span>
              <img
                src={aepjLogo}
                style={{ height: '33.86px', width: '84px' }}
              />
              <img
                src={starLogo}
                style={{ height: '41px', width: '131.86px' }}
              />
            </div>
          </div>
          <div className='d-flex row-2'>
            <div>
              <h4 className='get-in-touch'>{hm('footer.head')}</h4>
              <p className='please-sumbit-your-d'>{hm('footer.subhead')}</p>
              <div className='footer_form'>
                <IonRow>
                  <IonCol>
                    <FieldSet
                      style={{
                        backgroundColor: 'transparent',
                        border:
                          hasNumber.test(FName) ||
                          (errorOnSubmit == true && FName == '')
                            ? '1px solid red'
                            : '1px solid white',
                      }}
                    >
                      <Legend>
                        <LegendContainer>
                          <LegendLeftTitle className='full-name'>
                            {au('fields.fullname')}
                          </LegendLeftTitle>
                          <LegendSeparator
                            className='separator'
                            style={
                              hasNumber.test(FName) ||
                              (errorOnSubmit == true && FName == '')
                                ? { backgroundColor: 'rgb(234 5 5)' }
                                : { backgroundColor: 'white' }
                            }
                          />
                        </LegendContainer>
                      </Legend>
                      <ExperienceContent
                        onChange={(e) => {
                          setFName(e.target.value);
                          setErrorOnSubmit(false);
                        }}
                        value={FName}
                        id='fname'
                        maxLength={30}
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '17px',
                          color: '#fff',
                        }}
                      />
                    </FieldSet>
                    {hasNumber.test(FName) ? (
                      <label
                        style={{ color: 'white', paddingLeft: '30px' }}
                        className='Errorlabel'
                      >
                        {au('errorlabel.name')}
                      </label>
                    ) : null}
                    {errorOnSubmit == true && FName == '' ? (
                      <label
                        style={{ color: 'white', paddingLeft: '30px' }}
                        className='Errorlabel'
                      >
                        {au('errorlabel.field')}
                      </label>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <FieldSet
                      style={{
                        backgroundColor: 'transparent',
                        border:
                          errorOnSubmit == true && organization == ''
                            ? '1px solid red'
                            : '1px solid white',
                      }}
                    >
                      <Legend>
                        <LegendContainer>
                          <LegendLeftTitle className='full-name'>
                            {au('fields.organization')}
                          </LegendLeftTitle>
                          <LegendSeparator
                            className='separator'
                            style={
                              errorOnSubmit == true && organization == ''
                                ? { backgroundColor: 'rgb(234 5 5)' }
                                : { backgroundColor: 'white' }
                            }
                          />
                        </LegendContainer>
                      </Legend>
                      <ExperienceContent
                        type='text'
                        onChange={(e) => {
                          setOrganization(e.target.value);
                          setErrorOnSubmit(false);
                        }}
                        value={organization}
                        id='organization'
                        maxLength={100}
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '17px',
                          color: '#fff',
                        }}
                      />
                    </FieldSet>
                    {errorOnSubmit == true && organization == '' ? (
                      <label
                        style={{ color: 'white', paddingLeft: '30px' }}
                        className='Errorlabel'
                      >
                        {au('errorlabel.field')}
                      </label>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <FieldSet
                      style={{
                        backgroundColor: 'transparent',
                        border:
                          (!pattern.test(email) && email.length) ||
                          (errorOnSubmit == true && email == '')
                            ? '1px solid red'
                            : '1px solid white',
                      }}
                    >
                      <Legend>
                        <LegendContainer>
                          <LegendLeftTitle className='full-name'>
                            {au('fields.email')}
                          </LegendLeftTitle>
                          <LegendSeparator
                            className='separator'
                            style={
                              (!pattern.test(email) && email.length) ||
                              (errorOnSubmit == true && email == '')
                                ? { backgroundColor: 'rgb(234 5 5)' }
                                : { backgroundColor: 'white' }
                            }
                          />
                        </LegendContainer>
                      </Legend>
                      <ExperienceContent
                        type='text'
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrorOnSubmit(false);
                        }}
                        value={email}
                        id='email'
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '17px',
                          color: '#fff',
                        }}
                      />
                    </FieldSet>
                    {email == '' ? (
                      errorOnSubmit == true ? (
                        <label
                          style={{ color: 'white', paddingLeft: '30px' }}
                          className='Errorlabel'
                        >
                          {au('errorlabel.field')}
                        </label>
                      ) : null
                    ) : !pattern.test(email) ? (
                      <label
                        style={{ color: 'white', paddingLeft: '30px' }}
                        className='Errorlabel'
                      >
                        {au('errorlabel.email')}
                      </label>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <FieldSet
                      style={{
                        backgroundColor: 'transparent',
                        border:
                          errorOnSubmit == true && subject == ''
                            ? '1px solid red'
                            : '1px solid white',
                      }}
                    >
                      <Legend>
                        <LegendContainer>
                          <LegendLeftTitle className='full-name'>
                            {au('fields.subject')}
                          </LegendLeftTitle>
                          <LegendSeparator
                            className='separator'
                            style={
                              errorOnSubmit == true && subject == ''
                                ? { backgroundColor: 'rgb(234 5 5)' }
                                : { backgroundColor: 'white' }
                            }
                            // style={{ backgroundColor: 'white' }}
                          />
                        </LegendContainer>
                      </Legend>
                      <ExperienceContent
                        type='text'
                        onChange={(e) => {
                          setSubject(e.target.value);
                          setErrorOnSubmit(false);
                        }}
                        value={subject}
                        id='subject'
                        maxLength={40}
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '17px',
                          color: '#fff',
                        }}
                      />
                    </FieldSet>
                    {errorOnSubmit == true && subject == '' ? (
                      <label
                        style={{ color: 'white', paddingLeft: '30px' }}
                        className='Errorlabel'
                      >
                        {au('errorlabel.field')}
                      </label>
                    ) : null}
                  </IonCol>
                  <div
                    className='submit-btn-wrap'
                    style={{ display: 'flex', alignItems: ' center' }}
                  >
                    <Button
                      className='createNew'
                      variant='contained'
                      onClick={() => _onSubmit()}
                      color='primary'
                      endIcon={<ArrowRightAltIcon />}
                    >
                      {au('submit')}
                    </Button>
                  </div>
                </IonRow>
              </div>
            </div>
          </div>
          {/* <div>{errorOnSubmit?"All fiels are required":null}</div> */}
          <div
            className='d-flex row-3'
            style={{ justifyContent: 'space-between' }}
          >
            <div className='d-flex list-footer'>
              <div className='list'>
                <div className='title-name'>{hm('footer.about')} </div>
                <div>
                  <p>{hm('footer.technology')}</p>
                  <p>{hm('footer.exptype')}</p>
                </div>
              </div>
              <div className='list'>
                <div className='title-name'>{hm('footer.experience')} </div>
                <div className='sub-list d-flex'>
                  <p>{hm('footer.tour')}</p>
                  <p>{hm('footer.exhibit')}</p>
                  <p>{hm('footer.seminar')}</p>
                </div>
              </div>
              <div className='list'>
                <div className='title-name'>
                  {hm('footer.create_experience')}
                </div>
                <div className='sub-list d-flex'>
                  {/* <p style={{ cursor: 'pointer' }} onClick={() => history.push('/home/signin')}> */}
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpenSignIn(true)}
                  >
                    {hm('footer.log_in')}
                  </p>
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpenSignUp(true)}
                  >
                    {hm('footer.sign_up')}
                  </p>
                </div>
              </div>
              <div className='list'>
                <div className='title-name'>{hm('footer.partner')} </div>
                <div className='sub-list d-flex'>
                  <div className='mobileview'>
                    <Select
                      menuPlacement='top'
                      isSearchable={false}
                      onChange={handleSelect}
                      value={langugeList.find((obj) => obj.value === lang)}
                      options={langugeList}
                      styles={colourStyles}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex row-3' style={{ justifyContent: 'flex-end' }}>
            <div
              className='d-flex copy-right'
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <div className='desktopview'>
                <Select
                  menuPlacement='top'
                  isSearchable={false}
                  onChange={handleSelect}
                  value={langugeList.find((obj) => obj.value === lang)}
                  options={langugeList}
                  styles={colourStyles}
                  components={{
                    IndicatorSeparator: () => null,Option: IconOption
                  }}
                />
              </div>

              <p className='j-story-is-the-pro'>{hm('footer.j-story')}</p>
              <NavLink to='#' className='terms-of-service'>
                {hm('footer.service')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const css = `
   
      .main{
          // background-color: rgb(17, 79, 255);
          flex-direction: column;
          display: flex ;
          overflow-y: auto ;
          height:100vh
       }
      .footer-select option{
        color:#000;
      }
      .footer_form .md.hydrated{
        margin-bottom: 10px;
      }
      .mobileview{
        display:none;
      }
      #dropdown-menu-align-right {
        background-color: transparent;
        color: #fff;
      }
      .show.dropdown .dropdown-menu.show {
        border-radius: 20px !important;
      }
      .show.dropdown .dropdown-item:hover {
        background-color: #d2d5e0 !important;
        color: #fff !important;
      }
      
        .title{
            justify-content: center;
            // display: flex;
            // padding-top: 65px;
            color: #0849FF;
            font-family: "Source Sans Pro";
            font-size: 36px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 0px;
            text-align: center;
        }
        h3{
          color: #0849FF;
          font-family: "Source Sans Pro";
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 40px;
          margin-bottom: 30px;
        }
      
      .container {
          // max-width: 1500px;
          max-width: 1765px;
          margin: 0 auto;
      }
  .sub-list {
    flex-direction: column;
  }

  .footer-section{
    // height: 100%;
    background-color: #717C9C;
    padding: 53px 0;
  }
  
  .footer-section .footer-row-wrap span{
    height: 16px;
    // width: 102px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-right: 20px;
  }
  
  .footer-section .footer-row-wrap .row-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right-footer img{
    margin-right: 40px;
  }
  
  .get-in-touch {
    // height: 40px;
    width: 560px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  
  .please-sumbit-your-d {
    // height: 25px;
    // width: 475px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
  
  .full-name {
    height: 16px;
    // width: 69px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.86px;
    line-height: 16px;
  }
  
  fieldset {
    box-sizing: border-box;
    height: 55px;
    // width: 340px;
    // width:290px;
    // width: 310px;
    width: 300px;
    border: 2px solid rgba(255,255,255,0.5);
    border-radius: 15px;
    margin-right: 24px !important;
  }
  #email{
    height: 18px;
    font-size: 17px;
    color: #fff;
  }
  
  #fname,#organization,#subject{
    height: 18px;
  }
  
  ::placeholder{
    color:#fff;
  }
  
  .footer-section .createNew {
    height: 40px;
    // width: 141px;
    border-radius: 8px;
    background-color: #fac807;
    color: #2a2a2a;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;
  }
  .footer-section .createNew span{
    color: #2a2a2a;
    margin-right: 0px;
  }
  
  .footer-section  .createNew:hover {
    background-color: #c8a104;;
    color: #2a2a2a;
  }
  
  .footer-section .row-3 span,
  .footer-section .row-3 .title-name{
    // height: 25px;
    // width: 102px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .footer-section .row-3 .list p{
  // height: 25px;
  // width: 300px;
  width:100%;
  color: #FFFFFF;
  font-family: "Source Sans Pro";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 10px 0 2px;
  }
  
  .footer-section .row-3 .list{
  width: 160px;
  }
  
  .footer-section .separator{
    background-color: rgb(184 190 206);
    width: 0px;
    margin: 7px 0px 1px;
  height:1px;
  }
  
  .j-story-is-the-pro{
    height: 28px;
    width: 303px;
    color: #F7F7F7;
    font-family: Muli;
    font-size: 12px;
    letter-spacing: 0.33px;
    line-height: 31px;
    margin-right:30px;
    margin-bottom: 0;
  }
  .terms-of-service {
    height: 28px;
    // width: 102px;
    color: #F7F7F7;
    font-family: Muli;
    font-size: 12px;
    letter-spacing: 0.33px;
    line-height: 31px;
    text-align: right;
  }
  
  .row-1{
    margin-bottom:49px;
  }
  .row-2{
    // margin-bottom:72px;
    margin-bottom:15px;
  }
  
  @media only screen and (max-width: 1791px) {
    .footer-section .footer-row-wrap fieldset{
      margin: 1em 0;
    }
  }
  @media only screen and (max-width: 1620px) {
    .footer-section .footer-row-wrap fieldset{
      margin:0;
      margin-right: 0px !important;
      // width: 249px; 
    }
  }
  @media only screen and (max-width: 1502px) {
  .footer-section .footer-row-wrap fieldset {
    width: 100%;
  }
  }
  @media only screen and (max-width: 1440px) {
 
  }
  
  @media only screen and (max-width: 1366px) {
  // .copy-right{
  //   margin-left: -127px ;
  // }
}

  @media only screen and (max-width: 1199px) {
  // .copy-right {
  //   margin-left: -245px ;
  // }
}


.copy-right
   
  .footer-section .row-3 .list {
    width: 170px;
  }
  .copy-right{
    align-items: center;
  }
  .copy-right {
    // margin-left: -342px ;
    margin-bottom: -23px ;
    align-items: flex-end ;
}
  
  }

 
 
  @media only screen and (max-width: 1200px) {
  .title fieldset {
    width: 43vw ;
}
}
  @media only screen and (max-width: 991px) {
 
  .footer-section .row-3 .list {
    width: 121px;
  }
  .footer-section .row-3{
    display: flex;
    flex-direction: column;
  }
  .footer-section .row-3 .list {
    width: 232px;
  }
  .copy-right{
    // margin-left: 0px ;
  }
  }
  
  @media only screen and (max-width: 800px) {
    .please-sumbit-your-d {
      width: 100%;
    }
    }
  @media only screen and (max-width: 768px) {
 
  .mobileview{
    display:block;
  }
  .desktopview{
    display:none;
  }
  
  .slider-wrap {
    width: 84%;
  }
  
  .copy-right{
    // margin-left: 0 !important;
  }
  .footer-section .row-3 .list {
    width: 334px;
    min-width: 257px;
  }
  .footer-section .row-3 .list p{
    width: 297px !important;
  }
  .title fieldset {
    width: 82vw !important;
}
.footer-section  .list-footer{
  flex-wrap: wrap;
}

.title-name {
  padding-top: 0;
}

.footer-section .row-3 span, .footer-section .row-3 .title-name {
  font-size: 16px;
  margin-top: 0px;
  margin-right: 8px;
  }
  .book-btn {
    top: 170px;
}
.footer-section .footer-row-wrap fieldset {
  width: 85vw;
}
.createNew {
  width: auto;
}
}
  
  @media only screen and (max-width: 575px) {

  .footer-section .row-3 .list {
    width: 256px !important;
  }

  .footer-row-wrap .md.hydrated{
    display: block;
  }
  .footer-section .footer-row-wrap .row-1 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .footer-section {
    // height: auto;
    padding: 33px 0;
  }
  .row-2>div{
    width:100%;
  }
  .get-in-touch{
    width:100%;
  }
  .row-2 button{
    margin-top:15px;
  }
  .list-footer{
    flex-wrap: wrap;
  }
  .footer-section .row-3 .list p {
    width: 100% !important;
}
.j-story-is-the-pro {
  width: 100%;
  margin-right: 7px;
}
.terms-of-service {
  height: 28px;
  width: 100%;
  text-align: left;
}
.copy-right>div{
  margin-right: -103px;

}
.copy-right{
  flex-direction: column;
}
  }
  
  @media only screen and (max-width: 475px) {
    .footer-section .row-3 .list {
      width: 100%;
    }
    .please-sumbit-your-d {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 425px) {
    // .footer-section .footer-row-wrap fieldset {
    //   width: 97%;
    // }
    .please-sumbit-your-d {
      margin-bottom: 25px;
    }
    .row-2 {
      margin-bottom: 0px; 
  }
  }
  @media only screen and (max-width: 375px) {
  .row-2 button{
    // margin-left:0;
  }
  }

  @media only screen and (max-width: 360px){
    // .footer-section .footer-row-wrap fieldset {
    //     width: 100%;
    // }
    .footer-section .row-3 .list-footer{
      display: flex;
      flex-direction: column;
    }
    .footer-section .row-3 .list {
      width: 100%;
    }
    .copy-right{
      display: flex;
      flex-direction: column;
      width:100%;
      margin-top: 38px;
    }
  }
`;

export default Footer;
