import React, { useEffect,useRef, useState ,SyntheticEvent} from 'react';
import Collection from '../../model/Collection';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { arrowBack, heart } from 'ionicons/icons';
import { getCollections,deleteCollectionItem} from '../../util/API';
import Slider from 'react-slick';
import useTranslations from '../../util/useTranslation';
import useToken from '../../util/useToken';
import Header from '../common/Header';
import i18n from 'i18next';
import CollectionList from './CollectionList';
import { IonSpinner } from '@ionic/react';
import {useHistory} from 'react-router-dom';
import CompButton from '../common/Button';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
// import { ClearIcon } from 'ionicons/icons';
import UserMenu from '../../components/user-menu/UserMenu';
import styled from 'styled-components';

import { IonContent, IonModal } from '@ionic/react';
import { open } from 'ionicons/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RoomIcon from '@material-ui/icons/Room';
// import Button from '../../components/common/Button';
import SaveItemActions, {
  SaveItemActionsState,
  } from '../../components/common/SaveItem/Actions';

import EducationPremitted from '../../assets/bg/education-premitted.png';
import BackArrow from '../../assets/icon/back-arrow-icon.svg';
import NextArrow from '../../assets/icon/next-arrow-icon.svg';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import Link from '@material-ui/core/Link';
import ClearIcon from '@material-ui/icons/Clear';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display:'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },

}));

const Collections = () => {
  const [t] = useTranslations('collections');
  const [collections, setCollections] = useState<Collection[]>([]);
  const [SliderItem,setSliderItem] = useState<any>([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [option,setOption]=useState<string>(t('option_initial'));
  const itemsPerPage = 4;
  const [page, setpage] = useState<number>(1);
  const [ind,setInd]=useState<number>(0);
  const [token, setToken] = useToken();
  const [showModel, setshowModel] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const [p] = useTranslations('item', 'search','save-item');
  const saved = false;
  
  let history=useHistory();
 
  const {items: filterItems } = t('filterdropdown-collection', {
    returnObjects: true,
  }) as {
    items: Array<{ id: string; title: string; value: string }>;
  };

  const sliderRef = useRef<any>(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    // centerMode: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 2,
  };
  const fetchCollections = async () => {
   
    setSpinner(true);
    const {
      data: { collections },
    } = await getCollections(token, true).catch((error) => {
      setToken(null);
      window.location.reload();
      throw error;   
    });;
    setSpinner(false);
    setCollections(collections);
    console.log("dfhui",collections)
  };

  const fetchFilteredData=(e)=>
  {
    //Display data according filter option click action with API call
    console.log("action clicked is",e);
  }

  useEffect(() => {
    if (token) {
      fetchCollections();
      fetchFilteredData(option);  
    }
     setSaveActionsState((prev) => ({ ...prev, item }));
  }, []);


  const classes = useStyles();

  const handleChange = (event) => 
  {
      setOption(event.target.value);
      fetchFilteredData(event.target.value);
  };

  const onPageChange=(event, value)=>
  {
      setpage(value);
  } 

  const cardClickAction=(clicked,data,id,collectionId)=>
  {
   // history.push({pathname:`/app/search/item-detail/${encodeURIComponent(id)}`,state:{collectionId:collectionId}}); 
    setshowModel(clicked);
    setSliderItem(data)
    console.log("data is",data)
    let selectedIndex: number = data.findIndex(x => x.id === id);
    setInd(selectedIndex)
  }
 
   const _deleteItem= async (collectionId, itemId)=> {
  
    const collection:any = collections.find(task => task.id == collectionId);
    console.log("COllection id is",collection)
    if (collection && collection.item_details && collection.item_details.results.length) {       
      var itemIds: any = [];
      for (var i=0; i < collection.item_details.results.length; i++) {
        if (collection.item_details.results[i].id !== itemId) {
          itemIds.push(collection.item_details.results[i].id)
        }
      }
      let request={
        id: collection.id,
        description: collection.description,
        title: collection.title,
        preview_url: collection.preview_url,
        items: itemIds,
        user_id: collection.user_id
      }
      await deleteCollectionItem(request, token);
      fetchCollections();
    }  
  }


  const [saveActionsState, setSaveActionsState] =
  useState<SaveItemActionsState>({
    item,
    event: undefined,
    dismiss: () =>
    setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });

  const showSaveActions = (e: SyntheticEvent) =>
  setSaveActionsState((prev) => ({
  ...prev,
  event: e.nativeEvent,
  }));

  return (
    <div className='wide' id="MyCollectionList">
      {spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
       <div>
        <div className='container'>
        <Header>
          <h2>{t('header.headline')}</h2>
          <div className="custom-select-wrapper">
            <span style={{marginRight:'15px'}}>{t('filterBy.collectionsBy')} : </span>
            <div className="select-box">
                <div className="select-box__current"  onChange={handleChange} tabIndex={1}>
                 
                  {
                    filterItems?.map(({id,title,value },idx) => (
                      <div className="select-box__value">
                      <input className="select-box__input" type="radio" id={id} value={value} name="customSelect" checked={option == value ? true : false}/>
                      <p className="select-box__input-text">{title}</p>
                    </div>
                    ))
                  }
                  <img className="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
                </div>
                
                <ul className="select-box__list">
                {
                    filterItems?.map(({id,title},idx) => (
                      <li>
                    <label className="select-box__option" htmlFor={id} >{title}</label>
                  </li>
                    ))
                  }
                </ul>
              </div>

          </div>
        </Header>
        </div>
        

        {collections.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((collection) => (
            <CollectionList
              key={collection.id}
              colId={collection.id}
              deleteItem={(id) => _deleteItem(collection.id,id)}
              cardClicked={(clicked,data,id)=>cardClickAction(clicked,data,id,collection.id)}
              title={collection.title}
              totalDatalength={collection.item_details?.total_count}
              tags={collection.tags}
              items={collection.item_details?.results}
            />
          ))}



       
        <div className="pagination-section container">
          <div>
          <Pagination hidePrevButton={page==1?true:false} hideNextButton={page==Math.ceil(collections.length/itemsPerPage)?true:false} onChange={onPageChange}  count={Math.ceil(collections.length/itemsPerPage)} page={page} defaultPage={1}  color="primary" />
          </div>
        </div> 

        <IonContent>
              <Modal isOpen={showModel} backdropDismiss={false} >
              <Root>
            <main id='postDetails'>
            {spinner && (<div className="spinner">
             <IonSpinner color="primary" />
             </div>)}
              <div className='wide'>
            
            <Slider initialSlide={ind} ref={sliderRef} {...settings}>
            {SliderItem?.map((item: any) => {
              return (
                <SliderWrapper>
                <div className="content-wrapper">
                <div className="cross-btn">
                <ClearIcon onClick={()=>setshowModel(false)}/>
                  </div>
                <div className='preview hide'>
                  {/* <img src={item?.preview_url} alt='big-img' className="img-fluid" /> */}
                  <img src={item?.preview_url} alt='big-img' className="img-fluid" />
                </div>
                <div className="card-description-wrap">
                  <div className='post-content-wrap'>
                    <ItemStyled>
                      <div className='details'>
                        <div className='book-wrapper'>
                          <div className='book'>
                            <span>BOOK</span>
                          </div>
                        </div>
                        <h4 className='title'>{item?.title}</h4>
                  
                        <div className='description-para-wrap'>
                          <div className="d-flex ">

                        <span className="root-icon">
                            <RoomIcon />
                          </span>
                          <p className='description para'>
                          51 Street Name, Berlin, Germany <Link className="view-map-link" href='#'>{t('map-text')}</Link>
                            </p>
                          </div>
                          <div className="long-description">
                            <p className='description para'>
                            {item?.title}
                            </p>
                          </div>
                        </div>

                        <div className='heading-text-wrap d-flex'>
                          <div className='provider'>
                            <span>{p('provider.text')}</span>
                            <div className='d-flex provider-wraper' style={{ marginTop: '15px' }}>
                            <RoomIcon />
                              <div className='text-blue' style={{cursor: 'pointer'}} onClick={() => (item?.url) ? window.open(item?.url ,"_blank"): null}>{item?.provider.id}</div>
                            </div>
                          </div>
                          <div className='original-text-wrap'>
                            <img src={item?.provider.logo} alt={item?.provider.id} />
                          </div>
                        </div>
                        <div className="d-flex bottom-content-wrap">
                          <div className='original-text-wrap'>
                            <img style={{cursor: 'pointer'}} src={EducationPremitted} alt="Education-Premitted" onClick={() => window.open(item?.rights.url,"_blank")} />
                          </div>
                          <div className='actions'>
                            {/* <SaveItemButtonWrapper onClick={showSaveActions}>
                                <Button
                                  variant="outlined"
                                  className={classes.button}
                                  startIcon={<ClearIcon/>}
                                >
                                   Remove saved item
                                </Button>
                            </SaveItemButtonWrapper> */}
                             <SaveItemButtonWrapper onClick={showSaveActions}>
                                <CompButton text={t(`results.${saved ? 'saved' : 'save' }`, { ns: 'search' , })}
                                  color='primary' fill={saved ? 'solid' : 'outline' } icon={heart} />
                              </SaveItemButtonWrapper>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex collection-items-btn">
                          <div className="collection-title">{t('collection-item')}</div>
                              <img src={BackArrow} onClick={()=>sliderRef.current.slickPrev()}  alt="BackArrow" className="back-icon" />
                              <img src={NextArrow} onClick={()=>sliderRef.current.slickNext()} alt="BackArrow" className="next-icon" />
                          {/* <ArrowBackIcon/>
                          <ArrowForwardIcon/> */}
                      </div>
                     
                    </ItemStyled>
                  </div>

                </div>
              </div>
             
              </SliderWrapper>
              );
            })}
          </Slider>
            {/* </SliderWrapper> */}
              </div>
            </main>

          </Root>
      
          <SaveItemActions {...saveActionsState} />
            </Modal>
            </IonContent>

        </div>  
    </div>
  );
};


const Modal = styled(IonModal)`
  // --height: 80%;
  --height: 815px;
  --width: 1595px;
  --border-radius: 20px;
  --background-color: #F7F7F7;
  justify-content: center !important;
  padding: 40px 0;
  --overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 1440px) {
    --width: 88%;

    .modal-wrapper{
  height: 90vh;
  overflow: auto;
  }
  .ion-pag{
    height: 100vh;
  overflow: auto;
  }
}
 


  `;

const SliderWrapper = styled.div`
.content-wrapper{
    display:flex;
    overflow: auto;
    position: relative;
}
.long-description{
  margin:34px 0;
}

`;

const Root = styled.div`
#postDetails{
  margin-top: 0px;
  /* height: 85%; */
}
#postDetails .preview {
  /* width: 50vw; */
    width: 900px;
}
.view-map-link{
  font-size: 19px;
      color: #717c9c;
      font-weight: 600;
      text-decoration: underline;
}

.cross-btn{
  position: absolute;
    right: 26px;
    top: 23px;
    z-index: 1111;
}
.d-flex{
    display:flex;
}
ion-icon {
cursor: pointer;
font-size: 25px !important;
}
.post-content-wrap>div{
  flex-direction: column;
  align-items: end;
}
#postDetails .preview {
  height: auto;
}
#postDetails .preview img{
    height:100vh;
    width: inherit;
    object-fit: contain;
}
.card-description-wrap{
  width: 50%;
  overflow: auto;
}
.post-content-wrap{
  margin: 155px 0;
}
.description-para-wrap{
  align-items: center;
}
.description-para-wrap svg{
  height: 40px;
  width: 50px;
  fill: #0849FF;
}
.card-description-wrap {
    padding: 60px 5%;
}
#postDetails .title {
  margin-bottom: 37px;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 97%;
    display: block;
}
#postDetails .provider{
  /* width:auto; */
}
#postDetails .original-text-wrap img {
    /* width: 31%; */
    width: 43%;
    height: 72%;
}
#postDetails .provider div:nth-child(2){
  /* width: 85px; */
  width: 100%;
  cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 82%;
    display: flex;
    color: #0749ff;
}
.bottom-content-wrap {
    align-items: center;
}
.actions button{
  height: 39px;
    border: 1px solid #2A2A2A;
    border-radius: 35.84px;
    padding: 0 30px;
}
#postDetails .actions button span{
color: #2A2A2A;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37.8px;
}
#postDetails .bottom-content-wrap .original-text-wrap {
    width: 20%;
}
#postDetails .bottom-content-wrap .original-text-wrap img{
    width: 100%;
}
.collection-items-btn{
  margin-top: 55px;
}
.collection-title{
  color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}
.back-icon,.next-icon{
  margin-left: 20px;
}

.provider-wraper svg{
  /* transform: rotate(-60deg); */
}

@media only screen and (max-width: 1440px) {
  #postDetails .wide {
    margin-top: 0px;
}
#postDetails .preview img {
    height: 100%;
}
.post-content-wrap {
    margin: 20% 0;
}

}
@media only screen and (max-width: 1199px) {
#postDetails .para, #postDetails span {
    font-size: large;
}
.actions button{
  padding: 0 15px;
}
}

@media only screen and (max-width: 1024px) {
#postDetails .actions button span {
    font-size: 13px;
}
.bottom-content-wrap{
  flex-direction: column;
}
#postDetails .preview {
    width: 580px;
}
}
@media only screen and (max-width: 991px) {
#postDetails .preview img {
    height: 100%;
}
#postDetails .post-content-wrap {
  padding: 0px 0;
    margin: 100px 0;
}
.bottom-content-wrap{
  flex-direction: column;
}
 #postDetails .bottom-content-wrap .original-text-wrap {
    width: 50%;
}
}
@media only screen and (max-width: 768px) {
.content-wrapper{
  flex-direction: column;
}
.cross-btn svg{
fill:#fff;
}
#postDetails .preview img {
    height: 540px;
}
#postDetails .post-content-wrap {
    margin: 0px 0;
}
.card-description-wrap {
    padding: 40px 5%;
}
.card-description-wrap {
    height: 43vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.bottom-content-wrap {
    flex-direction: inherit;
}
#postDetails .bottom-content-wrap .original-text-wrap {
    width: 30%;
}
.post-content-wrap>div{
margin-bottom: 0;
}
.card-description-wrap{
  width: 100%;
}

}
@media only screen and (max-width: 767px) {
#postDetails .preview img {
    height: 370px;
  }
  .details{
    margin-top: 0;
  }
  #postDetails .original-text-wrap img {
    width: 70%;
    height: 72%;
}
}
@media only screen and (max-width: 475px) {
.bottom-content-wrap {
    flex-direction: column;
}
#postDetails .bottom-content-wrap .original-text-wrap {
    width: 46%;
}
}


`;

const ItemStyled = styled.div`
margin-top: 2rem;
margin-bottom: 7rem;
display: flex;

.details {
width: 100%;
}

.preview {
flex-shrink: 0;
border-radius: 1rem;
width: 16.25rem;
height: 25rem;
margin-right: 4rem;
}

img {
height: 100%;
object-fit: cover;
}

.title {
margin: 0;
color: var(--ion-color-primary);
font-size: 3rem;
font-weight: bold;
margin-bottom: 2.5rem;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
width: 460px;
}

.description {
margin: 0;
color: var(--ion-color-secondary);
font-size: 1.5rem;
width: 52%;
margin-right: 15%;
}

.actions {
display: flex;
align-items: center;
}
#postDetails .original-text-wrap {
    width: 20%;
    height: 52%;
}
.original-text-wrap img{
width:100%;
object-fit: fill;
}
`;

const SaveItemButtonWrapper = styled.div`
margin-right: 1.375rem;

ion-button {
--border-radius: 100px;
text-transform: capitalize;
font-weight: bold;
font-size: 1rem;
}
`;


export default Collections;


