import { IonApp } from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AboutPage from './components/About/About';
import ExperiencePage from './components/Experience/Experience';
import LocationPage from './components/Location/Location';
import SearchExplore from './components/search/SearchExplore';
import GlobalStyle from './components/styled/GlobalStyle';
import Home from './pages/Home/Home';
import Projectpage from './pages/Project';
import Root from './pages/Root';
import TravelHome from './pages/TravelHome';
import './theme/variables.scss';

const App: React.FC = () => {
  const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? '';
  return (
    <CookiesProvider>
      <PayPalScriptProvider
        options={{
          'client-id': paypalClientId,
          components: "buttons",
          currency: "EUR"
        }}
      >
        <IonApp>
          <GlobalStyle />
            <Switch>
              <Route path={`/search-explore/:id+`} component={SearchExplore} />
              <Route path={`/search-explore`} component={SearchExplore} />
              <Route path={`/experiences/:id+`} component={ExperiencePage} />
              <Route path={`/locations/:id+`} component={LocationPage} />
              <Route path={`/about`} component={AboutPage} />
              <Route path={`/projects/:id+`} component={Projectpage} />
              <Route path='/home' component={Home} />
              <Route path='/travel' component={TravelHome} />
              <Route path='/app' render={() => <Root path='/app' />} />
              <Route render={() => <Redirect to='/home' />} />
            </Switch>
          {/* </IonRouterOutlet>
        </IonReactRouter> */}
        </IonApp>
      </PayPalScriptProvider>
    </CookiesProvider>
  );
};

export default App;
