import {
    IonCol,
    IonRow
} from '@ionic/react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ClearIcon from '@material-ui/icons/Clear';
import { InviteModal } from './InviteGuestFormStyled'
import React, { useState } from 'react';
import Arrow from '../../../assets/icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import LinkIcon from '../../../assets/icons/link-icon.svg';
import useTranslations from '../../../util/useTranslation';
import {
    EnterTag,
    IconWrapper,
    Tag,
    TagContent
} from '../../experience-details/ExperienceDetails.style';

const CreateInviteForm = ({ isOpen, setOpenInvitationModal, addGuestInvite }) => {
    const [t] = useTranslations('passover');
    const [tags, setTags] = React.useState<any>([]);
    const [tag, setTag] = React.useState('');
    const [focus, setFocus] = useState(false);

    const handleInviteClose = () => {
        setOpenInvitationModal(false);
    };
    const removeTag = (index: number) =>
        setTags(tags.filter((_: any, i: any) => i !== index));
    const handleChangeTag = (e: any) => {
        setTag(e.target.value);
    };
    const handleEnteredTag = (e: any) => {
        if (e && e.key == 'Enter') {
            addTag(tag, '#0849ff');
        }
    };
    const addTag = (name: string, bgColor: string) => {
        let tagexist = tags.find(
            (t: any) => t.label.toLowerCase() === name.toLowerCase()
        );

        if (tagexist) {
            alert(t('alert.email.already'));
        } else {
            let newTag = {
                label: name,
                bgColor: bgColor,
            };
            name.trim()
                ? tags.length < 30
                    ? setTags([...tags, newTag])
                    : alert(t('alert.email.maxlimit'))
                : alert(t('alert.email.enter'));
            setTag('');
        }
    };
    const addInvite = (tags) => {
        addGuestInvite(tags)
    };

    return (
        <div>
            <InviteModal isOpen={isOpen} backdropDismiss={false}>
                <div>
                    <div className='canclebtn'>
                        <ClearIcon onClick={handleInviteClose}></ClearIcon>
                    </div>
                    <div className='titleInvite'>
                        <h3>Invite People to JHN Seder</h3>
                    </div>

                    <div className='field-wrap'>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <div className="contentEmailList">
                                    <TagContent>
                                        {tags.map((tag: any, i: any) => (
                                            <Tag
                                                key={i}
                                                theme={{
                                                    bgColor: tag.label ? tag.bgColor : '#717c9c',
                                                }}
                                            >
                                                {tag.label ? tag.label : tag}
                                                <IconWrapper onClick={(e) => removeTag(i)}>
                                                    <CloseIcon />
                                                </IconWrapper>
                                            </Tag>
                                        ))}
                                    </TagContent>
                                    <EnterTag
                                        placeholder={t('placeholder.textarea')}
                                        value={tag}
                                        onChange={handleChangeTag}
                                        onKeyDown={handleEnteredTag}
                                        onFocus={() => setFocus(true)}
                                        onBlur={() =>
                                            setTimeout(() => {
                                                setFocus(false);
                                            }, 300)
                                        }
                                    ></EnterTag>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ionRow'>
                            <IonCol>
                                <div className='d-flex ion-align-items-center'>
                                    <IonCol>
                                        <img src={LinkIcon} alt='Link Icon' />
                                        <Link href='#'>Public share link</Link>
                                    </IonCol>
                                    <IonCol>
                                        <Button
                                            className='createInvite'
                                            variant='contained'
                                            onClick={() => addInvite(tags)}
                                            color='primary'
                                            disabled={tags.length === 0}
                                            startIcon={
                                                <img
                                                    src={Arrow}
                                                    alt='Arrow'
                                                    style={{ width: '16.14px', height: '16px' }}
                                                />
                                            }
                                        >
                                            <span className="color-black">
                                                {t('button.submit')}
                                            </span>
                                        </Button>
                                    </IonCol>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </InviteModal>
        // </div>
    );
};

export default CreateInviteForm;