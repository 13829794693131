import { IonCol, IonContent, IonModal, IonRow } from '@ionic/react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import { ImFacebook, ImGooglePlus, ImTwitter } from 'react-icons/im';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import validator from 'validator';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { getCurrentUser, login } from '../../util/API';
import useToken from '../../util/useToken';
import useTranslations from '../../util/useTranslation';
import { setUser } from './CurrentUser';
import {
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendSeparator,
} from './Style';

const SignIn: React.FC<{
  open: boolean;
  redirect: string;
  signUpModal?: boolean;
  onCloseSignIn: any;
}> = ({ open, redirect, onCloseSignIn,signUpModal }) => {
  const [t] = useTranslations('authentication');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState('');
  const [FName, setFName] = React.useState<string>('');
  const [LName, setLName] = React.useState<string>('');
  const [checked, setchecked] = React.useState<boolean>(false);
  const [submit, setSubmit] = React.useState(false);
  const [token, setToken] = useToken();
  const history = useHistory();
  const [openSignIn, setOpenSignIn] = useState(signUpModal?false:true);
  const [openSignUp, setOpenSignUp] = useState(signUpModal?true:false);

  const submitSignIn = async () => {
    if (!validator.isEmail(email) || password.length < 8) {
      alert(t('signinvalidation.invalid'));
    } else {
      login(email, password)
        .then(({ data }) => {
          setToken(data);
          getCurrentUser(data).then(({ data }) => {
            setUser(data);
            history.push(redirect);
            history.go(0);
          });
        })
        .catch(() => {
          alert(t('signinvalidation.invalid'));
        });
    }
  };
  var hasNumber = /\d/;
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const submitSignUp = () => {
    setSubmit(true);
    if (
      !FName ||
      !LName ||
      !email ||
      !password ||
      hasNumber.test(FName) ||
      hasNumber.test(LName) ||
      !pattern.test(email) ||
      password.length < 8
    ) {
      alert(t('signupvalidation.form'));
    } else {
      if (!checked) {
        alert(t('signupvalidation.terms_service'));
      }
    }
  };
  const openSignInModal = () => {
    setOpenSignIn(true);
    setOpenSignUp(false);
  };

  const openSignUpModal = () => {
    setOpenSignIn(false);
    setOpenSignUp(true);
  };

  const handleCloseSignup = () => {
    console.log('handle close signup called');    
    history.push('/home');
    onCloseSignIn();
  };

  const handleCloseSigin = () => {
    history.push('/home');
    openSignInModal();
  };
  return (
    <Styled>
      <div className='signup-Model-wrap'>
        <div className='xyz'>
          <IonContent>
            <Modal isOpen={open} backdropDismiss={false} cssClass={openSignIn ? 'signIn' : 'signUp'}>
              {openSignIn && (
                <>
                  <div className='canclebtn'>
                    <ClearIcon onClick={handleCloseSignup}></ClearIcon>
                  </div>
                  <div className='d-flex sign-in-title title'>
                    <h5>{t('signin.text')}</h5>
                    <span>
                      {t('or.text')}
                      <span className='newaccount' onClick={openSignUpModal}>
                        {t('newaccount.text')}
                      </span>
                    </span>
                  </div>
                </>
              )}
              {openSignUp && (
                <>
                  <div className='canclebtn'>
                    <ClearIcon onClick={handleCloseSignup}></ClearIcon>
                  </div>
                  <div className='d-flex title'>
                    <h5>{t('signup.text')}</h5>
                    <span>
                      {t('or.text')}
                      <span
                        className='navheader list-item existing_account '
                        onClick={openSignInModal}
                      >
                        {t('existingaccount.text')}
                      </span>
                    </span>
                  </div>
                </>
              )}
              <div className='conten-wrapper'>
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<ImGooglePlus />}
                >
                  <span
                    style={{
                      textTransform: 'none',
                      color: '#114fff',
                      fontWeight: 'bold',
                      lineHeight: '16px'
                    }}
                  >
                    {t('socialauth.google')}
                  </span>
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<ImFacebook />}
                >
                  <span
                    style={{
                      textTransform: 'none',
                      color: '#114fff',
                      fontWeight: 'bold',
                      lineHeight: '16px'
                    }}
                  >
                    {t('socialauth.facebook')}
                  </span>
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<ImTwitter />}
                >
                  <span
                    style={{
                      textTransform: 'none',
                      color: '#114fff',
                      fontWeight: 'bold',
                      lineHeight: '16px'
                    }}
                  >
                    {t('socialauth.twitter')}
                  </span>
                </Button>
                <span className='single-line'></span>
              </div>

              {openSignIn && (
                <div>
                  <div className='field-wrap'>
                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            !validator.isEmail(email) && email.length
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {t('fields.email')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  !validator.isEmail(email) && email.length
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            placeholder={t('placeholder.email')}
                            type='text'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {email == '' ? null : !validator.isEmail(email) ? (
                      <label className='Errorlabel'>
                        {' '}
                        {t('errorlabel.email')}
                      </label>
                    ) : null}
                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            password.length < 8 && password.length
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {' '}
                                {t('fields.password')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  password.length < 8 && password.length
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            className='password-input'
                            placeholder={t('placeholder.password')}
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {password.length == 0 ? null : password.length < 8 ? (
                      <label className='Errorlabel'>
                        {t('errorlabel.password')}
                      </label>
                    ) : null}
                    <Link className='forgotPswd' href='#'>
                      {t('forgotpswd.text')}
                    </Link>
                  </div>

                  <div className='d-flex footer-wrap signin'>
                    <Button
                      className='signupbtn'
                      onClick={() => submitSignIn()}
                      variant='contained'
                      color='primary'
                      endIcon={<ArrowRightAltIcon />}
                    >
                      {t('signin.button')}
                    </Button>
                  </div>
                </div>
              )}

              {openSignUp && (
                <div>
                  <div className='field-wrap'>
                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            hasNumber.test(FName)
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {t('fields.firstname')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  hasNumber.test(FName)
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            placeholder={t('placeholder.firstname')}
                            type='text'
                            onChange={(e) => setFName(e.target.value)}
                            value={FName}
                            id='fname'
                            maxLength={30}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {hasNumber.test(FName) ? (
                      <label className='Errorlabel'>
                        {t('errorlabel.name')}
                      </label>
                    ) : null}

                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            hasNumber.test(LName)
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {t('fields.lastname')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  hasNumber.test(LName)
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            placeholder={t('placeholder.lastname')}
                            type='text'
                            id='lname'
                            onChange={(e) => setLName(e.target.value)}
                            value={LName}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {hasNumber.test(LName) ? (
                      <label className='Errorlabel'>
                        {t('errorlabel.name')}
                      </label>
                    ) : null}
                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            !pattern.test(email) && email.length
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {t('fields.email')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  !pattern.test(email) && email.length
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            placeholder={t('placeholder.email')}
                            type='text'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {email == '' ? null : !pattern.test(email) ? (
                      <label className='Errorlabel'>
                        {t('errorlabel.email')}
                      </label>
                    ) : null}
                    <IonRow>
                      <IonCol>
                        <FieldSet
                          style={
                            password.length < 8 && password.length
                              ? { border: '1px solid rgb(234 5 5)' }
                              : { border: '1px solid rgba(113,124,156,0.5)' }
                          }
                        >
                          <Legend>
                            <LegendContainer>
                              <LegendLeftTitle>
                                {t('fields.password')}
                              </LegendLeftTitle>
                              <LegendSeparator
                                className='separator'
                                style={
                                  password.length < 8 && password.length
                                    ? { backgroundColor: 'rgb(234 5 5)' }
                                    : {
                                        backgroundColor:
                                          'rgba(113,124,156,0.5)',
                                      }
                                }
                              ></LegendSeparator>
                            </LegendContainer>
                          </Legend>
                          <ExperienceContent
                            className='password-input'
                            placeholder={t('placeholder.password')}
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            maxLength={12}
                          />
                        </FieldSet>
                      </IonCol>
                    </IonRow>
                    {password.length == 0 ? null : password.length < 8 ? (
                      <label className='Errorlabel'>
                        {t('errorlabel.password')}
                      </label>
                    ) : null}
                  </div>
                  <div className='d-flex footer-wrap'>
                    <FormGroup className='footercheckbox'>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        name='checkedI'
                        onChange={(e) => setchecked(e.target.checked)}
                      />
                      <span className='agreebtn'>
                        {t('agreegation.agree')}
                        <span
                          className='terms_of_service'
                          onClick={openSignInModal}
                        >
                          {' '}
                          {t('agreegation.terms_service')}
                        </span>
                      </span>
                    </FormGroup>
                    <Button
                      onClick={() => submitSignUp()}
                      className='signupbtn'
                      variant='contained'
                      color='primary'
                      endIcon={<ArrowRightAltIcon />}
                    >
                      {t('signup.button')}
                    </Button>
                  </div>
                </div>
              )}
            </Modal>
          </IonContent>
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div``;

const Modal = styled(IonModal)`
  --width: 400px;
  justify-content: center !important;
  padding: 40px 0;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  ${({ cssClass }) => cssClass === 'signIn' && `
    --height: 510px;
  `}

    ${({ cssClass }) => cssClass === 'signUp' && `
    --height: 650px;
  `}

  .canclebtn {
    text-align: right;
    width: 96%;
    margin-bottom: -26px;
  }

  .canclebtn svg {
    fill: #8b7f9e;
    cursor: pointer;
  }

  .ion-page {
    justify-content: space-evenly !important;
    overflow: auto;
    background-color: #fff;
  }

  .ion-page::-webkit-scrollbar {
    display: none;
  }

  .modal-wrapper.sc-ion-modal-md {
    border-radius: 17px;
  }
  .Errorlabel {
    color: red;
    display: flex;
    justify-content: flex-end;
    margin: -3px 6px 3px;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    padding-top: 0px;
  }
  .title h5 {
    font-weight: 700;
    color: #5a5a5a;
  }

  .title span {
    color: #757f9e;
    margin-left: 5px;
    font-weight: 600;
    font-size: 15px;
  }
  .newaccount {
    color: #757f9e;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
  }
  .conten-wrapper {
    display: flex;
    flex-direction: column;
  }
  .conten-wrapper .single-line {
    width: 76%;
    height: 1px;
    background-color: #5a5a5a;
    margin: 7px auto 0;
  }
  .forgotPswd {
    padding-left: 8px;
    text-decoration-line: underline;
    font-size: 13px;
    font-weight: 600;
    color: #99a1b8;
  }
  .conten-wrapper button {
    border-radius: 50px;
    width: 80%;
    margin: 0 auto 10px;
  }
  .conten-wrapper .MuiButton-startIcon {
    margin-left: 8px;
    margin-right: 8px;
  }

  .conten-wrapper .MuiButton-label {
    display: flex;
    justify-content: end;
  }
  .conten-wrapper button:nth-child(2) .MuiButton-label {
    padding: 0 53px 0 0;
    color: rgb(17, 79, 255);
  }
  .conten-wrapper button:nth-child(1) .MuiButton-label {
    padding: 0 65px 0 0;
    color: rgb(17, 79, 255);
  }
  .conten-wrapper button:nth-child(3) .MuiButton-label {
    padding: 0 73px 0 0;
    color: rgb(17, 79, 255);
  }

  .conten-wrapper .MuiButton-label span:nth-child(2) {
    margin-left: 39px;
  }

  .field-wrap {
    width: 80%;
    margin: 10px auto 16px;
  }

  fieldset input {
    padding: 0;
    height: 100%;
    font-size: 14px;
  }

  fieldset {
    margin: 0 auto 0px;
    border: 1px solid #ccc;
    width: auto;
    margin-right: 0px !important;
  }

  .separator {
    margin: 7px 0 0 0;
  }

  .footer-wrap {
    justify-content: flex-end;
    width: 75%;
    margin: 7px auto 15px;
  }
  .footercheckbox {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    font-size: 13px;
  }

  .footercheckbox .MuiSvgIcon-fontSizeSmall {
    font-size: 15px;
  }

  .footercheckbox .MuiFormControlLabel-label {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .footercheckbox a.MuiLink-root.MuiLink-underlineHover {
    font-size: 12px;
    font-size: 12px;
    text-decoration: underline;
    bottom: -180px;
    margin-left: 5px;
    font-size: 13px;
  }

  .signupbtn {
    color: white;
    background-color: rgb(17, 79, 255);
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
    border-radius: 50px;
  }
  .signupbtn:hover {
    color: white;
    background-color: rgb(17, 79, 255);
  }

  @media only screen and (min-width: 1024px) {
    .footercheckbox a.MuiLink-root.MuiLink-underlineHover {
      bottom: -180px;
    }
  }
  @media only screen and (max-width: 375px) {
    .conten-wrapper .MuiButton-label span:nth-child(2) {
      margin-left: 16px;
    }
    .conten-wrapper button:nth-child(1) .MuiButton-label {
      padding: 0 50px 0 0;
    }
    .conten-wrapper .MuiButton-startIcon {
      margin-right: 25px;
    }
    .conten-wrapper button:nth-child(2) .MuiButton-label {
      padding: 0px 36px 0 0;
    }
    .conten-wrapper button:nth-child(3) .MuiButton-label {
      padding: 0 57px 0 0;
    }
  }

  @media only screen and (max-width: 360px) {
    .conten-wrapper button:nth-child(1) .MuiButton-label {
      padding: 0 31px 0 0;
    }
    .conten-wrapper button:nth-child(2) .MuiButton-label {
      padding: 0 14px 0 0;
    }
    .conten-wrapper button:nth-child(3) .MuiButton-label {
      padding: 0 36px 0 0;
    }
    .footer-wrap {
      flex-direction: column;
    }
    .footercheckbox {
      flex-direction: row;
      margin-bottom: 15px;
    }
  }
`;

export default SignIn;
