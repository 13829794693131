import { IonIcon, IonInput, IonItem } from '@ionic/react';
import useTranslations from '../../util/useTranslation';
import { arrowForward, close, searchSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SearchInput: React.FC<{
  getQuery: () => string;
  setQuery: (query: string) => void;
  clearSearch?: boolean;
  explore?:boolean,
  placeholder?: string;
}> = ({ getQuery, setQuery, placeholder,clearSearch,explore }) => {
  const [value, setValue] = useState(getQuery());
  const [dirty, setDirty] = useState(!!getQuery());

  const onSubmitQuery = (e?: { key: string }) => {
    if (e && e.key !== 'Enter') {
      return;
    }
    if(value){
      setQuery(value);
      setDirty(true);
    }
  };

  useEffect(()=>{
    setQuery(getQuery() ? getQuery() : '');
    setValue(getQuery() ? getQuery() : '');
    // setQuery('');
    // setValue('')
  },[clearSearch])


  const onIconClick = () => {
    if (dirty) {
      setQuery('');
      setValue('');
    } else {
      onSubmitQuery();
    }
  };

  return (
    <Root style={{width:explore?"100%":"100%"}}>
      <IonItem className='shadow'>
        <IonIcon className='icon-left' icon={searchSharp} slot='start' />
        <IonInput
          value={value}
          placeholder={placeholder}
          onIonChange={({ detail: { value: v } }) => {
            setValue(v as string);
            setDirty(false);

            if(!v){
              setQuery('');
              setValue('');
            }
          }}
          onKeyDown={onSubmitQuery}
        ></IonInput>
        <IonIcon
          className='icon-right'
          icon={dirty ? close : arrowForward}
          slot='end'
          color='primary'
          onClick={onIconClick}
        />
      </IonItem>
    </Root>
  );
};

const Root = styled.div`
  //  width: 40%;

   @media only screen and (max-width: 1980px) {
    width: 100%;
   }
  ion-item {
    border-radius: 100px;
    --border-width: 0;

    &.item-has-focus {
      --highlight-color-focused: none;
    }
  }

  ion-input {
    --placeholder-color: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
  }

  ion-icon.icon-left {
    transform: rotateZ(90deg);
  }

  ion-icon.icon-right {
    cursor: pointer;
  }
`;

export default SearchInput;
