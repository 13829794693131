import ButtonBase from '@material-ui/core/ButtonBase';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RoomIcon from '@material-ui/icons/Room';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import clockIcon from "../../../../assets/bg/clock-icon.svg";
import { ItemType } from '../../../../model/ItemType';
import { getPreviewImage, isComingSoon, isCompleted, shortenLocationName } from '../../../../util/Utils';
import { StyledItemCardMobile } from './ExperienceItemCardStyle';
import { StyledExperienceMobile } from './ExperinceMobileStyle';
import { StyledItemCardWrapperMobile } from './ItemCardWrapperStyle';

const ExperienceListMobile: React.FC<{ data?: any; ds: any; item?: any }> = ({
  data,
  ds,
  item,
}) => {
  const sliderRef = useRef<any>(null);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
    '3d-tour',
    'event'
  ]);
  const renderArrows = () => {
    return (
      <div className='sliderBtn-wrap'>
        <ButtonBase
          className='arrow-btn prev'
          onClick={() => sliderRef.current.slickPrev()}
        >

          <NavigateBeforeIcon />
        </ButtonBase>
        <ButtonBase
          className='arrow-btn next'
          onClick={() => sliderRef.current.slickNext()}
        >
          < NavigateNextIcon />
        </ButtonBase>
      </div>
    );
  };

  return (
    <StyledExperienceMobile>
      <div className='search-results recommended-cards-wrapper'>
        {renderArrows()}
        <StyledItemCardWrapperMobile className='recommendedCard carousel-slider-card'>
          <Slider ref={sliderRef} {...settings}>
            {Object.values(data).map((item: any) => {
              return (
                <>
                  <StyledItemCardWrapperMobile className='recommendedCard experiences-slider-card'>
                    <StyledItemCardMobile
                      className='shadow'
                      style={{ padding: '1.2rem 0', gap: ' 1.1rem', boxShadow: 'none'}}
                    >
                      {isComingSoon(item) ? 
                        <div className="coming-wrapper"><span className="coming-wrapper-text">{ds('coming-soon')}</span></div>
                        : null
                      }
                      {isCompleted(item) ? 
                        <div className="completed-wrapper"><span className="completed-wrapper-text">{ds('completed')}</span></div>
                        : null
                      }
                      <div style={{position:"relative"}}>
                        <img className='item-preview'
                        src={getPreviewImage(item)}
                        alt='Image'/>
                         <div className='item-details-menu-toggler'
                            style={{ marginLeft: 'inherit' }}
                          >
                            <span className='book-btn'>FREE</span>
                          </div>
                        </div>
                      <div
                        className='item-details d-flex d-flex-col '
                        style={{ marginTop: '-12px' }}
                      >
                        <div
                          className='item-details-header d-flex'
                          style={{ visibility: 'visible' }}
                        >
                        </div>
                        <div className='item-title-wrapper hide'>
                          <span className='self-guid self-guided-tour'>
                            {expTypes.map((i) =>
                              item?.type == i
                                ? `${ds(`experienceitems.${i}_plural`)}`
                                : null
                            )}
                          </span>
                          <h4 className='item-title jewish-vienna'>
                            <Link
                              className='item-title-link'
                              to={`/experiences/${item?.slug}`}
                            >
                              {item?.title}
                            </Link>
                          </h4>
                        </div>
                        <p className='item-details-description walking-tour-on-jewi hide'>
                          {item?.description}
                        </p>
                        <div
                          className='d-flex icon-wrap'
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            marginTop: '26px',
                          }}
                        >
                          <div className='pin-icon-wrap d-flex'>
                            <RoomIcon />
                            {item?.location == null ? (
                              <span>{ds('not-provide')}</span>
                            ) : (
                              <span>{shortenLocationName(item?.location?.name)}</span>
                            )}
                          </div>
                          <div className='clock-iocn-wrap d-flex'>
                            <img src={clockIcon} alt="clockIcon" />
                            <span>{item?.duration}m</span>
                          </div>
                        </div>
                      </div>
                    </StyledItemCardMobile>
                  </StyledItemCardWrapperMobile>
                </>
              );
            })}
          </Slider>
        </StyledItemCardWrapperMobile>
      </div>
    </StyledExperienceMobile>
  );


}

export default ExperienceListMobile;