import { startCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import useQuery from '../../util/useQuery';
import Button from '../common/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

const FilterItem: React.FC<{
  text: string,
  value: string;
  param?: any;
  filterKey: string;
  explore?: boolean;
  moreFilter?: boolean;
}> = ({ text, value, filterKey, param, moreFilter, explore }) => {
  const { toggleFilter, isActive } = useQuery(filterKey, value);

  return (
    <Styled>
      <div className='search-explore-tag-wrap'>
        {explore ? (
          moreFilter ? (
            <div className='search-explore-tag withmorefilter'>
              <Styled>
                <button
                  className='filter-btn-tag'
                  value={value}
                  style={{
                    background: isActive() ? 'rgb(17, 79, 255)' : '#efefef',
                  }}
                  onClick={toggleFilter}
                >
                  <div
                    style={{
                      color:
                        param?.filter((i) => value == i)?.length > 0
                          ? isActive()
                            ? 'white'
                            : '#696363'
                          : isActive()
                          ? 'white'
                          : '#696363',
                    }}
                    className='tag_text'
                  >
                    {/* <div style={{color:value=="Online Seminars"?isActive() ?"white":"#696363":isActive() ?"white":"#696363"}} className="tag_text"> */}
                    {text}
                  </div>
                </button>
              </Styled>
            </div>
          ) : (
            <div className='search-explore-tag withoutmorefilter'>
              <Styled>
                <button
                  className='filter-btn-tag'
                  value={value}
                  style={{
                    background: isActive() ? 'rgb(17, 79, 255)' : '#efefef',
                  }}
                  onClick={toggleFilter}
                >
                  <div
                    style={{ color: isActive() ? 'white' : '#696363' }}
                    className='tag_text'
                  >
                    {text}
                  </div>
                </button>
              </Styled>
            </div>
          )
        ) : (
          <span id='tag-btn-wrapper'>
            <FilterButtonWrapper onClick={toggleFilter}>
              <Button
                color={isActive() ? 'primary' : 'light'}
                text={text}
                size='small'
              />
            </FilterButtonWrapper>
          </span>
        )}
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .search-explore-tag {
    difplay: flex;
  }
  .search-explore-tag.withmorefilter {
    width: 190px;
  }
  .Component-switchBase-44.Component-checked-47 + .Component-track-46 {
    background-color: #0749ff !important;
  }
  span {
    background-color: red;
  }
  .tag_text {
    text-overflow: ellipsis;
    overflow: hidden;
    /* font-family: 'Lucida Grande', sans-serif; */
    font-family: 'Source Sans Pro Regular';
    font-size: small;
    text-align: center;
    font-weight: 400;
  }
  .filter-btn-tag {
    // background-color: #f7f7f7;
    margin-left: 2px;
    padding: 11px 15px;
    border-radius: 20px;
    width: 100%;
    // color: #696363;
  }
  .filter-btn-tag:hover {
    background-color: rgb(17, 79, 255);
    color: #f7f7f7;
  }

  @media only screen and (max-width: 1980px) {
    .filter-btn-tag .tag_text {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
@media only screen and (max-width: 768px) {
  .search-explore-tag.withmorefilter {
    width: 180px;
}
}
@media only screen and (max-width: 575px) {
 .footer-wrap.signin > div {
    align-items: baseline;
    display:flex;
    flex-direction: column;
}
.filter-model-wrap {
  height: 71vh;
}
}



`;
const FilterButtonWrapper = styled.div`
  ion-button {
    --border-radius: 100px;
    font-size: 0.875rem;
    text-transform: unset;
  }
  ion-button .button-inner {
    display: block !important;
    position: relative;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 72px !important;
    height: 13px !important;
    z-index: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #000;
  }
  .Component-switchBase-4.Component-checked-7 + .Component-track-6 {
    background-color: rgb(17, 79, 255);
  }
`;

export default FilterItem;
