import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomIcon from '@material-ui/icons/Room';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Card, Icon, Image } from 'semantic-ui-react';
import '../../../../node_modules/leaflet/dist/leaflet.css';
import {
  ItemCardWrapper,
  Mapcard,
  Styled,
  StyledItemCard,
} from './ExperienceMapStyle';
import { ItemType } from '../../../model/ItemType';
import useTranslations from '../../../util/useTranslation';
import React, { useState } from 'react';
import {shortenLocationName} from '../../../util/Utils';

const PointMarker: React.FC<{
  item: any;
  index: number;
}> = ({ item, index }) => {
  const [t] = useTranslations('search');
  const [ds] = useTranslations('dashboard');

  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
    '3d-tour'
  ]);


  const renderLocationPointMarker = (item) => {
    return (
      <Styled>
        <div
          className='search-results recommended-cards-wrapper'
          id='listOfCard'
        >
          <ItemCardWrapper className='recommendedCard'>
            <StyledItemCard className='shadow' style={{ padding: '1.2rem' }}>
              {item?.image_url === null ? (
                <img
                  className='item-preview'
                  src='./assets/bg/header.png'
                  alt='Image'
                />
              ) : (
                <img
                  className='item-preview'
                  src={item?.image_url}
                  alt='Image'
                />
              )}

              <div
                className='item-details d-flex d-flex-col '
                style={{ marginTop: '-12px' }}
              >
                <div
                  className='item-details-header d-flex'
                  style={{ visibility: 'visible' }}
                >
                  <div
                    className='item-details-menu-toggler'
                    style={{ marginLeft: 'inherit' }}
                  >
                    <span className='book-btn'>{t('searchexplore.free')}</span>
                    {/* <span className="book-btn euro">12,99€</span> */}
                  </div>
                </div>
                <div className='item-title-wrapper hide'>
                  <span className='self-guid self-guided-tour'>
                    {expTypes.map((i) =>
                      item?.type == i
                        ? `${ds(`experienceitems.${i}`)}`
                        : null
                    )}
                  </span>
                  <h4 className='item-title jewish-vienna'>
                    <Link
                      className='item-title-link'
                      to={`/experiences/${item.slug}`}
                    >
                      {item?.title}
                    </Link>
                  </h4>
                </div>
                <div
                  className='d-flex icon-wrap'
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <div className='pin-icon-wrap d-flex'>
                    <RoomIcon />
                    {item?.location == null ? (
                      <span>{t('notags.notags')}</span>
                    ) : (
                      <span>{shortenLocationName(item?.location?.name)}</span>
                    )}
                  </div>
                  {item?.duration ? <div className='clock-iocn-wrap d-flex'>
                    <AccessTimeIcon />
                    <span>{item?.duration}m</span>
                  </div> : null}
                </div>
              </div>
            </StyledItemCard>
          </ItemCardWrapper>
        </div>
      </Styled>
    );
  };

  return (
    <Marker position={[item.location.lat, item.location.lng]} key={`marker-${item.id}`}>
      <Popup>
        {renderLocationPointMarker(item)}
      </Popup>
    </Marker>
  );
};
export default PointMarker;
