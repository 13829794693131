import mapCardStyle from 'styled-components';

export const ItemCardWrapper = mapCardStyle.div`
width: 29rem;
height: 18.75rem;
font-size: 1.375rem;
line-height: 1.2;

.item-details-header {
  visibility: hidden;
}
.leaflet-popup-content {
  margin: 0px 0px;
}

`;

export const StyledItemCard = mapCardStyle.div`
width: 100%;
height: 100%;
border-radius: 1rem;
padding: 1.5rem;
display: flex;
gap: 1.5rem;

h4,
p {
  padding: 0;
  margin: 0;
}

.item {
  &-preview {
    width: 10rem;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }

  &-title {
    color: var(--ion-color-primary);
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
    word-wrap: break-word;

    &-wrapper {
      height: 2.5em;
      margin-bottom: 0.5em;
      flex-shrink: 0;
      max-width: 175px;
    }

    &-link {
      text-decoration: none;
    }
  }

  &-details {
    width: 100%;

    &-header {
      height: 1rem;
    }

    &-menu {
      &-toggler {
        font-size: 2rem;
        margin-left: auto;
        align-self: flex-end;
        color: var(--ion-color-secondary);
        cursor: pointer;
      }
    }

    &-description {
      color: var(--ion-color-secondary);
      font-size: 1rem;
      line-height: 1.25;
    }

    &-footer {
      height: 2rem;
      min-height: 2rem;
      margin-top: 0.5rem;
    }
  }
}
#listOfCard .leaflet-popup-content {
  margin: 0 0 !important;
}
`;
export const Styled = mapCardStyle.div`
#listOfCard .leaflet-popup-content{
margin:0 0 !important;
  }
.search-results {
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
  gap: 1.8rem;
  // max-width: 1500px;
  max-width: 1765px;
  margin: 0 auto;
}

.recommended-cards-wrapper .recommendedCard {
  position: relative;
  // height: 160px;
  width: 278px;
  border-radius: 15px;
  background-color: #ffffff;
  /* box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%); */
}

#listOfCard .recommendedCard{
  height: 12.75rem !important;     
}
.recommended-cards-wrapper .recommendedCard {
  position: relative;
  // height: 160px;
  /* width: 278px; */

}
.recommended-cards-wrapper .recommendedCard .item-preview {
  width:6rem;
}
.book-btn {
  padding: 5px 10px;
  font-size: 10px !important;
  color: #000;
  background-color: #fac807;
  border-radius: 7px;
  font-weight: 900 !important;
  position: absolute;
  top: -7px;
  right: 25px;

}
.book-btn.euro {
  background-color: #000;
  color: #fff !important ;
}
.self-guided-tour {
  height: 24px;
  width: 116px;
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 600;
  line-height: 27px;
  height: 24px;
  font-size: 12px;
  letter-spacing: 0.43px;
  line-height: 27px;
}


.jewish-vienna {
  height: 50px;
  /* width: 281px; */
  color: #0849ff;
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 15px;
}

.jewish-vienna .item-title-link {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // -webkit-line-clamp: 2;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // white-space: nowrap;
  // width: 225px;
  // height: 45px;
  // width: 116px;
  color: #0849FF;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;

}

.walking-tour-on-jewi {
  // height: 40px;
  /* width: 250px; */
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.clock-iocn-wrap ,
.pin-icon-wrap{
position:relative;
}

.pin-icon-wrap svg,
.clock-iocn-wrap svg {
  height: 21px;
  width: 20px;
  fill: #0849ff;
  margin-right: 6px;
}
.pin-icon-wrap span,
.clock-iocn-wrap span {
  height: 21px;
  width: 94px;
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  right: -26px;

}
#listOfCard .item-title-wrapper {
  height: auto;
  max-width: 100%;
}

#listOfCard .leaflet-popup-content{
  margin:0 0 !important;
    }


.item-details{
  width: 63%;
    }

    .filtermodel{
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
}


.jewish-vienna .item-title-link{
display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 292px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


@media only screen and (max-width: 1791px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 100%;
    margin: 0 auto;
  }
}

/* @media only screen and (max-width: 1502px) {
  #listOfCard .item-details {
    width: 27% !important;
  }
} */
@media only screen and (max-width: 1366px) {
  .walking-tour-on-jewi {
    width: 100%;
  }
}
@media only screen and (max-width: 1284px) {
  
  /* #listOfCard .item-details {
    width: 67% !important;
  } */
  .banner-inner-img {
    transform: translate(0, 22%);
}
}
@media only screen and (max-width: 1199px) {
  .pin-icon-wrap span,
  .clock-iocn-wrap span {
    width: 85px;
    font-size: 12px;
  }
  
}
@media only screen and (max-width: 1024px) {
  
  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: 95px;
    height: 100%;
  }
 /* #listOfCard .item-details {
    width: 60% !important;
} */
.jewish-vienna{
  margin-top: 6px;
}
.jewish-vienna .item-title-link {
  // font-size: 13px;
  // height: 48px;
  line-height: 21px;
}
.recommended-cards-wrapper .recommendedCard .item-title-wrapper {
  margin-bottom: 0;
}
.walking-tour-on-jewi+.icon-wrap{
  margin-top: 15px !important;

}
/* .item-details-header {
  margin-top: -11px;
} */
.book-btn {
  top: -15px;
}
}
@media only screen and (max-width: 991px){
/* #listOfCard .item-details {
  width: 55% !important;
} */
.recommended-cards-wrapper .recommendedCard .item-preview {
width: 12vw;
}
}

@media only screen and (max-width: 768px){
.recommended-cards-wrapper .recommendedCard {
width: 100%;
height: auto;
}
.recommended-cards-wrapper .recommendedCard .shadow{
/* flex-direction: column; */
}
.searchBar .search-inner-wrap{
text-align: left;
}
.book-btn {
top: -15px;
}
.signupbtn{
font-size: 1.8vw;
    width: 115px;
    margin-left: 0px;
    margin-bottom: 15px;
}
.filterview {
width: 100%;
flex-direction: initial;
}
.mobileview{
display:block;
}
.desktopview{
display:none;
}
.map-list-btn.desktop{
display:none;
}
#listOfCard .item-details {
width: 100% !important;
}
.related-card-wrap{
margin-bottom:35px;
}
.walking-tour-on-jewi+.icon-wrap{
margin-top: 15px !important;
}
}

@media only screen and (max-width: 575px){
    .walking-tour-on-jewi + .icon-wrap {
      display: flex !important;
      flex-direction: column !important;
    }
    .recommended-cards-wrapper .recommendedCard .item-preview {
      width: 25vw;
    }
}

`;


export const Mapcard = mapCardStyle.div`
.map-card .ui {
  width: 12rem;
  height: 100%;
}
.map-card .ui.image {
  border: 0;
  width: 12rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 16px;
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 4px !important;
  right: 2px !important;
}

.map-card .description {
  color: var(--ion-color-secondary);
  font-size: 17px;
  line-height: 1.5;
  font-family: 'Source Sans Pro';
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.extra.contet {
  color: #0849ff;
  font-size: 14px !important;
  text-transform: capitalize;
  line-height: 1.6;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 21px;
  font-family: 'Source Sans Pro', serif;
}
`