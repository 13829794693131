import {
  IonContent,
  IonModal
} from '@ionic/react';
import { IonCol, IonPage, IonRow } from "@ionic/react";
import {
  DescriptionContent,
  DescriptionTextArea,
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendRightTitle,
  LegendSeparator,
  NextButton,
  NextButtonLabel,
  PageContent,
  PageTitle,
  PageWrapper
} from "../../experience-details/ExperienceDetails.style";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../util/useTranslation';
import useToken from '../../../util/useToken';
import { createCollection } from '../../../util/API';
import Item from '../../../model/Item';
import { IonSpinner } from '@ionic/react';

// Icons
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrow-icon-right.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";


const SaveItemToNewCollectionModal: React.FC<{
  item?: Item;
  showModal: boolean;
  closeModal: () => void;
}> = ({ item, showModal, closeModal }) => {
  const [spinner, setSpinner] = useState<boolean>(false);
  const [id, setId] = useState<any>(null);
  const [preview_url, setPreviewUrl] = useState<any>('');
  const [items, setItems] = useState<any>([]);
  const [t] = useTranslations('save-item');
  const [item_details, setItemDetails] = useState<any>()
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [token,setToken] = useToken();
  const tags = [];
  

  useEffect(() => {
    setItems([item?.id as string]);
  },[])

  const createNewCollection = async () => {
    console.log("Create ::---")
    let request = { id , title, description, preview_url, items, item_details, tags};
    if (!title || !description) {
      //Form Invalid
      console.log("Form invalid")
    } else {
      // // // API Call
      setSpinner(true);
      await createCollection(request, token);
      setSpinner(false);
      closeModal();
    }
    
    
    
  };

  return (
    <IonContent>
      { spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
      <Modal isOpen={showModal} onDidDismiss={closeModal}>
        {/* <div className='main_model_wrapper'>
          <div className='model_title'>
          <IonListHeader>
            <span>Add item to new collection</span>
          </IonListHeader>
          </div>
          <div className='model_body'>
            <IonLabel>Title</IonLabel>
            <IonInput
              value={title}
              placeholder='Enter Title'
              onIonChange={(e) => setTitle(e.detail.value!)}
            ></IonInput>

            <IonLabel>Description</IonLabel>
            <IonTextarea
              value={description}
              placeholder='Enter Description'
              onIonChange={(e) => setDescription(e.detail.value!)}
            ></IonTextarea>
          </div>
          <div className='model_footer'>
            <IonItemGroup className='ion-align-self-end ion-padding'>
              <IonButton onClick={closeModal}>Cancel</IonButton>
              <IonButton onClick={createNewCollection}>Create</IonButton>
            </IonItemGroup>
          </div>
        </div> */}
        <IonPage id="home-page">
          <PageContent fullscreen>
            <PageWrapper>
            <IonRow>
              <IonCol>
                <PageTitle>{t('menu.add')}</PageTitle>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="title">
                <FieldSet style={title ? {border : '1px solid rgba(113,124,156,0.5)'} : {border : '1px solid rgb(234 5 5)'}}>
                  <Legend>
                    <LegendContainer>
                      <LegendLeftTitle>{t('menu.title')}</LegendLeftTitle>
                      <LegendSeparator></LegendSeparator>
                      <LegendRightTitle>
                        {title.length}/{t('menu.character')}
                      </LegendRightTitle>
                    </LegendContainer>
                  </Legend>
                  <ExperienceContent
                    placeholder={t('placeholder.title')}
                    value={title}
                    maxLength={60}
                    onChange={(e) => setTitle(e.target.value!)}
                  />
                </FieldSet>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="description">
                <FieldSet style={description ? {border : '1px solid rgba(113,124,156,0.5)'} : {border : '1px solid rgb(234 5 5)'}}>
                  <Legend>
                    <LegendContainer>
                      <LegendLeftTitle>{t('menu.desc')}</LegendLeftTitle>
                      <LegendSeparator></LegendSeparator>
                      <LegendRightTitle>
                        {description.length}/{t('menu.400')} {t('menu.char')}
                      </LegendRightTitle>
                    </LegendContainer>
                  </Legend>
                  <DescriptionContent data-replicated-value={description}>
                    <DescriptionTextArea
                      placeholder={t('placeholder.desc')}
                      value={description}
                      onChange={(e) => setDescription(e.target.value!)}
                      maxLength={400}
                    ></DescriptionTextArea>
                  </DescriptionContent>
                </FieldSet>
                </div>
              </IonCol>
            </IonRow>
            <div className='model_footer'>
            <IonRow>
              <IonCol>
                <NextButton>
                  <NextButtonLabel onClick={closeModal}>{t('option.cancel')}</NextButtonLabel>
                </NextButton>
                <NextButton>
                <NextButtonLabel onClick={createNewCollection}>{t('option.create')}</NextButtonLabel>
                </NextButton>
              </IonCol>
            </IonRow>
            </div>
          </PageWrapper>
            
          </PageContent>
        </IonPage>

      </Modal>
    </IonContent>
  );
};

const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;
  .main_model_wrapper {
    height: 100%;
  }
  .model_footer{
    display: flex;
    justify-content: center;
    text-align:center !important;
  }

  h1 {
    margin-top: 0.5rem;
    font-size: 2rem;
  }
  .title fieldset {
    height: 64px;
  }
  .description fieldset {
    height: 90px;
  }
  
  .description fieldset textarea {
    max-height: 50px;
    overflow-y: auto;
  }
  
`;

const Spinner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default SaveItemToNewCollectionModal;
