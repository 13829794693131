import React,{useEffect} from 'react';
import { IonCol, IonPage, IonRow } from "@ionic/react";
import useTranslations from '../../util/useTranslation';
// Icons
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-icon-right.svg";

// Components
import Footer from "../common/Footer";
import Header from "../common/experience-header/header";

// Style
import {
  ArrowRightIconWrapper,
  ExperienceBox,
  ExperienceBoxWrapper,
  ExperienceTitle,
  PageContent,
  PageDesc,
  PageTitle,
  PageWrapper,
} from "./NewExperience.style";

const NewExperience: React.FC = () => {
  const [t] = useTranslations('experience');
  const [td] = useTranslations('dashboard');

  return (
    <IonPage id="home-page">
      <Header showLeftIcon={false} />
      <PageContent fullscreen>
        <PageWrapper>
          <IonRow>
            <IonCol>
              <PageTitle>{t('new-experience.header')}</PageTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <PageDesc>
              {t('new-experience.sub-header')}
              </PageDesc>
            </IonCol>
          </IonRow>
          <ExperienceBoxWrapper>
            <Link to="tour" style={{ textDecoration: 'none' }}>
              <ExperienceBox>
                <ExperienceTitle>{td('items.tour')}</ExperienceTitle>
                <ArrowRightIconWrapper>
                  <ArrowRightIcon />
                </ArrowRightIconWrapper>
              </ExperienceBox>
            </Link>
            
            <Link to="exhibit" style={{ textDecoration: 'none' }}>
              <ExperienceBox>
                <ExperienceTitle>{td('items.exhibit')}</ExperienceTitle>
                <ArrowRightIconWrapper>
                  <ArrowRightIcon />
                </ArrowRightIconWrapper>
              </ExperienceBox>
            </Link>

            <Link to="story" style={{ textDecoration: 'none' }}>
              <ExperienceBox>
                <ExperienceTitle>{td('items.story')}</ExperienceTitle>
                <ArrowRightIconWrapper>
                  <ArrowRightIcon />
                </ArrowRightIconWrapper>
              </ExperienceBox>
            </Link>

            <Link to="longread" style={{ textDecoration: 'none' }}>
              <ExperienceBox>
                <ExperienceTitle>{td('items.longread')}</ExperienceTitle>
                <ArrowRightIconWrapper>
                  <ArrowRightIcon />
                </ArrowRightIconWrapper>
              </ExperienceBox>
            </Link>


            <Link to="seminar" style={{ textDecoration: 'none' }}>
              <ExperienceBox>
                <ExperienceTitle>{td('items.seminar')}</ExperienceTitle>
                <ArrowRightIconWrapper>
                  <ArrowRightIcon />
                </ArrowRightIconWrapper>
              </ExperienceBox>
            </Link>
          </ExperienceBoxWrapper>
        </PageWrapper>
      </PageContent>
      <Footer /> 
    </IonPage>
  );
};

export default NewExperience;
