import { IonCol, IonPage, IonRow, IonPicker } from "@ionic/react";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { addUpdateExperience, token2params } from '../../util/API'
import useTranslations from '../../util/useTranslation';
import useToken from "../../util/useToken";
import Item from '../../model/Item';
// Icons
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-icon-right.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
// Components
import Footer from "../common/Footer";
import Header from "../common/experience-header/header";
import { useHistory,Link, Route} from 'react-router-dom';
// Modal
import toursData from '../dashboard/tours.json';
import exhibitData from '../dashboard/exhibits.json';
import seminarsData from '../dashboard/seminars.json';
import Experience from "../../model/Experience"

// Style
import {
  ArrowRightIconWrapper,
  DescriptionContent,
  DescriptionTextArea,
  ExperienceContent,
  FieldSet,
  IconWrapper,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendRightTitle,
  LegendSeparator,
  NextButton,
  NextButtonLabel,
  PageContent,
  PageTitle,
  PageWrapper,
  Tag,
  TagContent,
  SavedLabel,
  EnterTag,
  SearchTagList,
  SearchTag
} from "./ExperienceDetails.style";
import { contrastOutline } from "ionicons/icons";

const ExperienceDetails: React.FC<{item:Item}> = ({item}) => {
  const itemType = useRouteMatch("/app/:type")?.params["type"];
  const [t] = useTranslations('experience');
  const [saved, setSaved] = useState(false)
  const [experience, setExperience] = useState<Experience>()
  const [tag, setTag] = useState('');
  const [allTags, setAllTags] = useState([
    { label: "Jerusalem", bgColor: "#717C9C" },
    { label: "Shalom", bgColor: "#717C9C" },
    { label: "Israeli", bgColor: "#717C9C" },
    { label: "Hebrew", bgColor: "#717C9C" },
    { label: "Torah", bgColor: "#717C9C" },
    { label: "Shabbat", bgColor: "#717C9C" },
    { label: "Mazeltov", bgColor: "#717C9C" },
    { label: "Rosh Hashana", bgColor: "#717C9C" },
    { label: "Synagogue", bgColor: "#717C9C" },
    { label: "Jewish food", bgColor: "#717C9C" },
    { label: "Menorah", bgColor: "#717C9C" },
    { label: "Jewish life", bgColor: "#717C9C" },
    { label: "Judaica", bgColor: "#717C9C" },
    { label: "Yom Kippur", bgColor: "#717C9C" },
    { label: "Mitzvah", bgColor: "#717C9C" },
    { label: "Rabbi", bgColor: "#717C9C" },
  ])
  const [searchTags, setSearchTags] = useState(allTags)
  const [tags, setTags] = useState<any>([]);
  const [itemId, setItemId] = useState<string>("");
  const history = useHistory<{taskId:any,id?:any,data?:any}>();
  const [expContent, setExpContent] = useState("");
  const [desc, setDesc] = useState('');
  const [focus, setFocus] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [token,setToken] = useToken();

  useEffect(()=>{
    const taskId=history.location.state;
    if(taskId !== undefined && taskId !== null)
    {
      setExpContent(taskId.data.title);
      setDesc(taskId.data.description);
      setTags(taskId.data.tags);
      setItemId(taskId.id);
    }
    setSaved(false);
    
  },[])
  const removeTag = (index: number) =>
    setTags(tags.filter((_:any, i:any) => i !== index));
  
  const saveExperience = async() => {
    setSubmitted(true);
    if(!expContent || !desc || tags.length == 0) {
      //Form invalid
    } else {
      let newexhibit = {
        id: itemId,
        title : expContent,
        description: desc,
//        tags: tags.map((tag) => tag["label"]),
        tags: tags,
        type: itemType
      }
      addUpdateExperience(newexhibit, token).then(({data}) => {
        setItemId(data.experience_id);
        newexhibit.id = data.experience_id;
        setExperience(newexhibit);
        setSaved(true);  
      })
        
      if(history.location.state !== undefined && history.location.state !== null)
      {
        const data=itemId=='tour'?[...toursData.results]
        :itemId=='exhibit'?[...exhibitData.results]:[...seminarsData.results] ;    
        let index=data.findIndex((i)=>i.id==history.location.state.data.id);
        data[index].title=expContent;
        data[index].description=desc;
        data[index].tags=tags;
      }
           
    }    
  }

  const handleEnteredTag = (e : any) => {
    if(e && e.key == 'Enter'){
      addTag(tag,"#0849ff");
    }
  }

  const addTag = (name : string, bgColor : string) => {
    let tagexist = tags.find((t:any) => t.label.toLowerCase() === name.toLowerCase())

      if (tagexist) {
        alert(t('alerts.tag'))
      } else {
        let newTag = {
          label: name,
          bgColor: bgColor
        }
        name.trim() ? tags.length < 30 ? setTags([...tags,newTag]) : alert(t('alerts.max_tag')) : alert(t('alerts.tag_name'))
        setTag('')
      }
  }

  const handleChangeTag = (e : any) => {
    setTag(e.target.value)
    let searchtag = e.target.value
    if (searchtag) {
      let searchableTags =  allTags.filter(t => t.label.trim().toLowerCase().includes(searchtag.trim().toLowerCase()))
      setSearchTags(searchableTags);
    } else {
      setSearchTags(allTags);
    }
  }

  return (
    <IonPage id="home-page">
      <Header showLeftIcon={true} />
      <PageContent fullscreen>
        { !saved ? 
        <PageWrapper>
        <IonRow>
          <IonCol>
            <PageTitle>{t('virtual-exhibit.header')}</PageTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FieldSet style={(submitted && !expContent) ? {border:'1px solid rgb(243 0 0)'} : { border:'1px solid rgba(113, 124, 156, 0.5)'}}>
              <Legend>
                <LegendContainer>
                  <LegendLeftTitle>{t('virtual-exhibit.name')}</LegendLeftTitle>
                  <LegendSeparator></LegendSeparator>
                  <LegendRightTitle>
                    {expContent.length}/{t('common.character')}
                  </LegendRightTitle>
                </LegendContainer>
              </Legend>
              <ExperienceContent
                placeholder={t('virtual-exhibit.name-placeholder')}
                value={expContent}
                onChange={(e) => setExpContent(e.target.value)}
                maxLength={60}
              />
            </FieldSet>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FieldSet style={(submitted && !desc.length) ? {border:'1px solid rgb(243 0 0)'} : { border:'1px solid rgba(113, 124, 156, 0.5)'}}>
              <Legend>
                <LegendContainer>
                  <LegendLeftTitle>{t('virtual-exhibit.description')}</LegendLeftTitle>
                  <LegendSeparator></LegendSeparator>
                  <LegendRightTitle>
                    {desc.length}/{400} {t('common.char')}
                  </LegendRightTitle>
                </LegendContainer>
              </Legend>
              <DescriptionContent data-replicated-value={desc}>
                <DescriptionTextArea
                  placeholder={t('virtual-exhibit.description-placeholder')}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  maxLength={400}
                ></DescriptionTextArea>
              </DescriptionContent>
            </FieldSet>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <FieldSet style={(submitted && !tags.length) ? {border:'1px solid rgb(243 0 0)'} : { border:'1px solid rgba(113, 124, 156, 0.5)'}}>
              <Legend>
                <LegendContainer>
                  <LegendLeftTitle>{t('virtual-exhibit.tags')}</LegendLeftTitle>
                  <LegendSeparator></LegendSeparator>
                  <LegendRightTitle>{tags.length}/{t('common.tags')}</LegendRightTitle>
                </LegendContainer>
              </Legend>
              <TagContent>
                {tags.map((tag:any, i:any) => (
                  <Tag key={i} theme={{ bgColor: tag.label?tag.bgColor:'#717c9c'}}>
                    {tag.label?tag.label:tag}
                    <IconWrapper onClick={(e) => removeTag(i)}>
                      <CloseIcon />
                    </IconWrapper>
                  </Tag>
                ))}
              </TagContent>
              <EnterTag
                placeholder={t('virtual-exhibit.tags-placeholder')}
                value={tag}
                onChange={handleChangeTag}
                onKeyDown={handleEnteredTag}
                onFocus={() => setFocus(true)}
                onBlur={() => setTimeout(() => {
                  setFocus(false)
                }, 300)}
              >
              </EnterTag>
              {focus ? 
              <SearchTagList>
              {searchTags.map((tg, j) => (
                  <SearchTag onClick={() => addTag(tg.label, tg.bgColor)}>
                    {tg.label}
                  </SearchTag>
                ))}
            </SearchTagList>
             : null}
              
            </FieldSet>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ textAlign: "right" }}>
            <NextButton>
              <NextButtonLabel onClick={saveExperience}>{t('virtual-exhibit.next-step')}</NextButtonLabel>
              <ArrowRightIconWrapper>
                <ArrowRightIcon />
              </ArrowRightIconWrapper>
            </NextButton>
          </IonCol>
        </IonRow>
      </PageWrapper>
      :
      <Route
      render={() => {
        let editorRoute = '/';
        if (itemType == 'longread') {
          editorRoute = `/editors/longreads?id=${itemId}`
        } else if(itemType == 'story' || itemType == 'exhibit') {
          editorRoute = `/editors/blocks/${itemType}.html?id=${itemId}`;
        } else if (itemType == 'tour') {
          editorRoute = `https://storymap.jhn.ngo/edit/?id=${itemId}`;
        }
        window.location.replace(editorRoute);
        return null;
      }}
    />
      /* <PageWrapper>
        <IonRow>
          <IonCol>
            <SavedLabel>
              {t('virtual-exhibit.save-message')}
            </SavedLabel>
          </IonCol>
        </IonRow>
      </PageWrapper> */
      }
        
      </PageContent>
      <Footer />
    </IonPage>
  );
};

export default ExperienceDetails;
