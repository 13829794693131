import React, {useState } from 'react';
import styled from 'styled-components';
import {IonModal} from '@ionic/react';
import { NavLink ,Link,useLocation} from 'react-router-dom';
import useTranslations from '../../util/useTranslation';
import { uploadNewItem} from '../../util/API';
import { IonCol, IonRow } from "@ionic/react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
// Style
import {
    ExperienceContent,
    FieldSet,
    IconWrapper,
    Legend,
    LegendContainer,
    LegendLeftTitle,
    LegendRightTitle,
    LegendSeparator,
    PageWrapper,
    Tag,
    SaveButton,
    SaveButtonLabel,
    TagContent,
    SavedLabel,
    ChoosenFile,
    EnterTag,
    SearchTagList,
    SearchTag
  } from "./UploadNewItem.style";
  
  import { makeStyles } from '@material-ui/core/styles';
  import AppBar from '@material-ui/core/AppBar';
  import CssBaseline from '@material-ui/core/CssBaseline';
  import Toolbar from '@material-ui/core/Toolbar';
  import Typography from '@material-ui/core/Typography';
  import ClearIcon from '@material-ui/icons/Clear';

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
}));

const UploadNewItem: React.FC<{
    viewUploadModal: boolean;
    closeUploadModal:() => void;
}> = ({viewUploadModal, closeUploadModal}) => {
    const classes = useStyles();
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [tag, setTag] = useState('');
    const [allTags, setAllTags] = useState([
      { label: "20th Century", bgColor: "#717C9C" },
      { label: "Jewish women", bgColor: "#717C9C" },
      { label: "Feminism", bgColor: "#717C9C" },
      { label: "Women's Rights", bgColor: "#717C9C" },
      { label: "Suffrage", bgColor: "#717C9C" },
    ])
    const [searchTags, setSearchTags] = useState(allTags)
    const [tags, setTags] = useState<any>([]);
    const [t] = useTranslations('experience');
    const [focus, setFocus] = useState(false)
    const [title ,setTitle] = useState("");
    const [desc, setDesc] = useState('');
    const onFileChange = (event) => {
        
        const Files = event.target.files;
        var selectedFile: any = []
        for(var i=0; i < Files.length; i++){
            console.log(`Files of ${i} is - ${Files[i]}`)
            selectedFile.push(Files[i])
        }

        setSelectedFiles([...selectedFiles, ...Files])
        // setTimeout(() => {
        //     console.log("Selected Files ::-",selectedFiles);
        // }, 5000);
    }
    const handleEnteredTag = (e : any) => {
        if(e && e.key == 'Enter'){
          addTag(tag,"#0849ff");
        }
      }
    
      const addTag = (name : string, bgColor : string) => {

        let tagexist = tags.find((t:any) => t.label.toLowerCase() === name.toLowerCase())
    
          if (tagexist) {
            alert(t('alerts.tag'))
          } else {
            let newTag = {
              label: name,
              bgColor: bgColor
            }
            name.trim() ? tags.length < 30 ? setTags([...tags,newTag]) : alert(t('alerts.max_tag')) : alert(t('alerts.name_tag'))
            setTag('')
          }
      }
    
      const handleChangeTag = (e : any) => {
        setTag(e.target.value)
        let searchtag = e.target.value
        if (searchtag) {
          let searchableTags =  allTags.filter(t => t.label.trim().toLowerCase().includes(searchtag.trim().toLowerCase()))
          setSearchTags(searchableTags);
        } else {
          setSearchTags(allTags);
        }
      }
      const removeTag = (index: number) =>
      setTags(tags.filter((_:any, i:any) => i !== index));

      const removeChoosenFile=(index: number)=>
      {
      setSelectedFiles(selectedFiles.filter((_:any, i:any) => i !== index));
      }
      const _SaveNewItem=()=>
      {
         console.log("Data to be saved is",{title,desc,tags,selectedFiles});
         //uploadNewItem()
      }
    return(
        <div>
          {console.log("LOCATION",useLocation().pathname)}
            <Modal1 isOpen={viewUploadModal ? true : false} onDidDismiss={() => {closeUploadModal()}}>
            <CssBaseline />
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                <div className='Top-bar'>
                    <Typography variant='h6' noWrap>
                     {t('upload')}
                    </Typography>
                    {
                    <ClearIcon onClick={() => 
                    // closeModal(results)
                    {closeUploadModal()}
                    }></ClearIcon>
                    }
                    
                </div>
                </Toolbar>
            </AppBar>
                <PageWrapper className="upload-content-wrapper">
                <IonRow>
                    <IonCol>
                        <FieldSet style={{ border:'1px solid rgba(113, 124, 156, 0.5)'}}>
                            <Legend>
                                <LegendContainer>
                                    <LegendLeftTitle>{t('common.title')}</LegendLeftTitle>
                                    <LegendSeparator></LegendSeparator>
                                    <LegendRightTitle>
                                        {title.length}/{t('common.tags0')}
                                    </LegendRightTitle>
                                </LegendContainer>
                            </Legend>
                            <ExperienceContent
                                placeholder={t('placeholder.title')}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                maxLength={60}
                            />
                        </FieldSet>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <FieldSet style={{ border:'1px solid rgba(113, 124, 156, 0.5)'}}>
                            <Legend>
                                <LegendContainer>
                                    <LegendLeftTitle>{t('common.description')}</LegendLeftTitle>
                                    <LegendSeparator></LegendSeparator>
                                    <LegendRightTitle>
                                        {desc.length}/{t('common.tags1')}
                                    </LegendRightTitle>
                                </LegendContainer>
                            </Legend>
                            <ExperienceContent
                                placeholder={t('placeholder.desc')}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                maxLength={400}
                            />
                        </FieldSet>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <FieldSet style={{ border:'1px solid rgba(113, 124, 156, 0.5)'}}>
                            <Legend>
                                <LegendContainer>
                                    <LegendLeftTitle>{t('common.tag')}</LegendLeftTitle>
                                    <LegendSeparator></LegendSeparator>
                                    <LegendRightTitle>
                                    {tags.length}/{t('common.tags')}
                                    </LegendRightTitle>
                                </LegendContainer>
                            </Legend>
                            <TagContent>
                {tags.map((tag:any, i:any) => (
                  <Tag key={i} theme={{ bgColor: tag.bgColor }}>
                    {tag.label}
                    <IconWrapper onClick={(e) => removeTag(i)}>
                      <CloseIcon />
                    </IconWrapper>
                  </Tag>
                ))}
              </TagContent>
              <EnterTag
                placeholder={t('placeholder.tags')}
                value={tag}
                onChange={handleChangeTag}
                onKeyDown={handleEnteredTag}
                onFocus={() => setFocus(true)}
                onBlur={() => setTimeout(() => {
                  setFocus(false)
                }, 300)}
              >
              </EnterTag>
              {focus ? 
              <SearchTagList>
              {searchTags.map((tg, j) => (
                  <SearchTag onClick={() => addTag(tg.label, tg.bgColor)}>
                    {tg.label}
                  </SearchTag>
                ))}
            </SearchTagList>
             : null}
                        </FieldSet>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <input type="file" multiple onChange={(e) => onFileChange(e)}/>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <h3>{t('file')}</h3>
                        {(selectedFiles.map((file : File,id:any) => {
                            return(
                            <ChoosenFile key={id}  theme={{ bgColor: "#0849ff" }}>
                            {file.name}
                            <IconWrapper style={{marginLeft:10}} onClick={(e) => removeChoosenFile(id)}>
                              <CloseIcon />
                            </IconWrapper>
                            </ChoosenFile>
                                
                            )
                        }))}
                    </IonCol>
                </IonRow>
                </PageWrapper>
                <SaveButton onClick={()=>_SaveNewItem()}>
                    <SaveButtonLabel>{t('save')}</SaveButtonLabel>
                </SaveButton>
            </Modal1>
        </div>
    )
}

const Modal1 = styled(IonModal)`
      --height: 100%;
      --width: 100%;
      --border-radius: 0.25rem;
      --font-family: "Blooming Elegant", sans-serif;
      
      a,button,select,li,svg{
        cursor: pointer;
      }
    
      .item-details-footer>div .chooseMe{
        text-align: right;
      }
      
      .modal-wrapper.sc-ion-modal-md {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        opacity: 0.01;
        width: 85%;
        height: 94vh;
        background-color: #fefefe;
      }
      .Top-bar {
        display: flex;
       justify-content: space-between;
        width: 100%;
        align-items: center;
      }
      
      .MuiButton-containedPrimary {
        color: rgb(0 0 0 / 87%);
        background-color: #fac807;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        margin: 15px auto !important;
        text-transform: capitalize;
      }
      
      header.MuiPaper-root{
        background-color: #0c3cf7;
        border-radius: 3px;
      }
      .arow-btn{
        position: absolute;
        top: 27% !important;
        right: 5% !important;
        z-index: 1;
       color: #0c3cf7;
      }
      .upload-content-wrapper{
        width: 95%;
        max-width: 95%;
        margin: 60px auto 10px;
      }
      }`

export default UploadNewItem