import React, { useState } from 'react';
import Item from '../../../model/Item';
import useTranslations from '../../../util/useTranslation';
import SaveItemMenu, { MenuSelection } from './Menu';
import SaveItemToExistingCollectionModal from './SaveItemToExistingCollectionModal';
import ChooseSampleContentItem from './ChooseContentItem';
import SaveItemToNewCollectionModal from './SaveItemToNewCollectionModal';

export interface SaveItemActionsState {
  item?: Item;
  event?: Event;
  dismiss: () => void;
}

const SaveItemActions: React.FC<SaveItemActionsState> = ({
  item,
  event,
  dismiss,
}) => {
  const [menuSelection, setMenuSelection] = useState<MenuSelection>(null);
  const [ex] = useTranslations('item','search','save-item');
  return (
    <>
      {!!event && (
        <SaveItemMenu
          {...{ event, dismiss, menuSelection, setMenuSelection }}
        />
      )}
      {menuSelection === 'existing' && (
        <SaveItemToExistingCollectionModal
          item={item}
          showModal={menuSelection === 'existing'}
          closeModal={() => setMenuSelection(null)}
        />
      )}

      {menuSelection === 'new' && (
        <SaveItemToNewCollectionModal
        item={item}
        showModal={menuSelection === 'new'}
        closeModal={() => setMenuSelection(null)}/>
      )}
    </>
  );
};

export default SaveItemActions;
