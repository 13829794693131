import axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';
import User from '../model/User';
import Collection from '../model/Collection';
import CollectionsResponse from '../model/CollectionsResponse';
import ItemsResponse from '../model/ItemsResponse';
import Experience from '../model/Experience'
import Token from '../model/Token';
import ExperiencesResponse from '../model/ExperiencesResponse';
import SubjectResponse from '../model/SubjectResponse';
import ProjectsResponse from '../model/ProjectsResponse';
import LocationsResponse from '../model/LocationsResponse';

// const { REACT_APP_API_URL = 'https://dj-story.jhn.ngo/api/v1' } =
//   process.env;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const ITEMS_PATH = '/items';
const RECOMMEND_PATH = '/recommend';
const COLLECTIONS_PATH = '/collections';
const EXPERIENCES_PATH = "/experiences";
const SUBJECTS_PATH = "/subjects";
const TAGS_PATH = "/tags"
const LOGIN_PATH = "/auth/login";
const REFRESH_PATH = "/auth/refresh";
const LOGOUT_PATH = "/auth/logout";
const USERS_PATH='/users';
const LOCATION_PATH = "/locations";
const PROJECT_PATH = "/projects";


const DEFAULT_SOURCE = "JHN"


function token2params(token:Token) {
  if (!token?.access_token) {
    return '';
  }
  return `access_token=${token?.access_token}&refresh_token=${token?.refresh_token}&expires=${token?.expires}`
}

function lang2param() {
  return `lang=${localStorage.getItem('language')}`
}

function idslug2param(idOrSlug) {
  return isNaN(idOrSlug) ? `slug=${idOrSlug}` : `id=${idOrSlug}`;
}

const http = axios.create({
  baseURL: REACT_APP_API_URL,
  responseType: 'json',
});

const login = async (username: string, password: string) => {
  return await http.post<Token>(LOGIN_PATH, {
      email: username,
      password: password
  });
};

const refreshToken = async (token: Token) => {
  return await http.post<Token>(REFRESH_PATH, {
    refresh_token: token.refresh_token 
  });
}

const queryItems = async (
  query: string,
  source: string = ""
): Promise<AxiosResponse<ItemsResponse>> => {
  if (!source) {
    source = DEFAULT_SOURCE;
  }
  return await http.get<ItemsResponse>(`${ITEMS_PATH}?${query}&source=${source}`);
};

const queryExperiences = async (
  query: string
): Promise<AxiosResponse<ItemsResponse>> => {
  return await http.get<ItemsResponse>(`${EXPERIENCES_PATH}?${lang2param()}&${query}`);
};

const getHoistingItem = async (): Promise<any[]> => {
   return [
    {
      id: 1,
      name: 'Salonica: The Balkan Jerusalem',
      link: 'Public share link',
      date: '8 April',
      time: '6:30 pm',
      guestCount: 5,
      ref: ' Salonica: The Balkan Jerusalem',
      buttonName: 'Start Event',
    },
    {
      id: 2,
      name: 'Lubartowska street',
      link: 'Public share link',
      date: '9 April',
      time: '7:00 pm',
      guestCount: 7,
      ref: " Lubartowska street",
      buttonName: 'Start Event',
    }
  ];  
};

const getInvitedAsGuestItem = async (): Promise<any[]> => {
  return [
    {
      id: 1,
      name: 'Live Centropa Jewish Berlin Tour',
      link: 'Public share link',
      date: '8 April',
      time: '6:30 pm',
      guestCount: 5,
      ref: ' Centropa Jewish Berlin Tour',
      buttonName: 'Join Now',
    },
    {
      id: 2,
      name: 'Today and Former Jewish life in Hannover',
      link: 'Public share link',
      date: '8 April',
      time: '6:30 pm',
      guestCount: 5,
      ref: 'Today and Former Jewish life in Hannover',
      buttonName: 'Join Now',
    },
  ];
};

const getGuestEmailItem = async (): Promise<any[]> => {
  return [
    {
      id: 1,
      email: 'alexander.raginsky@gmail.com',
    },
    {
      id: 2,
      email: 'pavel@jhn.ngo',
    },
  ];
};

const getItem = async (id: string, source: string = "", lang: string = 'en'): Promise<AxiosResponse<ItemsResponse>> => {
  if (!source) {
    source = DEFAULT_SOURCE;
  }
  // Not using {params: {id}} because axios will encode slashes in id once again
  return await http.get<ItemsResponse>(`${ITEMS_PATH}?id=${id}&source=${source}&lang=${lang}`);
};

const getRelatedItems = async (
  id: string,
  source: string = ""
): Promise<AxiosResponse<ItemsResponse>> => {
  if (!source) {
    source = DEFAULT_SOURCE;
  }
  return await http.get<ItemsResponse>(`${RECOMMEND_PATH}?item_id=${id}&source=${source}`);
};

const getCollections = async (token: Token, withDetails: boolean = false) => {
  try{
    return await http.get<CollectionsResponse>(
      `${COLLECTIONS_PATH}?details=${withDetails ? 1 : 0}&${token2params(token)}`
    )
  }catch(e) {
    return {data: { collections: []}} 
  }
};

const updateCollection = async (collection: Collection, token: Token) => {
  return await http.post(`${COLLECTIONS_PATH}?${token2params(token)}`, {
    payload: collection,
  });
};

const createCollection = async (request: Collection, token: Token) => {
  return await http.post(`${COLLECTIONS_PATH}?${token2params(token)}`, {
    payload: request
  })
}

const deleteCollectionItem = async (collection: any, token: Token) => {
  return await http.post(`${COLLECTIONS_PATH}?${token2params(token)}`, {
    payload: collection,
  });
}

const addUpdateExperience = async (exhibit : Experience, token: Token) => {
  return await http.post(`${EXPERIENCES_PATH}?${token2params(token)}`, {
    payload: exhibit
  });
}
const uploadNewItem = async (request: Collection, token: Token) => {
  return await http.post(`${COLLECTIONS_PATH}?${token2params(token)}`, {
    payload: request
  });
}

const getExperience = async (expIdOrSlug: any) => {
  return await http.get(`${EXPERIENCES_PATH}?${idslug2param(expIdOrSlug)}&${lang2param()}`);
}

const getMyExperiences = async (token: Token, type?: any) => {
  try{
    return await http.get<ExperiencesResponse>(
      `${EXPERIENCES_PATH}?type=${type}&${token2params(token)}&${lang2param()}&my-only=true`
    )
  }catch(e) {
    return {data: { experiences: []}} 
  }
}

const deleteExperience = async (itemId, token: Token) => {
   return await http.delete(`${EXPERIENCES_PATH}?id=${itemId}&${token2params(token)}`);
}

const exploreExperiences = async (featured?: any, limit?: any, page?: any, project?: any) => {
  const featured_param =  featured == undefined ? '' : `featured=${featured}`;
  const limit_param = limit == undefined ? '' : `limit=${limit}`;
  const page_param = page == undefined ? '' : `page=${page}`;
  const project_param = project == undefined ? '' : `project=${project}`;

  try{
    return await  http.get<ExperiencesResponse>(
      `${EXPERIENCES_PATH}?${lang2param()}&${featured_param}&${limit_param}&${page_param}&${project_param}`
    )
  }catch(e) {
    return {data: { experiences: []}} 
  }
}

const getSubjects = async () => {
  return await http.get<SubjectResponse>(`${SUBJECTS_PATH}?${lang2param()}`);
}

const getProjects = async (pId: any) => {
  return await http.get<ProjectsResponse>(`${PROJECT_PATH}?${idslug2param(pId)}&${lang2param()}`);
}

const getLocations = async (pId: any) => {
  return await http.get<LocationsResponse>(`${LOCATION_PATH}?${idslug2param(pId)}&${lang2param()}`);
}

const getCollectionList = async (token: Token, withDetails: boolean = false,collectionId) => {
  return await http.get<CollectionsResponse>(
    `${COLLECTIONS_PATH}?id=${collectionId}&details=${withDetails ? 1 : 0}&${token2params(token)}`
  );
};

const getTags = async (query : string) => {
  return await http.get(`${TAGS_PATH}?${query}`);
}

const getCurrentUser = async (token: Token) => {
  return await http.get<User>(`${USERS_PATH}?${token2params(token)}`);
}

const updatePassword = async (newPassword: string, token: Token) => {
  return await http.patch(`${USERS_PATH}?${token2params(token)}`, {
    payload: {
      password: newPassword
    }
  });
}

const updateLanguage = async (language: string, token: Token) => {
  return await http.patch(`${USERS_PATH}?${token2params(token)}`, {
    payload: {
      language: language
    }
  });
}

const updateGeneralInfo = async (name: string, email:  string, organization: string, token: Token) => {
  return await http.patch(`${USERS_PATH}?${token2params(token)}`, {
    payload: {
      first_name: name,   // TODO: split name into first and last name
      email: email,
      organization: organization
    }
  });
}

export {
  login,
  refreshToken,
  token2params,
  updatePassword,
  updateLanguage,
  updateGeneralInfo,
  getCurrentUser,
  queryItems,
  getItem,
  getSubjects,
  exploreExperiences,
  getRelatedItems,
  queryExperiences,
  getCollections,
  getCollectionList,
  uploadNewItem,
  updateCollection,
  createCollection,
  addUpdateExperience,
  getMyExperiences,
  getExperience,
  deleteExperience,
  getTags,
  deleteCollectionItem,
  getLocations,
  getProjects,
  getHoistingItem,
  getInvitedAsGuestItem,
  getGuestEmailItem,
};