import { IonSpinner } from '@ionic/react';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';
import StarRateIcon from '@material-ui/icons/StarRate';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import getSymbolFromCurrency from 'currency-symbol-map';
import { LatLngBounds } from 'leaflet';
import React, { useEffect, useState } from 'react';
// import TextsmsIcon from '@material-ui/icons/Textsms';
import { FaCommentDots } from 'react-icons/fa';
import { getPriceClass, getPriceText } from '../../util/Utils';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {
  MapContainer,
  Marker, TileLayer, useMap
} from 'react-leaflet';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ItemType } from '../../model/ItemType';
import CardComponent from '../../pages/Home/Card';
import Footer from '../../pages/Home/Footer';
import Header from '../../pages/Home/Header';
import MobileCardNowMore from '../../pages/Home/MobileViewCard';
import { exploreExperiences, getExperience } from '../../util/API';
import useTranslations from '../../util/useTranslation';
import PaypalButton from '../PaypalButton/PaypalButton';
import MobileCard from './mobileViewCard';
import ExperienceBrowse from '../ExperienceBrowse/ExperienceBrowse';
import {Helmet} from "react-helmet";
import dateFormat, { masks } from "dateformat";


const Experience: React.FC = () => {
  let [exps, setExps] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [groupData, setGroupData] = useState<any>([]);

  const [spinner, setSpinner] = useState(false);
  const [ds] = useTranslations('dashboard');
  const [expdata, setexpdata] = useState<any>({});
  const [mapRef, setMapRef] = useState<any>(null);
  const [t] = useTranslations('experience');
  let experiencecLocation: any = [];
  const { params } = useRouteMatch<{ id?: string }>();
  const [card] = useState<any>(params?.id);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [bbox, setBbox] = useState<any>();
  const zoom = 8;


  let [expTypes, setExpTypes] = useState<ItemType[]>([
    'tour',
    'exhibit',
    'story',
    'longread',
    'seminar',
    '3d-tour'
  ]);


  const formatDate = (date: string) => {
    console.log("Date: " + date);
    const format = new Date(date).getHours() == 0 ? "dd/mm/yy" : "dd/mm/yy H:MM";
    return dateFormat(date, format);
  }

  const fetchExperienceData = async () => {
    setSpinner(true);
    const {
      data: { experiences },
    } = await getExperience(params?.id);
    setExps(experiences);
    setexpdata(experiences[0]);
    setGroupData(groupExperiences(experiences[0]));

    fetchRecommendedExperiences(experiences[0].project?.id)
    setSpinner(false);

  };

  const fetchRecommendedExperiences = async (project) => {
    let numExperiences = 50;
    const {
      data: { experiences },
    } = await exploreExperiences(true, numExperiences, undefined, project);
    // TODO: get proper recommendations
    let random = Math.floor(Math.random() * (experiences.length - 3 - 1) + 1);
    console.log("Recommended experiences: " + JSON.stringify(experiences));
    let recommendedExps = experiences.filter(exp => exp.slug != params?.id).slice(random, random+3);
    setAllData(recommendedExps);
  };


  const groupExperiences = (exp) => {
    let groups = {};
    if (!exp.includes) {
      return groups;
    }
    
    exp.includes.map((inc_exp, index) => {
      let top_key = inc_exp.start_date ? dateFormat(inc_exp.start_date, "dd/mm/yy") : "";
      top_key += inc_exp.locations[0].locations_id.slug;
      if (!(top_key in groups)) {
        groups[top_key] = {};
      }
      let sec_key = new Date(inc_exp.start_date).getHours() != 0 ? dateFormat(inc_exp.start_date, "H:MM") : "" 
      if (!(sec_key in groups[top_key])) {
        groups[top_key][sec_key] = [];
      }
      groups[top_key][sec_key].push(inc_exp);
    });
    return groups;
  }

  const renderIncludedExperiences = () => {
    if (!groupData) {
      return null;
     }

    let render: any[] = []

    // Generate list of experiences
    for(const inc_data in groupData) {
      let showLocation = true;
      for (const hour in groupData[inc_data]) {  
        let showHour = true;     
        groupData[inc_data][hour].forEach((element, k) => {
          render.push(renderIncludedExperience(element, showLocation, showHour));
          showLocation = showHour = false;
        });
      }
      render.push(<hr/>);
    } 
    return (<div>{render}</div>)
  }      

  const renderIncludedExperience = (inc_exp, showLocation, showHour) => {
    let hours = dateFormat(inc_exp.start_date, "H:MM");
    return (
          <div>
            { showLocation ? 
            <h2 className="datelocation">
                {inc_exp.start_date ? dateFormat(inc_exp.start_date, "mmmm dS, yyyy") + " - " : null} 
                {inc_exp.locations[0] ?  
                  <NavLink to={'/locations/' + inc_exp.locations[0].locations_id.slug} className="datelocation" target="_blank">
                      {inc_exp.locations[0].locations_id.title} <ArrowRightAltIcon  />
                  </NavLink>
              : null}
            </h2> :  null }
            {showHour ? 
            <h2 className="hour">
              {inc_exp.start_date && hours != "0:00" ? hours : null} 
            </h2> : null}
            <div className="name-description">
              <h3 className="name">
                <NavLink to={'/experiences/' + inc_exp.slug} target="_blank" >
                          {inc_exp.title} <ArrowRightAltIcon  />
                </NavLink>
              </h3>
              <p>
                {inc_exp.description}
              </p>
              <div dangerouslySetInnerHTML={{__html: inc_exp.content}}></div>
            </div>
          </div>  
      )
  };

  useEffect(() => {
    fetchExperienceData();
  }, [params?.id, bbox]);

  const renderMap = () => {
    //const subExps = groupData;
    experiencecLocation = exps.filter((i) => i.location);
    const bounds =
      (experiencecLocation.length &&
        new LatLngBounds(
          experiencecLocation.reduce(
            (acc: any, { location: { lat, lng } }: any) =>
              acc.push([lat, lng]) && acc,
            []
          )
        )) ||
      undefined;
    console.log("groupData" + JSON.stringify(groupData));   
    return (
      bounds && (
        <div className='map-main-wrap'>
          <div className='map-img' style={{ borderRadius: '20px' }}>
            {expdata?.map_url ?
              <iframe width="100%" height="100%" frameBorder="0" src={expdata.map_url} />
              :
              <ExperienceBrowse isShowExperienceMap hideTitle allData={expdata.includes?.length ? expdata.includes.sort((a,b) => a.id-b.id): [expdata]} handleBboxdata={(value) => setBbox(value)} zoom={zoom}/>
             
            }
          </div>
        </div>
      )
    );
  };

  const ImageWrap = (props: any) => {
    const { image, className } = props;
    const imgClass = className ? className : "img-wrap";
    return (
      <div className={imgClass}>
        {image ? <img src={image} alt='img' /> : null}
      </div>
    );
  };

  const Platforms = () => {
    return (
      <>
        <h2>
          {t('new-experience.join', {
            price: getPriceText(expdata, t),
          })}
        </h2>
        {(!expdata.price || expdata.price.value == 0 || paymentStatus) ?
          (<>
            {['', '_1', '_2'].map((i) =>
              expdata[`platform${i}`]
                ?
                <Button
                  disabled={!expdata[`platform${i}_url`]}
                  onClick={() => window.open(expdata[`platform${i}_url`], '_blank')}
                  className='Ops-rectangle d-flex'
                >
                  {/* <EqualizerIcon /> */}
                  <img src={expdata[`platform${i}`].logo} alt='' />
                  <span className='izi-travel'>
                    {expdata[`platform${i}`].title}
                  </span>
                </Button>
                : null
            )}
          </>)
          : (<PaypalButton price={expdata.price.value} onSend={(data) => receivedData()} />)
        }
      </>
    )
  }


  const receivedData = () => {
    setPaymentStatus(true)
  };

  const pageTitle = () => {
    var title = expdata?.title;
    if (expdata?.locations?.length > 0) {
      title = title + " | " + expdata?.locations[0].locations_id.title;
    }
    return title;
  }

  return (
    <ExperienceWrap>
      <Helmet>
        <title>{pageTitle()}</title>
        <meta name="og:title" content={pageTitle()} />
        <meta name="og:description" content={expdata?.description} />
        <meta name="og:image" content={expdata.images && (expdata?.images[0] ?? expdata.images.length > 0 ) } />
      </Helmet>
      <div className='main'>
        <style>{css}</style>
        <Header />
        {spinner && (
          <div className='spinner'>
            <IonSpinner color='primary' />
          </div>
        )}

        <div className='header-bg'>
          <div className='container'>
            {/* <span className='rectangle desktopview'>Free</span> */}
            {/* <div className='rectangle notFree desktopview'>9,99€</div> */}
            <div className='d-flex title-wrap'>
              <div className='d-flex jewish-women-in-the'>
                <span className="experience-main-heading">{expdata?.title}</span>
                <span className={getPriceClass(expdata)}>{getPriceText(expdata, t)}</span>
                {/* <div className='rectangle notFree'>9,99€</div> */}
              </div>
            </div>
            <div
              className='d-flex'
              style={{ alignItems: 'center', flexWrap: 'wrap' }}
            >
              <div className='virtual-exhibit'>
                {expTypes.map((i) =>
                  i == expdata?.type
                    ? `${ds(`experienceitems.${i}`)}`
                    : null
                )}
              </div>
              <div className='online-wrap first'>
                <RoomIcon />
                <span className='online'>
                  {expdata?.locations?.length > 0
                    ? <Link to={'/locations/' + expdata?.locations[0].locations_id.slug}> {expdata?.locations[0].locations_id.title} </Link>
                    : ds('not-provide')}
                </span>
              </div>
              {expdata.duration ? <div className='online-wrap'>
                <WatchLaterIcon />
                <span className='online'>{expdata.duration} {t('duration.minutes')}</span>
              </div> : null }
              {expdata.start_date ? <div className='online-wrap'>
                <WatchLaterIcon />
                <span className='online'>{formatDate(expdata.start_date)}
              {expdata.end_date ? ' - ' + formatDate(expdata.end_date) : null}  
                </span>
              </div> : null }

              {expdata.size ? <div className='online-wrap'>
                <FaCommentDots style={{ fontSize: '40px' }} />
                <span className='online'>{expdata.size?.value} {t('size.' + expdata.size?.unit)}</span>
              </div>
                : null}
              {false ? <div className='online-wrap last'>
                {[...Array(3)].map((_) => (
                  <StarRateIcon style={{ fontSize: '40px' }} />
                ))}
                <span className='online'>51 Ratings</span>
              </div> : null
              }
            </div>
            <div className='desktopview'>
              {console.log("data is", expdata.images)}
              {expdata.images && expdata.images.length > 0 ? (
                <div className='imgs-wrapper d-flex'>
                  <ImageWrap image={expdata.images[0]} />
                  {expdata.images.length > 1 && expdata.images.length < 5 ?
                    <ImageWrap image={expdata.images[1]} /> : null
                  }
                  {expdata.images.length == 3 ?
                    <ImageWrap image={expdata.images[2]} /> : null
                  }
                  {expdata.images.length > 4 ?
                    <div className='img-wrap'>
                      <ImageWrap image={expdata.images[1]} className='img-sub-wrap' />
                      <ImageWrap image={expdata.images[4]} className='img-sub-wrap' />
                    </div> : null
                  }
                  {expdata.images.length > 3 ?
                    <div className='img-wrap'>
                      <ImageWrap image={expdata.images[2]} className='img-sub-wrap' />
                      <ImageWrap image={expdata.images[3]} className='img-sub-wrap' />
                    </div> : null
                  }

                </div>
              ) : <div style={{ paddingBottom: '5vh' }}></div>}
            </div>
            <div className='mobileview hero-slider'>
              <MobileCard data={exps} ds={ds}></MobileCard>
            </div>
          </div>
        </div>
        <div className='operated-section'>
          <div className='container'>
            <div className='d-flex operator-wrapper'>
              <div className='d-flex operator-sub-one'>
                <div className='d-flex'>
                  <div className='operator-profile-pic'>
                    {/* <ChatIcon /> */}
                    {/* <img src={Centropa} alt="Centropa"/> */}
                    <img src={expdata.operator?.logo_url} />
                  </div>
                  <div className='operation-profile-title'>
                    {console.log('expdataexpdata', expdata)}
                    <span className='operated-by'>
                      {t('new-experience.operate')}
                    </span>
                    <span className='centropa'>{expdata.operator?.name}</span>
                  </div>
                </div>
                <p>
                  {expdata?.description}
                </p>
              </div>
              {expdata.platform ? <div className='operator-sub-two'>
                <div className='actions-card desktopview '>
                  <Platforms />
                </div>
              </div>
              : null}
            </div>
            <div className='d-flex partnership'>
              {expdata.partners?.length > 0 ?
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <span className='created-in-partnersh'>
                    {t('new-experience.partenership')}
                  </span>
                  <div className='d-flex img-list'>
                  {expdata.partners.map((partner, index) => (
                  <div className='partner-logo-wrap'>
                    <a href={partner.partners_id.url}>
                      <img src={partner.partners_id.logo_url + '?width=150'} alt={partner.partners_id.name} style={{ objectFit: 'scale-down' }} />
                    </a>
                  </div>
                ))}
                  </div>
                </div>
                : null}
              {expdata.project ?
                <div>
                  <span className='created-in-partnersh'>
                    {t('new-experience.part_of')}
                  </span>
                  <NavLink to={'/projects/' + expdata.project.slug} className='link'>
                    {expdata.project.title}
                  </NavLink>
                </div>
                : null
              }
            </div>
          </div>
        </div>
        <div className='container'>
          { expdata.content ?
            <div className='experience-content' dangerouslySetInnerHTML={{__html: expdata.content}}></div>
           : null } 
          <div className='experience-content'>
            {renderIncludedExperiences()}

          </div>

          {exps?.length
            ? exps[0].location != null
              ?
              <div className="map-wrapper-list-view">
                { renderMap() }
                {/* <ExperienceBrowse isShowExperienceMap allData={allData} handleBboxdata={(value) => setBbox(value)} /> */}
                {expdata.platform ? <div className="normal-list-view test">
                  <div className='actions-card mobileview '>
                    <Platforms />
                  </div>
                </div> : null }
              </div>
              : null
            : null}
          {expdata.platform ?  
          <div className="normal-list-view view">
            <div className='actions-card mobileview '>
              <Platforms />
            </div>
          </div> : null}
        </div>
        <div className='youMightAlsoLike container'>
          <span className='cardheader'>{t('new-experience.card_section')}</span>
          <div
            className='desktopview'
            style={{ marginTop: '35px', marginBottom: '30px' }}
          >
            {/* <CardComponent data={allData} ds={ds}></CardComponent> */}
            <ExperienceBrowse isShowExperienceList data={allData} ds={ds} />
          </div>
          <div className='mobileview'>
            {/* <MobileCardNowMore data={allData} ds={ds}></MobileCardNowMore> */}
            <ExperienceBrowse isShowExperienceListMobile data={allData} ds={ds} zoom={zoom}/>
          </div>
        </div>
        <Footer />
      </div>
    </ExperienceWrap>
  );
};
const css = `
--body {
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.cardheader {
  color: #1e59ff;
  font-size: 30px;
  font-weight: bold;
}

.mobileview {
  display: none;
}

.online-wrap.last svg {
  margin-right: 0;
  font-size: 37px !important;
}

.online-wrap.last svg:nth-child(3) {
  margin-right: 9px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.container {
  // max-width: 1500px;
  max-width: 1765px;
  margin: 0 auto;
}

.col-md-4 {
  width: 33.33333333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
}

.actions-card.mobileview {
  display: none;
}

.map-img,
.map-img .map-content {
  height: 680px;
  // width: 640px;
}


@media only screen and (max-width: 1440px) {
  // .map-img, .map-img .map-content {
  // width: 42vw;
  // }
  .map-img,
  .map-img .map-content {
    width: 100%;
    margin: 28px auto;
  }
  .map-main-wrap {
    padding-bottom: 57px;
  }
}

@media only screen and (max-width: 1284px) {
  .banner-inner-img {
    transform: translate(0, 22%);
  }
  .card-wrap {
    width: 100vw;
    height: auto;
    margin-top: -19px;
    padding-top: 0;
  }
  .explore-wrap h3 {
    margin-bottom: 50px;
  }
  .card span,
  .highlight span {
    height: 32px;
    width: 117px;
  }
  .partner-card .card-1 {
    height: 420px;
    width: 48%;
  }
}

@media only screen and (max-width: 1244px) {
  .filter-wrapper.d-flex {
    width: 61%;
  }
  .title-wrap {
    flex-wrap: wrap;
  }
  .rectangle {
    // margin: 10px 0 44px ;
  }
}

@media only screen and (max-width: 1200px) {
  .filterview {
    justify-content: initial;
    width: 81%;
  }
  .morefilters,
  .switchbutton {
    font-size: 1vw;
    padding-left: 13px;
    padding-right: 13px;
  }
  .experience-the-jewis {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .card-wrap .card img,
  .card-wrap .highlight img {
    height: 67%;
    max-width: 158px;
  }
  .card-wrap {
    height: auto;
  }
  // .header-bg {
  //   height: 96vh;
  // }
}

@media only screen and (max-width: 1024px) {
  .experience-the-jewis {
    width: 100%;
    font-size: 4vw;
  }
  .j-story-is-a-communi {
    width: 100%;
  }
  .banner-content-wrap {
    width: 70%;
  }
  .j-story-is-a-communi {
    font-size: 2.7vw;
  }
  .shareStory .shareguid {
    padding: 57px 100px 2px 35px;
  }
  .shareStory .shareguid h4 {
    margin-top: -91px;
  }
  .shareStory .col-md-4:nth-child(2) .shareguid h4 {
    margin-top: -9px;
  }
  .shareStory .col-md-4:nth-child(2) .self-guid h4 {
    margin-top: 53px;
  }
  .shareStory .col-md-4:nth-child(3) {
    // padding: 206px 0 50px;
  }
  // .partner-card p {
  // font-size: 1.8vw;
  // }
}

@media only screen and (max-width: 991px) {
  .card {
    height: 227px;
    width: 178px;
  }
  .jewish-women-in-title {
    flex-wrap: wrap;
  }
  .operator-sub-one {
    width: 35%;
    margin: 0 auto;
  }
  .card span {
    height: 31px;
    width: 100%;
  }
  .partner-card-wrap .card-1 p {
    padding: 0 25px;
  }
  .partner-card .partner-btn {
    margin-top: 30px;
  }
  .searchBar {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
  }
  .searchBar .search-inner-wrap {
    width: 100%;
    margin-bottom: 30px;
  }
  .filterview {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .filter-wrapper.d-flex {
    width: 100%;
  }
  .filter {
    width: 18%;
    margin-bottom: 30px;
  }
  .morefilters,
  .switchbutton {
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .popular-card-wrap {
    display: flex;
    flex-direction: column;
  }
  .poplular-subject-title {
    width: 100%;
    height: 30px;
  }
  .card-wrap {
    height: auto;
    width: 877px;
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .experience-the-jewis {
    line-height: 43px;
  }
  .operator-sub-one {
    width: 100% !important;
  }
  .operator-sub-one p {
    font-size: 18px !important;
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
  .experience-main-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .normal-list-view .actions-card {
    // display:none;
  }
  .map-wrapper-list-view+.normal-list-view {
    display: none;
  }
  .youMightAlsoLike {
    padding: 72px 0 100px;
  }
  .hero-slider>div {
    margin: 0 auto;
  }
  .header-bg {
    margin-bottom: 100px!important;
  }
  .search-results.recommended-cards-wrapper {
    display: flex;
    flex-direction: column;
    // width: 77vw;
  }
  .online-wrap.first {
    width: 100% !important;
  }
  .partnership {
    flex-direction: column !important;
    align-items: baseline !important;
    margin-bottom: 5vh;
  }
  .partnership>div:nth-child(1) {
    margin-bottom: 40px;
  }
  .recommended-cards-wrapper .recommendedCard .shadow {
    box-shadow: none;
  }
  .cardheader {
    font-size: 23px;
    margin-top: 15px;
  }
  .created-in-partnersh {
    width: 100% !important;
    margin-bottom: 30px;
  }
  .youMightAlsoLike {
    padding: 60px 0 130px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .search-results.recommended-cards-wrapper {
    margin: 0 auto;
  }
  .youMightAlsoLike .mobileview {
    margin: 0 auto;
    width: 100%;
  }
  .youMightAlsoLike .sliderBtn-wrap {
    position: relative;
  }
  .youMightAlsoLike .sliderBtn-wrap .arrow-btn {
    position: absolute;
    top: 200px;
    z-index: 1111;
  }
  .youMightAlsoLike .recommended-cards-wrapper .recommendedCard.experiences-slider-card .item-preview {
      width: 100% !important;
      height: 150px !important;
  }
  .youMightAlsoLike .recommended-cards-wrapper .recommendedCard .item-preview {
    width: auto !important;
    height: 150px !important;
  }
  .youMightAlsoLike .recommended-cards-wrapper .recommendedCard.experiences-slider-card {
    width: 80% !important;
    height: auto !important;
  }
  .youMightAlsoLike .recommended-cards-wrapper .recommendedCard {
    width: 100% !important;
    height: auto !important;
  }
  .youMightAlsoLike .slick-dots li button:before {
    font-size: 14px;
    top: 11px;
  }
  .book-btn {
    top: -15px !important;
    right: 15px !important;
  }
  .normal-list-view.view .actions-card.mobileview {
    margin: 0px auto 0;
  }
  .actions-card.mobileview {
    display: block;
    display: block;
    margin: -27px auto 0;
    z-index: 1111;
    position: relative;
  }
  .youMightAlsoLike .sliderBtn-wrap .arrow-btn.next {
    right: -50px;
    z-index: 11;
  }
  .youMightAlsoLike .sliderBtn-wrap .arrow-btn.prev {
    left: -50px;
    z-index: 11;
  }
  .actions-card.desktopview {
    display: none;
  }
  .d-flex.operator-sub-one>.d-flex {
    margin-bottom: 35px;
  }
  .signupbtn {
    font-size: 1.8vw;
    width: 115px;
    background-color: 0749ff;
    color: #fff;
    box-shadow: none;
  }
  .signupbtn span span:nth-child(1) {
    display: block !important;
  }
  .card,
  .highlight {
    height: 220px;
    width: 26%;
  }
  .card-wrap .highlight span,
  .card-wrap .card span {
    width: 100%;
  }
  .card-wrap .card img,
  .card-wrap .highlight img {
    height: 67%;
    max-width: 150px;
  }
  .shareStory .col-md-4 {
    width: 100%;
  }
  .shareStory {
    height: 100vh;
  }
  .shareStory .row {
    display: flex;
    flex-direction: column;
  }
  .shareStory .col-md-4:nth-child(1) {
    padding: 50px 0 0px;
  }
  .shareStory .col-md-4 {
    padding: 0px 0 0px;
  }
  .shareStory .shareguid {
    padding: 0px;
  }
  .shareStory .col-md-4:nth-child(2) {
    display: flex;
  }
  .shareStory .col-md-4:nth-child(3) {
    padding: 50px 0 0px;
  }
  .shareStory .col-md-4:nth-child(2) .self-guid h4 {
    margin-top: 22px;
  }
  .shareStory h2 {
    width: 100%;
    font-size: 5vw;
  }
  .shareStory .col-md-4:nth-child(1) p {
    margin-top: -8px;
  }
  // .partner-card p {
  // // width: 100%;
  // }
  .partner-card .card-1 {
    padding: 0 2px;
  }
  .partner-card h4 {
    width: 100%;
    font-size: 19px;
  }
  .footer-section .row-3 .list {
    width: 180px;
  }
  .footer-section .row-3 .list p {
    width: 100%!important;
  }
  .card-wrap {
    margin-top: 23px;
    max-width: 669px;
    overflow-x: auto;
  }
  .container.search-explore-card-wrap>div {
    width: 100%;
  }
  .createbtn {
    display: none;
  }
  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: auto !important;
    height: 315px !important;
  }
  .sliderBtn-wrap {
    position: relative;
  }
  div.virtual-exhibit,
  div.online-wrap {
    margin: 10px 0 !important;
  }
  div.virtual-exhibit {
    width: 100%;
  }
  .related-card-wrap {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 663px) {
  .jewish-women-in-the {
    padding: 51px 0 0px !important;
  }
  .rectangle {
    margin-top: 0 !important;
  }
  .centropa {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .jewish-women-in-the {
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .header-bg {
    // height: 75vh ;
    // // height: 122vh ; 
    // margin-bottom: 104px !important;
  }
  .youMightAlsoLike .mobileview {
    margin: 40px auto 0;
    // width: 67%;
  }
  .actions-card.mobileview {
    // height: 50%;
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    // height: 60vh;
  }
  .signupbtn>span {
    align-items: center;
    justify-content: flex-start;
  }
  .banner-img .container {
    display: flex;
    flex-direction: column;
  }
  .banner-inner-img {
    height: 263.75px;
    width: 306.09px;
  }
  .experience-the-jewis {
    height: 70px;
    line-height: 31px;
    font-size: 23px;
  }
  .banner-content-wrap {
    margin: 52px 0 0;
  }
  .map-card-wrap .row {
    display: flex;
    flex-direction: column;
  }
  .map-img,
  .map-img .map-content {
    // width: 100%;
    height: 35vh;
  }
  .line {
    height: 1px;
    width: 90vw;
    margin-top: 40px;
  }
  .card-wrap {
    width: 87vw;
  }
  .partner-card .partner-card-wrap {
    flex-direction: column;
  }
  .partner-card .card-1 {
    height: 320px;
    width: 100%;
  }
  .partner-card .partner-btn {
    margin-top: -11px;
  }
  .partner-card .card-1 {
    margin: 24px 0;
  }
  .map-wrap .map-search {
    width: 80vw;
  }
  .map-search>div {
    width: 80%;
  }
  .row-2 button {
    margin-top: 15px;
  }
  .filter {
    margin-left: 9px;
  }
  .card,
  .highlight {
    height: 220px;
    width: 44%;
  }
}

@media only screen and (max-width: 475px) {
  .map-wrap .map-search {
    width: 76vw;
  }
  .please-sumbit-your-d {
    width: 100%;
  }
  .Ops-rectangle {
    // width: 55vw !important;
  }
  .banner-img .container,
  .banner-img .container p {
    width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .header-bg {
    // margin-bottom: 820px;
    // height: 65vh;
    // height: 65vh;
  }
}

@media only screen and (max-width: 425px) {
  .j-story-is-a-communi {
    font-size: 16px;
    line-height: 20px;
  }
  .actions-card h2 {
    height: auto !important;
    font-size: 21px !important;
    // width:100% !important;
    padding: 0 20px;
  }
  .actions-card.mobileview {
    width: 93% !important;
  }
  .hero-slider .search-results.recommended-cards-wrapper {
    width: 74vw !important;
  }
  .header-bg {
    // margin-bottom: 100px !important;
    // height: 80vh ;
  }
  .shareStory h2 {
    height: 66px;
  }
  .shareStory .col-md-4:nth-child(1) p {
    font-size: 4vw;
  }
  .partnership>div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 412px) {
  .operator-sub-one {
    margin-right: 0;
    width: 100% !important;
  }
  .youMightAlsoLike .sliderBtn-wrap .arrow-btn.next {
    right: -42px !important;
  }
}

@media only screen and (max-width: 375px) {
  .please-sumbit-your-d {
    width: 100%;
    margin-bottom: 45px;
  }
  .right-footer img {
    margin-right: 22px;
  }
  .created-in-partnersh {
    height: 25px;
    width: 100% !important;
    margin-bottom: 22px;
  }
  .slick-dots li {
    width: 14px !important;
  }
  @media only screen and (max-width: 360px) {
    .youMightAlsoLike .sliderBtn-wrap .arrow-btn.next {
      right: -41px;
    }
  }
  @media only screen and (max-width: 320px) {
    .morefilters,
    .switchbutton {
      padding-left: 16px;
      padding-right: 16px;
    }
    .jewish-vienna {
      width: 203px;
    }
    .actions-card h2 {
      width: 70% !important;
    }
    #listOfCard .leaflet-popup-content {
      margin: 0 0 !important;
    }
  }
  // New Style
  --body {
    overflow: hidden;
  }
  .main {
    // background-color: rgb(17, 79, 255);
    flex-direction: column;
    display: flex;
    overflow-y: auto;
    height: 100vh
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .logo {
    width: 108px;
    height: 50px;
    margin-right: 80px
  }
  .signupbtn {
    color: #fff;
    background-color: #0849FF;
    margin-left: 20px;
    text-transform: none;
    height: 20px;
    width: 56px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    border-radius: 25px;
    width: 132px;
    height: 40px;
  }
  .signupbtn:hover {
    color: #fff;
    background-color: #0849FF;
  }
  .title {
    justify-content: center;
    display: flex;
    // padding-top: 65px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
  }
  h3 {
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .exploreMore {
    height: 40px;
    width: 200px;
    margin-top: 70px;
    border-radius: 25px;
    background-color: #0849FF;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
  }
  .exploreMore:hover {
    background-color: #0849FF;
    color: #FFFFFF;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .container {
    // max-width: 1500px;
    max-width: 1765px;
    margin: 0 auto;
  }
  .card {
    height: 227px;
    width: 205px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    padding: 0px 20px;
    text-align: center;
  }
  .card-wrap {
    display: flex;
    flex-wrap: wrap;
    height: 787px;
    width: 622px;
    margin-top: -41px;
    padding: 26px 0;
    overflow: auto;
  }
  .card-wrap .card img {
    height: 180px;
    width: 180px;
    border-radius: 15px;
  }
  .card-wrap .card img:hover {
    box-shadow: 0px 0px 11px 1px #fac807;
  }
  .line {
    background-color: #7782A0;
    height: 712px;
    width: 2px;
    margin-top: 90px;
  }
  .card span {
    height: 31px;
    width: 180px;
    color: #717C9C;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 70px;
    text-align: center;
  }
  .card span:hover {
    color: #000;
  }
  .map-img,
  .map-img .map-content {
    height: 711px;
    //  width: 735px;
    width: 100%;
    border-radius: 20px;
  }
  .map-wrap {
    position: relative;
  }
  .map-wrap .map-search {
    position: absolute;
    z-index: 1111;
    width: 80%;
    left: 60px;
    top: 20px;
  }
  .shareStory {
    color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .item-native {
    height: 35px !important;
    min-height: 35px !important;
  }
  .col-md-4 {
    width: 33.33333333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 70px 0;
  }
  .shareStory .col-md-4 {
    padding: 70px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .shareStory h2 {
    height: 100px;
    width: 405px;
    // margin-bottom: 38px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    // line-height: 40px;
  }
  .shareStory p {
    // height: 100px;
    margin-bottom: 38px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }
  // .shareStory .createNew{
  // margin-left: 0px;
  // }
  .shareStory .shareguid {
    padding: 30px 100px 0px 100px;
  }
  .shareStory .shareguid h4 {
    height: 24px;
    width: 236px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .shareStory .shareguid p {
    height: 80px;
    width: 304px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .partner-card img {
    height: 60px;
    width: 65.92px;
  }
  .partner-card .card-1 {
    height: 420px;
    width: 622px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
    margin: 125px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 125px;
    text-align: center;
    align-items: center;
  }
  .partner-card .partner-card-wrap {
    display: flex;
    justify-content: center;
  }
  .partner-card .card-1:nth-child(1) {
    margin-right: 27px;
  }
  .partner-card h4 {
    // height: 31px;
    width: 304px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
  }
  .partner-card p {
    height: 72px;
    width: 405px;
    color: #545454;
    font-family: "Source Sans Pro";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  .partner-card .partner-btn {
    box-sizing: border-box;
    height: 42px;
    width: 200px;
    border: 2px solid #0849FF;
    border-radius: 25.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
  
  .partner-card .partner-btn span {
    height: 20px;
    // width: 131px;
    color: #0849FF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
  .banner-img {
    // height:100%;
    width: 100%;
    background-size: cover;
  }
  .banner-img .container {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .banner-inner-img {
    height: 534.75px;
    width: 589.09px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(0, 12%);
  }
  .experience-the-jewis {
    height: 128px;
    width: 649px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
  }
  .j-story-is-a-communi {
    height: 87px;
    width: 682px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 30px;
    letter-spacing: 0;
    line-height: 40px;
  }
  .map-card-wrap {
    margin-bottom: 142px;
    margin-top: 152px;
  }
  .sub-list {
    flex-direction: column;
  }
  .learMore {
    box-sizing: border-box;
    height: 42px;
    width: 162px;
    border: 2px solid #FFFFFF;
    border-radius: 25.6px;
    margin-right: 10px;
  }
  .learMore span:nth-child(1) {
    height: 20px;
    width: 105.39px;
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
  }
  .shareguid svg {
    margin-top: 16px;
    margin-right: 35px;
    width: 60px;
    height: 60px;
  }
  .mobileview {
    display: none;
  }
  @media only screen and (max-width: 1791px) {
    .map-img,
    .map-img .map-content {
      height: 680px;
      // width: 640px;
    }
    .line {
      height: 680px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .map-img,
    .map-img .map-content {
      // width: 42vw;
    }
    .shareStory .shareguid p {
      width: 100%;
    }
    .shareStory .shareguid {
      padding: 30px 100px 0px 64px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .shareStory .shareguid p {
      height: 80px;
    }
    .header-bg {
      // height: 8vh ;
    }
    .j-story-is-the-pro {
      margin-right: -8px;
    }
    .map-wrap .map-search {
      width: 80%;
    }
    .shareguid svg {
      margin-right: 9px;
    }
  }
  @media only screen and (max-width: 1284px) {
    .banner-inner-img {
      transform: translate(0, 22%);
    }
    .map-img,
    .map-img .map-content {
      width: 90vw !important;
    }
    .card-wrap {
      // width: 42vw;
      height: 680px;
      margin-top: -19px;
      padding-top: 0;
    }
    .explore-wrap h3 {
      margin-bottom: 50px;
    }
    .card span {
      height: auto;
    }
    .partner-card .card-1 {
      // height: 420px;
      width: 48%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .experience-the-jewis {
      width: 100%;
      font-size: 4vw;
    }
    .j-story-is-a-communi {
      width: 100%;
    }
    .banner-content-wrap {
      width: 70%;
    }
    .j-story-is-a-communi {
      font-size: 2.7vw;
    }
    .shareStory .shareguid {
      padding: 57px 100px 2px 35px;
    }
    .shareStory .shareguid h4 {
      margin-top: -91px;
    }
    .shareStory .col-md-4:nth-child(2) .shareguid h4 {
      margin-top: -9px;
    }
    .shareStory .col-md-4:nth-child(2) .self-guid h4 {
      margin-top: 53px;
    }
    .shareStory .col-md-4:nth-child(3) {
      // padding: 206px 0 50px;
    }
    .partner-card p {
      // font-size: 1.8vw;
      height: auto;
    }
    // .footer-section .row-3 .list {
    // width: 170px;
    // }
  }
  @media only screen and (max-width: 991px) {
    .card {
      height: 227px;
      width: 178px;
    }
    .card span {
      height: 31px;
      width: 100%;
    }
    .partner-card-wrap .card-1 p {
      padding: 0 25px;
    }
    .partner-card .partner-btn {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    .banner-content-wrap {
      width: 70%;
      padding: 70px 0 0 0;
    }
    .search-results.recommended-cards-wrapper {
      display: flex;
      flex-direction: column;
      // width: 90vw;
      // width: 80vw;
    }
    .operator-sub-one {
      width: 100% !important;
      margin-bottom: 20px;
      padding-bottom: 40px;
    }
    .operator-wrapper {
      flex-direction: column;
    }
    .banner-inner-img {
      transform: translate(0, -5%);
    }
    .desktopview {
      display: none;
    }
    .mobileview {
      display: flex;
    }
    .mobileview.upper-slider {
      margin: 0 auto;
      width: 66%;
    }
    .shareStory .col-md-4:nth-child(3) .mobileview {
      display: flex;
      flex-direction: column;
    }
    .banner-img .container {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .experience-the-jewis {
      line-height: 62px !important;
      font-size: 50px !important;
      height: auto !important;
    }
    .signupbtn {
      font-size: 16px;
      width: 115px;
      margin-top: 50px;
      margin-left: 0px;
    }
    .learMore {
      margin-bottom: 15px;
    }
    .footer-row-wrap .md.hydrated {
      display: block;
    }
    .submit-btn-wrap {
      justify-content: flex-end;
      margin-top: 20px;
    }
    .card {
      height: 260px;
      width: 100%;
    }
    .card-wrap .card img {
      height: 200px;
      width: 100%;
    }
    .shareStory .col-md-4 {
      width: 100%;
    }
    .shareStory .row {
      display: flex;
      flex-direction: column;
    }
    .shareStory .col-md-4:nth-child(1) {
      padding: 50px 0 0px;
    }
    .shareStory .col-md-4 {
      padding: 0px 0 0px;
    }
    .shareStory .shareguid {
      padding: 0px;
    }
    .shareStory .col-md-4:nth-child(2) {
      display: flex;
    }
    .shareStory .col-md-4:nth-child(3) {
      padding: 50px 0 0px;
    }
    .shareStory .col-md-4:nth-child(2) .self-guid h4 {
      margin-top: 0px;
    }
    .shareStory h2 {
      width: 100%;
      font-size: 5vw;
    }
    .shareStory .col-md-4:nth-child(1) p {
      margin-top: -8px;
    }
    .partner-card p {
      // width: 100%;
    }
    .partner-card .card-1 {
      padding: 0 2px;
    }
    .partner-card h4 {
      width: 100%;
      font-size: 19px;
    }
    // .footer-section .row-3 .list {
    // width: 180px;
    // }
    // .footer-section .row-3 .list p{{
    // width: 297px !important;
    // }
    .banner-content-wrap {
      padding: 82px 0 0 0;
    }
    div.virtual-exhibit,
    div.online-wrap {
      margin: 10px 0 !important;
    }
    .operator-sub-one {
      padding-bottom: 10px;
    }
  }
  @media only screen and (max-width: 575px) {
    .recommended-cards-wrapper .recommendedCard.carousel-slider-card {
      // width: 400px !important;
      // overflow: hidden;
    }
    .slick-initialized .slick-slide {
      display: block;
      // width:400px !important;
    }
    .jewish-women-in-the {
      font-size: 40px !important;
      line-height: 50px !important;
    }
    .mobileview {
      display: flex;
    }
    .desktopview {
      display: none;
    }
    .search-results {
      gap: 0rem;
    }
    .banner-content-wrap {
      width: 90%;
    }
    .leaflet-control-zoom {
      display: none;
    }
    .map-card-wrap h3 {
      text-align: center;
    }
    .signupbtn {
      // width: 57px;
      // width: 54%;
    }
    .signupbtn>span {
      align-items: center;
      justify-content: flex-start;
    }
    .banner-img .container {
      display: flex;
      flex-direction: column;
    }
    .banner-inner-img {
      height: 493.75px;
      // width: 555.09px;
      width: 100vw;
      transform: translate(0, 5%);
    }
    .title {
      padding-top: 80px;
    }
    .experience-the-jewis {
      height: 62%;
      line-height: 53px;
      font-size: 43px;
    }
    .j-story-is-a-communi {
      font-size: 4.7vw;
    }
    .banner-content-wrap {
      margin: 52px 0 0;
    }
    .map-card-wrap .row {
      display: flex;
      flex-direction: column;
    }
    .map-img,
    .map-img .map-content {
      width: 100%;
      height: 55vh;
      border-radius: 0px;
    }
    .map-card-wrap .container {
      width: auto;
    }
    .line {
      height: 1px;
      width: 100vw;
      margin-top: 40px;
    }
    .card-wrap {
      width: 100vw;
      height: 100%;
    }
    .map-card-wrap {
      margin-bottom: 50px;
    }
    .explore-wrap h3 {
      margin-top: 50px;
    }
    .card {
      height: 260px;
      width: 45%;
      margin: 0 auto;
    }
    .partner-card .partner-card-wrap {
      flex-direction: column;
    }
    .partner-card .card-1 {
      // height: 320px;
      width: 100%;
    }
    .partner-card .partner-btn {
      margin-top: 0px;
    }
    .partner-card .card-1 {
      margin: 24px 0;
    }
    .row-2>div {
      width: 100%;
    }
    .get-in-touch {
      width: 100%;
    }
    .map-wrap .map-search {
      width: 83vw;
      left: 19px;
    }
    .map-search>div {
      width: 80%;
    }
    .row-2 button {
      margin-top: 15px;
    }
    .shareStory .shareguid p {
      height: fit-content;
      width: 100%;
    }
    .shareStory .shareguid h4 {
      height: fit-content;
    }
    .shareStory {
      // padding-bottom: 100%;
    }
    .shareStory .col-md-4:nth-child(3) {
      padding: 100px 0 0px;
    }
    .shareStory .col-md-4:nth-child(3) .shareguid {
      margin-bottom: -10px;
    }
    .shareStory .col-md-4:nth-child(3) .shareguid svg {
      margin-top: -95px;
      margin-bottom: 47px;
    }
    .shareStory .col-md-4:nth-child(3) .mobileview {
      display: flex;
      flex-direction: column;
    }
    .shareStory .col-md-4:nth-child(3) .mobileview a {
      margin-top: 20px;
    }
    .map-wrap .map-search {
      width: 93vw;
    }
    .submit-btn-wrap {
      justify-content: end;
    }
    .list-footer {
      flex-wrap: wrap;
    }
    .map-card-wrap {
      margin-top: 60px;
    }
  }
  @media only screen and (max-width: 475px) {
    .map-wrap .map-search {
      width: 76vw;
    }
    .please-sumbit-your-d {
      width: 100%;
    }
    .line {
      width: 100%;
    }
    .map-card-wrap {
      margin-top: 50px;
    }
    .map-wrap .map-search {
      width: 90vw;
    }
  }
  @media only screen and (max-width: 475px) {}
  @media only screen and (max-width: 425px) {
    .shareStory h2 {
      height: 66px;
    }
    .shareStory .col-md-4:nth-child(1) p {
      font-size: 4vw;
    }
    .jewish-women-in-the {
      font-size: 28px !important;
      line-height: 39px !important;
    }
  }
  @media only screen and (max-width: 375px) {
    .please-sumbit-your-d {
      width: 100%;
      margin-bottom: 45px;
    }
    .right-footer img {
      margin-right: 22px;
    }
    .j-story-is-the-pro {
      height: 100%;
    }
    .shareStory {
      // padding-bottom: 8%;
    }
    .shareStory .shareguid {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .shareStory .shareguid h4 {
      width: 100%;
    }
    .shareStory .shareguid h4 {
      margin-top: -39px;
    }
    .shareStory .col-md-4:nth-child(3) .mobileview {
      align-items: center;
    }
  }
  @media only screen and (max-width: 360px) {
    .shareguid img {
      margin-bottom: 18px;
    }
  }
`;

const ExperienceWrap = styled.div`
  // Experience style
  .header-bg {
    /* height: 68vh; */
    height: 855px;
    width: 100%;
    background: linear-gradient(
      44.84deg,
      rgba(8, 73, 255, 0.9) 0%,
      rgba(0, 182, 234, 0.9) 100%
    );
    // margin-bottom: 227px;
    margin-bottom: 100px;
  }
  .jewish-women-in-the {
    // height: 64px;
    // width: 50%;
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 64px;
    padding: 51px 0 44px;
    margin: 0;
    margin-right: 20px;
    flex-wrap: wrap;
    align-items: center;
  }

  .rectangle {
    height: 40px;
    width: 138px;
    border-radius: 15px;
    background-color: #fac807;
    box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
    // margin: 10px 0 11px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 21px;
    text-transform: uppercase;
    margin-left: 35px;
  }

  .free {
    background-color: #fac807;
    color: black;
  }

  .notFree {
    background-color: #2a2a2a;
    color: #fff;
  }
  .virtual-exhibit {
    height: 33px;
    // width: 17%;
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 0.86px;
    line-height: 27px;
    text-transform: uppercase;
    // margin-right: 43px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .online {
    height: 38px;
    width: 72px;
    color: #ffffff;
    font-family: 'Source Sans Pro';
    font-size: 24px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 36px;
    margin-right: 43px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-height: 218px;
    /* display: -webkit-box; */
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  .online a {
    color: white; 
  }

  .location-icon {
    height: 27px;
    width: 18.78px;
    color: #ffffff;
  }

  .online-wrap.first {
    // width: 30%;
    width: fit-content;
    margin-left: 30px;
  }

  .online-wrap {
    display: flex;
    align-items: center;
    fill: #fff;
  }
  .online-wrap svg {
    fill: #fff;
    margin-right: 9px;
    font-size: 27px !important;
  }
  .online-wrap span {
    width: fit-content;
  }
  .img-wrap:nth-child(2) img,
  .img-wrap:nth-child(3) img {
    width: 1191px;
  }
  .img-wrap img {
    height: 500px;
    width: 1591px;
    border-radius: 10px;
    object-fit: cover;
  }
  .img-wrap {
    padding: 20px 10px;
  }
  .img-sub-wrap img {
    // height: 276px;
    height: 287px;
    object-fit: cover;
  }
  .img-sub-wrap {
    // padding: 0px 5px 11px;
    padding: 0px 0px 10px;
  }
  .img-sub-wrap.second {
    // padding: 11px 11px 0px;
    padding: 1px 0px 0px;
  }
  .imgs-wrapper.d-flex {
    margin-top: 63px;
  }

  .operator-profile-pic {
    height: 110px;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
    margin-right: 30px;
  }
  .operator-profile-pic svg {
    width: 50px;
    height: 50px;
    fill: #486cb3;
  }

  .operator-profile-pic img {
    width: 80%;
    height: 80%;
    object-fit: scale-down;

  }


  .operation-profile-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .operated-by {
    height: 30px;
    width: 105px;
    color: #717c9c;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
  }
  .centropa {
    height: 32px;
    width: 304px;
    color: #0849ff;
    font-family: 'Lucida Grande', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  .operator-sub-one {
    width: 65%;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: baseline;
    flex-direction: column;
    border-bottom: 1px solid #7782a0;
    // margin-right: 154px;
    margin-right: 6vw;
  }
  .operator-sub-one p {
    // height: 180px;
    height: 100%;
    // width: 973px;
    width: 100%;
    color: #717c9c;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
    margin-top:30px;
    margin-bottom:40px;

  }

  .operated-section {
    padding-bottom: 50px;
  }
  .actions-card {
    // height: 412px;
    height: auto;
    width: 455px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px 72px;
    background-color: #fff;
    box-shadow: 0px 0px 14px 0px #9e9e9e;
    border-radius: 20px;
  }
  .Ops-rectangle {
    box-sizing: border-box;
    height: 41px;
    width: 301px;
    border: 1px solid #0849ff;
    border-radius: 20px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Ops-rectangle:nth-child(1) img{
    //width: 44px;
    
  }
   .Ops-rectangle img{
    width: 34px;
  }

  .actions-card h2 {
    text-align: center;
    height: 60px;
    width: 304px;
    color: #0849ff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 auto 47px;
  }
  .izi-travel {
    height: 28.44px;
    color: #0849ff;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    text-transform: none;
    cursor: pointer;
    font-weight: bold;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    margin-left: 7px;
  }
  .Ops-rectangle svg {
    height: 19px;
    width: 41.83px;
    fill: #0749ff;
  }
  .youMightAlsoLike {
    padding: 0 0 100px;
    // padding:0;
  }
  .created-in-partnersh {
    height: 19px;
    width: 250px;
    color: #717c9c;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }
  .created-in-partnersh + img-list {
    margin-left: 47px;
  }
  .img-list img {
    height: 10vh;
  }
  .img-list div {
    margin-right: 55px;
  }
  .partnership {
    padding: 45px 0;
    align-items: center;
    text-transform: uppercase;
  }
  .link {
    margin-left: 15px;
  }
  .map-main-wrap{
    width: 100%;
    // height: 383px;
    overflow: hidden;
  }

  .map-img,
  .map-img .map-content {
    // width: 100%;
    height: 70vh;
    border-radius: 15px;
  }

  .map-main-wrap {
    padding-bottom: 57px;
  }


    /* @media only screen and (min-width: 1980px){
    .header-bg {
        height: 60%;
    } */

  @media only screen and (max-width: 1980px) {
    .operator-sub-one {
      width: 100vw;
      margin-right: 28px;
    }
    // .header-bg {
    //   height: 50vh !important;
    // }
  }

  @media only screen and (max-width: 1199px) {
    .online-wrap.first {
      width: 25%;
    }
   
  }

  @media only screen and (max-width: 1440px) {
    /* .header-bg {
      margin-bottom: 460px;
    } */
    .operated-section {
      padding: 0px 0 50px;
    }
  }

  @media only screen and (max-width: 1366px) {
    /* .header-bg {
      margin-bottom: 520px;
    } */
  }

  @media only screen and (max-width: 1024px) {
    .online-wrap svg {
      fill: #fff;
      margin-right: 9px;
      width: 22px;
    }
    .online {
      font-size: 20px;
    }
    .online-wrap span {
      width: 55%;
    }
    .virtual-exhibit {
      font-size: 20px;
    }
    .operator-sub-one p {
      font-size: 17px;
     margin-top:0;
    }
    .img-list div {
      margin-right: 38px;
    }
    .actions-card {
      width: 100%;
      padding: 2rem 2rem;
      justify-content: center;
    }

    .header-bg{
      /* height: 89vh; */
      /* margin-bottom: 380px; */
    }
  }

  @media only screen and (max-width: 991px) {
    .online-wrap.first {
      width: 30%;
    }
    .operator-sub-one {
      // width: 46vw;
      margin-right: 8%;
    }
    .centropa {
      width: 100%;
    }
    .operator-sub-one {
     
      margin:0 auto;
    }
  }

  @media only screen and (max-width: 768px) {
    .jewish-women-in-the {
      font-size: 32px;
      padding: 40px 0 12px ;
      line-height: 44px;
    }
    .footer-section .footer-row-wrap span{
      font-size:15px;
    }
    .experience-the-jewis{
      line-height: 62px !important;  
      font-size: 50px !important;
      height: auto !important;
      }
    .search-results.recommended-cards-wrapper{
      display:flex;
      flex-direction: column;
      width:65vw;
    }
    .partnership{
      padding:0px;
    }
    .map-img, .map-img .map-content {
      // width: 100vw;
      width: 100vw;
  }
  .operated-section+.container{
    margin:0;
    width:100%;
  }
  .map-img,
  .map-img .map-content {
    height: 45vh !important;
    border-radius: 0;
}

    .arrow-btn.next svg,
    .arrow-btn.prev svg{
      fill: #fff;
      width: 40px;
      height: 45px;
    }

    .youMightAlsoLike .arrow-btn.next svg,
    .youMightAlsoLike .arrow-btn.prev svg{
      fill: #000;
      width: 40px;
      height: 45px;
    }
    .youMightAlsoLike .mobileview{
      position: relative;
      margin: 70px 0;
      display: block !important;
    }

    .youMightAlsoLike .arrow-btn {
      top: 45%;
      font-size: 35px;
      z-index: 1;
      position: absolute;
  }
  .youMightAlsoLike  .arrow-btn.next {
    right: 0px;
  }
  .recommended-cards-wrapper .recommendedCard .shadow{
    display: flex;
    flex-direction: column;
  }
  .get-in-touch {
    width: 100%;
  }
    .jewish-women-in-the{
      padding: 0px 0 0px;
      margin: auto 0;
    }
    .online-wrap span {
      width: 91%;
    }
    .header-bg .container >.d-flex {
      flex-wrap: wrap;
      padding: 33px 0px 0;
    }
    .img-wrap img {
      height: 36vh;
    }
    .img-wrap .img-sub-wrap img {
      height: 125px !important;
    }
    .header-bg {
   /* margin-bottom: 165px; */
    }
    .operator-wrapper,
    .partnership {
      flex-direction: column;
    }
    .operator-sub-one {
      /* width: 100%; */
      margin-right: 0px;
      margin: 0 auto;
    }
    .operator-profile-pic {
      height: 70px;
      width: 70px;
    }
    .operator-profile-pic svg {
      width: 37px;
    }
    .centropa {
      width: auto;
      font-size: 19px;
    }

    .operator-sub-one p {
      height: 100%;
      width: 100%;
      font-size: 15px !important;
      margin-top:0;
      line-height: 30px;
    }

    .actions-card {
      height: 100%;
      width: 66vw;
      display:flex;
      // padding: 40px 61px;
      z-index: 11111;
      margin: -150px auto 100px;
      justify-content: center;
    }

    .desktopview {
      display: none;
    }
    .mobileview {
      display: flex;
  }

    .operator-sub-two {
      margin-top: 40px;
    }
    .partnership > div {
      margin-top: 21px;
      flex-direction: column;
    }
    .img-list {
      margin-top: 20px;
    }
    .img-list img {
      height: 46.47px;
      width: 152.4px;
      padding: 0px 20px;
    }
    .operated-section {
      padding: 0px 0 0;
    }
    .actions-card h2 {
      height: 40px;
      width: 100%;
    }
    .img-list div {
      margin-right: 15px;
    }
    .created-in-partnersh {
      width: 100%;
      font-size: 19px;
      text-align: center;
    }
    .map-img,
    .map-img .map-content {
      height: 25vh;
    }
    .youMightAlsoLike {
      padding: 0px 0;
  
    }
    .map-main-wrap {
      padding-bottom: 20px;
    }
    .Ops-rectangle {
      width: 100%;
    }

    .imgs-wrapper.d-flex{
      height: 300px;
    overflow: hidden;
    margin-top: 20px;
    padding: 0 40px;
    }

  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: auto;
    height: 20vh;
}
.book-btn {
// top: 164px;
top: 230px;
}
.recommended-cards-wrapper .recommendedCard .item-title-wrapper {
  margin-bottom: 0.1em;
}
.cardheader{
  display: flex;
  justify-content: center;
}
.slick-dots li button:before {
  font-size: 13px;
  top: 9px;
  }
  .youMightAlsoLike .recommended-cards-wrapper .recommendedCard .shadow{
    box-shadow: none;
  }
  div.virtual-exhibit,
  div.online-wrap{
    margin:10px 0 !important;
  }
}

  @media only screen and (max-width: 575px) {
.header-bg {
  }
  .Ops-rectangle {
    /* width: 53vw; */
  }
  .jewish-women-in-the {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}
@media only screen and (max-width: 475px) {
}

  @media only screen and (max-width: 425px) {
    .rectangle {
      font-size: 17px;
    }
    .imgs-wrapper.d-flex {
      display: flex;
      flex-direction: column;
    }
    .header-bg {
      /* margin-bottom: 100px !important;
      height: 80vh ; */
  }
    .img-wrap img {
      height: 200px;
      width: 100%;
    }
    
    .img-wrap {
      padding: 0px 5px;
    }
    .img-wrap .img-sub-wrap img {
      height: 200px !important;
    }
  .actions-card h2 {
    height: 85% !important;
    margin-top: 24px;
    }
    .izi-travel {
      height: 100%;
    }
    .slick-dots li {
      width: 14px;
    }
  }

  @media only screen and (max-width: 375px) {
    .header-bg {
      // margin-bottom: 915px;
    }
    .actions-card {
      padding: 40px 20px;
    }
    .created-in-partnersh {
      width: 100%;
      font-size: 3.8vw;
    }
    .link {
      font-size: 4vw;
    }
    .j-story-is-the-pro {
      width: 283px;
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 360px) {
  .header-bg {
      /* height: 75vh; */
  }
.header-bg {
    // height: 75vh;
}
}


@media only screen and (max-width: 320px) {
 
  .actions-card h2{
    width:70% !important;
  }
}

.experience-content {
  margin-bottom: 5vh;

}
.experience-content {

  .datelocation {
    color: var(--ion-color-primary);
    font-size: 1.5rem;
    margin-bottom: 1vw;
  }

  .hour {
    color: var(--ion-color-primary);
    font-size: 1.5rem;
  }

  .name {
    font-weight: 700px;
    margin-bottom: 0;
  }

  .name-description {
    margin-bottom: 4vw;
    font-size: 24px;
    color: var(--ion-color-secondary);

  }

  hr {
    background-color: var(--ion-color-medium-contrast);
    margin: 4vw 0;

  }
  p, h2 p {
    color: var(--ion-color-secondary);
    font-size: 24px;

  }

  h2 a {
    text-decoration: none;

  }
  h3 a {
    font-size: 24px;
    color: var(--ion-color-medium-contrast);
    text-decoration: none;
  }

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
}


@media only screen and (max-width: 768px) {
  .experience-content {
    padding: 0 5%;
  }
}
`;

export default Experience;
