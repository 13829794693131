import listStyled from 'styled-components';

export const StyledExperienceList = listStyled.div`

.mobileview{
display:none;
}

.search-results {
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
  max-width: 1765px;
  margin: 0 auto;
}

.recommended-cards-wrapper .recommendedCard {
  position: relative;
  height: 264px;
  width: 34.7rem;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 40px 20px rgb(113 124 156 / 10%);
}

.recommended-cards-wrapper .recommendedCard .item-preview {
  width: 8vw;
}
.book-btn {
  padding: 5px 21px;
  font-size: 14px !important;
  color: #000;
  background-color: #fac807;
  border-radius: 7px;
  font-weight: 900 !important;
  position: absolute;
  top: -7px;
  right: 45px;
}
.book-btn.euro {
  background-color: #000;
  color: #fff !important ;
}
.self-guided-tour {
  height: 24px;
  width: 149px;
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 27px;
  text-transform: uppercase;
}
.jewish-vienna {
  height: 60px;
  color: #0849ff;
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 15px;
}

.jewish-vienna .item-title-link {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.walking-tour-on-jewi {
  /* width: 250px; */
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.pin-icon-wrap svg,
.clock-iocn-wrap svg {
  height: 21px;
  width: 20px;
  fill: #0849ff;
}
.pin-icon-wrap span,
.clock-iocn-wrap span {
  height: 21px;
  width: 94px;
  color: #717c9c;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
}
#listOfCard .item-title-wrapper {
  height: auto;
  max-width: 100%;
}
/* .item-details-description{
  margin-top:30px;
} */

@media only screen and (max-width:2097px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 33.7rem;
  }
}
@media only screen and (max-width:2040px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 32.7rem;
  }
}

@media only screen and (max-width: 1982px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 31.7rem;
  }
}
@media only screen and (max-width: 1924px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 30.7rem;
  }
}

@media only screen and (max-width: 1867px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 29.7rem;
  }
}
@media only screen and (max-width: 1809px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 28.7rem;
  }
}
@media only screen and (max-width: 1752px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 27.7rem;
  }
}

@media only screen and (max-width: 1694px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 27.5rem;
  }
  #listOfCard .item-details {
    width: 64%;
}
}
@media only screen and (max-width: 1683px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 25.8vw;
  }
}



@media only screen and (max-width: 1502px) {
  #listOfCard .item-details {
    width: 67% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 27.9vw
  }
 #listOfCard .item-details {
    width: 55% !important;
}
.walking-tour-on-jewi {
  width: 100%;
}

}

@media only screen and (max-width: 1366px) {
  .walking-tour-on-jewi {
    width: 100%;
  }
}
@media only screen and (max-width: 1284px) {
  #listOfCard .item-details {
    width: 67% !important;
  }
  .banner-inner-img {
    transform: translate(0, 22%);
  }
}
@media only screen and (max-width: 1244px) {
 .recommended-cards-wrapper .recommendedCard {
    width: 42.8vw;
}
}


@media only screen and (max-width: 1199px) {
  .pin-icon-wrap span,
  .clock-iocn-wrap span {
    width: 85px;
    font-size: 12px;
  }
  
.recommended-cards-wrapper .recommendedCard {
  width: 43.2vw;
}

}
@media only screen and (max-width: 1088px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 43.1vw;
 }
 }

@media only screen and (max-width: 1074px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 42.2vw;
    margin: 0 auto;
  }
  }
@media only screen and (max-width: 1024px) {
  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: 150px;
    height: 100%;
  }
  #listOfCard .item-details {
    width: 60% !important;
  }
  .jewish-vienna {
    margin-top: 6px;
  }
  .jewish-vienna .item-title-link {
    font-size: 20px;
  }
  .walking-tour-on-jewi + .icon-wrap {
    margin-top: 15px !important;
  }
  .item-details-header {
    margin-top: -11px;
  }
  .book-btn {
    top: -15px;
  }
}
@media only screen and (max-width: 991px) {
  #listOfCard .item-details {
    width: 55% !important;
  }
  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: 15vw;
  }
}
@media only screen and (max-width: 800px) {
.jewish-vienna .item-title-link{
  width: 160px;
}
}

@media only screen and (max-width: 768px) {
  .recommended-cards-wrapper .recommendedCard {
    width: 100%;
    height: auto;
  }
  .recommended-cards-wrapper .recommendedCard .experi-wrapper {
    flex-direction: column;
  }
  #listOfCard .item-details {
    width: 100% !important;
  }
  .mobileview{
    display:block;
      }
      .jewish-vienna .item-title-link {
        width: 100%;
      }
      .recommended-cards-wrapper .recommendedCard .item-preview{
        width: 100%;
        //  width: 22vw;
        height: 230px;
      }
      .recommended-cards-wrapper .recommendedCard .box-wrapper .item-preview{
        height: 230px;
        width: 22vw;
      }
      .book-btn {
        // top: 238px ;
        top: -15px ;
      }
      .walking-tour-on-jewi + .icon-wrap {
        justify-content:flex-start !important;
      }
      .clock-iocn-wrap{
margin-left:10px;
      }
      .item-details-description{
        margin-top:20px;
      }

}
@media only screen and (max-width: 575px) {
#listOfCard .item-details ,
.jewish-vienna{
  width: 100% !important;
  .recommended-cards-wrapper .recommendedCard .shadow {
    display: flex;
    flex-direction: column;

  }
  .recommended-cards-wrapper .recommendedCard .item-preview {
    width: auto;
    height: 165px;
  }
  .book-btn {
    top: 238px !important;
}
.searchBar .search-inner-wrap{
  text-align: left;
}

}
.book-btn {
  top: 158px !important;
}
}

@media only screen and (max-width: 425px) {
.item-details-description{
margin-top:25px;
}

}


@media only screen and (max-width: 375px) {
  #listOfCard .item-details {
    width: 100% !important;
  }
  .pin-icon-wrap span, .jjTLoc .clock-iocn-wrap span {
    width: 68px;
  }

}
`;
