import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import { DeleteModal } from './deleteStyled';

const DeletePopup = ({ isOpen, setDeleteModal }) => {
    const [form, setForm] = useState<any>([]);

    const handleClose = () => {
        setDeleteModal(false);
    };
    const submitForm = (form) => {

    };

    return (
        <div>
            <DeleteModal isOpen={isOpen} backdropDismiss={false}>
                <div>
                    <div className='d-flex'>
                        <div className='title'>
                            <h3>Are you sure you want delete this Passover Event?</h3>
                        </div>
                        <div className='canclebtn'>
                            <ClearIcon onClick={handleClose}></ClearIcon>
                        </div>
                    </div>
                    <div className='field-wrap'>
                        <Button
                            className='createNew'
                            variant='contained'
                            onClick={handleClose}
                            color='primary'
                        >
                            <span className="color-black">
                                No
                            </span>
                        </Button>
                        <Button
                            className='createNew'
                            variant='contained'
                            onClick={() => submitForm(form)}
                            color='primary'
                        >
                            <span className="color-black">
                                Yes
                            </span>
                        </Button>
                    </div>
                </div>
            </DeleteModal>
        </div>
    )
}

export default DeletePopup;