import React from 'react';
import styled from 'styled-components';

const Header: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};

const Root = styled.header`
  min-height: 200px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 3rem;
    padding: 0;
    margin: 0;
    margin-right: auto;
    font-weight: bold;
    color: var(--ion-color-primary);
  }

  @media only screen and (max-width: 1024px) {
    .logocontainer img{
    max-width: 81%;
    border: 0;
  }
}
 
  
`;

export default Header;
