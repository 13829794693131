import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import useToken from '../../util/useToken';
import ChooseContentItem from '../common/SaveItem/ChooseContentItem';
import useTranslations from '../../util/useTranslation';
import styled from 'styled-components';

const SearchWidget=()=>
{
    const [t] = useTranslations('item', 'search','save-item');
    const [token, setToken] = useToken();
    const location = useLocation();
    let params = queryString.parse(location.search);
    
    useEffect(() => {
        if (params['access_token']) {
            setToken({'access_token': params['access_token']});
        }            
    }, []);


    return(
        <Styled>
        <div>
            <ChooseContentItem showModal={false} 
                            closeModal={(data)=>window.parent.postMessage({ message: params['id'], value: [] }, "*")}
                            onSelect={(item: any) => {
                                    window.parent.postMessage({ message: params['id'], value: [item] }, "*");

                            }}
                            multiSelect={true}>

            </ChooseContentItem>
        </div>
        </Styled>
    )
}
export default SearchWidget;

const Styled = styled.div`
`;

    