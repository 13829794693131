import React from 'react';
import { useHistory, useLocation } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import useTranslations from '../../util/useTranslation';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';

import { HashLink } from 'react-router-hash-link';
// import Item from '../../model/Item';
import logo from '../../assets/logo/jstory.svg';
import { NavLink } from 'react-router-dom';
import UserMenu from '../../components/user-menu/UserMenu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end'
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			display: 'flex',
			flexDirection: 'column',
			alignItems: ' flex-start',
			padding: '16px 20px 24px',
			borderBottomLeftRadius: '20px'
		}
	})
);

const Header: React.FC<{navigateToSection?:boolean}> = ({navigateToSection}) => {
	const [hm] = useTranslations('home');
	let history = useHistory();

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div style={{backgroundColor:'#fff'}}>
			<style>{css}</style>
			{/* <IonHeader className='ion-no-border'>
        <IonToolbar> */}
			<div className="container wide header-container">
				<div className="header">
					<div className="logocontainer">
						{
							<a href="/home">
								<img src={logo} alt="J-Story" />
							</a>
						}
					</div>
					{/* mobile view */}
					<div className="rightheader desktopview">
						<NavLink className="navheader" to="/about">
							{hm('navBar.about')}
						</NavLink>
						{
							<HashLink
							className="navheader"
							style={{ marginLeft: '20px' }}
							to="/home#partnerCardSection"
							scroll={(el) => {el.scrollIntoView({ behavior: 'smooth'});window.history.pushState({}, '', '/home')}}>
							{hm('navBar.partner')}
						   </HashLink>
						}

						<Button
							className="signupbtn"
							variant="contained"
							onClick={() => history.push(`/search-explore`)}
							color="primary"
							endIcon={<ArrowRightAltIcon />}
						>
							<span>{hm('navBar.explore')}</span>
						</Button>
						{/*<NavLink to={`/app/new-experience`} style={{ textDecoration: 'none' }}>
							<Button className="createNew" variant="contained" color="primary" endIcon={<AddIcon />}>
								<span>{hm('navBar.experience')}</span>
							</Button>
						</NavLink>
						*/}
						<span style={{ marginLeft: '40px' }}>
							<UserMenu />
						</span>
					</div>

					{/* mobile view */}
					<div className="rightheader mobileview">
						<div>
							<button className="bar-icon" type="button" onClick={handleOpen}>
								<MenuIcon />
							</button>
							<Modal
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								className={classes.modal}
								open={open}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500
								}}
							>
								<Fade in={open}>
									<div className={classes.paper}>
										<div onClick={handleClose} className="clear-icon">
											<ClearIcon />
										</div>
										<NavLink
											className="create-new-explore"
											to={`/search-explore`}
											style={{ textDecoration: 'none' }}
										>
											<Button
												className="signupbtn"
												variant="contained"
												// onClick={() => history.push(`/app/search-explore`)}
												color="primary"
												endIcon={<ArrowRightAltIcon />}
											>
												<span>{hm('navBar.explore')}</span>
											</Button>
										</NavLink>
										<NavLink
											className="create-new-explore"
											to={`/app/new-experience`}
											style={{ textDecoration: 'none' }}
										>
											<Button
												className="createNew"
												variant="contained"
												color="primary"
												endIcon={<AddIcon />}
											>
												<span>{hm('navBar.experience')}</span>
											</Button>
										</NavLink>
										<NavLink className="navheader" to="#">
											{hm('navBar.about')}
										</NavLink>
										<a className="navheader" href="mailto:infoi@jhn.ngo">
											{hm('navBar.partner')}
										</a>
										<span className="navheader">
											<UserMenu />
										</span>
									</div>
								</Fade>
							</Modal>
						</div>
					</div>
				</div>
			</div>
			{/* </IonToolbar>
			</IonHeader> */}
		</div>
	);
};

const css = `
	.rightheader.mobileview {
		display: none;
	}
	.MuiButton-contained:hover {
		box-shadow: unset;
		background-color:#031e6a;
	}
	.MuiButton-contained {
		box-shadow: unset;
	}

	.header-container{
			min-height: 100px;
			display: flex;
			align-items: center;
	}
	.bar-icon {
		background-color: transparent;
		color: #9e9e9e;
	}

	.bar-icon svg {
		width: 46px;
		height: 100px;
	}

	.clear-icon {
		display: flex;
		position: absolute;
		right: 19px;
		top: 19px;
		color: #9e9e9e;
	}
	.container {
		// min-height: 100px;
		// display: flex;
		// justify-content: space-between;
		// max-width: 1500px;
		max-width: 1765px;
		margin: 0 auto;
	}

	.rightheader {
		display: flex;
		align-items: center;
	}

	h2,
	p {
		margin: 0;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
	}

	li + li {
		margin-left: clamp(0.6rem, 5vw, 4rem);
	}

	.list-item {
		padding: 0.5rem 0;
		cursor: pointer;
		text-decoration: none;
		border-bottom: 3px solid transparent;
		color: var(--ion-color-secondary);
		font-weight: bold;
	}

	.list-item {
		&.active {
			color: var(--ion-color-primary);
			border-color: var(--ion-color-primary);
		}
	}

	.navigation {
		.brand {
			width: 9rem;

			* {
				width: 100%;
			}
		}

		.list-item {
			font-size: 1.125rem;
		}
	}

	#user-menu-checkbox {
		display: none;

		&:checked {
			& ~ label {
				color: red;

				.avatar {
					background-color: red;
				}
			}
		}
	}

	.avatar {
		margin-right: 0.5em;
		display: inline-block;
		vertical-align: middle;
		width: 2.5rem;
		height: 2.5rem;
		background-color: var(--ion-color-medium);
		border-radius: 50%;
	}
	.createNew {
		height: 40px;
		// width: 230px;
		border-radius: 8px;
		background-color: #fac807;
		color: #2a2a2a;
		font-family: 'Source Sans Pro';
		font-size: 15px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
		text-transform: capitalize;
		margin-left: 20px;
	}
	.createNew:hover {
		background-color: #c8a104;
		color: #2a2a2a;
	}

	.header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		color: #0849ff;
		font-size: 20px !important;
		text-transform: capitalize;
		line-height: inherit;
		font-weight: bold;
		word-wrap: break-word;
		font-size: 21px;
		font-family: 'Source Sans Pro', serif;
		text-decoration: none;
	}
	.navheader {
		color: #717c9c;
		text-decoration: none;
		font-size: 18px;
		font-weight: bold;
		line-height: 24px;
	}
	.signupbtn {
		color: #fff;
		background-color: #0849ff;
		margin-left: 20px;
		text-transform: none;
		height: 20px;
		// width: 56px;
		color: #ffffff;
		font-family: "Source Sans Pro";
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
		border-radius: 8px;
		// width: 132px;
		height: 40px;
		padding:auto 10px;
	}
	.signupbtn:hover {
		color: #fff;
		// background-color: #0849ff;
	}


	@media only screen and (max-width: 800px) {
		.navheader {
			margin-top: 15px;
			margin-left: 13px;
			color: #717c9c;
			text-decoration-line: none;
			font-size: 18px;
			font-weight: bold;
			line-height: 24px;
		}
		.rightheader.mobileview {
			display: block;
		}
		.navheader .list-item {
			margin-left: 0px;
		}
		.rightheader.desktopview {
			display: none;
		}

		.create-new-explore {
			margin-top: 20px;
		}
		.signupbtn ,
		.createNew{
		margin-left: 0px;
		  }
	}

	@media only screen and (max-width: 575px) {
		.logocontainer img {
			width: 80%;
		}
		// .createNew span span:nth-child(1) {
		// 	display: none;
		// }
	
		.createNew > span {
			align-items: center;
			justify-content: flex-start;
		}
		
		.signupbtn > span {
			align-items: center;
			justify-content: flex-start;
			align-items: center;
			justify-content: center;
		}
	}

	@media only screen and (max-width: 425px) {
		.createNew {
			// margin-left: 6px;
		}
	}
	
`;

export default Header;
