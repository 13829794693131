export function getUser() {
    const userString = localStorage.getItem('user');
    if (!userString) {
        return null;
    }
    return JSON.parse(userString);
}

export function setUser(user) {
    if (user) {
        localStorage.setItem("user", JSON.stringify(user));
    } else {
        localStorage.removeItem("user");
    }

}