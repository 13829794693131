import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { getItem } from '../../util/API';
import Item from '../../model/Item';
import info_icon from '../../assets/icons/info-icon.svg';
import styled from 'styled-components';

const ItemWidget=()=>
{
    let [item, setItem] = useState<Item>();
    const [spinner, setSpinner] = useState<boolean>(false);
    const location = useLocation();
    let params = queryString.parse(location.search);
    const item_id: string = params.id ? (Array.isArray(params.id) ? params.id[0] : params.id) : "";

    useEffect(() => {
      const fetchItem = async () => {
        setSpinner(true);
        const {
          data: { results },
        } = await getItem(item_id);
        setItem(results[0]);
        setSpinner(false);
      };
  
      fetchItem();
    
    }, []);

    return(
        !item ? null : 
        <Styled>
            {spinner && (<div className="spinner"/>)}

            <StyledImg src={item?.preview_url} />
            <Tooltip item={item} />
            
        </Styled>
    )
}
export default ItemWidget;

const Tooltip: React.FC<{
  item: Item;
}> = ({item})=>
{


  return (
    <TooltipStyled>
      <img src={info_icon}></img>
      <TooltipTextStyled>
        <TooltipTitleStyled>
          <a href={item.image_url}>{item.title}</a>
        </TooltipTitleStyled>
        <TooltipProviderStyled>
          
          {item.provider} | <a href={item.rights?.url}><img style={{width: "6vw"}} src={item.rights?.icon}/></a>

        </TooltipProviderStyled>
      </TooltipTextStyled>
    </TooltipStyled>

  )
}

const TooltipTextStyled = styled.span`
    visibility: hidden;
    width: max-content;
    color: black;
    background-color: white;
    opacity: 0.8;
    text-align: left;
    border-radius: 6px;
    font-size: 2vw;
    padding: 0.5vh 0.5vw;
    margin: -12vw 0;	
    /* Position the tooltip */
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      visibility: visible;
    }
`


const TooltipStyled = styled.p`
  color: white;
  background-color: black;
  opacity: 0.8;
  font-size: 4vw;

  width: 4vw;
  height: 4vw;
  border-radius: 4vw;
  line-height: 4vw;
  text-align: center;
  padding-left: 1px;
  margin-top: -5vh;

  &:hover ${TooltipTextStyled} {
    visibility: visible;
  }

  `

const TooltipTitleStyled = styled.span`
`

const TooltipProviderStyled = styled.span`
`


const Styled = styled.div`
    width: 95vw;
    height: 95vh;

`;

const StyledImg = styled.img`
    width: inherit;
    object-fit: cover;
    height: inherit;

`