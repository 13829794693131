import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Item from '../../model/Item';
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from 'react-router-dom';
import useTranslations from '../../util/useTranslation';
import {
  IonItem,
  IonList,
  useIonPopover,
  IonLabel,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import NoImg from '../../assets/NoImage.png';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Container, Row, Col } from 'reactstrap';
import { useEffect, useState } from 'react';

const PopoverList: React.FC<{
  onHide: () => void;
  translate: any[];
  onItemDelete?: () => void;
  item: Item;
}> = ({ onHide, translate, item, onItemDelete }) => {
  const [t] = translate;
  return (
    <div className='xyz'>
      <StyledMenu>
        <IonList lines='none'>
          <IonItem
            className='ionItem'
            onClick={() => (onItemDelete ? onItemDelete() : null, onHide())}
          >
            {t('menu.delete')}
          </IonItem>
        </IonList>
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled.div`
  .sc-ion-popover-md-h {
    --width: 200px !important;
  }
  a {
    text-decoration: none;
    color: var(--ion-color-secondary);
  }
`;
const CollectionList: React.FC<{
  key?: any;
  title?: string;
  colId: any;
  cardClicked: (clicked: any, data: any, id: any) => void;
  deleteItem?: (id: any) => void;
  totalDatalength: number;
  tags: string[];
  items?: Item[];
}> = ({
  title,
  cardClicked,
  key,
  colId,
  items,
  deleteItem,
  totalDatalength,
  tags
}) => {
  const [showMore, setshowMore] = useState<boolean>(false);
  const [data, setdata] = useState<any>([]);
  const [t] = useTranslations('collections');
  const [clickedItem, setClickedItem] = useState<any>();
  const [itemId, setItemId] = useState<any>();

  const CardClick = (itemList, id) => {
    cardClicked(true, itemList, id);
  };

  useEffect(() => {
    if (items !== undefined) {
      if (items.length >= 8) {
        setdata(items.slice(0, 8));
      } else {
        setdata(items);
      }
    }
    console.log('KEY IS', colId);
  }, [items]);

  const ShowMoredata = () => {
    setdata(items);
    setshowMore(true);
  };
  const _onCardClick = (idx, items, itemId) => {
    if (idx == 7) {
      showMore ? CardClick(items, itemId) : ShowMoredata();
    } else {
      CardClick(items, itemId);
    }
  };
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onItemDelete: () => (deleteItem ? deleteItem(clickedItem.id) : null),
    item: clickedItem,
    translate: useTranslations('item'),
  });
  return (
    <div className='main-content-wrapper'>
      <div className='desktop-view'>
        <div className='collection-heading container'>
          <Typography variant='h6' display='block' gutterBottom>
            <NavLink to={{pathname:`/app/collectionDetail/${encodeURIComponent(colId)}`,state:{collectionId:colId}}} className='title_icon'>
              {title}
              <ArrowForwardIcon className='right-arrow select' />
            </NavLink>
          </Typography>
          <div className='collectionTag'>
            <Typography
              className='para'
              variant='caption'
              display='block'
              gutterBottom
            >
               {t('collectionincludes.text', {
                count: totalDatalength
              })}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='tag-title'>{t('collectiontag.tags')} :</span>
              <div className='tag-list'>
                {tags?.map((tag) => (
                  <span>{tag}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='tablet-view'>
        <div className='collection-heading container'>
          <div>
            <Typography variant='h6' display='block' gutterBottom>
              <NavLink to={{pathname:`/app/collectionDetail/${encodeURIComponent(colId)}`,state:{collectionId:colId}}} className='title_icon'>
                {title}
                <ArrowForwardIcon className='right-arrow select' />
              </NavLink>
            </Typography>
            <Typography
              className='para'
              variant='caption'
              display='block'
              gutterBottom
            >
              {t('collectionincludes.text', {count: totalDatalength})}
            </Typography>
          </div>
          <div className='collectionTag'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='tag-title'>{t('tags')} :</span>
              <div className='tag-list'>
                {tags?.map((tag) => (
                  <span>{tag}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container className='row-content'>
        <Row>
          {data.map((item, index) => (
            <Col xs='auto'>
              <Card
                onClick={() => {
                  _onCardClick(index, items, item.id);
                  setItemId(item.id);
                }}
                className='cards'
              >
                <div
                  style={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                    border: ' none',
                  }}
                >
                  <CardMedia
                    className='media'
                    image={item.preview_url ? item.preview_url : NoImg}
                    title='Profile'
                  />
                  {index == 7 ? (
                    showMore == true ? null : (
                      <span className='media-last-card'>
                        {totalDatalength == 8
                          ? null
                          : // totalDatalength-7
                            `+${totalDatalength - 7}`}
                      </span>
                    )
                  ) : null}
                  <CardActions className='viewMoreBtn'>
                    <IconButton
                      onClick={(e) => {
                        present({
                          event: e.nativeEvent,
                        });
                        e.stopPropagation();
                        setClickedItem(item);
                      }}
                      aria-label='settings'
                      className='viewMoreBtnItem'
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </CardActions>
                </div>
                <CardHeader
                  className='cardTitle'
                  title={item.title}
                  // subheader={item.creator?item.creator:"Not Mentioned"}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CollectionList;
