import { IonModal } from '@ionic/react';
import styled from 'styled-components';

export const InviteModal = styled(IonModal)`
  --height: 425px;
  --width: 500px;
  justify-content: center !important;
  padding: 40px 0;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .canclebtn {
    text-align: right;
    width: 96%;
    padding: 17px 0px 0 0;
    margin-bottom: -29px;
  }

  .canclebtn svg {
    fill: #8b7f9e;
    cursor: pointer;
  }

  .titleInvite {
    width: 66%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
  }
  .titleInvite h3 {
    font-weight: 700;
    color: var(--ion-color-primary);
    font-size: 24px;
  }

  .field-wrap {
    width: 80%;
    margin: 10px auto 66px;
  }

  .createInvite {
    height: 40px;
    width: 230px;
    border-radius: 5px;
    background-color: #fac807;
    color: #2a2a2a;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;
    left: 2%;
  }
  .createInvite:hover {
    background-color: #fac807;
    color: #2a2a2a;
  }

  .ionRow {
    margin: 3% auto;
  }
  .link {
    font-size: 16px !important;
    text-decoration: underline;
    color: #0849ff;
  }
  .contentEmailList {
      background-color: #ffffff;
      height: 190px !important;
      overflow: auto !important;
  }
  .color-black {
    color: #000000;
  }
`;