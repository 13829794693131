import React from 'react';
import ExperienceList from './ExperienceList/ExperinceListDesktop/ExperienceList';
import ExperienceListMobile from './ExperienceList/ExperinceListMobile/ExperinceListMobile';
import ExperienceMap from './ExperienceMap/ExperienceMap';

const ExperienceBrowse: React.FC<{ data?: any; ds?: any; item?: any; allData?: any; handleBboxdata?: any; searchData?: any; isShowExperienceList?: boolean; isShowExperienceListMobile?: boolean; isShowExperienceMap?: boolean; isLocationMap?: boolean; hideTitle?: boolean; zoom?: number}> = ({
  isShowExperienceList,
  isShowExperienceListMobile,
  isShowExperienceMap,
  data,
  ds,
  item,
  allData,
  handleBboxdata,
  searchData,
  isLocationMap,
  hideTitle,
  zoom
}) => {
  
  return (
    <>
      {isShowExperienceList && <ExperienceList data={data} ds={ds} item={item} ></ExperienceList>}
      {isShowExperienceListMobile && <ExperienceListMobile data={data} ds={ds} ></ExperienceListMobile>}
      {isShowExperienceMap && <ExperienceMap isLocationMap={isLocationMap} allData={allData} handleBboxdata={handleBboxdata} searchData={searchData} data={data} hideTitle={hideTitle} zoom={zoom}></ExperienceMap>}
    </>
  );
};

export default ExperienceBrowse;