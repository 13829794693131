import Button from '@material-ui/core/Button';
import ListIcon from '@material-ui/icons/List';
import RoomIcon from '@material-ui/icons/Room';
import React from "react";
import useTranslations from "../../util/useTranslation";
import FilterItem from '../search/FilterItem';
import { StyledFilterToolbar } from './FilterToolbarStyled';

const FilterToolbar: React.FC<{ location?: any, mapLocation?: any, map?: any, handleOpen?: any, handleMap?: any }> = ({
  location,
  mapLocation,
  map,
  handleOpen,
  handleMap
}) => {
  const [ds] = useTranslations('dashboard');
  const [t] = useTranslations('search');
  const [h] = useTranslations('home');

  const {
    filter: filters,
  } = t('searchexplore.filterslist', {
    returnObjects: true,
  }) as {
    filter: Array<{ id: string; text: string }>;
  };

  return (
    <div>
      <StyledFilterToolbar>
        <div className='container'>
          <div className='searchBar'>
            <div className='search-inner-wrap'>
              {location ? 
                ds('location.exp', { location: location?.title })
                : h('footer.experience')
              }
            </div>
            <div className='filterview' id='search-explore-tag'>
              <div className='filter-wrapper d-flex'>
                {filters.map((item) => (
                  <div className='filter'>
                    <FilterItem
                      text={item.text}
                      value={item.id}
                      filterKey='type'
                      explore={true}
                    />
                  </div>
                ))}
              </div> 
              <div className='d-flex'>
                 
                <Button className='morefilters' onClick={() => handleOpen(true)}>
                  {t('searchexplore.morefilter')}
                </Button> 
                <div className='map-list-btn desktop'>
                  {map == false ? (
                    <Button
                      onClick={() => handleMap(true)}
                      className='switchbutton show-map'
                    >
                      {t('searchexplore.map')}
                      <RoomIcon />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        mapLocation(null);
                        handleMap(false);
                      }}
                      className='switchbutton list-view'
                    >
                      {t('searchexplore.list')}
                      <ListIcon />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledFilterToolbar>
    </div>
  );
}

export default FilterToolbar;