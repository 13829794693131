import { IonModal } from '@ionic/react';
import styled from 'styled-components';

export const DeleteModal = styled(IonModal)`
--height: 265px;
--width: 425px;
justify-content: center !important;
padding: 40px 0;
overflow: auto;
::-webkit-scrollbar {
    display: none;
}

.canclebtn {
    padding-top: 17px;
}
.canclebtn svg {
    fill: #8b7f9e;
    cursor: pointer;
}

.title {
    width: 80%;
    margin-bottom: 20px;
    margin-left: 30px;
}
.title h3 {
    font-weight: 700;
    color: var(--ion-color-primary);
    font-size: 24px;
}
.field-wrap {
    width: 80%;
    margin: 25px auto 66px;
    display: flex;
    justify-content: space-between;
}
.createNew {
    height: 40px;
    width: 150px;
    border-radius: 5px;
    background-color: #fac807;
    color: #2a2a2a;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: capitalize;
  }
.createNew:hover {
    background-color: #fac807;
    color: #2a2a2a;
}
`