import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { getCurrentUser } from './API';
import { setUser } from '../components/authentication/CurrentUser';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    if (!tokenString) {
        return null;
    }
    const userToken = JSON.parse(tokenString);

    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (!userToken) {
      localStorage.removeItem('token');
      setUser(null);

    } else {
      localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken);  
      getCurrentUser(userToken.access_token).then(({data}) => {
        setUser(data);
      });
    }
  };

  return [token,saveToken]
    
}
// TODO: make this one as a default (need to resolve Cross-domain issues (for localhost)
export function useTokenCookies() {

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const getToken = () => {
    const tokenString = cookies.token;
    if (!tokenString) {
      return null;
    }
      const userToken = JSON.parse(tokenString);
  
      return userToken;
    };
    
  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if (!userToken) {
      //localStorage.removeItem('token');
      removeCookie('token');
      setUser(null);
      setToken(null);  

    } else {
      //localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken); 
      setCookie('token', JSON.stringify(userToken), {path: '/', sameSite: 'none', secure: true}) 
      getCurrentUser(userToken).then(({data}) => {
        setUser(data);
      });
    }
  };

  return [token, saveToken]
    
}