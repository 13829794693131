import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import React, { useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { getHoistingItem, getInvitedAsGuestItem, getGuestEmailItem } from '../../util/API';
import Image2 from '../../assets/banner/read.png';
import Image1 from '../../assets/banner/support.png';
import Arrow from '../../assets/icons/arrow.svg';
import ArrowRed from '../../assets/icons/arrow-red.svg';
import ArrowYellow from '../../assets/icons/arrow-yellow.svg';
import useTranslations from '../../util/useTranslation';
import useToken from '../../util/useToken';
import {getMyExperiences} from '../../util/API';
import Footer from '../common/Footer';
import Header from '../common/Header';
import CreateSedarForm from './SederForm/sedarForm';
import CreateInviteForm from './InviteGuestForm/inviteGuestForm';
import Hosting from './HostingEvents/Hosting';
import InviteAsGuest from './InviteAsGuestEvents/GuestInvite';
import { Styled } from './styled';

const PassoverEvents = () => {
  const [t] = useTranslations('passover');
  let [token,setToken] = useToken();
  const [open, setOpen] = React.useState(false);
  const [openInvitation, setOpenInvitation] = React.useState(false);
  const [hostingData, setHostingData] = React.useState([{}]);
  const [inviteGuestList, setInviteGuestList] = React.useState([{}]);
  const [guestEmailList, setGuestEmailList] = React.useState([{}]);
  const [inviteHosting, setInviteHosting] = React.useState<any>();
  const [inviteAsGuest, setInviteAsGuest] = React.useState<any>();
  const [exps, setExps] = useState<any>([]);


  const toggleInviteHosting = (id: number) => {
    if (isInviteShownHosting(id)) {
      setInviteHosting(null);
    } else {
      setInviteHosting(id);
    }
  };
  const isInviteShownHosting = (id: number) => {
    return inviteHosting === id ? true : false;
  };

  const toggleInviteAsGuest = (id: number) => {
    if (isInviteShownAsGuest(id)) {
      setInviteAsGuest(null);
    } else {
      setInviteAsGuest(id);
    }
  };
  const isInviteShownAsGuest = (id: number) => {
    return inviteAsGuest === id ? true : false;
  };

  const addInvite = (tags) => {
    const payload = {
      id: 3,
      email: tags[0].label,
    };
    guestEmailList.push(payload);
    setOpenInvitation(false);
  };

  const submitForm = (form) => { };
  const handleInviteClose = () => {
    setOpenInvitation(false);
  };

  // Get Hoisting Event
  useEffect(() => {
    // setSpinner(true)  
    const fetchHoistingEvent = async () => {
      const results: any = await getHoistingItem();
      // setSpinner(false)
      if (results.length) {
        setHostingData(results);
      }
    };
    fetchHoistingEvent();
  }, []);

  // Get Invited As Guest Event
  useEffect(() => {
    // setSpinner(true)  
    const fetchInvitedAsGuestEvent = async () => {
      const results: any = await getInvitedAsGuestItem();
      // setSpinner(false)
      if (results.length) {
        setInviteGuestList(results);
      }
    };
    fetchInvitedAsGuestEvent();
  }, []);

  // Get Guest Email
  useEffect(() => {
    // setSpinner(true)
    const fetchGuestEmail = async () => {
      const results: any = await getGuestEmailItem();
      // setSpinner(false)
      if (results.length) {
        setGuestEmailList(results);
      }
    };
    fetchGuestEmail()
  }, []);

  // Get Hoisting Event
  useEffect(() => {
    // setSpinner(true)  
    const fetchExperiences = async (exType: any) => {
      const {
        data: { experiences },
      } = await getMyExperiences(token, exType);
      setExps(experiences);
  
    };
    fetchExperiences('tour');
  }, []);

  return (
    <div className='container wide'>
      <Styled>
        {/* {spinner == true ? (
        <div className='spinner'>
          <IonSpinner color='primary' />
        </div>
      ) : ( */}
        <div>
          {/* header */}
          <Header>
            <h2>{t('header.headline')}</h2>
            <Button
              className='createNew'
              variant='contained'
              onClick={() => setOpen(true)}
              color='primary'
              startIcon={
                <img
                  src={Arrow}
                  alt='Arrow'
                  style={{ width: '16.14px', height: '16px' }}
                />
              }
            >
              {t('header.button')}
            </Button>
          </Header>

          {/* Hosting Data */}
          <Hosting data={hostingData} guestEmail={guestEmailList} openInvitation={setOpenInvitation}/>

          {/* Invite As Guest List */}
          <InviteAsGuest guestData={inviteGuestList} guestEmail={guestEmailList} openInvitation={setOpenInvitation} />

          <Footer />
          {/*  */}
        </div>

        {/* Create seder form */}
        {open && (
          <CreateSedarForm experiences={exps} isOpen={open} setOpenModal={setOpen} />
        )}

        {/* Create Invite Form */}
        {openInvitation && (
          <CreateInviteForm isOpen={openInvitation} setOpenInvitationModal={handleInviteClose} addGuestInvite={addInvite} />
        )}

      </Styled>
    </div>
  );
};

export default PassoverEvents;